import React from 'react'
import Chip from '@material-ui/core/Chip'

export const Tags = ({
  tags,
  color = 'default',
  className = '',
  onDelete = undefined,
  clickable = false,
  disabled = false,
}) => {
  return tags.length
    ? tags.map((d) => (
        <Chip
          key={d}
          disabled={disabled}
          variant="outlined"
          color={color}
          size="small"
          label={d}
          clickable={clickable}
          className={className}
          style={{ marginRight: '0.25rem' }}
          onDelete={() => onDelete(d)}
        />
      ))
    : null
}

import { omit } from 'lodash'
import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { DatagroupResponse, DatagroupProgressBar } from 'api/generated'

import { RequestStatus } from 'utils/constants'

const prefix = 'scenarios'

interface IGroups {
  byId: { [key: string]: DatagroupResponse }
}

interface IGroupsSynthProgress {
  byId: { [key: string]: DatagroupProgressBar }
}

interface IScenariosState {
  groups: IGroups
  groupsSynthProgress: IGroupsSynthProgress
  synthStatus: RequestStatus
  synthMessage?: string
  positions: any[]
  groupsStatus: RequestStatus
}

const initialState: IScenariosState = {
  groups: {
    byId: {},
  },
  groupsSynthProgress: { byId: {} },
  synthStatus: RequestStatus.Idle,
  positions: [],
  groupsStatus: RequestStatus.Idle,
}

const scenarioReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    didFetchGroups: (
      state,
      { payload }: PayloadAction<{ groups: DatagroupResponse[] }>
    ) => {
      const byId = payload.groups.reduce((acc: any, group) => {
        acc[group.id] = group
        return acc
      }, {})
      const positions = payload.groups.map((group) => group.id)
      state.groups.byId = byId
      state.positions = positions
    },
    createGroup: (state, { payload }: PayloadAction<{ title: string }>) => {
      state.groupsStatus = RequestStatus.Loading
    },
    duplicateGroup: (state, { payload }: PayloadAction<{ id: any }>) => {
      state.groupsStatus = RequestStatus.Loading
    },
    didCreateGroup: (
      state,
      { payload }: PayloadAction<{ group: DatagroupResponse }>
    ) => {
      const { group } = payload
      state.groupsStatus = RequestStatus.Succeeded
      state.groups.byId = { ...state.groups.byId, [group.id]: group }
      state.positions.push(group.id)
    },
    didGroupUpdate: (
      state,
      { payload }: PayloadAction<{ group: DatagroupResponse }>
    ) => {
      const { group } = payload
      state.groups.byId[group.id] = group
    },
    didDeleteGroup: (state, { payload }: PayloadAction<{ id: any }>) => {
      state.groups.byId = omit(state.groups.byId, payload.id)
      state.positions = state.positions.filter(
        (position) => position !== payload.id
      )
    },
    didUpdateGroupPosition: (state, { payload }) => {
      const { initPosition, targetPosition } = payload
      state.positions.splice(
        targetPosition,
        0,
        state.positions.splice(initPosition, 1)[0]
      )
    },

    synthesizeData: (state) => {
      state.synthMessage = ''
      state.synthStatus = RequestStatus.Loading
    },
    stopSynthesizeData: (state) => {
      state.synthStatus = RequestStatus.Idle
      state.groupsSynthProgress.byId = {}
      Object.keys(state.groups.byId).forEach((id) => {
        state.groups.byId[id].status = 'STATUS_STOPPED'
      })
    },
    fetchSynthesizeData: (state) => {
      state.synthMessage = ''
      state.synthStatus = RequestStatus.Loading
    },
    didSynthesizeDataEmpty: (state) => {
      state.synthStatus = RequestStatus.Idle
    },
    didFetchSynthesizeProgress: (state, { payload }) => {
      state.groupsSynthProgress.byId = payload.dataById
    },
    didSynthesizeData: (state, { payload }) => {
      state.synthStatus = RequestStatus.Succeeded
      payload.synthGroups.forEach((group: DatagroupProgressBar) => {
        state.groups.byId[group.datagroup_id].status = 'STATUS_FINISHED'
      })
    },
    didSynthesizeDataFail: (state) => {
      state.synthStatus = RequestStatus.Failed
      state.synthMessage = 'We were unable to synthesize, please try again'
      state.groupsSynthProgress.byId = {}
    },
  },
})

const fetchGroups = createAction(`${prefix}/fetchGroups`)
const updateGroupPosition = createAction(`${prefix}/updateGroupPosition`)
const deleteGroup = createAction(`${prefix}/deleteGroup`)
const updateGroup = createAction(`${prefix}/updateGroup`)

const exportGroupFile = createAction(`${prefix}/exportGroupFile`)

export const scenarioActions = {
  ...scenarioReducer.actions,
  updateGroupPosition,
  deleteGroup,
  updateGroup,
  fetchGroups,
  exportGroupFile,
}

export default scenarioReducer.reducer

import React, { FC, useState, useEffect, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as api from 'legacy/utils/services/permissions'
// import { IPermission } from './typings'
import { PermissionsTable } from './PermissionsTable'

const useStyles = makeStyles({
  body: {},
  permissions: {},
  table: {},
})

interface IPermissionsProps {
  project: any
  projectId?: number
  handleLoading: any
  handleSetActions: any
  showNotification: any
  hideModal: any
}

export const Permissions: FC<IPermissionsProps> = memo(
  ({
    project,
    projectId: datasetId,
    handleSetActions,
    handleLoading,
    showNotification,
    hideModal,
  }) => {
    const classes = useStyles()
    const [permissionsData, setPermissionsData] = useState([])
    const [requestsMap, setRequestsMap] = useState({})

    const handleChange = (newDate: any) => {
      const { values, item, index } = newDate

      setPermissionsData((prevData) => {
        const data = [...prevData]
        data[index] = {
          ...item,
          permission: values.value,
        }
        return data
      })

      setRequestsMap((prevMap) => ({
        ...prevMap,
        [item.id]: {
          ...item,
          permission: values.value,
        },
      }))
    }

    const acceptRequest = (item: any, index: number) => {
      handleChange({ values: { value: item.permission }, item, index })
    }

    const deletePermission = (item: any, index: number) => {
      setPermissionsData((prevData) =>
        prevData.filter((_, itemIdex) => itemIdex !== index)
      )
      const method =
        item.status === 'Pending'
          ? api.deleteProjectPermissionsRequest
          : api.deleteProjectUserPermission

      method({ datasetId, id: item.id })
        .then(() => {
          showNotification({
            message:
              item.status === 'Pending'
                ? 'Permission request was deleted'
                : 'Permission was deleted',
            severity: 'warning',
          })
        })
        .catch(() => {
          // showNotification({
          //   message: 'Coudn`t delete permission',
          //   severity: 'error',
          // })
        })
    }

    const resendInvite = (item: any) => {
      const invite = { emails: [item.user_email], permission: item.permission }

      api
        .shareDatasetWithEmail({ datasetId, invites: invite })
        .then(() => {
          showNotification({
            message: 'Permission invite was resend',
            severity: 'warning',
          })
        })
        .catch(() => {
          showNotification({
            message: 'Coudn`t send permission',
            severity: 'error',
          })
        })
    }

    const getRequests = () => {
      return Object.values(requestsMap)
    }

    const handleSubmit = () => {
      const changes = getRequests().filter((el: any) => el.permission !== -1)

      const tasksQue = Promise.all([
        ...changes.map(({ user_email: userMail, permission }: any) => {
          const invites = { emails: [userMail], permission }
          return api.shareDatasetWithEmail({ invites, datasetId })
        }),
        ...changes.map(({ status, id }: any) => {
          if (status === 'Pending') {
            return api.deleteProjectPermissionsRequest({ datasetId, id })
          }
          return null
        }),
      ])

      handleLoading(true)
      tasksQue
        .then(() => {
          showNotification({
            message: 'Permissions was successfully granted.',
            severity: 'success',
          })
        })
        .catch((err) => {
          showNotification({
            message: err.message,
            severity: 'error',
          })
        })
        .finally(() => {
          hideModal()
          handleLoading(false)
        })
    }

    useEffect(() => {
      handleLoading(true)
      const ownerRow: any = {
        id: 0,
        user_email: project.created_by,
        permission: 2,
        status: '',
      }

      const taskQue = [
        api.getProjectUsersWithPermissions({ datasetId }),
        api.getProjectPermissionsRequests({ datasetId }),
      ]

      Promise.all(taskQue)
        .then(([permissionsDataSet = [], requestsDataSet = []]: any) => {
          const combinedData = [
            ...permissionsDataSet.map((el: any) => {
              return {
                ...el,
                status: 'Invitation accepted',
              }
            }),
            ...requestsDataSet.map((el: any) => {
              return {
                ...el,
                user_email: el.user_email || '',
                status: 'Pending',
              }
            }),
          ]

          setPermissionsData(combinedData)
        })
        .catch((err) => {
          setPermissionsData([ownerRow])
          showNotification({
            message: err.error_message || 'Failed loading resources',
            severity: 'success',
          })
        })
        .finally(() => {
          handleLoading(false)
        })
    }, [])

    useEffect(() => {
      handleSetActions({
        submit: { action: handleSubmit, label: 'Upgrade', disabled: false },
      })
    }, [permissionsData])

    return (
      <div className={classes.permissions}>
        <div className={classes.body}>
          <PermissionsTable
            data={permissionsData}
            owner={project.created_by}
            onChange={handleChange}
            rowActions={{ resendInvite, deletePermission, acceptRequest }}
          />
        </div>
      </div>
    )
  }
)

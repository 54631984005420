import React from 'react'
import { makeStyles } from '@material-ui/core'

export const RegularCell: React.FunctionComponent<{
  value: string
}> = React.memo(
  ({ value }: { value: string }) => {
    const classes = useStyles()

    return (
      <div className={classes.nameColumn}>
        {value && value.toLocaleString()}
      </div>
    )
  },
  (prev, next) => prev.value === next.value
)

const useStyles = makeStyles((theme) => ({
  nameColumn: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    padding: theme.spacing(1.9),
  },
}))

/* colors to use on visual components start */
const colors = [
  '#515fe7',
  '#23a956',
  '#ff15c2',
  'rgb(255, 192, 0)',
  'rgb(148, 163, 222)',
]

const randomColor = (function () {
  const golden_ratio_conjugate = 0.618033988749895
  let h = Math.random()

  const hslToRgb = function (h, s, l) {
    let r
    let g
    let b

    if (s === 0) {
      r = g = b = l
    } else {
      function hue2rgb(p, q, t) {
        if (t < 0) t += 1
        if (t > 1) t -= 1
        if (t < 1 / 6) return p + (q - p) * 6 * t
        if (t < 1 / 2) return q
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
        return p
      }

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s
      const p = 2 * l - q
      r = hue2rgb(p, q, h + 1 / 3)
      g = hue2rgb(p, q, h)
      b = hue2rgb(p, q, h - 1 / 3)
    }

    return `#${Math.round(r * 255).toString(16)}${Math.round(g * 255).toString(
      16
    )}${Math.round(b * 255).toString(16)}`
  }

  return function () {
    h += golden_ratio_conjugate
    h %= 1
    return hslToRgb(h, 0.5, 0.6)
  }
})()

const correlation_colors = (number, sign = 'pos') =>
  `hsl(${sign === 'pos' ? 127 : 0}, 57%, ${80 - Math.round(number * 40)}%)`

for (let i = 0; i < 100; i++) {
  colors.push(randomColor())
}
/* colors to use on visual components end */

export { colors, correlation_colors }

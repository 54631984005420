/* tslint:disable */
/* eslint-disable */
/**
 * Synthesized Backend API
 * This is a current deployed API documentation on `synthesized/synthesized-web`. You can try in out with the `Authorization: {{auth_token}}` you\'ve received from login. <p />Backend version: not-specified <p />Frontend version: not-specified <p />
 *
 * The version of the OpenAPI document: v1.1.3
 * Contact: mark@synthesized.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Version } from '../modelPkg';
/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get version information
         * @summary Get version information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         * Get version information
         * @summary Get version information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVersionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Version>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVersionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         * Get version information
         * @summary Get version information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVersionGet(options?: any): AxiosPromise<Version> {
            return localVarFp.apiVersionGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * Get version information
     * @summary Get version information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public apiVersionGet(options?: any) {
        return VersionApiFp(this.configuration).apiVersionGet(options).then((request) => request(this.axios, this.basePath));
    }
}

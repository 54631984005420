import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Typography, spacing } from 'legacy/shared'
import User from 'legacy/components/Account/User'

import { Header } from 'layouts/Auth/Header'
import { Layout } from 'layouts/Auth/Layout'

import { Routes } from 'legacy/utils'

import { forgotPasswordActions } from 'services/auth/forgotPassword/reducer'
import { getForgotPasswordStatus } from 'services/auth/forgotPassword/selectors'

import { Confirmation } from './ForgotPasswordConfirmation'
import ForgotPasswordForm from './ForgotPasswordForm'

const useStyles = makeStyles({
  linkContainer: {
    marginTop: `${spacing.gutterLarge}px`,
  },
})

interface ISelectedProps {
  status: ReturnType<typeof getForgotPasswordStatus>
}

type DispatchProps = typeof mapDispatchToProps

type IForgotPasswordProps = ISelectedProps & DispatchProps

export const ForgotPasswordPage: React.FC<IForgotPasswordProps> = ({
  status,
  resetForm,
}) => {
  const classes = useStyles()

  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [resetForm])

  return (
    <Layout>
      <>
        {status ? (
          <>
            <Header heading="Email sent!" subTitle={`for ${status.email}`} />
            <Confirmation />
          </>
        ) : (
          <>
            <Header
              heading="Reset Your Password"
              subTitle="Enter your email below and we'll send you a link to reset your password."
            />
            <ForgotPasswordForm />
          </>
        )}

        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.linkContainer}
        >
          <Typography size="captionBold">
            {!User.isAuth() ? (
              <Link to={Routes.SignIn()}>Return to Sign In</Link>
            ) : (
              <Link to={Routes.Profile()}>Return to Profile</Link>
            )}
          </Typography>
        </Grid>
      </>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  status: getForgotPasswordStatus,
})

const mapDispatchToProps = {
  resetForm: forgotPasswordActions.resetForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)

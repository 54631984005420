import React from 'react'
import { connect } from 'react-redux'

import HeaderComponent from 'layouts/Header'

import { Routes, pushRoute } from '../../../utils'
import { cancelBuildSandbox } from './actions'

export const SandboxBuildHeader = (props) => {
  const { cancelBuildSandbox } = props

  const onCancel = () => {
    cancelBuildSandbox()
    pushRoute(Routes.CleanRooms())
  }

  return (
    <HeaderComponent title="Build Data Clean Room" onClickBack={onCancel} />
  )
}

const mapDispatchToProps = { cancelBuildSandbox }

export default connect(null, mapDispatchToProps)(SandboxBuildHeader)

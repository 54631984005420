import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isArray } from 'lodash'
import { ExcelIcon } from 'pages/TestingSuites/icons'
import { colors } from 'legacy/shared'
import {
  IFilePreview,
  SUITE_FILE_TYPES,
} from 'services/createTestingSuite/types'
import { FilePreview } from 'components/UploadFileForm/FilePreview'
import { DataUnitForm } from './DataUnitForm'

interface IDataUnitProps {
  sources:
    | { name: string; size: number }
    | { name: string; size: number }[]
    | File[]
  handleDeleteFile: (file: IFilePreview | File) => void
  isShown: boolean
  handleDrop: (files: File[]) => void
  handleClose: (arg: string) => void
  multipleForm: boolean
  fileType?: SUITE_FILE_TYPES
}

export const DataUnit: FC<IDataUnitProps> = ({
  sources,
  handleDeleteFile,
  isShown,
  handleDrop,
  handleClose,
  multipleForm,
  fileType,
}) => {
  const classes = useStyles()
  const src = isArray(sources) ? sources : [sources]

  return (
    <>
      <Box>
        {
          // @ts-ignore
          src.map(
            (el: File | IFilePreview) =>
              el.name && (
                <FilePreview
                  key={`${el.name}_${
                    'updated_at' in el ? el.updated_at : el.size
                  }`}
                  file={el as unknown as File}
                  icon={<ExcelIcon />}
                  handleDeleteFile={handleDeleteFile}
                  className={classes.filePreview}
                />
              )
          )
        }
      </Box>
      {isShown && (
        <DataUnitForm
          fileType={fileType}
          handleDrop={handleDrop}
          handleClose={handleClose}
          multiple={multipleForm}
        />
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  filePreview: {
    backgroundColor: colors.hover,
    padding: theme.spacing(1),
  },
}))

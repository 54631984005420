import React from 'react'

export const ProfileIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M36 27.17C35.2614 29.7836 33.6896 32.0847 31.5237 33.7233C29.3577 35.362 26.716 36.2487 24 36.2487C21.284 36.2487 18.6423 35.362 16.4763 33.7233C14.3104 32.0847 12.7386 29.7836 12 27.17C15.5703 24.8618 19.7494 23.6742 24 23.76C28.2506 23.6742 32.4297 24.8618 36 27.17Z" />
    <path d="M24 21.76C26.7614 21.76 29 19.5214 29 16.76C29 13.9986 26.7614 11.76 24 11.76C21.2386 11.76 19 13.9986 19 16.76C19 19.5214 21.2386 21.76 24 21.76Z" />
  </svg>
)

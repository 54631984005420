export enum RequestStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export enum TaskStatus {
  STATUS_QUEUED = 'STATUS_QUEUED',
  STATUS_STARTED = 'STATUS_STARTED',
  STATUS_FINISHED = 'STATUS_FINISHED',
  STATUS_STOPPED = 'STATUS_STOPPED',
  STATUS_FAILED = 'STATUS_FAILED',
}

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import User from 'legacy/components/Account/User'

import { signInActions } from 'services/auth/signIn/reducer'
import { profileActions } from 'services/profile/reducer'
import { globalActions } from 'services/global/reducer'

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  useEffect(() => {
    rest.fetchProfileImage()
    rest.fetchUserLimits()
    rest.refreshToken()
  }, [])
  return (
    <Route
      {...rest}
      render={(props) => {
        if (User.isAuth()) {
          // @ts-ignore
          if (window.Intercom) {
            // @ts-ignore
            window.Intercom('boot', {
              app_id: 'h40g61x3',
              email: User.email,
              user_id: User.email,
              created_at: Date.now(),
            })
          }
          // @ts-ignore
          // if (!window.Usersnap) {
          //   if (
          //     ['user@synthesized.io', 'community@synthesized.io'].includes(
          //       User.email
          //     )
          //   ) {
          //     // @ts-ignore
          //     window.onUsersnapCXLoad = function (api) {
          //       // @ts-ignore
          //       window.Usersnap = api
          //       api.init()
          //     }
          //     let script = document.createElement('script')
          //     // @ts-ignore
          //     script.defer = 1
          //     script.src =
          //       'https://widget.usersnap.com/global/load/df1a1ae3-a677-4751-b00f-e9f179452664?onload=onUsersnapCXLoad'
          //     document.getElementsByTagName('head')[0].appendChild(script)
          //   }
          // }

          return Component
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

const mapDispatchToProps = {
  fetchProfileImage: profileActions.fetchImage,
  fetchUserLimits: globalActions.getUserLimits,
  refreshToken: signInActions.refreshToken,
}

export default connect(null, mapDispatchToProps)(PrivateRoute)

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Box from '@material-ui/core/Box'
import { Button } from 'UIkit/Button/Button'

import {
  getProjectRunningTaskListState,
  getMaxModelsRunning,
} from 'services/global/selectors'

import _ from 'legacy/components/App/Constants'

import User from '../Account/User'
import { Typography, Progress, Spinner, SelectSimple } from '../../shared'
import { DisplayColumns } from './DisplayColumns'
import {
  getTargetColumnState,
  getDisplayColumnsState,
} from './projectSettings/selectors'
import { startTraining, stopTraining } from './actions'
import { updateBiasSetting } from './projectSettings/actions'
import {
  getProjectDataState,
  getProjectStatusState,
  getProjectPreprocessingStatusState,
  getProjectPreviewIterationState,
  getEstimatedTimeState,
  getIsSettingEnable,
  getBiasProgress,
  getQualityProgress,
  getProjectPermissionsForUser,
} from './projectSelectors'

import EntityAnnotation from './EntityAnnotation'

import { statuses, trainingStatus } from './constants'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '1rem 0',
    minHeight: '36px',
  },
  annotateContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '2.2rem',
  },
  targetcolumn: {
    display: 'flex',
    alignItems: 'center',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    display: 'flex',
  },
  stopButton: {
    marginLeft: '1.5rem',
  },
  iconStatus: {
    alignSelf: 'center',
    marginRight: '6px',
    '& svg': {
      height: '1em',
      width: '1em',
    },
  },
  marginTop: {
    marginTop: '1px',
  },
  baseline: {
    '& svg': {
      // top: '.07em',
      position: 'relative',
    },
  },
  checkboxLabelStyles: {
    marginBottom: 0,
    marginRight: '1.5rem',
  },
  biasProgress: {
    marginLeft: '1.5rem',
  },
  statusComponent: {
    display: 'flex',
    alignItems: 'center',
  },
})

const ReadyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="black"
    width="18px"
    height="18px"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      fill="#168F3C"
    />
  </svg>
)

const StatusComponent = ({ statusObj }) => {
  const { status, title } = statusObj
  const classes = useStyles()
  return (
    <div className={classnames(classes.statusComponent, 'tour-trained-step1')}>
      {status === trainingStatus.Ready ? (
        <>
          <div className={classnames(classes.iconStatus, classes.baseline)}>
            <ReadyIcon />
          </div>
          <Typography size="captionBold" className={classes.marginTop}>
            {title}
          </Typography>
        </>
      ) : (
        <Typography size="captionBold">{title}</Typography>
      )}
    </div>
  )
}

const TrainingProgress = ({
  previewIteration,
  preprocessingStatus,
  estimatedTime,
}) => {
  const classes = useStyles()

  return previewIteration === null ? (
    <div className={classes.spinnerContainer}>
      <Spinner size={14} />
      <Typography size="captionBold" className="ml-1">
        {preprocessingStatus}
      </Typography>
    </div>
  ) : (
    <Progress
      value={previewIteration}
      label={true}
      labelText={`Learning data structure... ${
        estimatedTime ? `${msToTime(estimatedTime)} left` : ''
      }`}
      width="16rem"
    />
  )
}

const msToTime = (s) => {
  function pad(n, z) {
    z = z || 2
    return `00${n}`.slice(-z)
  }

  const ms = s % 1000
  s = (s - ms) / 1000
  const secs = s % 60
  s = (s - secs) / 60
  const mins = s % 60

  return `${pad(mins)}:${pad(secs)}`
}

export const AnalyzeBar = (props) => {
  const {
    canEdit,
    data,
    status,
    runningTaskList,
    maxModelsRunning,
    preprocessingStatus,
    previewIteration,
    estimatedTime,
    targetColumn,
    isSettingEnable,
    biasProgress,
    qualityProgress,
    displayColumns,
    startTraining,
    stopTraining,
    handleShowComplexRow,
    isShowComplexRow,
    updateBiasSetting,
  } = props

  const resultsOnly = data.access_type && data.access_type === 'RESULTS_ONLY'
  const classes = useStyles()
  const statusObj = statuses.filter((d) => d.status === status)[0]
  const isFreeTier = User.isFreeTier()
  const isCETier = User.isCETier()
  const isTrainTask =
    isFreeTier &&
    (!runningTaskList.train ||
      (runningTaskList && runningTaskList.train >= maxModelsRunning))

  // TODO move in selectors
  const columnNameOptions = data.meta
    ? data.meta.columns.map((column) => ({
        value: column.name,
        label: column.name,
      }))
    : []

  const isAnalyzeDisable =
    !canEdit ||
    statusObj.status === 'training' ||
    !data.meta ||
    !Object.keys(data.meta).length

  const targetColumnOptions =
    status === trainingStatus.Init || targetColumn === 'none'
      ? [{ value: 'none', label: 'None' }, ...columnNameOptions]
      : columnNameOptions

  const handleSelectTargetColumn = (id, targetColumn) => {
    updateBiasSetting(id, { targetColumn })
  }

  return (
    <div>
      <div className={classes.annotateContainer}>
        <EntityAnnotation
          handleShowComplexRow={handleShowComplexRow}
          isShowComplexRow={isShowComplexRow}
          disabled={isFreeTier || isCETier}
        >
          {_.IS_FAIRNESS_ENABLED ? (
            <div
              className={classnames(classes.targetcolumn, 'tour-orig-step4')}
            >
              <SelectSimple
                values={targetColumnOptions}
                value={
                  targetColumnOptions.filter((d) => d.value === targetColumn)[0]
                    ?.value
                }
                label="Bias Target Column"
                labelId="biasTargetColumn"
                handleChange={(value) => {
                  handleSelectTargetColumn(data.dataset_id, value)
                }}
                styles={{ menu: (base) => ({ ...base, zIndex: 2000 }) }}
                disabled={!isSettingEnable}
                style={{ width: '10rem' }}
                from="analyzeBar"
              />
            </div>
          ) : null}
        </EntityAnnotation>
      </div>

      <div className={classes.root}>
        <div className={classes.leftContainer}>
          {status === trainingStatus.Ready || (
            <Button
              style={{ marginRight: '1.5rem' }}
              color="secondary"
              onClick={() => startTraining(data.dataset_id)}
              className="tour-orig-step5"
              disabled={isAnalyzeDisable || isTrainTask}
            >
              Analyze
            </Button>
          )}
          {statusObj.status === 'training' ? (
            <Button
              style={{ marginRight: '1.5rem' }}
              onClick={() => stopTraining(data.dataset_id)}
              className="ml-4"
            >
              Stop
            </Button>
          ) : null}
          {statusObj.status !== trainingStatus.Init ? (
            <div className={classes.status}>
              <Typography>Status: </Typography>
              <Box ml={1}>
                {statusObj.status === 'training' ? (
                  <TrainingProgress
                    previewIteration={previewIteration}
                    preprocessingStatus={preprocessingStatus}
                    estimatedTime={estimatedTime}
                  />
                ) : (
                  <StatusComponent statusObj={statusObj} />
                )}
              </Box>
            </div>
          ) : null}
          {biasProgress !== null || qualityProgress !== null ? (
            <Progress
              value={biasProgress !== null ? biasProgress : qualityProgress}
              label={true}
              labelText={
                biasProgress !== null
                  ? 'Calculating Fairness...'
                  : 'Calculating Quality...'
              }
              width="16rem"
              classNames={{ root: classes.biasProgress }}
            />
          ) : null}
        </div>
        <div className={classes.rightContainer}>
          <DisplayColumns
            data={data}
            displayColumns={displayColumns}
            results_only={resultsOnly}
            statusObj={statusObj}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  data: getProjectDataState,
  status: getProjectStatusState,
  runningTaskList: getProjectRunningTaskListState,
  maxModelsRunning: getMaxModelsRunning,
  preprocessingStatus: getProjectPreprocessingStatusState,
  previewIteration: getProjectPreviewIterationState,
  estimatedTime: getEstimatedTimeState,
  targetColumn: getTargetColumnState,
  isSettingEnable: getIsSettingEnable,
  biasProgress: getBiasProgress,
  qualityProgress: getQualityProgress,
  displayColumns: getDisplayColumnsState,
  canEdit: getProjectPermissionsForUser,
})

const mapDispatchToProps = {
  startTraining,
  stopTraining,
  updateBiasSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeBar)

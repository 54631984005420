import React from 'react'
import { timeFormat } from 'd3'
import { makeStyles } from '@material-ui/core'

const formatTime = timeFormat('%Y-%m-%dT%H:%M:%S')
export const TimeCell: React.FunctionComponent<{ value: any }> = React.memo(
  ({ value }) => {
    const classes = useStyles()

    return (
      <div className={classes.timeColumn}>
        {value && formatTime(new Date(value))}
      </div>
    )
  },
  (prev, next) => prev.value === next.value
)

const useStyles = makeStyles({
  timeColumn: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

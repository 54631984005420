import { makeRequest } from './apiClient'

export default {
  async getTemplateList() {
    const templates = await makeRequest({
      path: `templates`,
      method: 'GET',
    })
    return templates
  },

  async createDatasetFromTemplate(id) {
    const response = await makeRequest({
      path: `templates/${id}/dataset`,
      method: 'POST',
    })
    return response
  },
}

export const ProjectSettingsActions = {
  GENERATE_SYNTHETIC_DATA: 'project/settings/GENERATE_SYNTHETIC_DATA',
  DID_SYNTH_PROGRESS: 'project/settings/DID_SYNTH_PROGRESS',

  START_LOADING: 'project/settings/START_LOADING',
  STOP_LOADING: 'project/settings/STOP_LOADING',

  EXPORT_CSV: 'project/settings/EXPORT_CSV',
}

export const generateSyntheticData = ({ id, rows }) => ({
  type: ProjectSettingsActions.GENERATE_SYNTHETIC_DATA,
  id,
  rows,
})

export const didSynthProgress = (progress, id) => ({
  type: ProjectSettingsActions.DID_SYNTH_PROGRESS,
  progress,
  id,
})

export const startLoadingSettings = ({ name }) => ({
  type: ProjectSettingsActions.START_LOADING,
  name,
})

export const stopLoadingSettings = () => ({
  type: ProjectSettingsActions.STOP_LOADING,
})

export const exportCSV = ({ id, callback }) => ({
  type: ProjectSettingsActions.EXPORT_CSV,
  id,
  callback,
})

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { Typography } from '../../../shared'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  legendItemRoot: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0,
    },
  },
  colorBox: {
    width: '10px',
    height: '10px',
    borderRadius: '2px',
    marginRight: '.35rem',
  },
  typographyLineHeight: {
    lineHeight: '20px',
  },
  marginRight: {
    marginRight: '1rem',
  },
})

export const LegendItem = ({ legend }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.legendItemRoot, classes.marginRight)}>
      <div
        className={classes.colorBox}
        style={{ backgroundColor: legend.color }}
      />
      <Typography className={classes.typographyLineHeight}>
        {legend.name}
      </Typography>
    </div>
  )
}

export const LegendList = ({
  legends = [],
  classNames = { listRoot: '' },
  from = '',
}) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.root, classNames.listRoot)}>
      {from === 'scenario' && (
        <Typography
          className={classnames(
            classes.typographyLineHeight,
            classes.marginRight
          )}
        >
          Legend:{' '}
        </Typography>
      )}
      {legends.length
        ? legends.map((d) => <LegendItem key={d.name} legend={d} />)
        : null}
    </div>
  )
}

import React, { useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Typography, SwitchCase } from 'legacy/shared'
import Alert from '@material-ui/lab/Alert'
import { Button } from 'UIkit/Button/Button'

import HeaderComponent from 'layouts/Header'
import PageContainer from 'layouts/PageContainer'

import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'
import OriginalDataView from './OriginalDataView'
import {
  getSynthTabIsEnable,
  getProjectTitle,
  getNotFoundPage,
  getProjectSelectedTabState,
  getProjectPermissionsForUser,
} from './projectSelectors'
import { resetProjectState, updateTab } from './actions'
import { startShareProject } from './shareProject/actions'
import { ProjectNotFound } from './ProjectViewNotFound'
import ScenarioDataView from './scenarios/ScenarioDataView'
import ProductTour from '../ProductTour/ProductTour'
import { productTourActions } from '../ProductTour/reducer'
import { projectPermissionsActions as permissionsActions } from './reducers/projectPermissionsReducer'
import PermissionsButton from '../PermissionsButton/PermissionsButton'

import { SpinnerSection } from './biasAndQuality/SpinnerSection'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rootTabs: {
    backgroundColor: '#eff0f6',
    minHeight: '44px',
    paddingTop: '.5rem',
  },
  rootTab: {
    marginRight: '.5rem',
    backgroundColor: '#fcfcff',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    minHeight: '36px',
    '& > span': {
      zIndex: 2,
    },
  },
  selected: {
    backgroundColor: '#fff',
  },
  requestButton: {
    marginLeft: 14,
  },
  indicator: {
    display: 'none',
  },
})

export const ProjectView = ({
  datasetTitle,
  isDataProductEnable,
  match,
  canEdit,
  getPermissions,
  resetProjectState,
  startShareProject,
  showModal,
  pauseTip,
  notFoundPage,
  selectedTab,
  updateTab,
}) => {
  const classesTabs = useStyles()

  const { id } = match.params

  useEffect(() => {
    getPermissions({ projectId: id })
    return () => {
      resetProjectState()
    }
  }, [resetProjectState, id])

  const handleChange = (event, newValue) => {
    updateTab(newValue)
    pauseTip({})
  }

  const tabs = [
    {
      id: 'original',
      label: <span>Original Data</span>,
    },
    {
      id: 'sds',
      label: !isDataProductEnable ? (
        <Tooltip title="You should complete Analyze" arrow>
          <span>My Data Product</span>
        </Tooltip>
      ) : (
        <span>My Data Product</span>
      ),
      disabled: !isDataProductEnable,
    },
  ]

  const handleChangeTitle = () => {
    showModal({ modalType: ModalTypes.ChangeProjectName })
  }

  return (
    <div className={classesTabs.root}>
      <ProductTour />
      {notFoundPage ? (
        <ProjectNotFound />
      ) : (
        <>
          <HeaderComponent
            title="Data Products"
            subTitle={
              <>
                <div onClick={handleChangeTitle} style={{ cursor: 'pointer' }}>
                  <Typography size="subTitle" variant="h2">
                    {datasetTitle}
                  </Typography>
                </div>
                {datasetTitle && !canEdit && (
                  <PermissionsButton className={classesTabs.requestButton} />
                )}
              </>
            }
            backPath="/projects"
          >
            {canEdit && (
              <div style={{ position: 'absolute', right: 0 }}>
                <Button onClick={() => startShareProject(id)}>
                  Share data product
                </Button>
              </div>
            )}
          </HeaderComponent>
          {selectedTab !== '' ? (
            <>
              <Tabs
                tabs={
                  canEdit
                    ? tabs.filter(Boolean)
                    : tabs.filter((tab) => tab.id !== 'original')
                }
                onChange={handleChange}
                value={selectedTab}
                tabsClasses={{
                  root: classesTabs.rootTabs,
                  indicator: classesTabs.indicator,
                }}
                tabClasses={{
                  root: classesTabs.rootTab,
                  selected: classesTabs.selected,
                }}
              />
              <PageContainer pt={0}>
                <SwitchCase value={selectedTab}>
                  {{
                    original: <OriginalDataView datasetId={id} />,
                    sds: <ScenarioDataView datasetId={id} />,
                    error: (
                      <Alert severity="error">Project is not available</Alert>
                    ),
                  }}
                </SwitchCase>
              </PageContainer>
            </>
          ) : (
            <div style={{ marginTop: '120px' }}>
              <SpinnerSection />
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  datasetTitle: getProjectTitle,
  isDataProductEnable: getSynthTabIsEnable,
  notFoundPage: getNotFoundPage,
  selectedTab: getProjectSelectedTabState,
  canEdit: getProjectPermissionsForUser,
})

const mapDispatchToProps = {
  resetProjectState,
  startShareProject,
  showModal: modalActions.show,
  updateTab,
  getPermissions: permissionsActions.getProjectPermissions,
  pauseTip: productTourActions.pauseTip,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView)

import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { IconButton, Menu } from 'legacy/shared'

import { scenarioActions } from 'services/project/scenarios/reducer'

const useStyles = makeStyles({
  triggerButton: {
    padding: 0,
  },
  verDivider: {
    color: '#C1C6CC',
  },
})

const eventPrevent = (e) => {
  e.stopPropagation()
  e.preventDefault()
  e.target.blur()
}

export const ActionMenu = ({
  group,
  disabled,
  duplicateGroup,
  deleteGroup,
}) => {
  const classes = useStyles()
  return (
    <div onClick={(e) => eventPrevent(e)}>
      <Menu
        items={[
          {
            text: 'Duplicate',
            onClick: () => duplicateGroup({ id: group.id }),
            disabled,
          },
          {
            text: 'Delete',
            onClick: () => deleteGroup({ id: group.id }),
            disabled,
          },
        ].filter(Boolean)}
        renderTriggerComponent={({ showMenu }) => (
          <div style={{ display: 'flex' }}>
            <IconButton onClick={showMenu} className={classes.triggerButton}>
              <MoreVertIcon />
            </IconButton>
          </div>
        )}
      />
    </div>
  )
}

const mapDispatchToProps = {
  duplicateGroup: scenarioActions.duplicateGroup,
  deleteGroup: scenarioActions.deleteGroup,
}

export default connect(null, mapDispatchToProps)(ActionMenu)

export interface ICreateTestingSuitePayload {
  type?: string
  index?: number
  data: FormData
  files?: File[]
  formData: boolean
  suitName: string
}

export interface IDBConfig {
  name: string
  type: string
  host: string
  port: string
  userName: string
  password: string
  database: string
}
export enum SUITE_FILE_TYPES {
  PRIMARY_KEY = 'PRIMARY_KEY',
  FOREIGN_KEY = 'FOREIGN_KEY',
  DATABASE = 'DATABASE',
  FUNCTIONAL_MAPPING = 'FUNCTIONAL_MAPPING',
  JOIN_STATEMENT = 'JOIN_STATEMENT',
}

export enum SUITE_FILE_STATUSES {
  LOADING = 'LOADING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export type suiteFileType =
  | 'PRIMARY_KEY'
  | 'FOREIGN_KEY'
  | 'DATABASE'
  | 'FUNCTIONAL_MAPPING'
  | 'JOIN_STATEMENT'

export interface IFilePreview {
  name: string
  size: number
  updated_at?: string
  id?: number
}

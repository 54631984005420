import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IForgotPasswordForm,
  ISignFormFail,
  IForgotPasswordState,
} from '../typings'

const prefix = 'forgotPassword'

const initialState: IForgotPasswordState = {
  isLoading: false,
  serverError: null,
  status: null,
}

const forgotPasswordReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    resetPassword: (state) => {
      state.isLoading = true
      state.serverError = null
    },
    didResetPassword: (
      state,
      { payload }: PayloadAction<IForgotPasswordForm>
    ) => {
      state.status = { email: payload.email }
      state.isLoading = false
      state.serverError = null
    },
    didResetPasswordFail: (
      state,
      { payload }: PayloadAction<ISignFormFail>
    ) => {
      state.isLoading = false
      state.serverError = payload
    },
    resetForm: () => {
      return { ...initialState }
    },
  },
})

export const forgotPasswordActions = forgotPasswordReducer.actions

export default forgotPasswordReducer.reducer

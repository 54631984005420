import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const getGlobalListState = (state: IStore) => state.global

export const getIsLoadingState = createSelector(
  getGlobalListState,
  (state) => state.isLoading
)

export const getProjectRunningTaskListState = createSelector(
  getGlobalListState,
  (state) => state.runningTaskList
)

export const getUserLimits = createSelector(
  getGlobalListState,
  (state) => state.userLimits || {}
)

export const getMaxModelsRunning = createSelector(
  getUserLimits,
  (limits) => limits.num_max_models_running || 3
)

export const getProjectsLimit = createSelector(
  getUserLimits,
  (limits) => limits.num_projects_current || 0
)

export const getProjectsMaxLimit = createSelector(
  getUserLimits,
  (limits) => limits.num_projects_max || 3
)

export const getIsUploadLimitExceeded = createSelector(
  getProjectsLimit,
  getProjectsMaxLimit,
  (projectsCurrent, projectsMax) => {
    if (projectsCurrent >= projectsMax) {
      return true
    }
    return false
  }
)

export const getUploadLimitCounter = createSelector(
  getProjectsLimit,
  getProjectsMaxLimit,
  (projectsCurrent, projectsMax) => {
    if (projectsMax >= projectsCurrent) {
      return projectsMax - projectsCurrent
    }
    return 0
  }
)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from 'UIkit'

import { ColumnTitleLayout } from './ColumnTitle'
import { ColumnsStatistics } from './ColumnStatistics'
import { SampleList } from './SampleList'

const useStyles = makeStyles({
  box: {
    width: 300,
  },
})

const DataGroupOutputColumnData = (props: any) => {
  const { synthColumn, sampleColumn = [] } = props
  const classes = useStyles()

  return (
    <div>
      <Box border={1} className={classes.box}>
        <>
          <ColumnTitleLayout title={synthColumn.name} />
          <ColumnsStatistics columnData={synthColumn} />
          <SampleList sample={sampleColumn} />
        </>
      </Box>
    </div>
  )
}

export default DataGroupOutputColumnData

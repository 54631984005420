const complexTypes = [
  {
    value: 'Person',
    label: 'Person',
    color: '#00bae950',
    selects: [
      { name: 'firstname_label', label: 'firstname_label', selectedColumn: [] },
      { name: 'lastname_label', label: 'lastname_label', selectedColumn: [] },
      { name: 'sex_label', label: 'gender_label', selectedColumn: [] },
      { name: 'email_label', label: 'email_label', selectedColumn: [] },
      {
        name: 'mobile_number_label',
        label: 'mobile_number_label',
        selectedColumn: [],
      },
    ],
  },
]

export const getComplexTypes = () => {
  return complexTypes
}

export const transformToUICouplings = (couplings) => {
  const arr = []
  const BreakException = {}

  if (Array.isArray(couplings)) {
    return couplings
  }

  if (!couplings || Object.keys(couplings).length === 0) {
    return arr
  }

  Object.keys(couplings).forEach((coup) => {
    try {
      getComplexTypes().forEach((d) => {
        if (d.selects.findIndex((dd) => dd.name === coup) !== -1) {
          arr.push({
            name: [...couplings[coup]],
            color: d.color,
            complexSelect: coup,
            complexType: d.label,
          })
          throw BreakException
        }
      })
    } catch (e) {
      if (e !== BreakException) throw e
    }
  })

  return arr
}

export const transformToBECoupling = (couplings) => {
  if (couplings) {
    if (Array.isArray(couplings)) {
      if (couplings.length === 0) {
        return {}
      }
      return couplings.reduce((acc, elem) => {
        acc[elem.complexSelect] = elem.name
        return acc
      }, {})
    }
    return couplings
  }
}

import { takeLatest, put, call } from 'redux-saga/effects'
import { authService } from 'legacy/utils/services'
import { notificationActions } from 'services/notification/reducer'
import { CETierActions } from './reducer'

function* handleContactUs() {
  try {
    yield call(authService.sendContactUs)
  } catch (error) {
    put(
      notificationActions.showNotification({
        message: 'Error occurs. Please use email to write us',
        severity: 'error',
      })
    )
  }
  yield put(CETierActions.didSendContactUs())
  yield put(
    notificationActions.showNotification({
      message:
        'Thank you, your request is submitted and we will contact you soon!',
    })
  )
}

export default function* watchAuthSaga() {
  yield takeLatest(CETierActions.sendContactUs.type, handleContactUs)
}

export const SandboxesDatasetActions = {
  FETCH_DATASETS_LIST: 'sandboxes/build/FETCH_DATASETS_LIST',
  DID_FETCH_DATASETS_LIST: 'sandboxes/build/DID_FETCH_DATASETS_LIST',
  CHANGE_DATASET_SELECTION: 'sandboxes/build/CHANGE_DATASET_SELECTION',
  SET_SEARCH_VALUE: 'sandboxes/build/SET_SEARCH_VALUE',
}

export const fetchDatasets = () => ({
  type: SandboxesDatasetActions.FETCH_DATASETS_LIST,
})

export const didFetchDatasets = (datasets) => ({
  type: SandboxesDatasetActions.DID_FETCH_DATASETS_LIST,
  datasets,
})

export const changeSelection = (dataset) => ({
  type: SandboxesDatasetActions.CHANGE_DATASET_SELECTION,
  dataset,
})

export const setSearchValue = (string = '') => ({
  type: SandboxesDatasetActions.SET_SEARCH_VALUE,
  searchString: string,
})

import * as React from 'react'
import { Typography } from '../typography'

export const Heading = ({ children, size }) => {
  return (
    <Typography size={size} color="black" variant="h1" noWrap>
      {children}
    </Typography>
  )
}

Heading.displayName = 'Heading'

import { createSelector } from 'reselect'
import { getSandboxBuildState } from '../sandboxBuildSelectors'

export const getSandboxDBState = createSelector(
  getSandboxBuildState,
  (state) => state.db
)

export const getSandboxSelectedDB = createSelector(
  getSandboxDBState,
  (state) => state.selectedDBById
)

export const getSelectedSandboxDBName = createSelector(
  getSandboxSelectedDB,
  (state) => state.name
)

export const getIsStepCompleted = createSelector(
  getSandboxSelectedDB,
  (state) => Boolean(state.id)
)

import { sortBy, omit } from 'lodash'
import { makeRequest } from './apiClient'

const groupsService = {
  async getGroups({ datasetId }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups`,
      method: 'GET',
    })
    return sortBy(response, ['order'])
  },
  async createGroup({ datasetId, group }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups`,
      method: 'POST',
      params: JSON.stringify(group),
    })
    return response
  },
  async updateGroup({ datasetId, id, group }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups/${id}`,
      method: 'PUT',
      params: JSON.stringify(group),
    })
    return response
  },
  async updateGroupPositions({ datasetId, groups }) {
    return Promise.all(
      groups.map((group) =>
        groupsService.updateGroup({
          datasetId,
          id: group.id,
          group: omit(group, 'dataset_id', 'id', 'status'),
        })
      )
    )
  },
  async deleteGroup({ datasetId, id }) {
    makeRequest({
      path: `datasets/${datasetId}/datagroups/${id}`,
      method: 'DELETE',
    })
  },
  async synthesizeData({ datasetId }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups/transform`,
      method: 'POST',
    })
    return response
  },
  async stopSynthesizeData({ datasetId }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups/transform`,
      method: 'DELETE',
    })
    return response
  },
  async fetchSynthesizeData({ datasetId }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups/transform?sample_size=10`,
      method: 'GET',
    })
    return response
  },
  async fetchGroupSamples({ datasetId }) {
    const response = await makeRequest({
      path: `datasets/${datasetId}/datagroups/samples?sample_size=10`,
      method: 'GET',
    })
    return response
  },
  async exportCSVGroup({ datasetId, id }) {
    const response = makeRequest({
      path: `datasets/${datasetId}/datagroups/${id}/export`,
      method: 'GET',
      type: 'export',
    })
    return response
  },
}

export default groupsService

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'CETier'

const CETierReducer = createSlice({
  name: prefix,
  initialState: { isLoading: false, serverError: {} },
  reducers: {
    sendContactUs: (state) => {
      state.isLoading = true
      state.serverError = {}
    },
    didSendContactUs: (state) => {
      state.isLoading = false
      state.serverError = {}
    },
    didSendContactUsFail: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.serverError = payload
    },
  },
})

export const CETierActions = CETierReducer.actions

export default CETierReducer.reducer

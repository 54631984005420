import React, { FC } from 'react'
import { connect } from 'react-redux'
import { useFormik } from 'formik'

import { Button, TextField } from 'UIkit'
import { ModalButtonContainer } from 'components/Modals/ModalButtonContainer'
import { modalActions } from 'services/modal/reducer'
import { scenarioActions } from 'services/project/scenarios/reducer'

interface IAddGroupForm {
  title: string
}

type DispatchProps = typeof mapDispatchToProps

const validate = (values: IAddGroupForm) => {
  const errors = {
    title: '',
  }

  if (!values.title) {
    errors.title = `Required`

    return errors
  }
  return {}
}

const AddGroupForm: FC<DispatchProps> = (props) => {
  const { hideModal, createGroup } = props
  const formik = useFormik({
    initialValues: { title: '' },
    validate,
    onSubmit(value: IAddGroupForm): void | Promise<any> {
      createGroup(value)
      hideModal()
    },
  })
  const { title } = formik.values

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="title"
        label="Group Name"
        data-testid="groupTitleField"
        variant="outlined"
        size="small"
        value={title}
        onChange={(e) => formik.handleChange(e)}
        onBlur={(e) => formik.handleBlur(e)}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
      />
      <ModalButtonContainer>
        <Button variant="outlined" onClick={hideModal}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </ModalButtonContainer>
    </form>
  )
}

const mapDispatchToProps = {
  hideModal: modalActions.hide,
  createGroup: scenarioActions.createGroup,
}

export default connect(null, mapDispatchToProps)(AddGroupForm)

import React, { FC } from 'react'
import { connect } from 'react-redux'

import CleanRoomShareForm from 'pages/CleanRooms/CleanRoomShareForm'
import ModalDataSourceEdit from 'pages/DataSources/dataSourceModal/ModalDataSourceEdit'
import ModalDataSourceCreate from 'pages/DataSources/dataSourceModal/ModalDataSourceCreate'
import {
  selectIsModalOpen,
  selectModalConfig,
  selectModalIsLoading,
  selectModalType,
} from 'services/modal/modalSelectors'
import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'

import AddGroupForm from 'pages/Projects/AddGroupForm'
import { Modal as ModalCard } from '../../legacy/shared'
import { ModalCustom } from '../../UIkit/Modal/ModalCustom'
import ShareProjectForm from '../../legacy/components/Projects/shareProject/ShareProjectForm'
import ConfirmationModal from './ConfirmationModal'
import EditColumnName from '../../legacy/components/Projects/modals/EditColumnName'
import ProjectNameForm from '../../legacy/components/Projects/ProjectNameForm'
import ProjectEdit from '../../legacy/components/Datasets/ProjectEditModal'
import { IStore } from '../../core/rootReducer'

export const ModalContainer: FC<any> = ({
  isOpen,
  type,
  config,
  hideModal,
  isLoading,
}) => {
  const classes = { header: {}, content: {} }
  const MODALS = {
    [ModalTypes.ShareProject]: <ShareProjectForm />,
    [ModalTypes.Confirmation]: <ConfirmationModal isLoading={isLoading} />,
    [ModalTypes.ShareSandbox]: <CleanRoomShareForm />,
    [ModalTypes.ProjectEditColumnName]: <EditColumnName />,
    [ModalTypes.ChangeProjectName]: <ProjectNameForm />,
    [ModalTypes.ProjectAddGroup]: <AddGroupForm />,
    [ModalTypes.ProjectEdit]: <ProjectEdit />,
    [ModalTypes.DataSourceEdit]: <ModalDataSourceEdit />,
    [ModalTypes.DataSourceCreate]: <ModalDataSourceCreate />,
  }

  const Modal = config?.custom ? ModalCustom : ModalCard

  if (type) {
    return (
      <Modal
        open={isOpen}
        hideModal={hideModal}
        headerText={config ? config.headerText : undefined}
        headerClassName={classes.header}
        contentClassName={classes.content}
      >
        {MODALS[type]}
      </Modal>
    )
  }

  return null
}

const mapStateToProps = (state: IStore) => ({
  isOpen: selectIsModalOpen(state),
  type: selectModalType(state),
  config: selectModalConfig(state),
  isLoading: selectModalIsLoading(state),
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { ProjectStatuses } from './constants'
import { SwitchCase } from '../../shared/components'
import { colors } from '../../shared'

const useStyles = makeStyles((theme) => ({
  colorBox: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 6,
  },
  success: {
    backgroundColor: theme.palette.success.main, // GREEN
  },
  warningDark: {
    backgroundColor: theme.palette.warning.dark, // ORANGE
  },
  warningLight: {
    backgroundColor: theme.palette.warning.light, // YELLOW
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main, // RED
  },
  grey: {
    backgroundColor: colors.lightGrey,
  },
}))

export const StatusTag = ({ status }) => {
  const classes = useStyles()
  return (
    <SwitchCase value={status || 'STATUS_NOT_TRAINED'}>
      {{
        [ProjectStatuses.STATUS_STARTED.id]: (
          <>
            <div
              className={classnames(classes.colorBox, classes.warningDark)}
            />
            {ProjectStatuses.STATUS_STARTED.label}
          </>
        ),
        [ProjectStatuses.STATUS_FAILED.id]: (
          <>
            <div className={classnames(classes.colorBox, classes.grey)} />
            {ProjectStatuses.STATUS_FAILED.label}
          </>
        ),
        [ProjectStatuses.STATUS_FINISHED.id]: (
          <>
            <div className={classnames(classes.colorBox, classes.success)} />
            {ProjectStatuses.STATUS_FINISHED.label}
          </>
        ),
        [ProjectStatuses.STATUS_STOPPED.id]: (
          <>
            <div className={classnames(classes.colorBox, classes.secondary)} />
            {ProjectStatuses.STATUS_STOPPED.label}
          </>
        ),
        [ProjectStatuses.STATUS_QUEUED.id]: (
          <>
            <div
              className={classnames(classes.colorBox, classes.warningDark)}
            />
            {ProjectStatuses.STATUS_QUEUED.label}
          </>
        ),
        [ProjectStatuses.STATUS_NOT_TRAINED.id]: (
          <>
            <div
              className={classnames(classes.colorBox, classes.warningLight)}
            />
            {ProjectStatuses.STATUS_NOT_TRAINED.label}
          </>
        ),
      }}
    </SwitchCase>
  )
}

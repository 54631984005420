import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { BiasTable } from './BiasTable'
import { Typography, HighlightSection } from '../../../shared'
import { BiasScore } from './BiasScore'
import { SectionTitle } from '../Layouts'

import {
  getTotalScore,
  getBiasTarget,
  getIsSignificantBias,
  getDistBiasesToDrop,
  getBiasMitigationFailed,
} from './utils'
import { FairnessDataMetafield } from '../../../../api/generated/modelPkg'

const useStyles = makeStyles({
  root: {
    padding: '1.5rem',
  },
  titleMargin: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  smallTitle: {
    display: 'flex',
  },
  smallTitleTypography: {
    marginRight: '.5rem',
  },
  tableLayout: {
    width: '60rem',
  },
  cellRoot: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
})

interface ISynthFairness {
  groupTitle?: string
  originalFairness?: FairnessDataMetafield
  synthFairness?: FairnessDataMetafield
  numRowsSynth?: number | string | null
  isTargetColumnAreTheSame: boolean
}

const checkDropData = (dropData: any) => dropData.count && dropData.count.length

export const SynthBias: React.FC<ISynthFairness> = ({
  groupTitle,
  originalFairness,
  synthFairness,
  numRowsSynth,
  isTargetColumnAreTheSame,
}) => {
  const classes = useStyles()

  if (!synthFairness) {
    return null
  }

  const numRows = numRowsSynth

  const isSignificantBias = getIsSignificantBias(synthFairness)
  const targetColumn = getBiasTarget(synthFairness)

  const totalFairnessScoreOrig = getTotalScore(originalFairness)

  const totalFairnessScoreSynth = getTotalScore(synthFairness)

  const distBiasesToDrop = getDistBiasesToDrop(synthFairness)
  const biasMitigationFailed = getBiasMitigationFailed(synthFairness)

  const FairnessInfo = () => {
    return (
      <>
        <SectionTitle title="Bias and Fairness" />
        <div className={classnames(classes.titleMargin, classes.smallTitle)}>
          <Typography size="body" className={classes.smallTitleTypography}>
            Group title:
          </Typography>
          <Typography size="bodyBold">{groupTitle}</Typography>
        </div>
        <div className={classnames(classes.titleMargin, classes.smallTitle)}>
          <Typography size="body" className={classes.smallTitleTypography}>
            Target Column:
          </Typography>
          <Typography size="bodyBold">{targetColumn}</Typography>
        </div>
        {isTargetColumnAreTheSame && totalFairnessScoreOrig !== null && (
          <BiasScore
            title="Original Fairness:"
            totalFairnessScore={totalFairnessScoreOrig}
            tooltip
          />
        )}

        {!biasMitigationFailed && totalFairnessScoreSynth !== null && (
          <BiasScore
            title="Enhanced Fairness:"
            totalFairnessScore={totalFairnessScoreSynth}
            tooltip
          />
        )}
      </>
    )
  }

  if (biasMitigationFailed) {
    return (
      <>
        <FairnessInfo />
        <HighlightSection color="error">
          <div className={classnames(classes.titleMargin)}>
            <Typography size="body" className={classes.smallTitleTypography}>
              The original dataset unfortunately doesn't have the information
              needed for data rebalancing of the following groups preserving the
              overall integrity of data. Hence to make ensure the output dataset
              is unbiased, the groups need to be analysed separately and dropped
              from the output in this case. Another solution would be to sample
              the target variable for the following groups at random which will
              affect the overall integrity of the output data.
            </Typography>
          </div>
        </HighlightSection>
      </>
    )
  }

  if (checkDropData(distBiasesToDrop)) {
    return (
      <>
        <FairnessInfo />
        <div className={classnames(classes.titleMargin)}>
          <Typography size="body" className={classes.smallTitleTypography}>
            The groups in the original data which do not appear in the output
            data:
          </Typography>
        </div>
        <BiasTable
          data={distBiasesToDrop}
          numRows={numRows}
          isSignificantBias={isSignificantBias}
        />
      </>
    )
  }
  return null
}

import { combineReducers } from 'redux'
import undoable from 'redux-undo'

import scenarios from 'services/project/scenarios/reducer'

import project from './projectReducer'
import projectSetting from './projectSettingsReducer'
import report from './reportReducer'
import synthDataset from './synthDatasetReducer'
import modal from './modalReducer'
import projectPermissions from './projectPermissionsReducer'
import { ProjectActions } from '../actions'

const reducer = combineReducers({
  project,
  projectSetting,
  report,
  synthDataset,
  modal,
  scenarios: undoable(scenarios),
  projectPermissions,
})

const projectReducer = (state, action) => {
  if (action.type === ProjectActions.RESET_STATE) {
    state = undefined
  }

  return reducer(state, action)
}

export default projectReducer

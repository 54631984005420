import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Typography from '@material-ui/core/Typography'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Button } from 'UIkit/Button/Button'
import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'
import {
  getProjectId,
  getProjectPermissions,
} from '../Projects/projectSelectors'
import { projectPermissionsActions as actions } from '../Projects/reducers/projectPermissionsReducer'
import { PERMISSION_IDS } from '../Projects/projectPermissions/constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    width: 240,
    height: 156,
  },
  text: {
    marginBottom: 16,
  },
  button: {
    border: '1px solid #6E6B8C',
    color: '#6E6B8C',
    backgroundColor: '#D7D6DB',
    padding: '8px 10px',
    height: 26,
    width: 106,
    '&:hover': {
      border: '1px solid #6E6B8C',
      color: '#6E6B8C',
      backgroundColor: '#D7D6DB',
    },
  },
}))

const PermissionsButton = ({
  className,
  projectId,
  showModal,
  requestPermission,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const requestEditAccess = () => {
    setAnchorEl(null)
    showModal({
      modalType: ModalTypes.Confirmation,
      config: {
        headerText: 'Send request to be an owner?',
        confirmationConfig: {
          confirmButtonColor: '#0D0840',
          confirmButtonText: 'Send',
          confirmButtonAction: () =>
            requestPermission({
              datasetId: projectId,
              permissionId: PERMISSION_IDS.CONSUMER,
            }),
        },
      },
    })
  }

  const open = Boolean(anchorEl)
  const id = open ? 'transitions-popper' : undefined

  return (
    <div className={className}>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Button
              className={classes.button}
              startIcon={<VisibilityIcon />}
              endIcon={<ArrowDropDownIcon />}
              {...bindTrigger(popupState)}
            >
              View only
            </Button>
            <Popover
              id={id}
              {...bindPopover(popupState)}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 75, left: 730 }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.container}>
                <Typography border="none" className={classes.text}>
                  You can only <b>view</b> this dataset. To make changes, ask
                  owner access.
                </Typography>
                <Button onClick={requestEditAccess}>
                  Request owner access
                </Button>
              </div>
            </Popover>
          </>
        )}
      </PopupState>
    </div>
  )
}
const mapStateToProps = createStructuredSelector({
  projectId: getProjectId,
  permissions: getProjectPermissions,
})

const mapDispatchToProps = {
  showModal: modalActions.show,
  requestPermission: actions.requestProjectPermissions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsButton)

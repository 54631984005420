import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SynthesizeBar from './SyntheizeBar'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  marginRight: {
    marginRight: '1.5rem',
  },
})

const ScenarioHeader = ({ datasetId }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SynthesizeBar id={datasetId} />
    </div>
  )
}

export default ScenarioHeader

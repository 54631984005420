import React from 'react'
import { connect } from 'react-redux'
import { IStore } from 'core/rootReducer'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { TextField, Typography, Form, spacing } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'
import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'
import { RequestStatus } from 'utils/constants'

import { ModalButtonContainer } from 'components/Modals/ModalButtonContainer'

import { cleanRoomsActions } from 'services/cleanrooms/reducer'
import {
  getSharedCleanRoom,
  getShareModalState,
  getShareModalError,
} from 'services/cleanrooms/selectors'
import { modalActions } from 'services/modal/reducer'

const ShareCleanRoomSchema = {
  email: constraints.requiredString,
}

const useStyles = makeStyles({
  errorMsg: {
    marginTop: spacing.gutter * 2,
  },
  info: {
    marginBottom: spacing.gutter * 2,
  },
})

const CredentialInfo = ({ credentials, name }: any) => {
  const classes = useStyles()

  if (!credentials) {
    return null
  }
  const { username, password, host, port } = credentials
  return (
    <div className={classes.info}>
      <Typography>
        <b>Data Clean Room: </b>
        {name}
      </Typography>
      <Typography>
        <b>Username: </b>
        {username}
      </Typography>
      <Typography>
        <b>Password: </b>
        {password}
      </Typography>
      <Typography>
        <b>Host: </b>
        {host}
      </Typography>
      <Typography>
        <b>Port: </b>
        {port}
      </Typography>
    </div>
  )
}

type SelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

export const ShareSandboxForm = ({
  shareStatus,
  errorMessage,
  cleanRoom,
  shareCleanRoom,
  hideModal,
}: SelectedProps & DispatchProps) => {
  const classes = useStyles()

  const originalDataIncluded = Boolean(cleanRoom?.dataset_id_list?.length)

  return (
    <>
      <CredentialInfo
        credentials={cleanRoom.access_credentials}
        name={cleanRoom.name}
      />
      <Form
        initialValues={{
          email: '',
        }}
        onSubmit={({ email }: any) => shareCleanRoom({ email })}
        validate={createValidatorWithSchema(ShareCleanRoomSchema)}
      >
        {(props: any) => (
          <>
            <TextField
              name="email"
              label="Email"
              onChange={props.handleChange}
            />
            {originalDataIncluded ? (
              <div style={{ marginTop: spacing.gutter * 2 }}>
                <Alert severity="warning">
                  Attention! You will share original data
                </Alert>
              </div>
            ) : null}
            <ModalButtonContainer>
              <Button
                loading={shareStatus === RequestStatus.Loading}
                onClick={props.submitForm}
              >
                Share
              </Button>
              <Button variant="outlined" onClick={hideModal}>
                Cancel
              </Button>
            </ModalButtonContainer>
            {shareStatus === RequestStatus.Failed ? (
              <Typography color="red" className={classes.errorMsg}>
                {errorMessage}
              </Typography>
            ) : null}
          </>
        )}
      </Form>
    </>
  )
}

const mapStateToProps = (state: IStore) => ({
  shareStatus: getShareModalState(state),
  errorMessage: getShareModalError(state),
  cleanRoom: getSharedCleanRoom(state),
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
  shareCleanRoom: cleanRoomsActions.shareCleanRoom,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareSandboxForm)

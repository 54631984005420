import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IStore } from 'core/rootReducer'
import { debounce } from 'lodash'
import { Grid } from '@material-ui/core'

import { RequestStatus } from 'utils/constants'
import { Spinner, SwitchCase } from 'legacy/shared'

import { templateActions } from 'services/templates/reducer'
import {
  selectTemplateList,
  selectTemplatesStatus,
  selectIsDatasetCreating,
  selectSelectedTemplate,
} from 'services/templates/selectors'

import { TemplateCard } from './TemplateCard'
import { ErrorPage } from './ErrorPage'

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const SpinnerSection = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
      }}
    >
      <Spinner size={32} />
    </div>
  )
}

const Templates: React.FC<SelectedProps & DispatchProps> = ({
  templates,
  status,
  isDatasetCreating,
  selectedTemplate,
  fetchTemplates,
  createDataset,
}) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    fetchTemplates()
  }, [fetchTemplates])

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 500)

    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const handlerCreateDataset = (id: number) => {
    createDataset({ id })
  }

  return (
    <SwitchCase value={status}>
      {{
        [RequestStatus.Succeeded]: (
          <Grid container spacing={3} justify="flex-start">
            {templates.map((template) => (
              <Grid item md={4} sm={6} xs={12} key={template.id}>
                <TemplateCard
                  template={template}
                  isDatasetCreating={isDatasetCreating}
                  selectedTemplate={selectedTemplate}
                  handlerCreateDataset={handlerCreateDataset}
                  pageWidth={dimensions.width}
                />
              </Grid>
            ))}
          </Grid>
        ),
        [RequestStatus.Loading]: <SpinnerSection />,
        [RequestStatus.Failed]: (
          <ErrorPage
            fetchTemplates={fetchTemplates}
            isLoading={status === RequestStatus.Loading}
          />
        ),
      }}
    </SwitchCase>
  )
}

const mapStateToProps = (state: IStore) => ({
  templates: selectTemplateList(state),
  status: selectTemplatesStatus(state),
  isDatasetCreating: selectIsDatasetCreating(state),
  selectedTemplate: selectSelectedTemplate(state),
})

const mapDispatchToProps = {
  fetchTemplates: templateActions.fetchTemplates,
  createDataset: templateActions.createDataset,
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates)

import { combineReducers, PayloadAction } from '@reduxjs/toolkit'
import undoable from 'redux-undo'

import globalReducer, { globalActions } from 'services/global/reducer'
import authReducer from 'services/auth/reducer'
import notificationReducer from 'services/notification/reducer'
import templateReducer from 'services/templates/reducer'
import dataSourcesReducer from 'services/datasources/reducer'
import profileReducer from 'services/profile/reducer'
import inviteCodeReducer from 'services/inviteCode/reducer'
import uploadDatasetReducer from 'services/uploadDataset/reducer'
import cleanRoomReducer from 'services/cleanrooms/reducer'
import createTestingSuiteReducer from 'services/createTestingSuite/reducer'
import testingSuitesReducer from 'services/testingSuites/reducer'
import modalReducer from 'services/modal/reducer'
// LEGACY reducers
import sandboxReducer from 'legacy/components/Sandboxes/reducers'
import projectReducer from 'legacy/components/Projects/reducers'
import datasetsReducer from 'legacy/components/Datasets/reducer'
import CETierReducer from 'legacy/components/FreeTier/reducer'
import productTourReducer from 'legacy/components/ProductTour/reducer'

// VERY LEGACY reducer
import dataReducer from 'legacy/store/reducers/data'

const appReducer = combineReducers({
  data: dataReducer,
  global: globalReducer,
  profile: profileReducer,
  templates: templateReducer,
  dataSources: dataSourcesReducer,
  notification: notificationReducer,
  auth: authReducer,
  uploadDataset: uploadDatasetReducer,
  modal: modalReducer,
  datasets: datasetsReducer,
  sandboxes: sandboxReducer,
  cleanRooms: undoable(cleanRoomReducer),
  project: projectReducer,
  CETier: CETierReducer,
  productTour: productTourReducer,
  inviteCode: inviteCodeReducer,
  testingSuites: testingSuitesReducer,
  createTestingSuite: createTestingSuiteReducer,
})

export const rootReducer = (state: IStore, action: PayloadAction<any>) => {
  if (action.type === globalActions.resetStore.type) {
    state = undefined
  }

  return appReducer(state, action)
}

export type IStore = ReturnType<typeof appReducer>

export const ProjectReportActions = {
  FETCH_REPORT: 'project/report/FETCH_REPORT',
  DID_FETCH_REPORT: 'project/report/DID_FETCH_REPORT',
  ADD_BLOCK: 'project/report/ADD_BLOCK',
  DID_ADD_BLOCK: 'project/report/DID_ADD_BLOCK',
  UPDATE_BLOCK_SETTINGS: 'project/report/UPDATE_BLOCK_SETTINGS',
  DID_UPDATE_BLOCK_SETTINGS: 'project/report/DID_UPDATE_BLOCK_SETTINGS',
  DELETE_BLOCK: 'project/report/DELETE_BLOCK',
  DID_DELETE_BLOCK: 'project/report/DID_DELETE_BLOCK',
  MOVE_BLOCK: 'project/report/MOVE_BLOCK',
  CHANGE_BLOCK_CHECKBOX: 'project/report/CHANGE_BLOCK_CHECKBOX',
  CHANGE_BLOCK_DROPDOWN: 'project/report/CHANGE_BLOCK_DROPDOWN',
  START_LOADING: 'project/report/START_LOADING',
  STOP_LOADING: 'project/report/STOP_LOADING',
}

export const fetchReport = ({ id }) => ({
  type: ProjectReportActions.FETCH_REPORT,
  id,
})

export const didFetchReport = (data) => ({
  type: ProjectReportActions.DID_FETCH_REPORT,
  data,
})

export const addBlock = ({ id, typeBlock }) => ({
  type: ProjectReportActions.ADD_BLOCK,
  id,
  typeBlock,
})

export const didAddBlock = (data) => ({
  type: ProjectReportActions.DID_ADD_BLOCK,
  data,
})

export const updateBlockSettings = ({ id, item_id, settings }) => ({
  type: ProjectReportActions.UPDATE_BLOCK_SETTINGS,
  id,
  item_id,
  settings,
})

export const didUpdateBlockSettings = (data) => ({
  type: ProjectReportActions.DID_UPDATE_BLOCK_SETTINGS,
  data,
})

export const deleteBlock = ({ id, item_id }) => ({
  type: ProjectReportActions.DELETE_BLOCK,
  id,
  item_id,
})

export const didDeleteBlock = (id) => ({
  type: ProjectReportActions.DID_DELETE_BLOCK,
  id,
})

export const moveBlock = ({ id, item_id, new_order }) => ({
  type: ProjectReportActions.MOVE_BLOCK,
  id,
  item_id,
  new_order,
})

export const changeBlockCheckbox = ({
  idx_blocks,
  idx_cat,
  idx_subcat,
  idx_values,
}) => ({
  type: ProjectReportActions.CHANGE_BLOCK_CHECKBOX,
  idx_blocks,
  idx_cat,
  idx_subcat,
  idx_values,
})

export const changeBlockDropdown = ({ dropdown_id, idx_blocks, idx_cat }) => ({
  type: ProjectReportActions.CHANGE_BLOCK_DROPDOWN,
  dropdown_id,
  idx_blocks,
  idx_cat,
})

export const startLoading = () => ({
  type: ProjectReportActions.START_LOADING,
})

export const stopLoading = () => ({
  type: ProjectReportActions.STOP_LOADING,
})

import React, { FC, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { colors } from 'legacy/shared'
import * as api from 'legacy/utils/services/permissions'

import { Button } from 'UIkit/Button/Button'
import { InvitesRow } from './InvitesRow'
import { IInviteItemGroup } from './typings'
import { createInviteItem, validateInvites } from './utils'

interface IInvitesProps {
  projectId?: number
  project: any
  handleSetActions: any
  handleLoading: any
  hideModal: any
  showNotification: any
}

const useStyles = makeStyles({
  invites: {},
  invitesBody: {},
  subtitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: '16px',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0',
  },
  addInvite: {
    background: colors.white,
    borderColor: colors.white,
    color: colors.darkPurple,
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.white,
      color: colors.darkPurple,
      boxShadow: 'none',
      background: 'rgba(207, 206, 217, 0.2)',
    },
  },
})

export const Invites: FC<IInvitesProps> = ({
  projectId,
  handleSetActions,
  handleLoading,
  hideModal,
  showNotification,
}) => {
  const classes = useStyles()

  const initState = [createInviteItem(0)]
  const [invites, setInvites] = useState(initState)

  const handleChange = (item: IInviteItemGroup, index: number) => {
    setInvites((prevInvites) => {
      const nextInvites = [...prevInvites]
      nextInvites[index] = item
      return nextInvites
    })
  }

  const handleSubmit = () => {
    handleLoading(true)
    const invitesData = invites.map(
      ({ items, permission: permissionObject }) => ({
        emails: items.map(({ email }) => email),
        permission: permissionObject.permission,
      })
    )

    const queryTasks: Promise<any>[] = []

    invitesData.forEach((group) => {
      queryTasks.push(
        api.shareDatasetWithEmail({ invites: group, datasetId: projectId })
      )
    })

    Promise.all(queryTasks)
      .then(() => {
        showNotification({
          message: 'Invitation was successfully sent.',
          severity: 'success',
        })
        hideModal()
      })
      .catch((err) => {
        const message = err.error_message
          ? err.error_message
          : 'User is not registered on platform'
        showNotification({ message, severity: 'error' })
      })
      .finally(() => {
        hideModal()
        handleLoading(false)
      })
  }

  const handleAddInvite = () => {
    setInvites((prevInvites) => [
      ...prevInvites,
      createInviteItem(prevInvites.length),
    ])
  }

  useEffect(() => {
    handleSetActions({
      submit: {
        action: handleSubmit,
        label: 'Send',
        disabled: validateInvites(invites),
      },
    })
  }, [invites])

  return (
    <div className={classes.invites}>
      <div className={classes.subtitle}>Send invite</div>
      <div className={classes.invitesBody}>
        {invites.map((group, idx) => (
          <InvitesRow
            key={group.id}
            index={idx}
            value={group}
            onChange={handleChange}
          />
        ))}
      </div>
      <div className={classes.action}>
        <Button
          startIcon={<AddIcon />}
          className={classes.addInvite}
          onClick={handleAddInvite}
        >
          Add people
        </Button>
      </div>
    </div>
  )
}

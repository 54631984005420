export const QualityAndBiasActions = {
  START_QUALITY: 'project/START_QUALITY',
  UPDATE_QUALITY_STATUS: 'project/UPDATE_QUALITY_STATUS',
  START_BIAS: 'project/START_BIAS',
  UPDATE_BIAS_STATUS: 'project/UPDATE_BIAS_STATUS',
  UPDATE_BIAS_STATUS_FAIL: 'project/UPDATE_BIAS_STATUS_FAIL',
  START_BIAS_AND_QUALITY: 'project/START_BIAS_AND_QUALITY',
  DID_FETCH_QUALITY_DATA: 'project/DID_FETCH_QUALITY_DATA',
  DID_FETCH_BIAS_DATA: 'project/DID_FETCH_BIAS_DATA',
  EXPORT_PDF_FAIRNESS: 'project/EXPORT_PDF_FAIRNESS',
}

export const startQuality = (id) => ({
  type: QualityAndBiasActions.START_QUALITY,
  id,
})

export const updateQualityStatus = (status, progress = null) => ({
  type: QualityAndBiasActions.UPDATE_QUALITY_STATUS,
  status,
  progress,
})

export const startBias = ({ id, targetColumn }) => ({
  type: QualityAndBiasActions.START_BIAS,
  id,
  targetColumn,
})

export const updateBiasStatus = (status, progress = null) => ({
  type: QualityAndBiasActions.UPDATE_BIAS_STATUS,
  status,
  progress,
})

export const updateBiasStatusFail = () => ({
  type: QualityAndBiasActions.UPDATE_BIAS_STATUS_FAIL,
})

export const startBiasAndQuality = (id) => ({
  type: QualityAndBiasActions.START_BIAS_AND_QUALITY,
  id,
})

export const didFetchQualityData = (qualityData) => ({
  type: QualityAndBiasActions.DID_FETCH_QUALITY_DATA,
  qualityData,
})

export const didFetchBiasData = (biasData) => ({
  type: QualityAndBiasActions.DID_FETCH_BIAS_DATA,
  biasData,
})

export const exportPDFFairness = ({ id, callback }) => ({
  type: QualityAndBiasActions.EXPORT_PDF_FAIRNESS,
  id,
  callback,
})

const buildData = ({ transform_data, xAxisData, plot_type }) => {
  const transform_arr = []
  const keys = transform_data.map((d) => {
    return { name: d.name, label: d.label, color: d.color }
  })

  for (let i = 0; i < xAxisData.length; i++) {
    const obj = {}

    obj.x = xAxisData[i]
    transform_data.forEach((d, ii) => {
      obj[keys[ii].name] =
        transform_data[ii].values[i] !== null && transform_data[ii].values[i]
          ? transform_data[ii].values[i]
          : 0
    })

    transform_arr.push(obj)
  }

  // for intervals
  if (plot_type === 'density') {
    transform_arr.push({
      x: xAxisData[xAxisData.length - 1],
      origin: 0,
      synth: 0,
    })
  }

  return {
    transform_arr,
    keys,
  }
}

export { buildData }

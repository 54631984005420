import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  SandboxesConfirmActions,
  didBuildSandbox,
  didBuildSandboxFail,
} from './actions'
import { sandBoxesService } from '../../../../utils/services'
import { Routes, pushRoute } from '../../../../utils'
import { getSelectedDatasetsIds } from '../dataset/sandboxDatasetSelectors'

const deriveDatasetId = (arr, prefix) => {
  return arr
    .filter((d) => d.includes(prefix))
    .map((d) => Number(d.split('-')[1]))
}

function* handleBuildSandbox(action) {
  const { sandboxName } = action
  const selectedDatasetsIds = yield select(getSelectedDatasetsIds)

  const datasetIdList = deriveDatasetId(selectedDatasetsIds, 'dataset-')
  const synthIdList = deriveDatasetId(selectedDatasetsIds, 'synth-')

  const sandboxPayload = {
    name: sandboxName,
    dataset_id_list: datasetIdList,
    synthesis_id_list: synthIdList,
  }
  try {
    yield call(sandBoxesService.createSandbox, sandboxPayload)
    yield put(didBuildSandbox())
    yield call(pushRoute, Routes.CleanRooms())
  } catch (error) {
    yield put(didBuildSandboxFail())
  }
}

export default function* watchSandboxesDatasetSaga() {
  yield takeLatest(SandboxesConfirmActions.BUILD_SANDBOX, handleBuildSandbox)
}

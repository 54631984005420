import React, { FC } from 'react'
import AlertMui, { AlertProps } from '@material-ui/lab/Alert'

interface MUIAlertProps extends AlertProps {
  /**
   * The severity of the alert. This defines the color and icon used.
   */
  severity: 'error' | 'info' | 'success' | 'warning'
  /**
   * The variant to use.
   */
  variant?: 'filled' | 'outlined' | 'standard'
  children: React.ReactNode
}

export const Alert: FC<MUIAlertProps> = ({
  severity = 'warning',
  variant = 'standard',
  children,
  ...rest
}) => (
  <AlertMui severity={severity} variant={variant} {...rest}>
    {children}
  </AlertMui>
)

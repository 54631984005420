import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { makeStyles } from '@material-ui/core'
import icon1 from 'legacy/images/avatar.png'

export const ImageCell: React.FunctionComponent<{
  cell: any
  style: any
}> = React.memo(
  ({ cell }) => {
    const userAvatar = cell.value
    const classes = useStyles()

    const speaker = (
      <Popover>
        <div
          className={classes.popoverBody}
          onClick={(e) => e.stopPropagation()}
        >
          {`${cell.row.original.created_by_name}  <`}
          <a href={`mailto:${cell.row.original.created_by}`}>
            {cell.row.original.created_by}
          </a>
          {'>'}
        </div>
      </Popover>
    )
    return (
      <Whisper placement="bottom" speaker={speaker}>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src={userAvatar || icon1}
            alt="avatar"
          />
        </div>
      </Whisper>
    )
  },
  (prevProps, nextProps) => prevProps.cell.value === nextProps.cell.value
)

const useStyles = makeStyles({
  popoverBody: {
    textTransform: 'none',
    color: 'black',
  },
  imageContainer: {
    width: 26,
    height: 26,
    background: '#f5f5f5',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'inline-block',
  },
  image: { height: '100%' },
})

import React from 'react'
import { LinerPlot } from '../../shared'

const lineColor = '#6675F2'
const lineAreaColor = '#E8EAFE'
const plotBackgroundColor = '#F7F7FF'

export const QualityPlot = ({ qualityData }) => {
  if (!qualityData || qualityData.length === 0) {
    return <div data-testid="empty-quality">Empty quality data</div>
  }

  const data = qualityData.map((d) => ({ x: d.n_rows, y: d.quality_score }))
  return (
    <>
      <LinerPlot
        data={data}
        area={[]}
        showArea={false}
        axesLabels={{ x: 'amount', y: 'avg_std' }}
        lineColor={lineColor}
        lineAreaColor={lineAreaColor}
        plotBackgroundColor={plotBackgroundColor}
      />
    </>
  )
}

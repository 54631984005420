import _, { getServerApiUrl } from '../../components/App/Constants'
import 'whatwg-fetch'
import User from '../../components/Account/User'
import { history } from '../index'

const apiUrl = getServerApiUrl()

export const makeRequest = async ({
  path,
  method = 'GET',
  params = {},
  contentType = 'application/json',
  formData = false,
  type = null,
}) => {
  const options = {
    method,
    body: params,
    headers: {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache, must-revalidate',
    },
    mode: 'cors',
    credentials: 'include',
    timeout: 5,
  }
  if (method === 'GET') delete options.body
  if (method === 'GET') delete options.headers['Content-Type']
  if (formData) delete options.headers['Content-Type']

  const token = User.sessionToken
  if (token) options.headers.Authorization = `Bearer ${token}`

  if (params === 'refresh') {
    const refresh_token = User.refreshToken
    if (refresh_token) options.headers.Authorization = `Bearer ${refresh_token}`
  }

  let response = ''

  // for export dataset CSV
  if (type !== 'export') {
    response = await fetch(
      path.indexOf('http') === 0 ? path : apiUrl + path,
      options
    )
      .then(async (response) => {
        const responseContentType = response.headers.get('content-type')

        if (response.status === 500) {
          return Promise.reject({
            status: 500,
            message: 'Something went wrong. Contact us for more information.',
          })
        }
        if (response.status === 401) {
          const res = response.clone()
          res.json().then((data) => {
            if ('logged_out' in data) {
              User.logout()
              history.push('/login')
              return Promise.reject({ type: 'Unauthorized' })
            }
          })
        }

        if (response.status === 401 && history.location.pathname !== '/login') {
          return response.json().then((data) => {
            if (data && data.message && data.message === 'Token has expired') {
              User.logout()
              history.push('/login')
              return Promise.reject({ type: 'Unauthorized' })
            }
            User.logout()
            history.push('/login')
            return Promise.reject({ type: 'Unauthorized' })
          })
        }

        if (response.status === 422) {
          return response.json().then((data) => {
            if (
              data &&
              data.message &&
              data.message === 'Signature verification failed'
            ) {
              User.logout()
              history.push('/login')
              return Promise.reject({ type: 'Unauthorized' })
            }
            return data
          })
        }

        if (response.status === 409 && type === 'datasource') {
          return response.json().then(() => {
            return Promise.reject()
          })
        }

        // for progress when get Synthetic data (click on synthesize button)
        if (response.status === 202 && type && type === 'dataset_synth') {
          return response.json().then((data) => {
            return { data, status: 202 }
          })
        }

        // for progress when get Synthetic data (click on synthesize button)
        if (response.status === 409 && type && type === 'dataset_synth') {
          return response.json().then((data) => {
            return { data, status: 409 }
          })
        }

        if (!response.ok) {
          if (responseContentType?.includes('text/html')) {
            throw new Error(response.statusText)
          }
          return response.json().then((res) => {
            throw { ...res, status: response.status }
          })
        }

        if (response.status === 204) return

        if (type === 'pdf') {
          const filename = response.headers
            .get('content-disposition')
            .split('filename=')[1]
            .replace(/"/g, '')

          const result = await response.arrayBuffer()

          return { data: result, filename }
        }

        // for image
        if (type === 'image') {
          if (responseContentType !== 'image/png') {
            throw new Error('User does not have avatar yet')
          }
          return response.blob()
        }

        // check if content type non json object => return plain text
        if (!responseContentType?.includes('application/json')) {
          return response.text()
        }

        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch((error) => {
        if (error && error.status === 500) {
          throw new Error(
            'Something went wrong. Contact us for more information.'
          )
        }
        if (error && error.type === 'Unauthorized') return

        if (error && error.status === 404) {
          throw error
        }

        if (error && error.status === 403) {
          if (!error.message) {
            error.message = 'Sorry, you have insufficient permissions'
          }
          throw error
        }

        throw new Error(
          error.message || error.error?.message || 'Server error occurred'
        )
      })
  } else {
    response = await fetch(
      path.indexOf('http') === 0 ? path : apiUrl + path,
      options
    ).then(async (response) => {
      if (response.status === 500) {
        return { message: 'File not downloaded!' }
      }
      if (!response.ok) {
        return { message: 'File not downloaded!' }
      }
      const reader = response.body.getReader()

      // Step 2: get total length
      const contentLength = +response.headers.get('Content-Length')

      // Step 3: read the data
      let receivedLength = 0 // received that many bytes at the moment
      const chunks = [] // array of received binary chunks (comprises the body)
      // let bar = document.getElementById('export-progress');
      // let progress = document.getElementById('export_csv_dataset_progress')
      // let bar = document
      //   .getElementById('export_csv_dataset')
      //   .getElementsByTagName('div')[0]

      // progress.style.display = 'block'

      let idx = 1
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }

        chunks.push(value)
        receivedLength += value.length

        if (!(idx % 25)) {
          // const percent = (receivedLength / contentLength) * 100
          // bar.style.transform = `translateX(-${100 - percent}%)`
        }

        idx++
      }

      // Step 4: concatenate chunks into single Uint8Array
      const chunksAll = new Uint8Array(receivedLength)
      let position = 0

      for (const chunk of chunks) {
        chunksAll.set(chunk, position)
        position += chunk.length
      }

      // Step 5: decode into a string
      const result = new TextDecoder('utf-8').decode(chunksAll)

      // progress.style.display = 'none'
      // bar.style.transform = `translateX(-100%)`

      const filename = response.headers
        .get('content-disposition')
        .split('filename=')[1]
        .replace(/"/g, '')

      return { data: result, filename }
    })
  }

  return response
}

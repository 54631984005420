import React, { FC, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableRow,
  TablePagination,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
} from '@material-ui/core'
import { IPermission } from './typings'
import { EnhancedTableToolbar } from './Toolbar'
import { getComparator, stableSort } from './utils'

import { PermissionRow } from './PermissionRow'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tableRoot: {
    minWidth: '400px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '520px',
    border: '1px solid transparent',
    '& MuiTableContainer-root': {
      overflowX: 'none',
    },
  },
  paginationToolbar: {
    minHeight: 0,
    paddingRight: 0,
    paddingLeft: '16px',
    backgroundColor: 'rgba(207, 206, 217, 0.2)',
    height: '40px',
    overflow: 'hidden',
    border: '1px solid #DCE0E6',
    borderTop: 'none',
    borderRadius: '0px 0px 3px 3px',
    fontFamily: 'Loto',
    color: '#0E083F',
    '& .MuiTablePagination-actions': {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '&  .MuiInputBase-input': {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '3px',
      border: '1px solid #dce0e6',
      width: '48px',
      textAlign: 'left' as const,
      height: '24px',
      padding: '3px 8px',
      textAlignLast: 'left' as const,
    },
  },
  paginationToolbarSpacer: {
    display: 'none',
  },
}))

interface IPermissionTableProps {
  data: IPermission[]
  onChange: any
  owner: string
  rowActions: any
}

export const PermissionsTable: FC<IPermissionTableProps> = ({
  data: rows,
  onChange,
  rowActions,
  owner,
}) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const count = page * rowsPerPage
  const pages = page * rowsPerPage + rowsPerPage

  const getSearch = ({ user_email: userMail = '' }: any) =>
    userMail.includes(search) || search.includes(userMail)

  const displayData = rows.filter(getSearch)

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_: any, newPage: any) => {
    setPage(newPage)
  }

  const handleSearch = (e: any) => {
    setSearch(e.target.value)
  }

  const handleSelectRole = ({ props }: any, item: any, index: number) => {
    onChange({ values: props, item, index })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const rowRenderer = (row: any, index: number) => {
    return (
      <PermissionRow
        key={row.id}
        row={row}
        index={index}
        ownerEmail={owner}
        rowActions={rowActions}
        handleSelectRole={handleSelectRole}
      />
    )
  }

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        data={rows}
        sort={handleRequestSort}
        search={search}
        handleSearch={handleSearch}
        handleFilter={() => {}}
      />
      <TableContainer>
        <Table
          classes={{ root: classes.tableRoot }}
          className={classes.table}
          padding="none"
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={6} />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(displayData, getComparator(order, orderBy))
              .slice(count, pages)
              .map(rowRenderer)}
            {!displayData.length && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell align="center" colSpan={6}>
                  Nothing was found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/*@ts-ignore*/}
      <TablePagination
        classes={{
          toolbar: classes.paginationToolbar,
          spacer: classes.paginationToolbarSpacer,
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

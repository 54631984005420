import React from 'react'

import DataSourceForm from './DataSourceForm'
import DataSourceCreateButtons from './DataSourceCreateButtons'

const CreateDatasourceModal = () => {
  return <DataSourceForm ButtonComponent={DataSourceCreateButtons} />
}

export default CreateDatasourceModal

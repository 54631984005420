export enum DataProductsErrors {
  DATA_PRODUCTS_FETCH_FAILED = 'Data products are not loaded',
  DATA_PRODUCT_DELETE_FAILED = 'Data product is not deleted',
  DATA_PRODUCT_UPDATE_FAILED = 'Data product is not updated',
}

export enum TestingSuitesErrors {
  TESTING_SUITES_FETCH_FAILED = 'Testing suites are not loaded',
  TESTING_SUITE_DELETE_FAILED = 'Testing suite is not deleted',
}

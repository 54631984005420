export const actionSetAvatar = (url) => {
  return {
    type: 'SET_AVATAR',
    payload: url,
  }
}

export const actionTabsSidebar = (data) => {
  return {
    type: 'TABS_SIDEBAR',
    payload: data,
  }
}

export const actionResetTabs = () => {
  return {
    type: 'RESET_TABS',
  }
}

export const actionInitData = (data) => {
  return {
    type: 'INIT_DATA',
    payload: data,
  }
}

export const actionInitSynthData = (data) => {
  return {
    type: 'INIT_SYNTH_DATA',
    payload: data,
  }
}

export const actionInitBlocksData = (data, from) => {
  return {
    type: 'INIT_BLOCKS_DATA',
    payload: { data, from },
  }
}

export const actionInitTemplates = (data) => {
  return {
    type: 'INIT_TEMPLATES',
    payload: data,
  }
}

export const actionResetData = () => {
  return {
    type: 'RESET_DATA',
  }
}

export const actionResetState = () => {
  return {
    type: 'RESET_STATE',
  }
}

export const actionGetListDatasets = (data) => {
  return {
    type: 'GET_LIST_DATASETS',
    payload: data,
  }
}

export const actionDeleteDataset = (id) => {
  return {
    type: 'DELETE_DATASET',
    payload: id,
  }
}

export const actionChangeProjectTitle = (title) => {
  return {
    type: 'CHANGE_PROJECT_TITLE',
    payload: title,
  }
}

export const actionEditDatasetColumnsName = (data) => {
  return {
    type: 'EDIT_DATASET_COLUMNS_NAME',
    payload: data,
  }
}

export const actionEditDatasetColumnsTypeFamily = (data) => {
  return {
    type: 'EDIT_DATASET_COLUMNS_TYPE_FAMILE',
    payload: data,
  }
}

export const actionStartLoading = () => {
  return {
    type: 'START_LOADING',
  }
}

export const actionStartLoadingReport = () => {
  return {
    type: 'START_LOADING_REPORT',
  }
}

export const actionStartLoadingDatasource = () => {
  return {
    type: 'START_LOADING_DATASOURCE',
  }
}

export const actionStartLoadingDataset = () => {
  return {
    type: 'START_LOADING_DATASET',
  }
}

export const actionStopLoading = () => {
  return {
    type: 'STOP_LOADING',
  }
}

export const actionStopLoadingDatasource = () => {
  return {
    type: 'STOP_LOADING_DATASOURCE',
  }
}

export const actionStopLoadingReport = () => {
  return {
    type: 'STOP_LOADING_REPORT',
  }
}

export const actionUpdateStatus = (status) => {
  return {
    type: 'UPDATE_STATUS',
    status,
  }
}

export const actionUpdateDisabledColumns = (data) => {
  return {
    type: 'UPDATE_DISABLED_COLUMNS',
    payload: data,
  }
}

export const actionUpdateComplexColumns = (data) => {
  return {
    type: 'UPDATE_COMPLEX_COLUMNS',
    payload: data,
  }
}

export const actionDeleteComplexColumns = (type) => {
  return {
    type: 'DELETE_COMPLEX_COLUMNS',
    payload: type,
  }
}

export const actionAddBlocks = (data) => {
  return {
    type: 'ADD_BLOCK',
    payload: data,
  }
}

export const actionChangeCheckbox = (data, from) => {
  return {
    type: 'CHANGE_CHECKBOX',
    payload: { data, from },
  }
}

export const actionChangeDropdown = (data, from) => {
  return {
    type: 'CHANGE_DROPDOWN',
    payload: { data, from },
  }
}

export const actionRemoveBlock = (id, from) => {
  return {
    type: 'REMOVE_BLOCK',
    payload: { id, from },
  }
}

export const actionSortedBlocksData = (data) => {
  return {
    type: 'SORTED_BLOCKS_DATA',
    payload: data,
  }
}

export const actionSetActiveBlock = (id) => {
  return {
    type: 'SET_ACTIVE_BLOCK',
    payload: id,
  }
}

export const actionChangeColumnName = (index, title) => {
  return {
    type: 'CHANGE_COLUMN_NAME',
    payload: { index, title },
  }
}

// DATASOURCES

export const actionInitListDatasources = (data) => {
  return {
    type: 'INIT_LIST_DATASOURCES',
    payload: data,
  }
}

export const actionUpdateDatasources = (string, data) => {
  return {
    type: 'UPDATE_DATASOURCES',
    payload: { string, data },
  }
}

export const actionInitSourceTables = (data) => {
  return {
    type: 'INIT_SOURCE_TABLES',
    payload: data,
  }
}

export const actionInitQueryData = (data) => {
  return {
    type: 'INIT_QUERY_DATA',
    payload: data,
  }
}

export const actionResetQueryData = () => {
  return {
    type: 'RESET_QUERY_DATA',
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Typography, Spacer, spacing } from 'legacy/shared'
import { Routes } from 'legacy/utils'

import { Header } from 'layouts/Auth/Header'
import { Layout } from 'layouts/Auth/Layout'

const useStyles = makeStyles({
  text: {
    textAlign: 'center',
    width: '310px',
    margin: '0 auto',
  },
  largeSpaceOnTop: {
    marginTop: spacing.gutter * 4,
  },
})

export const SignUpConfirmation: React.FC = () => {
  const classes = useStyles()
  return (
    <Layout>
      <>
        <Header heading="Congratulations! 🎁" />
        <Typography size="body" color="grey" className={classes.text}>
          We are skipping you to the front of our waitlist.
        </Typography>
        <Spacer />
        <Typography size="body" color="grey" className={classes.text}>
          You will realise best data practices with fast ML-driven creation of
          new data assets which never existed before!
        </Typography>
        <Grid container direction="row" alignItems="center" justify="center">
          <Typography size="captionBold" className={classes.largeSpaceOnTop}>
            <Link to={Routes.SignIn()}>Return to Sign In</Link>
          </Typography>
        </Grid>
      </>
    </Layout>
  )
}

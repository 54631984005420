import React, { FC, useMemo } from 'react'
import { SimpleTable as Table, RegularCell } from 'components/Table'

interface IDataGeneratedTableProps {
  data: any
}

export const DataGeneratedTable: FC<IDataGeneratedTableProps> = ({ data }) => {
  const columns = useMemo(
    () =>
      Array.isArray(data) && data.length > 0
        ? Object.keys(data[0]).map((label: any) => ({
            Header: label,
            id: label,
            accessor: label,
            Cell: RegularCell,
          }))
        : [],
    [data]
  )

  return (
    <div style={{ width: '100%', overflowX: 'scroll' }}>
      <Table withPagination columns={columns} data={data} />
    </div>
  )
}

import User from 'legacy/components/Account/User'
import { ProjectSettingsActions } from '../projectSettings/actions'
import { ProjectActions } from '../actions'

const defaultProjectState = {
  synthesisSettings: {
    rowNumber: 1000,
    isOriginalDataIncluded: false,
    isProduceNans: false,
    isImputeNans: true,
    isMitigateBias: true,
    biasMitigationMinDist: 0.05,
  },
  biasSettings: { targetColumn: 'none' },
  displayColumns: [],
}

export default (state = defaultProjectState, action) => {
  switch (action.type) {
    case ProjectSettingsActions.DID_UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      }

    case ProjectActions.DID_FETCH_DATASET: {
      const { synthesisSettings, biasSettings, display_column } =
        action.data.settings
      const { id } = action.data

      const displayColumns = User.getDisplayColumns(id) || display_column

      return {
        ...state,
        synthesisSettings: synthesisSettings || state.synthesisSettings,
        biasSettings: biasSettings || state.biasSettings,
        displayColumns: displayColumns || state.displayColumns,
      }
    }

    default:
      return state
  }
}

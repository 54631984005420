import { Box, Drawer as MaterialDrawer, DrawerProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { Typography } from 'legacy/shared'
import { makeStyles } from '@material-ui/core/styles'

interface IDrawerProps {
  title?: string | ReactElement
  subtitle?: string | ReactElement
  anchor: 'bottom' | 'left' | 'right' | 'top'
}

export const Drawer = ({
  title,
  subtitle,
  children,
  ...props
}: DrawerProps & IDrawerProps) => {
  const classes = useStyles()

  return (
    <MaterialDrawer {...props}>
      <Box py={3} className={classes.drawerContainer}>
        {title && (
          <Box pl={5} mb={3} className={classes.title}>
            <Typography size="subheaderBold">{title}</Typography>
          </Box>
        )}
        {subtitle && (
          <Box pl={5} pb={2} className={classes.subtitle}>
            <Typography size="body">{subtitle}</Typography>
          </Box>
        )}
        <Box className={classes.body}>{children}</Box>
      </Box>
    </MaterialDrawer>
  )
}

const useStyles = makeStyles({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 500,
  },
  title: {
    display: 'flex',
  },
  subtitle: {
    display: 'flex',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
})

export default Drawer

import React from 'react'
import { connect } from 'react-redux'

import { IStore } from 'core/rootReducer'
import { notificationActions } from 'services/notification/reducer'
import { getNotificationState } from 'services/notification/selectors'

import { Notification } from 'legacy/shared'

type SelectedProps = ReturnType<typeof mapStateToProps>
type IDispatchProps = typeof mapDispatchToProps

export const NotificationContainer = (
  props: SelectedProps & IDispatchProps
) => {
  const { message: text } = props

  return <Notification text={text} {...props} />
}

const mapStateToProps = (state: IStore) => getNotificationState(state)

const mapDispatchToProps = {
  hideNotification: notificationActions.hideNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer)

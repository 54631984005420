import React from 'react'

const style = {
  margin: '0.4rem',
  position: 'relative',
}

const cell_data = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Type',
    value: 'column_type',
  },
  {
    label: 'Nullable',
    value: 'is_nullable',
  },
]

class TableComponent extends React.PureComponent {
  render() {
    const { table, name } = this.props

    return (
      <div style={{ ...style }}>
        <div
          className="tables_columns"
          style={{
            minWidth: '180px',
          }}
        >
          {cell_data.map((d, idx) => (
            <div key={idx} className="_cell-styles">
              <span className="_source-bold-text">{`${d.label}: `}</span>
              <span className="_source-light-text">
                {`${this.props[d.value]}`}
              </span>
            </div>
          ))}
          <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
            <div
              className="_add-button"
              onClick={() => this.props.addColumn({ name, table })}
            >
              Add
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TableComponent

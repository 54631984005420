import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Paper } from 'legacy/shared'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import BarChartIcon from '@material-ui/icons/BarChart'
import ShowChartIcon from '@material-ui/icons/ShowChart'

import { Button } from 'UIkit/Button/Button'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    padding: '20px',
  },
  paper: {
    width: '13rem',
    minHeight: '16rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentHeader: {
    marginBottom: '1rem',
  },
  contentDesc: {
    marginTop: '1rem',
    textAlign: 'center',
  },
})

const cards = [
  {
    label: 'Correlation',
    name: 'correlation',
    description: 'Compare correlations in original and synthetic datasets.',
    icon: BarChartIcon,
  },
  {
    label: 'Modelling',
    name: 'modelling',
    description: 'Assess the prediction performance of the synthetic dataset.',
    icon: ShowChartIcon,
  },
]

const Content = ({ label, Icon, description }) => {
  const classes = useStyles()
  return (
    <Box className={classes.content}>
      <Typography
        size="bodyBold"
        color="blueRoyal"
        className={classes.contentHeader}
      >
        {label}
      </Typography>
      <Icon fontSize="large" />
      <Typography
        size="caption"
        color="lightGrey"
        className={classes.contentDesc}
      >
        {description}
      </Typography>
    </Box>
  )
}

const RightPanel = ({ disabled, handlerAddBlocks }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={5} justify="center" alignItems="center">
        {cards.map((d) => (
          <Grid key={d.name} item>
            <Paper className={classes.paper}>
              <Content
                label={d.label}
                Icon={d.icon}
                description={d.description}
              />
              <Button
                color="primary"
                disabled={disabled}
                onClick={() => handlerAddBlocks(d.name)}
              >
                Generate Report
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default RightPanel

import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'

const prefix = 'global'

interface IGlobalState {
  runningTaskList: any
  userLimits: any
  isLoading: boolean
}

const initialState: IGlobalState = {
  runningTaskList: {},
  userLimits: {},
  isLoading: true,
}

const globalReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    updateRunningTaskList: (
      state,
      { payload }: PayloadAction<{ list: any }>
    ) => {
      state.runningTaskList.train = payload.list.filter(
        (d: any) => d.external_task_name === 'train'
      ).length
    },
    updateTrainTask: (state, { payload }: PayloadAction<{ mode: string }>) => {
      state.runningTaskList.train =
        payload.mode === 'add'
          ? state.runningTaskList.train + 1
          : state.runningTaskList.train - 1
    },
    getUserLimits: (state) => {
      state.isLoading = true
    },
    didGetUserLimits: (state, { payload }: PayloadAction<any>) => {
      state.userLimits = payload
      state.isLoading = false
    },
    increaseUploadedProjectNum: ({ userLimits }) => {
      userLimits.num_projects_current += 1
    },
  },
})

// Action to reset redux store
export const resetStore = createAction(`${prefix}/resetStore`)

export const globalActions = { ...globalReducer.actions, resetStore }

export default globalReducer.reducer

import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'

import { authService } from 'legacy/utils/services'

import { forgotPasswordActions } from './reducer'
import { IForgotPasswordForm } from '../typings'

function* handleResetPassword({ payload }: PayloadAction<IForgotPasswordForm>) {
  const body = { email: payload.email }
  try {
    yield call(authService.resetPassword, body)
    yield put(forgotPasswordActions.didResetPassword(body))
  } catch (error) {
    const message =
      error.status === 404 ? 'Given email doesn’t exist' : error.message
    yield put(forgotPasswordActions.didResetPasswordFail({ message }))
  }
}

export default function* () {
  yield takeLatest(
    forgotPasswordActions.resetPassword.type,
    handleResetPassword
  )
}

import React from 'react'
import FreeTierPage from '../FreeTierPage'

import datasources_upgrade_image from '../../images/icons_svg/datasources_free_tier.svg'

const _data = {
  url: datasources_upgrade_image,
  headerTitle: 'Datasources',
  title:
    'Upgrade your account to streamline access to all of your enterprise data',
  subtitles: [
    {
      title:
        'Synthesized has started the data collaboration revolution, unleashing the value of data trapped in any enterprise database, data warehouse or data lake.',
    },
    {
      title: 'Risk-free and without any delays.',
    },
    {
      title:
        'The Synthesized data platform streamlines the process of data extraction and data provisioning with:',
    },
  ],
  checks: [
    {
      title:
        'Out-of-the-box connectivity to virtually any data source on any cloud, private or public.',
    },
    {
      title:
        'Single robust platform synchronised with an arbitrary number of data sources.',
    },
    {
      title: 'All your data and test projects consolidated in one place.',
    },
    {
      title: 'Streamlined collaboration internally with ML-powered access.',
    },
  ],
}

export const DatasourcesFreeTier = () => {
  return <FreeTierPage data={_data} />
}

export default DatasourcesFreeTier

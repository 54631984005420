import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Button } from 'UIkit/Button/Button'
import { TextField, Form } from 'legacy/shared'

import { getProjectTitle } from './projectSelectors'
import { ModalButtonContainer } from '../../../components/Modals/ModalButtonContainer'
import { updateTitle } from './actions'
import { createValidatorWithSchema } from '../../utils/validate'

import {
  getProjectFormReducerState,
  getProjectFormErrorMessageState,
} from './modals/modalSelectors'
import { modalActions } from '../../../services/modal/reducer'

const constraints = {
  title: {
    presence: true,
    length: {
      minimum: 1,
      message: 'Field is required',
    },
  },
}

const ProjectNameForm = (props) => {
  const { title, reducerState, updateTitle, hideModal } = props
  const loading = reducerState === 'saving'

  return (
    <Form
      initialValues={{
        title,
      }}
      onSubmit={({ title }) => updateTitle(title)}
      validate={createValidatorWithSchema(constraints)}
    >
      {({ values, handleChange, submitForm, errors }) => (
        <>
          <TextField
            name="title"
            label="Title"
            fullWidth
            value={values.title}
            onChange={handleChange}
            error={Boolean(errors.title)}
          />
          <ModalButtonContainer>
            <Button loading={loading} onClick={submitForm}>
              Save
            </Button>
            <Button variant="outlined" onClick={hideModal}>
              Cancel
            </Button>
          </ModalButtonContainer>
        </>
      )}
    </Form>
  )
}

const mapStateToProps = createStructuredSelector({
  title: getProjectTitle,
  reducerState: getProjectFormReducerState,
  errorMessage: getProjectFormErrorMessageState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
  updateTitle,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNameForm)

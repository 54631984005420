import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Typography } from 'legacy/shared'

const useStyles = makeStyles({
  totalRow: {
    padding: '0.6rem 0',
  },
})

export const TotalRows = ({ showingRows, totalRows }: any) => {
  const classes = useStyles()
  return (
    <div className={classes.totalRow}>
      <Typography>
        Showing <b>{showingRows}</b> rows of <b>{totalRows}</b>
      </Typography>
    </div>
  )
}

import React from 'react'

import { Header } from 'layouts/Auth/Header'
import { Layout } from 'layouts/Auth/Layout'

import ChangePasswordForm from './ChangePasswordForm'

export const ChangePasswordPage: React.FC = () => {
  return (
    <Layout>
      <Header heading="Change Your Password" />
      <ChangePasswordForm />
    </Layout>
  )
}

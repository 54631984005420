export const getServerApiUrl = () => {
  return '/api/'
}

const storageKeys = {
  SESSION_TOKEN: 'synth_auth_user',
  SESSION_REFRESH_TOKEN: 'synth_refresh',
  USER_ID: 'synth_id',
  USER_AVATAR_PATH: 'synth_avatar_path',
  USER_EMAIL: 'synth_email',
  USER_FIRST_NAME: 'synth_first_name',
  USER_LAST_NAME: 'synth_last_name',
  USER_PHONE_NUMBER: 'synth_phone_number',
  USER_JOB_TITLE: 'synth_job_title',
  USER_COMPANY: 'synth_company',
  USER_ROLES: 'synth_roles',
  USER_TIER: 'synth_tier',
  USER_SHOW_ONBOARDING: 'synth_show_onborading',
  QUERIES: 'synth_queries', // for datasource
}

export default {
  STORAGE: localStorage,
  ...storageKeys,
  IS_SSO_ENABLED: process.env.REACT_APP_IS_SSO_ENABLED
    ? process.env.REACT_APP_IS_SSO_ENABLED === 'true'
    : false, // default is disabled
  IS_INVITES_ENABLED: process.env.REACT_APP_IS_INVITES_ENABLED
    ? process.env.REACT_APP_IS_INVITES_ENABLED === 'true'
    : true, // default is enabled
  IS_EMAIL_CONFIRM_ENABLED: process.env.REACT_APP_IS_EMAIL_CONFIRM_ENABLED
    ? process.env.REACT_APP_IS_EMAIL_CONFIRM_ENABLED === 'true'
    : true, // default is enabled
  IS_TESTING_SUIT_ENABLED:
    process.env.REACT_APP_IS_TESTING_SUIT_ENABLED === 'true',
  IS_FAIRNESS_ENABLED: process.env.REACT_APP_IS_FAIRNESS_ENABLED === 'true',
}

import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { CloseButton } from 'components/CloseButton'
import DropZone from 'components/UploadFileForm/DropZone'

interface IDataUnitFormProps {
  className?: string
  formats?: string
  fileType?: string
  handleDrop: (arg1: any) => void
  handleClose?: (type: string) => void
  multiple: boolean
}

export const DataUnitForm: FC<IDataUnitFormProps> = ({
  fileType,
  handleClose,
  handleDrop,
  multiple,
}) => {
  const styles = useStyles()

  const handleCloseButton = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    handleClose(fileType)
  }

  return (
    <div className={styles.dataUnitForm}>
      {handleClose && (
        <div className={styles.controls}>
          <CloseButton size="small" onClick={handleCloseButton} />
        </div>
      )}
      <DropZone
        multiple={multiple}
        handleDrop={handleDrop}
        formats="CSV"
        inputId={fileType}
      />
    </div>
  )
}

const useStyles = makeStyles({
  dataUnitForm: {
    position: 'relative',
  },
  controls: {
    position: 'absolute',
    left: 'auto',
    right: '12px',
    top: '16px',
    zIndex: 1,
  },
})

import React from 'react'
import { connect } from 'react-redux'

import { selectDataSourceForModal } from 'services/datasources/datasources/selectors'

import DataSourceForm from './DataSourceForm'
import DataSourceEditButtons from './DataSourceEditButtons'

const EditDatasourceModal = ({ selectedDataSource }: any) => {
  const initialValues = {
    ...selectedDataSource,
    port: String(selectedDataSource?.port),
  }

  return (
    <DataSourceForm
      initialValues={initialValues}
      ButtonComponent={DataSourceEditButtons}
    />
  )
}

const mapStateToProps = (state: any) => ({
  selectedDataSource: selectDataSourceForModal(state),
})

export default connect(mapStateToProps, null)(EditDatasourceModal)

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Alert from '@material-ui/lab/Alert'
import { TextField, Typography, Form, spacing } from '../../../../shared'
import { SandboxStepHeader } from '../SandboxStepHeader'
import NavButtons from '../NavButtons'
import { buildSandbox } from './actions'
import {
  getIsSavingInProgress,
  getServerErrorStatus,
} from './sandboxConfirmSelectors'

export const ConfirmSandboxView = (props) => {
  const { buildSandbox, isSaving, serverError } = props
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: spacing.gutter * 4,
        }}
      >
        <SandboxStepHeader label="Check the selected options and input Data Clean Room name" />
      </div>
      <Form
        initialValues={{
          sandboxName: '',
        }}
        onSubmit={({ sandboxName }) => buildSandbox(sandboxName)}
      >
        {(props) => (
          <>
            <TextField
              name="sandboxName"
              label="Data Clean Room Name"
              onChange={props.handleChange}
            />
            {serverError ? <Alert severity="error">{serverError}</Alert> : null}
            <NavButtons
              isNextDisable={props.values.sandboxName.trim().length === 0}
              buttonNames={{ next: 'Build New Data Clean Room', back: 'Back' }}
              confirmAction={props.submitForm}
              loading={isSaving}
            />
          </>
        )}
      </Form>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  isSaving: getIsSavingInProgress,
  serverError: getServerErrorStatus,
})

const mapDispatchToProps = { buildSandbox }

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSandboxView)

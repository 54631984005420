import { takeLatest, put, call, select } from 'redux-saga/effects'
import { dataSetsService } from 'legacy/utils/services'
import User from 'legacy/components/Account/User'
import { getProjectSettings } from '../projectSelectors'
import { transformToBECoupling } from '../helper'
import { ProjectSettingsActions, didUpdateSettings } from './actions'
import {
  getProjectsSettingState,
  getSynthesisSettingsState,
  getBiasSettingsState,
  getDisplayColumnsState,
} from './selectors'

export function* handleUpdateDisplayColumns({ id, settings }) {
  // const displayColumns = yield select(getDisplayColumnsState)

  yield put(didUpdateSettings({ displayColumns: settings }))

  User.setDisplayColumns(id, settings)

  // yield call(handleSaveDatasetSettings, {
  //   id,
  //   prevSettings: { displayColumns },
  // })
}

export function* handleUpdateBiasSettings({ id, settings }) {
  const biasSettings = yield select(getBiasSettingsState)
  const updatedBiasSettings = { ...biasSettings, ...settings }

  yield put(didUpdateSettings({ biasSettings: updatedBiasSettings }))
  yield call(handleSaveDatasetSettings, {
    id,
    prevSettings: { biasSettings },
  })
}

export function* handleUpdateSynthSettings({ id, settings }) {
  const synthesisSettings = yield select(getSynthesisSettingsState)
  const updatedSynthSettings = { ...synthesisSettings, ...settings }

  yield put(didUpdateSettings({ synthesisSettings: updatedSynthSettings }))
  yield call(handleSaveDatasetSettings, {
    id,
    prevSettings: { synthesisSettings },
  })
}

export function* handleSaveDatasetSettings({ id, prevSettings }) {
  try {
    const updatedDatasetSetting = yield select(getProjectsSettingState)
    const settings = yield select(getProjectSettings)
    const transformedSettings = {
      ...settings,
      couplings: transformToBECoupling(settings.couplings),
    }
    yield call(dataSetsService.updateDatasetSettings, {
      id,
      settings: {
        ...transformedSettings,
        ...{
          biasSettings: updatedDatasetSetting.biasSettings,
          synthesisSettings: updatedDatasetSetting.synthesisSettings,
          display_column: updatedDatasetSetting.displayColumns,
        },
      },
    })
  } catch (error) {
    put(didUpdateSettings(prevSettings))
    // add throw
  }
}

export default function* watchProjectSettingsSaga() {
  yield takeLatest(
    ProjectSettingsActions.UPDATE_SYNTHESIS_SETTINGS,
    handleUpdateSynthSettings
  )
  yield takeLatest(
    ProjectSettingsActions.UPDATE_DISPLAY_COLUMNS,
    handleUpdateDisplayColumns
  )
}

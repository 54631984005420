import React, { useState } from 'react'
import { Button } from 'UIkit/Button/Button'
import { DropZone } from 'components/UploadFileForm/DropZone'
import { Box, CircularProgress } from '@material-ui/core'
import { colors } from 'legacy/shared'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { isEmpty, without } from 'lodash'
import { FilePreview } from './FilePreview'
import { ExcelIcon } from '../../pages/TestingSuites/icons'

interface IUploadFileForm {
  isFileUploading: boolean
  multiple: boolean
  embedded?: boolean
  showControl?: boolean
  formats?: string
  handleCancel: () => void
  handleUpload: (UploadPayload: any) => void
}

export const UploadFileForm: React.FunctionComponent<IUploadFileForm> = ({
  isFileUploading,
  multiple,
  embedded = false,
  showControl = true,
  formats,
  handleUpload,
  handleCancel,
}) => {
  const [uploadingFiles, setUploadingFiles] = useState([])
  const styles = useStyles()

  const handleDrop = (uploadedFiles: File[]) => {
    setUploadingFiles(uploadedFiles)
  }
  const handleDeleteFile = (removedFile: File) => {
    setUploadingFiles(
      without(uploadingFiles, removedFile) // Creates an array excluding all given values
    )
  }
  const onCancel = () => {
    handleCancel()
    setUploadingFiles([])
  }
  const onCreate = () => {
    const formData = new FormData()
    uploadingFiles.forEach((file: File) => formData.append('file', file))
    handleUpload(formData)
  }

  return (
    <form className={styles.formContainer}>
      <Box>
        <Box mb={2}>
          <DropZone
            multiple={multiple}
            className={cn({ [styles.embedded]: embedded })}
            handleDrop={handleDrop}
            formats={formats}
          />
        </Box>

        {!isEmpty(uploadingFiles) &&
          uploadingFiles.map((file: File) => (
            <FilePreview
              key={file?.name}
              file={file}
              icon={<ExcelIcon />}
              handleDeleteFile={() => handleDeleteFile(file)}
            >
              <div className={styles.fileName}>{file?.name}</div>
            </FilePreview>
          ))}
      </Box>
      {showControl && (
        <Box mt={6} mb={6} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              onClick={onCancel}
              variant="outlined"
              size="large"
              disabled={isFileUploading}
            >
              Cancel
            </Button>
          </Box>
          <Button
            onClick={onCreate}
            disabled={isFileUploading || isEmpty(uploadingFiles)}
            size="large"
            loading={isFileUploading}
          >
            Create
          </Button>
        </Box>
      )}
    </form>
  )
}

const useStyles = makeStyles({
  formContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  embedded: {
    border: '1px dashed',
    borderColor: colors.disabledGrey,
    backgroundColor: colors.creame,
  },
  fileName: {
    fontWeight: 'bold',
  },
  progress: {
    margin: '0 auto',
  },
})

export default UploadFileForm

import { IStore } from 'core/rootReducer'
import { createSelector } from 'reselect'

export const getTestingSuitesState = (state: IStore) => state.testingSuites
export const selectSuites = createSelector(
  getTestingSuitesState,
  ({ suites }) => suites
)
export const selectIsLoading = createSelector(
  getTestingSuitesState,
  ({ loading }) => loading
)
export const selectUploadedFiles = createSelector(
  getTestingSuitesState,
  ({ files }) => files
)

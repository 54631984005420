import numeral from 'numeral'
import _v from '../styles/_variables.scss'
import { transformToUICouplings } from '../components/Projects/helper'

const initData = (data, from) => {
  if (from === 'origin' && !data.settings) {
    data.settings = {}
    data.settings.couplings = []
    data.settings.conditions = {}
  } else if (from === 'origin' && data.settings) {
    if (!data.settings.couplings) {
      data.settings.couplings = []
    } else {
      data.settings.couplings = transformToUICouplings(data.settings.couplings)
    }
    if (!data.settings.conditions) data.settings.conditions = {}
  }

  return data
}

const formatNumber = (number, defaultFmt = '0.[00000]') => {
  if (number === 0) {
    return 0
  }
  return numeral(number).format(
    ((number > 0 && number < 0.00001) || (number > -0.00001 && number < 0)) &&
      number !== 0
      ? '0.0e+0'
      : number >= 1000000000000
      ? '0.0e+0'
      : number >= 1000000
      ? '0.[00]a'
      : number >= 1000
      ? '0,0'
      : number >= 100
      ? '0.[000]'
      : number >= 10
      ? '0.[0000]'
      : defaultFmt
  )
}

// build correlation r-panel
const getCorrelationObject = (data) => {
  const obj = {
    id: data.id,
    order: data.order,
    results: data.results,
    settings: data.settings,
    name: 'correlation',
    head: 'Variable selections',
    cats: [
      {
        title: 'Continuous columns',
        type: 'input',
        values: [
          {
            title: null,
            values: data.options.columns.map((d, i) => {
              return {
                check: data.settings.columns
                  ? data.settings.columns.some((s) => s === d)
                  : false,
                color: _v.orange,
                label: d,
              }
            }),
          },
        ],
      },
    ],
  }

  return obj
}

const transformCorrelationChartData = (data_item) => {
  const keys = Object.keys(data_item).filter((d) => data_item[d] !== null)

  // [{0: (original_sample) {x: ..., y: ..., color: ...}}, {1: (synthetic_sample) {x: ..., y: ..., color: ...}}]
  const transform_data_item = keys.map((d) => {
    return data_item[d].x.map((dd, ii) => {
      return {
        x: !isNaN(+dd) ? +dd : 0,
        y: !isNaN(+data_item[d].y[ii]) ? +data_item[d].y[ii] : 0,
        x_label: data_item[d].x_label,
        y_label: data_item[d].y_label,
        color: data_item[d].color,
      }
    })
  })

  return keys.length > 1
    ? transform_data_item[0].concat(transform_data_item[1])
    : transform_data_item[0]
}

// build moddeling r-panel
const getModellingObject = (data) => {
  const columns = data.options.categorical_columns.concat(
    data.options.continuous_columns
  )
  // const models = data.options["categorical_models"].concat(data.options["continuous_models"]);
  const models =
    data.options.categorical_columns
      .map((d) => d)
      .indexOf(data.settings.response_variable) !== -1
      ? data.options.categorical_models
      : data.options.continuous_models

  const obj = {
    id: data.id,
    order: data.order,
    options: data.options,
    results: data.results,
    settings: data.settings,
    name: 'modelling',
    head: 'Modelling options',
    cats: [
      {
        title: 'Response variable',
        type: 'dropdown',
        name: 'response_variable',
        values: columns.map((d, i) => {
          return {
            id: i, // TODO temporary. after possible delete, if make with requests but not redux
            label: d,
            active: data.settings.response_variable === d,
          }
        }),
      },
      {
        title: 'Explanatory variables',
        type: 'input',
        values: [
          {
            title: 'Categorical Columns',
            values: data.options.categorical_columns.map((d, i) => {
              return {
                disabled: data.settings.response_variable === d,
                check: data.settings.explanatory_variables
                  ? data.settings.explanatory_variables.some((s) => s === d)
                  : false,
                color: _v.yellow,
                label: d,
              }
            }),
          },
          {
            title: 'Continuous Columns',
            values: data.options.continuous_columns.map((d, i) => {
              return {
                disabled: data.settings.response_variable === d,
                check: data.settings.explanatory_variables
                  ? data.settings.explanatory_variables.some((s) => s === d)
                  : false,
                color: _v.green,
                label: d,
              }
            }),
          },
        ],
      },
      {
        title: 'Model',
        type: 'dropdown',
        name: 'model',
        values: models.map((d, i) => {
          return {
            id: i, // TODO temporary. after possible delete, if make with requests but not redux
            label: d,
            active: data.settings.model === d,
          }
        }),
      },
    ],
  }

  return obj
}

const getNeededColumnObject = (type) => {
  let obj = {}

  switch (true) {
    case type === 'ID':
      obj = {
        name: 'id_column',
        type: 'ID',
        settings: {
          start: 0,
        },
      }
      break
    case type === 'CATEGORICAL':
      obj = {
        name: 'categorical_column',
        type: 'CATEGORICAL',
        settings: {
          classes: [
            {
              name: 'male',
              ratio: 0.4,
            },
            {
              name: 'female',
              ratio: 0.6,
            },
          ],
        },
      }
      break
    case type === 'CONTINUOUS':
      obj = {
        name: 'continuous_column',
        type: 'CONTINUOUS',
        settings: {
          distribution: 'gauss',
          range_start: -5,
          range_end: 5,
          mean: parseFloat(0.5),
          std: parseFloat(0.5),
          loc: 0,
          scale: 1,
          shape: 1,
        },
      }
      break
    case type === 'DATE_TIME':
      obj = {
        name: 'date_time_column',
        type: 'DATE_TIME',
        settings: {
          range_end: '2018-12-01T00:00:00Z',
          range_start: '2018-01-01T00:00:00Z',
        },
      }
      break
    default:
      break
  }
  return obj
}

const transformCouplings = (checks) => {
  if (checks.couplings) {
    if (checks.couplings.length === 0) {
      checks.couplings = {}
      return checks
    }

    const obj = {}
    checks.couplings.forEach((d) => {
      obj[d.complexSelect] = d.name
    })
    checks.couplings = obj
  }

  return checks
}

export {
  initData,
  formatNumber,
  getCorrelationObject,
  getModellingObject,
  getNeededColumnObject,
  transformCorrelationChartData,
  transformCouplings,
}

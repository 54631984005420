import React, { useState, useMemo, useRef } from 'react'
import classnames from 'classnames'
import MUITable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { CollapseRow } from './CollapseRow'

import {
  getComparator,
  stableSort,
  filterData,
  EmptyMessage,
  TableSpinner,
  useTableStyles,
} from './Table'

const getListStyle = () => ({
  background: '#dde1e7',
})

export const DNDTable = (props) => {
  const {
    headerRow,
    bodyRow,
    rows,
    loading,
    filterString = '',
    defaultSort = { name: 'name', order: 'asc' },
    cellClasses = {},
    className = '',
    emptyMessage = '',
    hover = false,
    customProps = {},
    updateGroupPosition,
    isDragDisabled = false,
    CollapseComponent,
  } = props
  const classes = useTableStyles()
  const detailsRef = useRef(null)
  const [order, setOrder] = useState(defaultSort.order)
  const [orderBy, setOrderBy] = useState(defaultSort.name)

  const handleSort = (column) => {
    const isAsc = orderBy === column && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(column)
  }

  const filterKeys = useMemo(
    () => headerRow.map((header) => header.id).filter(Boolean),
    [headerRow]
  )
  const tableClasses = {
    cell: { root: cellClasses.root ? cellClasses.root : '' },
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    updateGroupPosition({
      oldIndex: result.source.index,
      newIndex: result.destination.index,
    })
  }

  return (
    <div className={classnames(classes.root, className)}>
      <TableContainer style={{ padding: '4px' }}>
        <MUITable aria-label="simple table" style={{ minWidth: '860px' }}>
          <TableHead>
            <TableRow>
              <TableCell
                classes={{ root: tableClasses.cell.root }}
                style={{ minWidth: '58px' }}
              />
              <TableCell
                classes={{ root: tableClasses.cell.root }}
                style={{ minWidth: '58px' }}
              />
              {headerRow.map((header) =>
                header.sortable ? (
                  <TableCell
                    ref={header.id === 'details' ? detailsRef : null}
                    sortDirection={orderBy === header.id ? order : false}
                    classes={{
                      root: classnames(
                        tableClasses.cell.root,
                        header.align ? header.align : '',
                        header.width ? header.width : ''
                      ),
                      head: header.class,
                    }}
                    style={
                      header.width
                        ? {
                            width: header.width,
                            minWidth:
                              header.id === 'details' ? '100%' : 'initial',
                          }
                        : {}
                    }
                    align={header.align || 'left'}
                  >
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={() => handleSort(header.id)}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    ref={header.id === 'details' ? detailsRef : null}
                    classes={{
                      root: classnames(
                        tableClasses.cell.root,
                        header.align ? header.align : '',
                        header.width ? header.width : ''
                      ),
                      head: header.class,
                    }}
                    align={header.align || 'left'}
                    style={
                      header.width
                        ? {
                            width: header.width,
                            minWidth:
                              header.id === 'details' ? '100%' : 'initial',
                          }
                        : {}
                    }
                  >
                    {header.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => {
                return (
                  <TableBody
                    {...provided.droppableProps}
                    style={getListStyle()}
                    ref={provided.innerRef}
                  >
                    {stableSort(
                      filterData(rows, filterString, filterKeys),
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      return (
                        <Draggable
                          isDragDisabled={isDragDisabled}
                          key={row.id}
                          draggableId={String(row.id)}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <CollapseRow
                                index={index}
                                headerRow={headerRow}
                                bodyRows={bodyRow}
                                row={row}
                                tableClasses={tableClasses}
                                customProps={customProps}
                                hover={hover}
                                provided={provided}
                                snapshot={snapshot}
                                detailsRef={detailsRef}
                                CollapseComponent={CollapseComponent}
                              />
                            )
                          }}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </TableBody>
                )
              }}
            </Droppable>
          </DragDropContext>
        </MUITable>
        {loading ? <TableSpinner /> : null}
      </TableContainer>
      {emptyMessage && !rows.length && (
        <EmptyMessage emptyMessage={emptyMessage} />
      )}
    </div>
  )
}

import React from 'react'
import { connect } from 'react-redux'
import { IStore } from 'core/rootReducer'

import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'

import User from 'legacy/components/Account/User'
import { Button } from 'UIkit/Button/Button'
import { Form, TextField, Typography, spacing } from 'legacy/shared'

import { profileActions } from 'services/profile/reducer'
import { selectProfileFormState } from 'services/profile/selectors'

import { getInitialValues } from './utils'

const ProfileFormSchema = {
  fullname: constraints.fullname,
  phoneNumber: constraints.phoneNumber,
}

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const fieldMargin = {
  marginBottom: spacing.gutterLarge,
}

export const ProfileForm: React.FC<SelectedProps & DispatchProps> = ({
  formState,
  changeUserInfo,
}) => {
  const { isLoading, serverError } = formState

  const initialValues = getInitialValues(User)
  return (
    <>
      <Form
        onSubmit={changeUserInfo}
        initialValues={initialValues}
        validate={createValidatorWithSchema(ProfileFormSchema)}
      >
        {({ values, errors, handleSubmit, handleChange }: any) => {
          return (
            <>
              <TextField
                name="email"
                label="Email"
                value={values.email}
                variant="outlined"
                fullWidth
                style={fieldMargin}
                disabled
              />
              <TextField
                name="fullname"
                label="Fullname"
                value={values.fullname}
                onChange={handleChange}
                error={Boolean(errors.fullname)}
                helperText={errors.fullname && errors.fullname[0]}
                variant="outlined"
                fullWidth
                style={fieldMargin}
              />
              <TextField
                name="phoneNumber"
                label="Phone Number"
                value={values.phoneNumber}
                onChange={handleChange}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber && errors.phoneNumber[0]}
                variant="outlined"
                fullWidth
                style={fieldMargin}
              />
              <TextField
                name="title"
                label="Job Title"
                value={values.title}
                onChange={handleChange}
                error={Boolean(errors.title)}
                helperText={errors.title && errors.title[0]}
                variant="outlined"
                fullWidth
                style={fieldMargin}
              />
              <TextField
                name="company"
                label="Company"
                value={values.company}
                onChange={handleChange}
                error={Boolean(errors.company)}
                helperText={errors.company && errors.company[0]}
                variant="outlined"
                fullWidth
                style={fieldMargin}
              />
              {serverError && (
                <Typography color="red">{serverError.message}</Typography>
              )}

              <Button
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                fullWidth
                onClick={handleSubmit}
              >
                Save Changes
              </Button>
            </>
          )
        }}
      </Form>
    </>
  )
}

const mapStateToProps = (state: IStore) => ({
  formState: selectProfileFormState(state),
})

const mapDispatchToProps = {
  changeUserInfo: profileActions.changeUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)

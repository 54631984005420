import * as React from 'react'
import classnames from 'classnames'
import { Divider as MUIDivider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../theme'

const useStyles = makeStyles({
  hor: {
    marginTop: spacing.gutterLarge,
    marginBottom: spacing.gutterLarge,
  },
})

const Component = ({ className, orientation = 'horizontal', ...rest }) => {
  const classes = useStyles()

  return (
    <MUIDivider
      className={classnames(className, {
        [classes.hor]: orientation === 'horizontal',
      })}
      {...rest}
      orientation={orientation}
    />
  )
}

export const Divider = (props) => <Component {...props} />

Divider.displayName = 'Divider'

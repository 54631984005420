import { all } from 'redux-saga/effects'
import watchDatasets from '../components/Datasets/sagas'
import watchSandboxBuildSaga from '../components/Sandboxes/build/sandboxBuildSagas'
import watchProjectAllSaga from '../components/Projects/sagas'
import watchDataSourceSaga from '../components/Datasources/dataSourceSagas'
import watchFreeTier from '../components/FreeTier/sagas'
import watchProductTour from '../components/ProductTour/sagas'

export const legacySagas = [
  watchDatasets(),
  watchSandboxBuildSaga(),
  watchProjectAllSaga(),
  watchDataSourceSaga(),
  watchFreeTier(),
  watchProductTour(),
]

export default function* rootSaga() {
  yield all(legacySagas)
}

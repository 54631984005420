import React from 'react'
import { connect } from 'react-redux'
import { colors } from '../../../Colors'

import Chart from '../../../LegacyChart'

class DropCharts extends React.Component {
  render() {
    const {
      plot_data,
      plot_type,
      type,
      mean,
      std,
      median,
      max,
      min,
      height,
      _from,
    } = this.props

    const transform_data = []
    const density_arr = []

    if (plot_data.density) {
      density_arr.push({
        name: 'origin',
        label: 'Data',
        color: colors[0],
        values: plot_data.density,
      })
    }

    if (plot_type === 'density') {
      transform_data.push({
        name: 'origin',
        label: 'Data',
        color: colors[0],
        values: plot_data.hist,
      })
    }

    const options = {
      transform_data,
      density_arr,
      xAxisData: plot_type === 'density' ? plot_data.edges : plot_data.bins,
      density_support: plot_data.density_support,
      truncated: plot_data.truncated,
      plot_type,
      type,
      mean,
      std,
      median,
      max,
      min,
      height,
      _from,
    }

    return (
      <React.Fragment>
        <Chart {...options} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { query_data } = state.data
  return {
    ...state,
    query_data: query_data || {},
  }
}

export default connect(mapStateToProps, null)(DropCharts)

import React from 'react'
import { makeStyles } from '@material-ui/core'
import { colors } from '../../legacy/shared'

export const CustomHeaderCell: React.FunctionComponent<{
  title: string
}> = React.memo(
  ({ title }: { title: string }) => {
    const classes = useStyles()

    return <div className={classes.headerCellStyle}>{title}</div>
  },
  (prev, next) => prev.title === next.title
)

const useStyles = makeStyles({
  headerCellStyle: {
    fontSize: '.7rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.lightGrey,
  },
})

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { Button } from 'UIkit/Button/Button'

import HeaderComponent from 'layouts/Header'
import { PageContentLayout } from './PageContentLayout'
import { Typography, colors } from '../shared'

import { CETierActions } from './FreeTier/reducer'
import { getIsLoading } from './FreeTier/selectors'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      [theme.breakpoints.down(1115)]: {
        justifyContent: 'center',
      },
    },
    image: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      height: '27rem',
      width: '27rem',
    },
    paper: {
      maxWidth: '21rem',
      margin: theme.spacing(0, 7),
      marginTop: theme.spacing(1),
    },
    rightMarginTop: {
      marginTop: theme.spacing(2),
    },
    rightButtonsMarginTop: {
      marginTop: theme.spacing(4),
    },
    checkContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    button: {
      width: '140px',
    },
    or: {
      margin: theme.spacing(0, 1),
    },
    learnmore: {
      width: '100%',
      marginBottom: '20px',
    },
    lineHeight: {
      lineHeight: '1.3rem',
    },
  }
})

export const FreeTierPage = ({ data, sendContactUs, isLoading }) => {
  const classes = useStyles()

  return (
    <>
      {data.headerTitle ? <HeaderComponent title={data.headerTitle} /> : null}
      <PageContentLayout>
        <div className={classes.root}>
          <div
            className={classes.image}
            style={{ backgroundImage: `url(${data.url})` }}
          />
          <div>
            <div className={classes.paper}>
              <Typography size="title" color="freeTierPage">
                {data.title}
              </Typography>
              {data.subtitles.map((d, i) => (
                <Typography
                  key={d.title + i}
                  color="freeTierPage"
                  className={classnames(
                    classes.rightMarginTop,
                    classes.lineHeight
                  )}
                >
                  {d.title}
                </Typography>
              ))}
              {data.checks.map((d, i) => (
                <div
                  key={d.title + i}
                  className={classnames(
                    classes.checkContainer,
                    classes.rightMarginTop
                  )}
                >
                  <CheckCircleOutlineOutlinedIcon
                    style={{
                      marginRight: '.5rem',
                      fontSize: '2rem',
                      fill: colors.freeTierPage,
                    }}
                  />
                  <Typography
                    color="freeTierPage"
                    className={classes.lineHeight}
                  >
                    {d.title}
                  </Typography>
                </div>
              ))}
              <div
                className={classnames(
                  classes.buttons,
                  classes.rightButtonsMarginTop
                )}
              >
                <Button
                  className={classes.button}
                  onClick={() => sendContactUs()}
                  loading={isLoading}
                >
                  Contact Us
                </Button>
              </div>
              <Box
                className={classnames(
                  classes.learnmore,
                  classes.rightButtonsMarginTop
                )}
              />
            </div>
          </div>
        </div>
      </PageContentLayout>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  isLoading: getIsLoading,
})

const mapDispatchToProps = { sendContactUs: CETierActions.sendContactUs }

export default connect(mapStateToProps, mapDispatchToProps)(FreeTierPage)

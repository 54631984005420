import React from 'react'
import classnames from 'classnames'
import { Stepper as MUIStepper, Step, StepButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '../typography'

const useStyles = makeStyles({
  text: {
    textAlign: 'left',
  },
  optionalText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 250,
  },
})

const Component = (props) => {
  const { activeStep, steps, onClickStep, selectedData = [], classes } = props
  const ownClasses = useStyles()
  return (
    <MUIStepper activeStep={activeStep} classes={classes}>
      {steps.map((label, i) => {
        const stepProps = {}
        const buttonProps = {
          optional: (
            <Typography
              className={classnames(ownClasses.text, ownClasses.optionalText)}
            >
              {selectedData[i] || ''}
            </Typography>
          ),
        }
        return (
          <Step key={label} {...stepProps}>
            <StepButton onClick={() => onClickStep(i)} {...buttonProps}>
              <Typography className={ownClasses.text}>{label}</Typography>
            </StepButton>
          </Step>
        )
      })}
    </MUIStepper>
  )
}

export const Stepper = (props) => <Component {...props} />

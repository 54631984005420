import React from 'react'
import ReactDOM from 'react-dom'
import * as d3 from 'd3-legacy'

class DigitalBox extends React.Component {
  componentDidMount() {
    const { data, color } = this.props

    this.initGraph(data, color)
  }

  initGraph(data, color) {
    const margin = { top: -1, right: 0, bottom: 0, left: 0 }

    this.svg = d3
      .select(ReactDOM.findDOMNode(this))
      .append('svg')
      .attr('width', '100%')
      .attr('height', '120px')
      .attr('fill', 'green')

    this.tooltip = d3.select('.tooltip')

    if (!this.tooltip.node()) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
    }

    this.g = this.svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    this.renderDigitalBox(data, color)
  }

  renderDigitalBox(data, color) {
    this.g.select('g').remove()

    const { tooltip } = this
    this.g
      .append('rect')
      .attr('class', 'rect_color')
      .attr('fill', color)
      .attr('height', '100%')
      .attr('width', '100%')

    this.g
      .append('text')
      .attr('class', 'text')
      .attr('text-anchor', 'start')
      .attr('x', 10)
      .attr('y', 60)
      .attr('dy', '.35em')
      .attr('fill', '#fff')
      .attr('cursor', 'default')
      .text(data === 0 ? 0 : `${data}%`)

    this.g
      .append('rect')
      .attr('class', 'rect_tooltip')
      .attr('fill', 'transparent')
      .attr('height', '100%')
      .attr('width', '100%')
      .on('mouseover', function (d, i) {
        d3.select(this).attr('fill', '#fff').style('opacity', 0.15)
        const tpl = `
        <ul class="t-ul-style">
          <li class="t-li-style" ><div class="t-li-legend" style="background-color: ${color}"></div><div>
          ${
            data === 0
              ? 'No correlation in original data'
              : `Similarity of correlation: ${data}%`
          }
          </div></li>
        </ul>`

        tooltip.transition().duration(100).style('opacity', 0.9)
        tooltip
          .html(tpl)
          .style('left', `${d3.event.pageX + 8}px`)
          .style('top', `${d3.event.pageY - 48}px`)
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('fill', 'transparent').style('opacity', 0)
        tooltip.transition().duration(200).style('opacity', 0)
      })
  }

  redrawDigitalBox(data, color) {
    const rect_color = this.svg.select('g').selectAll('.rect_color')
    const text = this.svg.select('g').selectAll('.text')
    // const rect_tooltip = this.svg.select("g").selectAll(".rect_tooltip");

    rect_color.attr('fill', color)
    text.text(data)
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillReceiveProps(next) {
    if (next.data !== this.props.data || next.color !== this.props.color) {
      this.redrawDigitalBox(next.data, next.color)
    }
  }

  render() {
    return (
      <div className="digital_box" style={{ width: '100%', height: '100%' }}>
        {this.props.children}
      </div>
    )
  }
}

export default DigitalBox

import React, { FC, useMemo } from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import {
  SimpleTable as Table,
  BarCell,
  RegularCell,
  RowProps,
} from 'components/Table'
import { getColor } from './utils'
import { DataTabs } from './DataTabs'
import { selectTotalData, selectTotalValue } from './selectors'

interface IDataTableProps {
  loading?: boolean
  data?: any
  columns?: any
}

const percantageFormatter = (value: number) => Math.round(value * 100)

const Cell = (cellProps: any) => {
  const value = percantageFormatter(cellProps.cell.value)
  return !cellProps.row.original.noData ? (
    <BarCell value={value} {...getColor(value)} />
  ) : null
}

const CellTotal = (cellProps: any) => {
  const value = selectTotalValue(
    cellProps.row.values.analyzedrules,
    cellProps.cell.value
  )

  return !cellProps.row.original.noData ? (
    <BarCell value={value} {...getColor(value)} />
  ) : null
}

const CellRegular = (cellProps: any) =>
  !cellProps.row.original.noData ? (
    <RegularCell value={cellProps.cell.value} />
  ) : null

const columns = [
  {
    Header: 'Target',
    id: 'target',
    accessor: 'target',
    width: 356,
    Cell: CellRegular,
  },
  {
    Header: 'Old coverage',
    accessor: 'coverage_old.coverage',
    id: 'oldcoverage',
    alignItems: 'center',
    justifyContent: 'center',
    Cell,
  },
  {
    Header: 'New coverage',
    id: 'newcoverage',
    accessor: 'coverage_new.coverage',
    alignItems: 'center',
    justifyContent: 'center',
    Cell,
  },
  {
    Header: 'Samples old',
    id: 'samplesold',
    alignItems: 'center',
    justifyContent: 'center',
    accessor: 'coverage_old.samples',
    Cell: CellRegular,
  },
  {
    Header: 'Samples new',
    id: 'samplesnew',
    justifyContent: 'center',
    alignItems: 'center',
    accessor: 'coverage_new.samples',
    Cell: CellRegular,
  },
  {
    Header: 'Analyzed rules',
    id: 'analyzedrules',
    alignItems: 'center',
    accessor: 'n_analyzed_rules',
    Cell: CellRegular,
  },
  {
    Header: 'Total rules',
    id: 'analyzedrulesPercentage',
    alignItems: 'center',
    accessor: 'n_total_rules',
    Cell: CellTotal,
  },
]

export const DataTable: FC<IDataTableProps> = ({ loading = false, data }) => {
  const handleRowClick = () => {}

  const renderRowSubComponent = ({ row }: RowProps) => (
    <DataTabs data={row.original} />
  )

  const footerData = useMemo(() => selectTotalData(data), [data])

  return (
    <TableContainer>
      {data && data.length > 0 && (
        <Table
          expandable
          footerData={footerData}
          onRowClick={handleRowClick}
          loading={loading}
          data={data}
          renderRowSubComponent={renderRowSubComponent}
          columns={columns}
        />
      )}
      {(!data || data.length === 0) && (
        <Box>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            No data in report...
          </Typography>
        </Box>
      )}
    </TableContainer>
  )
}

const TableContainer = styled(Box)({
  height: 'calc(100% - 64px)',
  overflow: 'auto',
})

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { omit } from 'lodash'

import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { Alert, Button, CheckBox } from 'UIkit'
import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'

import { Routes, pushRoute } from 'legacy/utils'
import User from 'legacy/components/Account/User'
import { authService } from 'legacy/utils/services'

import { globalActions } from 'services/global/reducer'
import { productTourActions } from 'legacy/components/ProductTour/reducer'

import { Form, TextField, Typography, spacing } from 'legacy/shared'
import { RequestStatus } from 'utils/constants'

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: spacing.gutterLarge,
  },
  rememberMeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  errorContainer: {
    marginBottom: spacing.gutterLarge,
  },
})

const SignInValidationSchema = {
  email: constraints.email,
  password: constraints.requiredString,
}

type DispatchProps = typeof mapDispatchToProps

type ISignInProps = DispatchProps

const fieldMargin = {
  marginBottom: spacing.gutterLarge,
}

export const SignInForm: React.FunctionComponent<ISignInProps> = ({
  resetStore,
  setIsTourEnabled,
}) => {
  const [signInStatus, setSignInStatus] = useState<{
    status: RequestStatus
    message?: string
  }>({
    status: RequestStatus.Idle,
    message: '',
  })

  const submitSignIn = async (values: any) => {
    const body = omit(values, 'rememberMe')
    try {
      setSignInStatus({ status: RequestStatus.Loading })
      const tokens = await authService.userAuthenticate(body)
      User.login(tokens, {
        email: values.email,
        password: values.password,
      })

      const user: any = await authService.userGetInfo()
      User.create_user_info(user)

      setSignInStatus({ status: RequestStatus.Succeeded })

      // turn on Segment analytics
      // @ts-ignore
      if (window.analytics) {
        // @ts-ignore
        window.analytics.identify(user.user_id, {
          email: user.email,
        })
      }
      //
      resetStore()

      setIsTourEnabled({ isEnabled: true })

      pushRoute(Routes.Root())
    } catch (error) {
      setSignInStatus({ status: RequestStatus.Failed, message: error.message })
    }
  }

  const classes = useStyles()
  return (
    <Form
      onSubmit={submitSignIn}
      initialValues={{ email: '', password: '', rememberMe: false }}
      validate={createValidatorWithSchema(SignInValidationSchema)}
    >
      {({ values, errors, handleSubmit, handleChange, setFieldValue }: any) => {
        const handleCheckBoxChange = (
          _: React.ChangeEvent<{}>,
          checked: boolean
        ) => {
          setFieldValue('rememberMe', checked)
        }
        return (
          <>
            <TextField
              id="sign_in_email"
              name="email"
              label="Email"
              type="email"
              value={values.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              id="sign_in_password"
              name="password"
              label="Password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            {signInStatus.status === RequestStatus.Failed && (
              <Alert
                severity="error"
                data-testid="sign_in_error"
                className={classes.errorContainer}
              >
                {signInStatus.message}
              </Alert>
            )}
            <div className={classes.actionsContainer}>
              <div className={classes.rememberMeContainer}>
                <CheckBox
                  onChange={handleCheckBoxChange}
                  label={
                    <Typography size="captionBold" color="stone">
                      Remember me
                    </Typography>
                  }
                  checked={values.rememberMe}
                  className={classes.checkbox}
                />
              </div>
              <div>
                <Typography size="captionBold">
                  <Link to={Routes.ForgotPassword()}>Forgot Password?</Link>
                </Typography>
              </div>
            </div>
            <Button
              data-testid="sign_in_submit"
              type="submit"
              color="primary"
              variant="contained"
              loading={signInStatus.status === RequestStatus.Loading}
              fullWidth
              onClick={handleSubmit}
            >
              Sign In
            </Button>
          </>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = {
  resetStore: globalActions.resetStore,
  setIsTourEnabled: productTourActions.setIsTourEnabled,
}

export default connect(null, mapDispatchToProps)(SignInForm)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TemplateResponse } from 'api/generated'
import { RequestStatus } from 'utils/constants'
import { arrayToObjectById } from 'utils/arrayToObjectById'

import { ITemplateState } from './types'

const prefix = 'templates'

const initialState: ITemplateState = {
  byId: {},
  status: RequestStatus.Idle,
  serverError: null,
  isDatasetCreating: false,
  selectedTemplateId: null,
}

const templateReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchTemplates: (state) => {
      state.status = RequestStatus.Loading
      state.serverError = null
    },
    didFetchTemplates: (
      state,
      { payload }: PayloadAction<{ templates: TemplateResponse[] }>
    ) => {
      state.byId = arrayToObjectById(payload.templates, 'id')
      state.status = RequestStatus.Succeeded
      state.serverError = null
    },
    didFetchTemplatesFail: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.byId = {}
      state.status = RequestStatus.Failed
      state.serverError = payload.message
    },
    createDataset: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.isDatasetCreating = true
      state.selectedTemplateId = payload.id
    },
    didCreateDataset: (state) => {
      state.isDatasetCreating = false
      state.selectedTemplateId = null
    },
  },
})

export const templateActions = templateReducer.actions

export default templateReducer.reducer

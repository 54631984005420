import { createSelector } from 'reselect'

import { IStore } from 'core/rootReducer'
import { RequestStatus, TaskStatus } from 'utils/constants'

import User from 'legacy/components/Account/User'

const getProjectState = (state: IStore) => state.project

export const getProjectScenariosState = createSelector(
  getProjectState,
  (state) => state.scenarios.present
)

export const selectGroupsById = createSelector(
  getProjectScenariosState,
  (state) => state.groups.byId
)

export const selectGroupPositions = createSelector(
  getProjectScenariosState,
  (state) => state.positions
)

export const selectIsGroupCreating = createSelector(
  getProjectScenariosState,
  (state) => state.groupsStatus === RequestStatus.Loading
)

export const selectDataGroups = createSelector(
  selectGroupPositions,
  selectGroupsById,
  (positions, groupsById) => {
    return positions.map((id) => groupsById[id])
  }
)

export const getMaxGroupOrderState = createSelector(
  selectGroupPositions,
  selectGroupsById,
  (positions, groupsById) => {
    if (positions.length) {
      return groupsById[positions[positions.length - 1]].order
    }
    return 0
  }
)

export const selectGroupSynthProgressById = createSelector(
  getProjectScenariosState,
  (state) => state.groupsSynthProgress.byId
)

export const selectSynthesizeProgress = createSelector(
  getProjectScenariosState,
  (state) => {
    const statuses = Object.values(state.groupsSynthProgress.byId).map(
      (groupData) => groupData.status
    )
    const total = statuses.length
    if (total === 0) {
      return null
    }
    const finishedGroups = statuses.filter(
      (status) => status === TaskStatus.STATUS_FINISHED
    ).length

    const progress = finishedGroups === 0 ? 10 : (finishedGroups / total) * 100

    return { total, finishedGroups, progress }
  }
)

export const selectSynthStatus = createSelector(
  getProjectScenariosState,
  (state) => state.synthStatus
)

export const selectIsSynthesizing = createSelector(
  selectSynthStatus,
  (status) => status === RequestStatus.Loading
)

export const selectSynthErrorMessage = createSelector(
  getProjectScenariosState,
  (state) => state.synthMessage
)

export const getSubTabState = createSelector(
  selectSynthStatus,
  User.isCETier,
  (synthStatus, isCETier) => {
    const tabState = {
      dataset: { disabled: true },
      report: { disabled: true },
    }
    if (synthStatus === RequestStatus.Succeeded) {
      tabState.dataset = { disabled: false }
      if (!isCETier) {
        tabState.report = { disabled: false }
      }
    }
    return tabState
  }
)

export const getGroupsBiasData = createSelector(
  selectGroupsById,
  selectGroupSynthProgressById,
  (groupsById, groupProgressById) => {
    const groupsWithBiasMitigation = Object.values(groupsById).filter(
      (group) => group.to_mitigate_bias
    )
    if (groupsWithBiasMitigation) {
      return groupsWithBiasMitigation
        .map((group) => ({
          ...group,
          fairness_data:
            groupProgressById[group.id]?.preview?.meta?.fairness_data,
        }))
        .filter((group) => group.fairness_data)
    }
    return []
  }
)

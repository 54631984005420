import React, { FC, ReactNode } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import cn from 'classnames'

export interface AccordionBoxProps {
  expanded?: boolean
  embedded?: boolean
  defaultExpanded?: boolean
  className?: string
  title?: ReactNode
  headerContent?: ReactNode
}

export const AccordionBox: FC<AccordionBoxProps> = ({
  children,
  headerContent,
  className,
  title,
  expanded,
  embedded,
  defaultExpanded,
}) => {
  const classes = useStyles()

  return (
    <Box mb={3} className={className}>
      <Accordion
        defaultExpanded={defaultExpanded}
        expanded={expanded}
        classes={{
          root: embedded ? classes.embeddedAccordion : classes.accordion,
        }}
      >
        <AccordionSummary
          classes={{ expandIcon: classes.expandIcon }}
          expandIcon={<ExpandMoreIcon className={classes.iconBox} />}
          aria-controls={`${title}-content`}
          id={`${title}-panel`}
        >
          <div className={classes.heading}>
            <div className={classes.groupLeft}>
              <Typography
                className={cn(classes.title, {
                  [classes.embeddedTitle]: embedded,
                })}
              >
                {title}
              </Typography>
            </div>
            <div className={classes.groupRight}>{headerContent}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  accordionDetails: {
    display: 'block',
  },
  heading: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accordion: {
    border: '1px solid #dce0e6',
    borderRadius: '4px',
    boxShadow: 'none',
  },
  embeddedAccordion: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  embeddedTitle: {
    color: '#565279',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '13px',
  },
  expandIcon: {
    order: -1,
    marginRight: '12px',
    padding: 0,
  },
  iconBox: {
    color: '#3E3965',
    display: 'block',
    width: '16px',
    height: '16px',
    borderRadius: '4px',
    padding: 0,
    backgroundColor: '#cfced9b3',
  },
  groupLeft: {
    alighSelf: 'left',
  },
  groupRight: {
    alighSelf: 'right',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
  },
}))

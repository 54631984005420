import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ProjectChart from './ProjectChart'

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      '& $iconButton': {
        opacity: 1,
      },
    },
  },
  title: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  labelContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  label: {
    padding: '0 0.3rem',
    borderRadius: '10px',
    fontWeight: 700,
    fontSize: 12,
  },
  iconButton: {
    padding: 0,
    opacity: 0,
  },
})

const HeaderConstant = ({ name }) => {
  return (
    <div style={{ height: 200 }}>
      <div
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {name.toLowerCase()}
      </div>
    </div>
  )
}

export const HeaderChart = ({
  activeTab,
  d,
  i,
  data,
  status,
  cur_data,
  synth_data = {},
  settings = {},
  disableDragBars = true,
  isBrush = false,
  brushValue = {},
  handleChangeBrush,
  ...otherProps
}) => {
  const _settings =
    otherProps.from === 'scenario' ? { ...settings } : { ...data.settings }
  if (
    d.type_family === 'id' ||
    d.type_family === 'date' ||
    d.type_family === 'sampling'
  ) {
    return (
      <HeaderConstant
        name={d.type_family === 'sampling' ? 'sampled' : d.type_family}
      />
    )
  }
  const _synth_data = JSON.parse(JSON.stringify(synth_data))
  if (
    (activeTab === 'synth' || otherProps.from === 'scenario') &&
    _synth_data.meta &&
    Object.keys(_synth_data.meta).length
  ) {
    Object.keys(_settings.conditions).length &&
      Object.keys(_settings.conditions).forEach((f) => {
        const idx = _synth_data.meta.columns.findIndex((d) => d.name === f)
        if (idx !== -1) {
          _synth_data.meta.columns[idx].plot_data.hist =
            _synth_data.meta.columns[idx].plot_data.bins.map(
              (bin) => _settings.conditions[f][bin]
            )
        }
      })
  }
  return (
    <ProjectChart
      {...d}
      isBrush={isBrush}
      brushValue={brushValue}
      handleChangeBrush={handleChangeBrush}
      activeTab={activeTab}
      settings={_settings}
      index={i}
      status={status}
      disableDragBars={disableDragBars || d.plot_type === 'density'}
      dataset_id={data.dataset_id}
      origin_data={
        cur_data !== null && Object.keys(cur_data).length ? cur_data : {}
      }
      curr_synth_data={Object.keys(_synth_data).length ? _synth_data : {}}
      {...otherProps}
    />
  )
}

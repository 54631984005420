import * as React from 'react'
import { FC } from 'react'
import {
  Checkbox as MUICheckbox,
  CheckboxProps,
  FormControlLabel,
} from '@material-ui/core'

interface MUICheckboxProps extends CheckboxProps {
  label: string | React.ReactNode
  labelClassName?: string
  labelClassNameLabel?: string
  'data-testid'?: string
}

const Component: FC<MUICheckboxProps> = ({
  disabled = false,
  checked = false,
  label,
  onChange,
  className,
  labelClassName = '',
  labelClassNameLabel = '',
  'data-testid': testId,
}) => {
  return (
    <FormControlLabel
      control={
        <MUICheckbox
          data-testid={testId}
          checked={checked}
          onChange={onChange}
          color="primary"
          classes={{
            root: className,
          }}
        />
      }
      disabled={disabled}
      label={label}
      classes={{
        root: labelClassName,
        label: labelClassNameLabel,
      }}
    />
  )
}

export const CheckBox = (
  props: MUICheckboxProps & { children?: React.ReactNode }
) => <Component {...props} />

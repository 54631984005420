import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles({
  headerLayout: {
    minHeight: '68px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.8rem 1.5rem',
    borderBottom: '1px solid #e0e4ed',
  },
})

export const HeaderLayout: React.FC<any> = ({ children, className = {} }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.headerLayout, className)}>
      {children}
    </div>
  )
}

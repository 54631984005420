import { memoize } from 'lodash'
import { IInviteItemGroup } from './typings'

export const createInviteItem = (id: number): IInviteItemGroup => ({
  id,
  items: [],
  permission: {
    id: 1,
    permission: 0,
    label: 'Viewer',
  },
})

export const validateEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

export const validateInvites = (invites: IInviteItemGroup[]) => {
  const allItems = invites.flatMap((invite) => invite.items)
  if (allItems.length === 0) {
    return true
  }

  return allItems.filter((item) => item.error).length > 0
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const randomColor = memoize((_: any) => {
  const hex = Math.floor(Math.random() * 0xffffff)
  const color = `#${hex.toString(16)}`

  return color
})

export function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

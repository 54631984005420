import update from 'immutability-helper'
import { ProjectReportActions } from '../report/actions'

const defaultProjectState = {
  blocks: [],
  loading: 'regular',
}

export default (state = defaultProjectState, action) => {
  switch (action.type) {
    case ProjectReportActions.DID_FETCH_REPORT:
      return {
        ...state,
        blocks: action.data,
      }

    case ProjectReportActions.START_LOADING: {
      return {
        ...state,
        loading: 'save',
      }
    }

    case ProjectReportActions.STOP_LOADING: {
      return {
        ...state,
        loading: 'regular',
      }
    }

    case ProjectReportActions.DID_DELETE_BLOCK: {
      const idx = state.blocks.findIndex((d) => d.id === action.id)
      return {
        ...state,
        blocks: update(state.blocks, {
          $splice: [[idx, 1]],
        }),
      }
    }

    case ProjectReportActions.CHANGE_BLOCK_CHECKBOX: {
      const { idx_blocks, idx_cat, idx_subcat, idx_values } = action
      return {
        ...state,
        blocks: update(state.blocks, {
          [idx_blocks]: {
            cats: {
              [idx_cat]: {
                values: {
                  [idx_subcat]: {
                    values: {
                      [idx_values]: {
                        check: {
                          $set: !state.blocks[idx_blocks].cats[idx_cat].values[
                            idx_subcat
                          ].values[idx_values].check,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        }),
      }
    }

    case ProjectReportActions.CHANGE_BLOCK_DROPDOWN: {
      const { dropdown_id, idx_blocks, idx_cat } = action
      return {
        ...state,
        blocks: update(state.blocks, {
          [idx_blocks]: {
            cats: {
              [idx_cat]: {
                values: {
                  $apply: (drops) =>
                    drops.map((drop, i) => ({
                      ...drop,
                      active: drop.id === dropdown_id,
                    })),
                },
              },
            },
          },
        }),
      }
    }

    default:
      return state
  }
}

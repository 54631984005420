import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import BarChartIcon from '@material-ui/icons/BarChart'
import classnames from 'classnames'

import { IStore } from 'core/rootReducer'
import HeaderComponent from 'layouts/Header'
import PageContainer from 'layouts/PageContainer'
import { Button } from 'UIkit/Button/Button'
import { AccordionBox } from 'UIkit/AccordionBox/AccordionBox'
import { CloseButton } from 'components/CloseButton'
import { SwitchCase } from 'components/SwitchCase/SwitchCase'

import {
  getCreateSuiteState,
  selectCurrentSuite,
  selectReportData,
  selectDataSources,
} from 'services/createTestingSuite/selectors'
import {
  SUITE_FILE_TYPES,
  suiteFileType,
} from 'services/createTestingSuite/types'
import { createTestingSuiteActions } from 'services/createTestingSuite/reducer'
import { DataGroup } from 'pages/CreateTestingSuite/DataGroup'
import { DataUnit } from 'pages/CreateTestingSuite/DataUnit'
import { DataTable } from 'pages/CreateTestingSuite/DataTable'
import HeaderControlPanel from 'pages/CreateTestingSuite/HeaderControlPanel'
import { AddButton } from 'pages/CreateTestingSuite/AddButton'
import { colors } from 'legacy/shared'
import { Tabs } from 'UIkit/Tabs/Tabs'
import { Alert } from '@material-ui/lab'
import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'
import { isEmpty } from 'lodash'

type ISelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const CreateTestingSuites = ({
  getReport,
  synthesizeSuite,
  stopSynthesizeSuite,
  createSuiteState,
  suite,
  getSuite,
  reportData,
  updateSuite,
  downloadData,
  downloadReport,
  dataSources,
  deleteFile,
  showModal,
  setModalLoading,
}: ISelectedProps & DispatchProps) => {
  const classes = useStyles()
  const { id } = useParams()
  const [areas, setArea] = useState<Record<string, boolean>>({
    [SUITE_FILE_TYPES.DATABASE]: false,
    [SUITE_FILE_TYPES.FUNCTIONAL_MAPPING]: false,
    [SUITE_FILE_TYPES.PRIMARY_KEY]: false,
    [SUITE_FILE_TYPES.FOREIGN_KEY]: false,
    [SUITE_FILE_TYPES.JOIN_STATEMENT]: false,
  })
  const [isShowFM, setIsShowFunctionalMapping] = useState(false)
  const [activeTab, setActiveTab] = useState('settings')

  useEffect(() => {
    getSuite(id)
    getReport({ id })
  }, [id])

  const { isSynthesizing, reportLoading } = createSuiteState
  const suiteConfigFilesMap = [
    {
      fileType: SUITE_FILE_TYPES.PRIMARY_KEY,
      title: 'Primary Keys',
      sources: { name: suite.pks_filename, size: suite.pks_size },
      updateType: 'pk',
    },
    {
      fileType: SUITE_FILE_TYPES.FOREIGN_KEY,
      title: 'Foreign Keys',
      sources: { name: suite.fks_filename, size: suite.fks_size },
      updateType: 'fk',
    },
    {
      fileType: SUITE_FILE_TYPES.JOIN_STATEMENT,
      title: 'Join Statements',
      sources: {
        name: suite.join_statements_filename,
        size: suite.join_statements_size,
      },
      updateType: 'join',
    },
  ]
  const tabs = [
    {
      id: 'settings',
      label: <span>Settings</span>,
      disabled: false,
      icon: <SettingsIcon fontSize="small" />,
    },
    {
      id: 'dataset',
      label: <span>Output</span>,
      disabled: isEmpty(reportData),
      icon: <BarChartIcon fontSize="small" />,
    },
  ]
  const handleSynthesize = () => {
    synthesizeSuite({ id })
  }

  const handleStopSynthesize = () => {
    stopSynthesizeSuite({ id })
  }
  const handleSetArea = (type: string) => {
    setArea((prevAreas) => ({ ...prevAreas, [type]: !prevAreas[type] }))
  }

  const handleDropFiles = (
    files: File[],
    updateType: string,
    fileType: suiteFileType,
    multi: boolean
  ) => {
    if (!multi) {
      handleSetArea(SUITE_FILE_TYPES[fileType])
    }
    const formData = new FormData()
    files.forEach((file: Blob) => formData.append('file', file))
    updateSuite({ updateType, formData, id })
  }
  const handleDeleteFile = (
    title: string,
    updateType: string,
    suiteDataSourceID?: number
  ) => {
    showModal({
      modalType: ModalTypes.Confirmation,
      config: {
        headerText: `Delete ${title} file?`,
        confirmationConfig: {
          actionDescription: (
            <Alert severity="warning">
              {`Warning! By deleting ${title} file all synthesized data
              will also be deleted.`}
            </Alert>
          ),
          confirmButtonText: 'Delete',
          confirmButtonAction: () => {
            setModalLoading(true)
            deleteFile({ id: suite.id, updateType, suiteDataSourceID })
          },
        },
      },
    })
  }

  const dataSourceGroup = (
    <AccordionBox
      title="Datasources"
      defaultExpanded
      headerContent={
        !areas[SUITE_FILE_TYPES.DATABASE] && (
          <Button
            variant="outlined"
            color="primary"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation()
              handleSetArea(SUITE_FILE_TYPES.DATABASE)
            }}
          >
            Upload more files
          </Button>
        )
      }
    >
      <DataUnit
        sources={dataSources.map((el) => ({ ...el, name: el.filename }))}
        handleDeleteFile={(file) =>
          // @ts-ignore
          handleDeleteFile('Data Sources', 'csv', file.id)
        }
        isShown={areas[SUITE_FILE_TYPES.DATABASE]}
        handleDrop={(files) =>
          handleDropFiles(files, 'csv', SUITE_FILE_TYPES.DATABASE, true)
        }
        handleClose={() => handleSetArea(SUITE_FILE_TYPES.DATABASE)}
        multipleForm
      />

      <Box>
        {suiteConfigFilesMap.map((el: any) => (
          <DataGroup
            key={el.title}
            title={(el.sources.name || areas[el.fileType]) && el.title}
          >
            <DataUnit
              sources={el.sources}
              handleDeleteFile={() => handleDeleteFile(el.title, el.updateType)}
              isShown={areas[el.fileType]}
              handleDrop={(files: File[]) =>
                handleDropFiles(files, el.updateType, el.fileType, false)
              }
              handleClose={() => handleSetArea(el.fileType)}
              multipleForm={false}
              fileType={el.fileType}
            />
          </DataGroup>
        ))}

        <Box className={classes.controlPanel}>
          {suiteConfigFilesMap.map((el: any) => {
            if (!areas[el.fileType]) {
              return (
                <AddButton
                  key={`button_${el.title}`}
                  title={el.title}
                  handleClick={() => handleSetArea(el.fileType)}
                />
              )
            }
          })}
        </Box>
      </Box>
    </AccordionBox>
  )

  const functionalMappingGroup =
    !isShowFM && !suite.functional_mapping_filename ? (
      <div className={classnames(classes.controlPanel, classes.alignRight)}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsShowFunctionalMapping(true)}
        >
          Add Functional Mapping
        </Button>
      </div>
    ) : (
      <AccordionBox
        title="Functional mapping"
        defaultExpanded
        headerContent={
          !suite.functional_mapping_filename && (
            <CloseButton
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation()
                setIsShowFunctionalMapping(false)
              }}
            />
          )
        }
      >
        <DataUnit
          sources={{
            name: suite.functional_mapping_filename,
            size: suite.functional_mapping_size,
          }}
          handleDeleteFile={() =>
            handleDeleteFile('Functional Mapping', 'mapping')
          }
          isShown={
            !areas[SUITE_FILE_TYPES.FUNCTIONAL_MAPPING] &&
            !suite.functional_mapping_filename
          }
          handleDrop={(files) =>
            handleDropFiles(
              files,
              'mapping',
              SUITE_FILE_TYPES.FUNCTIONAL_MAPPING,
              false
            )
          }
          handleClose={() => handleSetArea(SUITE_FILE_TYPES.FUNCTIONAL_MAPPING)}
          multipleForm={false}
        />
        {!suite.functional_mapping_filename &&
          areas[SUITE_FILE_TYPES.FUNCTIONAL_MAPPING] && (
            <AddButton
              title="Add Functional Mapping"
              handleClick={() =>
                handleSetArea(SUITE_FILE_TYPES.FUNCTIONAL_MAPPING)
              }
            />
          )}
      </AccordionBox>
    )

  return (
    <>
      <HeaderComponent title="Testing suite" subTitle={suite?.name} />
      <PageContainer pt={0}>
        <>
          <Tabs
            tabs={tabs}
            onChange={(e: MouseEvent, value: 'settings' | 'output') =>
              setActiveTab(value)
            }
            value={activeTab}
            size="extraSmallBold"
            tabsClasses={{
              root: classes.rootTabs,
              indicator: classes.indicator,
            }}
            tabClasses={{ root: classes.rootTab, wrapper: classes.wrapper }}
          />
          <SwitchCase value={activeTab}>
            {{
              settings: (
                <>
                  <HeaderControlPanel
                    isSynthesizing={isSynthesizing}
                    handleSynthesize={handleSynthesize}
                    handleStopSynthesize={handleStopSynthesize}
                    progress={reportData.progress}
                  />
                  {dataSourceGroup}
                  {functionalMappingGroup}
                </>
              ),
              dataset: (
                <>
                  {reportData && (
                    <>
                      {reportData.report && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px',
                          }}
                        >
                          <Button
                            className={classes.downloadData}
                            variant="outlined"
                            onClick={() => downloadData({ id })}
                          >
                            Download data
                          </Button>
                          <Button onClick={() => downloadReport({ id })}>
                            Download report
                          </Button>
                        </div>
                      )}
                      <DataGroup title="Coverage report">
                        <DataTable
                          data={reportData.report}
                          loading={reportLoading}
                        />
                      </DataGroup>
                    </>
                  )}
                </>
              ),
            }}
          </SwitchCase>
        </>
      </PageContainer>
    </>
  )
}

const mapStateToProps = (state: IStore) => ({
  createSuiteState: getCreateSuiteState(state),
  suite: selectCurrentSuite(state),
  reportData: selectReportData(state),
  dataSources: selectDataSources(state),
})

const mapDispatchToProps = {
  getSuite: createTestingSuiteActions.getSuite,
  getReport: createTestingSuiteActions.getReport,
  uploadSuite: createTestingSuiteActions.uploadSuite,
  synthesizeSuite: createTestingSuiteActions.synthesizeSuite,
  stopSynthesizeSuite: createTestingSuiteActions.stopSynthesizeSuite,
  updateSuite: createTestingSuiteActions.updateSuite,
  deleteFile: createTestingSuiteActions.deleteSuiteConfigFile,
  showModal: modalActions.show,
  downloadData: createTestingSuiteActions.downloadData,
  downloadReport: createTestingSuiteActions.downloadReport,
  setModalLoading: modalActions.setLoading,
}

const useStyles = makeStyles((theme) => ({
  controlButton: {
    color: colors.buttonDefault,
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  controlPanel: {
    display: 'flex',
    justifyContent: 'start',
    '& > button': {
      margin: theme.spacing(2.5),
      marginLeft: 0,
    },
  },
  rootTabs: {
    minHeight: '35px',
    padding: 0,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.tableBorder}`,
  },
  rootTab: {
    minHeight: '35px',
    height: '35px',
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  wrapper: {
    '& > *:first-child': {
      marginBottom: '0!important',
      marginRight: '.3rem',
    },
  },
  indicator: {
    backgroundColor: colors.black,
  },
  downloadData: {
    marginRight: '8px',
  },
}))

export default connect(mapStateToProps, mapDispatchToProps)(CreateTestingSuites)

import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Typography, spacing } from 'legacy/shared'
import { Routes } from 'legacy/utils'

import { Header } from 'layouts/Auth/Header'
import { Layout } from 'layouts/Auth/Layout'

import SignUpForm from './SignUpForm'

const useStyles = makeStyles({
  haveAccountContainer: {
    marginTop: `${spacing.gutterLarge}px`,
  },
})

export const SignUpPage: React.FC = () => {
  const classes = useStyles()

  return (
    <Layout>
      <>
        <Header heading="Create an Account" />
        <SignUpForm />
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          className={classes.haveAccountContainer}
        >
          <Typography size="captionBold">
            Already have an account?&nbsp;
            <Link to={Routes.SignIn()} data-test-id="sign-in-link">
              Sign In here
            </Link>
          </Typography>
        </Grid>
      </>
    </Layout>
  )
}

export default SignUpPage

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  contentLayout: {
    flex: 1,
    overflow: 'auto',
    padding: '20px 40px',
  },
})

export const PageContentLayout = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.contentLayout}>{children}</div>
}

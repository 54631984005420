import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'

import { Form, TextField, Typography, spacing } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'

import { authService } from 'legacy/utils/services'
import { RequestStatus } from 'utils/constants'
import { useApi } from 'hooks/useApi'

import { pushRoute, Routes } from 'legacy/utils'

import { Alert, CheckBox } from 'UIkit'
import { getUtmFromUrl } from './utils'

const useStyles = makeStyles({
  agreementContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  allowMarketingCheckbox: {
    marginBottom: '0',
  },
  checkbox: {
    padding: '4px 9px',
  },
})

const SignUpSchema = {
  fullname: constraints.fullname,
  email: constraints.email,
  company: constraints.requiredString,
}

const fieldMargin = {
  marginBottom: spacing.gutterLarge,
}

export const SignUpForm: React.FC = () => {
  const classes = useStyles()
  const { search, pathname } = useLocation()
  const history = useHistory()
  const [utm, setUtm] = useState({})

  useEffect(() => {
    const parsedUtm = getUtmFromUrl(search)
    if (parsedUtm) {
      setUtm(parsedUtm)
      history.replace(pathname)
    }
  }, [])

  const [signUpStatus, signUp] = useApi(authService.registerUnapproved)

  return (
    <Form
      onSubmit={async (fields: any) => {
        const { fullname, allowMarketing, consentGranted, ...rest } = fields
        const [firstName, lastName] = fullname.split(' ')

        const body = {
          ...rest,
          firstname: firstName,
          lastname: lastName,
          allow_marketing: allowMarketing,
          consent_granted: consentGranted,
          ...utm,
        }

        try {
          await signUp(body)
          pushRoute(Routes.SignUpConfirmed())
        } catch (error) {}
      }}
      initialValues={{
        fullname: '',
        email: '',
        company: '',
        allowMarketing: false,
        consentGranted: false,
      }}
      validate={createValidatorWithSchema(SignUpSchema)}
    >
      {({ values, errors, handleSubmit, handleChange, setFieldValue }: any) => {
        const handleCheckBoxChange = (
          _: React.ChangeEvent<{}>,
          checked: boolean
        ) => {
          setFieldValue('consentGranted', checked)
        }

        const handleAllowMarketing = (
          _: React.ChangeEvent<{}>,
          checked: boolean
        ) => {
          setFieldValue('allowMarketing', checked)
        }
        return (
          <>
            <TextField
              id="sign_up_fullname"
              name="fullname"
              label="Full Name"
              placeholder="Full Name"
              value={values.fullname}
              onChange={handleChange}
              error={Boolean(errors.fullname)}
              helperText={errors.fullname && errors.fullname[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              id="sign_up_email"
              name="email"
              label="Email"
              type="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              id="sign_up_company"
              name="company"
              label="Company"
              placeholder="Company"
              value={values.company}
              onChange={handleChange}
              error={Boolean(errors.company)}
              helperText={errors.company && errors.company[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            {signUpStatus.status === RequestStatus.Failed && (
              <Alert severity="error" data-testid="sign_up_error">
                {signUpStatus.message}
              </Alert>
            )}
            <CheckBox
              style={fieldMargin}
              className={classes.checkbox}
              onChange={handleAllowMarketing}
              checked={values.allowMarketing}
              label={
                <Typography size="captionBold" color="stone">
                  I want to receive news and offers.
                </Typography>
              }
              labelClassName={classes.allowMarketingCheckbox}
            />
            <div className={classes.agreementContainer}>
              <CheckBox
                className={classes.checkbox}
                onChange={handleCheckBoxChange}
                checked={values.consentGranted}
                data-testid="sign_up_agreement"
                label={
                  <Typography size="captionBold" color="stone">
                    I agree to Synthesized&apos;s&nbsp;
                    <a
                      href="https://www.synthesized.io/terms-of-service"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Terms of Service
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      href="https://www.synthesized.io/data-processing-addendum"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Data Processing Addendum.
                    </a>
                  </Typography>
                }
              />
            </div>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              loading={signUpStatus.status === RequestStatus.Loading}
              disabled={!values.consentGranted}
              fullWidth
              data-testid="sign_up_submit"
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
          </>
        )
      }}
    </Form>
  )
}

export default SignUpForm

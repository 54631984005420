import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { colors } from '../../theme'

const DEFAULT_SPINNER_SIZE = 24

export const Spinner = ({
  size = DEFAULT_SPINNER_SIZE,
  color = colors.buttonDefault,
  style = {},
  className = '',
  'data-testid': testId = undefined,
}) => (
  <CircularProgress
    data-testid={testId}
    className={className}
    size={size}
    style={{ color, ...style }}
  />
)

Spinner.displayName = 'Spinner'

import { ProjectModalActions } from '../modals/actions'

const defaultProjectState = {
  columnData: {},
  reducerState: 'regular',
  errorMessage: '',
}

export default (state = defaultProjectState, action) => {
  switch (action.type) {
    case ProjectModalActions.SELECT_COLUMN:
      return {
        ...state,
        columnData: action.data,
      }

    case ProjectModalActions.SUBMIT_ACTION:
      return {
        ...state,
        ...{ reducerState: 'saving', errorMessage: '' },
      }

    case ProjectModalActions.DID_SUBMIT_ACTION_FAIL:
      return {
        ...state,
        ...{ reducerState: 'regular', errorMessage: action.message },
      }
    //
    // case ModalActions.HIDE_APPLICATION_MODAL:
    //   return {
    //     ...state,
    //     ...{ reducerState: 'regular', columnData: {}, errorMessage: '' }
    //   };

    default:
      return state
  }
}

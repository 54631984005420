import { DatagroupResponse } from 'api/generated'

const checkConditions = (conditions: any, searchString: string) => {
  return Object.keys(conditions).filter((condition) =>
    condition.toLowerCase().includes(searchString)
  )
}

export const filterGroups = (groups: DatagroupResponse[], search: string) => {
  const searchString = search.trim().toLowerCase()
  if (searchString === '') {
    return groups
  }
  return groups.filter((group) => {
    if (group.title.toLowerCase().includes(searchString.toLowerCase())) {
      return true
    }
    if (String(group.samples).includes(searchString)) {
      return true
    }
    if (checkConditions(group.conditions, searchString).length) {
      return true
    }
    return false
  })
}

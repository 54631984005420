export const ProjectSettingsActions = {
  DID_FETCH_SETTINGS: 'project/DID_FETCH_SETTINGS',

  UPDATE_DISPLAY_COLUMNS: 'project/UPDATE_DISPLAY_COLUMNS',
  UPDATE_SYNTHESIS_SETTINGS: 'project/UPDATE_SYNTHESIS_SETTINGS',
  UPDATE_BIAS_SETTINGS: 'project/UPDATE_BIAS_SETTINGS',
  DID_UPDATE_SETTINGS: 'project/DID_UPDATE_SETTINGS',
}

export const didFetchSettings = (payload) => ({
  type: ProjectSettingsActions.DID_FETCH_SETTINGS,
  payload,
})

export const updateDisplayColumns = (id, settings) => ({
  type: ProjectSettingsActions.UPDATE_DISPLAY_COLUMNS,
  id,
  settings,
})

export const updateSynthesisSetting = (id, settings) => ({
  type: ProjectSettingsActions.UPDATE_SYNTHESIS_SETTINGS,
  id,
  settings,
})

export const updateBiasSetting = (id, settings) => ({
  type: ProjectSettingsActions.UPDATE_BIAS_SETTINGS,
  id,
  settings,
})

export const didUpdateSettings = (payload) => ({
  type: ProjectSettingsActions.DID_UPDATE_SETTINGS,
  payload,
})

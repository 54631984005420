import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const getCleanRoomsState = (state: IStore) => state.cleanRooms.present

export const getCleanRoomsById = createSelector(
  getCleanRoomsState,
  (state) => state.byId
)

export const getCleanRoomsList = createSelector(getCleanRoomsById, (byId) =>
  Object.values(byId)
)

export const getCleanRoomPageState = createSelector(
  getCleanRoomsState,
  (state) => state.status
)

export const getSearchStringValue = createSelector(
  getCleanRoomsState,
  (state) => state.searchString
)

export const getSharedCleanRoom = createSelector(
  getCleanRoomsState,
  (state) => state.byId[state.cleanRoomIdToShare]
)

export const getShareModalState = createSelector(
  getCleanRoomsState,
  (state) => state.shareStatus
)

export const getShareModalError = createSelector(
  getCleanRoomsState,
  (state) => state.shareErrorMessage
)

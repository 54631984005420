import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'notification'

type NotificationSeverity = 'error' | 'warning' | 'success'

const defaultDuration = 3000

interface IShowNotificationPayload {
  message: string
  severity?: NotificationSeverity
  duration?: number
}

export interface INotificationState {
  open: boolean
  duration: number
  message: string
  severity: NotificationSeverity
}

const initialState: INotificationState = {
  open: false,
  duration: defaultDuration,
  message: '',
  severity: 'success',
}

const notificationReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    showNotification: (
      state,
      { payload }: PayloadAction<IShowNotificationPayload>
    ) => {
      state.message = payload.message
      state.severity = payload.severity || 'success'
      state.duration = payload.duration || defaultDuration
      state.open = true
    },
    hideNotification: (state) => {
      state.open = false
    },
  },
})

export const notificationActions = notificationReducer.actions

export default notificationReducer.reducer

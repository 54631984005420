import { takeLatest, put } from 'redux-saga/effects'
import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'
import { ShareProjectActions, didActiveRow } from './actions'

function* handleStartShareProject({ id }) {
  try {
    yield put(didActiveRow(id))
    yield put(
      modalActions.show({
        modalType: ModalTypes.ShareProject,
        config: { custom: true },
      })
    )
  } catch (error) {
    // TODO add error handler
  }
}

export default function* watchShareProjectSaga() {
  yield takeLatest(
    ShareProjectActions.START_SHARE_PROJECT,
    handleStartShareProject
  )
}

import React, { useState, FC } from 'react'
import { connect } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { makeStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Box } from 'UIkit'
import { SplitButton } from 'UIkit/Button/SplitButton'
import { TextField, Typography } from 'legacy/shared'

import { IStore } from 'core/rootReducer'
import { scenarioActions } from 'services/project/scenarios/reducer'
import {
  selectDataGroups,
  selectGroupSynthProgressById,
} from 'services/project/scenarios/selectors'

import { exportCSV } from 'legacy/components/Projects/settings/actions'
import { TaskStatus } from 'utils/constants'

import { DatagroupResponse } from 'api/generated/modelPkg/datagroup-response'
import { OutputTable } from './OutputTable'
import BiasTable from './BiasTable'
import { GroupStatus } from '../GroupStatus'

const useStyles = makeStyles(() => ({
  grid: {
    height: '100%',
  },
  sidebar: {
    borderRight: '1px solid #DCE0E6',
  },
  table: {
    overflow: 'hidden',
  },
  title: {
    lineHeight: '33px',
  },
  statusIcon: {
    minWidth: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 8,
  },
  groupName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  download: {
    textAlign: 'end',
  },
}))

const GroupItem = ({
  group,
  synthStatus,
}: {
  group: DatagroupResponse
  synthStatus: TaskStatus
}) => {
  const classes = useStyles()

  return (
    <>
      <GroupStatus synthStatus={synthStatus} />
      <Typography className={classes.groupName}>{group.title}</Typography>
    </>
  )
}

type OwnProps = { datasetId: number }
type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export const DataGroupOutput: FC<OwnProps & SelectedProps & DispatchProps> = ({
  datasetId,
  groups,
  synthDataByGroupId,
  exportGroupFile,
  exportAllGroups,
}) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const [selectedGroup, setSelectedGroup] = useState(groups[0])

  const { callback } = useDebouncedCallback(
    (value) => setSearchValue(value),
    300
  )

  const handleExportGroupCSV = (id: any) => {
    // @ts-ignore
    exportGroupFile({
      datasetId,
      id,
      callback: (exp: any, filename: any) => {
        const url = window.URL.createObjectURL(new Blob([exp]))
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
    })
  }

  const handleExportAllCSV = () => {
    exportAllGroups({
      id: datasetId,
      callback: (exp: any, filename: any) => {
        const url = window.URL.createObjectURL(new Blob([exp]))
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
    })
  }

  return (
    <>
      <Grid container className={classes.grid}>
        <Grid item md={3} className={classes.sidebar}>
          <Box p={2}>
            <Box pt={1} pb={1}>
              <Typography size="bodyBold" className={classes.title}>
                All groups
              </Typography>
            </Box>
            <Box pt={1} pb={1}>
              <TextField
                onChange={(e: any) => callback(e.target.value)}
                label="Search"
                name="search"
                placeholder="Search..."
                autoComplete="off"
                style={{ width: '100%' }}
              />
            </Box>
            <List>
              {groups
                .filter((group: DatagroupResponse) => {
                  const title = group.title || ''
                  return title.toLowerCase().includes(searchValue.toLowerCase())
                })
                .map((group: DatagroupResponse) => (
                  <ListItem
                    data-testid="sibebar-group-name"
                    key={group.id}
                    button
                    selected={selectedGroup === group}
                    onClick={() => setSelectedGroup(group)}
                  >
                    <GroupItem
                      group={group}
                      synthStatus={
                        synthDataByGroupId[group.id]?.status as TaskStatus
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </Grid>
        <Grid item md={9} className={classes.table}>
          <Box p={2}>
            <Box pt={1} pb={1}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography size="bodyBold" className={classes.title}>
                    {selectedGroup.title}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.download}>
                  {selectedGroup.status === TaskStatus.STATUS_FINISHED ? (
                    <SplitButton
                      options={[
                        {
                          label: 'Download Group',
                          action: () => handleExportGroupCSV(selectedGroup.id),
                        },
                        {
                          label: 'Download All Data',
                          action: () => handleExportAllCSV(),
                        },
                      ]}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Box>
            <OutputTable synthData={synthDataByGroupId[selectedGroup.id]} />
            {synthDataByGroupId[selectedGroup.id]?.preview?.meta
              ?.fairness_data ? (
              <BiasTable
                title={selectedGroup.title}
                synthData={synthDataByGroupId[selectedGroup.id]}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state: IStore) => ({
  synthDataByGroupId: selectGroupSynthProgressById(state),
  groups: selectDataGroups(state),
})

const mapDispatchToProps = {
  exportGroupFile: scenarioActions.exportGroupFile,
  exportAllGroups: exportCSV,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataGroupOutput)

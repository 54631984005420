import React, { useState } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import BarChartIcon from '@material-ui/icons/BarChart'
import DescriptionIcon from '@material-ui/icons/Description'

import { SwitchCase, colors } from 'legacy/shared'
import { Tabs } from 'UIkit/Tabs/Tabs'

import Report from '../report/Report'
import ScenarioSettingsView from './ScenarioSettingsView'
import ScenarioOutputView from './output/ScenarioOutputView'

import { getSubTabState } from 'services/project/scenarios/selectors'

const synthTabs = [
  {
    id: 'settings',
    label: <span>Settings</span>,
    disabled: false,
    icon: <SettingsIcon fontSize="small" />,
  },
  {
    id: 'dataset',
    label: <span>Output</span>,
    disabled: true,
    icon: <BarChartIcon fontSize="small" />,
  },
  {
    id: 'report',
    label: <span>Reports</span>,
    disabled: true,
    icon: <DescriptionIcon fontSize="small" />,
  },
]

const useTabsStyles = makeStyles({
  rootTabs: {
    minHeight: '35px',
    padding: 0,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.tableBorder}`,
  },
  rootTab: {
    minHeight: '35px',
    height: '35px',
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  wrapper: {
    '& > *:first-child': {
      marginBottom: '0!important',
      marginRight: '.3rem',
    },
  },
  indicator: {
    backgroundColor: colors.black,
  },
})

const ScenarioDataView = ({ datasetId, subTabState }) => {
  const classesTabs = useTabsStyles()
  const [activeTabScenario, setActiveTabScenario] = useState('settings')

  const tabs = synthTabs.map((tab) => ({
    ...tab,
    disabled: subTabState[tab.id] ? subTabState[tab.id].disabled : tab.disabled,
  }))

  const handleChange = (event, newValue) => {
    setActiveTabScenario(newValue)
  }
  return (
    <>
      <Tabs
        tabs={tabs}
        onChange={handleChange}
        value={activeTabScenario}
        size="extraSmallBold"
        tabsClasses={{
          root: classesTabs.rootTabs,
          indicator: classesTabs.indicator,
        }}
        tabClasses={{ root: classesTabs.rootTab, wrapper: classesTabs.wrapper }}
      />
      <SwitchCase value={activeTabScenario}>
        {{
          settings: <ScenarioSettingsView datasetId={datasetId} />,
          dataset: <ScenarioOutputView datasetId={datasetId} />,
          report: <Report />,
        }}
      </SwitchCase>
    </>
  )
}

const mapStateToProps = (state) => ({
  subTabState: getSubTabState(state),
})

export default connect(mapStateToProps, null)(ScenarioDataView)

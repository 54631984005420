import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'

const prefix = 'inviteCode'

interface IInviteCodeState {
  inviteCode: string
}

const initialState: IInviteCodeState = {
  inviteCode: '',
}

const inviteCodeReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    didGenerateInviteCode: (state, { payload }: PayloadAction<any>) => {
      state.inviteCode = payload
    },
  },
})

const generateInviteCode = createAction<any>(`${prefix}/generateInviteCode`)

export const inviteCodeActions = {
  ...inviteCodeReducer.actions,
  generateInviteCode,
}

export default inviteCodeReducer.reducer

import React from 'react'

export const ProjectIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 25.5H10C8.89543 25.5 8 26.3954 8 27.5V35.5C8 36.6046 8.89543 37.5 10 37.5H14C15.1046 37.5 16 36.6046 16 35.5V27.5C16 26.3954 15.1046 25.5 14 25.5Z" />
    <path d="M26 10.5H22C20.8954 10.5 20 11.3954 20 12.5V35.5C20 36.6046 20.8954 37.5 22 37.5H26C27.1046 37.5 28 36.6046 28 35.5V12.5C28 11.3954 27.1046 10.5 26 10.5Z" />
    <path d="M38 19.5H34C32.8954 19.5 32 20.3954 32 21.5V35.5C32 36.6046 32.8954 37.5 34 37.5H38C39.1046 37.5 40 36.6046 40 35.5V21.5C40 20.3954 39.1046 19.5 38 19.5Z" />
  </svg>
)

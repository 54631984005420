import { takeLatest, put, call } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ActionCreators } from 'redux-undo'

import { omit } from 'lodash'

import { dataSourceService } from 'legacy/utils/services'
import { DatasourceResponse } from 'api/generated/modelPkg'

import { notificationActions } from 'services/notification/reducer'
import { modalActions } from 'services/modal/reducer'
import { dataSourcesActions } from './reducer'

function* handleFetchDataSources() {
  try {
    const dataSources = yield call(dataSourceService.getDataSources)
    yield put(dataSourcesActions.didFetchDataSources(dataSources))
  } catch (error) {
    yield put(dataSourcesActions.didFetchDataSourcesFail())
  }
}

function* handleCreateDataSource({
  payload,
}: PayloadAction<DatasourceResponse>) {
  try {
    const dataSource = yield call(dataSourceService.createDataSource, {
      data: payload,
    })
    yield put(dataSourcesActions.didCreateDataSource(dataSource))
  } catch (error) {
    yield put(dataSourcesActions.didCreateDataSourceFail())
    yield put(
      notificationActions.showNotification({
        message: 'Datasource can not be created',
        severity: 'error',
      })
    )
  }
}

function* handleUpdateDataSource({
  payload,
}: PayloadAction<{ datasource: DatasourceResponse; id: string }>) {
  try {
    yield put(modalActions.hide())
    yield call(dataSourceService.updateDataSource, {
      id: payload.id,
      data: omit(payload, 'id', 'datasource_id'),
    })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Datasource can not be updated',
        severity: 'error',
      })
    )
  }
}

function* handleDeleteDataSource({ payload }: PayloadAction<{ id: string }>) {
  try {
    yield put(modalActions.hide())
    yield call(dataSourceService.deleteDataSource, {
      id: payload.id,
    })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Datasource can not be deleted',
        severity: 'error',
      })
    )
  }
}

function* handleCheckDataSource({
  payload,
}: PayloadAction<DatasourceResponse>) {
  try {
    yield call(dataSourceService.checkDatasourceConnection, {
      data: omit(payload, 'id', 'datasource_id'),
    })
    yield put(dataSourcesActions.didCheckDataSource())
    yield put(
      notificationActions.showNotification({
        message: 'Datasource has been checked',
      })
    )
  } catch (err) {
    yield put(dataSourcesActions.initCheckStatus())
    yield put(
      notificationActions.showNotification({
        message: 'Datasource can not be checked',
        severity: 'error',
      })
    )
  }
}

export default function* watchDataSourcesSaga() {
  yield takeLatest(dataSourcesActions.fetchDataSources, handleFetchDataSources)
  yield takeLatest(dataSourcesActions.createDataSource, handleCreateDataSource)
  yield takeLatest(dataSourcesActions.updateDataSource, handleUpdateDataSource)
  yield takeLatest(dataSourcesActions.deleteDataSource, handleDeleteDataSource)
  yield takeLatest(dataSourcesActions.checkDataSource, handleCheckDataSource)
}

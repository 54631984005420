import React from 'react'
import { connect } from 'react-redux'
import { IStore } from 'core/rootReducer'

import { profileActions } from 'services/profile/reducer'
import { selectProfileImageState } from 'services/profile/selectors'

import emptyAvatar from 'assets/avatar.png'
import { Button } from 'UIkit/Button/Button'

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const ProfileImage: React.FC<SelectedProps & DispatchProps> = ({
  imageState,
  uploadImage,
}) => {
  const { imageUrl, isLoading } = imageState

  const clickOnImage = () => {
    document.getElementById('image_click').click()
  }

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const file = event.target.files[0]
    if (file) {
      uploadImage({ file })
    }
  }

  let imagePreview = null

  if (imageUrl) {
    imagePreview = <img width={140} src={imageUrl} alt="Avatar" />
  } else {
    imagePreview = <img src={emptyAvatar} alt="" width={140} />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div style={{ marginBottom: '1rem' }}>{imagePreview}</div>
      <Button onClick={() => clickOnImage()} loading={isLoading}>
        Upload New Picture
        <input
          type="file"
          id="image_click"
          style={{ display: 'none' }}
          onChange={(event) => handleUploadImage(event)}
        />
      </Button>
    </div>
  )
}

const mapStateToProps = (state: IStore) => ({
  imageState: selectProfileImageState(state),
})

const mapDispatchToProps = {
  uploadImage: profileActions.uploadImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage)

import { uniq } from 'lodash'
import { all, call, CallEffect } from 'redux-saga/effects'

import { profileService } from 'legacy/utils/services'

export function addAvatarsToData<T>(data: T[], avatarKey: keyof T): CallEffect {
  const uniqAvatarUrls: any = uniq(data.map((item: any) => item[avatarKey]))
  return call(handleAddAvatarsToData, data, avatarKey, uniqAvatarUrls)
}

function* handleAddAvatarsToData<T>(
  data: T[],
  avatarKey: any,
  avatarUrls: string[]
) {
  if (avatarUrls.length) {
    const aa = yield all(
      avatarUrls.map((url) => call(getLocalAvatarUrlSaga, url))
    )
    const avatarObj = aa.reduce(
      (acc: any, [localUrl, url]: [string, string]) => {
        return { ...acc, [url]: localUrl }
      },
      {}
    )

    return data.map((item: any) => ({
      ...item,
      [avatarKey]: avatarObj[item[avatarKey]],
    }))
  }
  return data
}

export function* getLocalAvatarUrlSaga(avatarUrl: string) {
  try {
    const avatar = yield call(profileService.userGetAvatar, {
      avatarPath: avatarUrl,
    })
    return [URL.createObjectURL(avatar), avatarUrl]
  } catch (error) {
    return ['', avatarUrl]
  }
}

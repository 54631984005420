import React from 'react'
import { connect } from 'react-redux'

import { dataSourcesActions } from 'services/datasources/datasources/reducer'
import {
  selectDataSourceCheckStatus,
  selectDataSourceSaveStatus,
} from 'services/datasources/datasources/selectors'

import { Button } from 'UIkit/Button/Button'

export const DataSourceEditButtons = ({
  values,
  handleSubmit,
  checkStatus,
  saveStatus,
  updateDataSource,
  deleteDataSource,
}: any) => {
  const handlerUpdateDatasource = (dataSource: any) => {
    updateDataSource(dataSource)
  }

  const handlerDeleteDatasource = () => {
    deleteDataSource({ id: values.id })
  }

  const isChecking = checkStatus === 'loading'
  const isChecked = checkStatus === 'succeeded'
  const isSaving = saveStatus === 'loading'

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        color="secondary"
        onClick={() => {
          handlerDeleteDatasource()
        }}
        disabled={isChecking}
      >
        Delete
      </Button>
      <Button
        type="submit"
        onClick={() => {
          handleSubmit()
        }}
        loading={isChecking}
      >
        Check
      </Button>
      <Button
        onClick={() => {
          handlerUpdateDatasource(values)
        }}
        disabled={!isChecked}
        loading={isSaving}
      >
        Save
      </Button>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  checkStatus: selectDataSourceCheckStatus(state),
  saveStatus: selectDataSourceSaveStatus(state),
})

const mapDispatchToProps = {
  deleteDataSource: dataSourcesActions.deleteDataSource,
  updateDataSource: dataSourcesActions.updateDataSource,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSourceEditButtons)

import {
  call,
  takeLatest,
  put,
  select,
  delay,
  race,
  take,
} from 'redux-saga/effects'
import { ActionCreators } from 'redux-undo'
import { omit } from 'lodash'

import { groupsService } from 'legacy/utils/services'

import { notificationActions } from 'services/notification/reducer'
import { scenarioActions } from 'services/project/scenarios/reducer'
import {
  selectGroupsById,
  getMaxGroupOrderState,
  selectDataGroups,
} from 'services/project/scenarios/selectors'
import { getProjectId, getOriginalRowsQuantity } from '../projectSelectors'

function* handleFetchGroup({ payload }) {
  const { datasetId } = payload
  try {
    const groups = yield call(groupsService.getGroups, { datasetId })
    if (groups.length === 0) {
      return yield put(scenarioActions.createGroup())
    }
    // fix issue when min_dist is null for old groups
    const modifiedGroups = groups.map((group) => ({
      ...group,
      min_dist: Number(group.min_dist),
    }))
    //
    yield put(
      scenarioActions.didFetchGroups({
        groups: modifiedGroups,
      })
    )
  } catch (error) {
    // TODO add handler
  }
}

function* handleCreateGroup({ payload }) {
  const datasetId = yield select(getProjectId)
  const originalRowNumber = yield select(getOriginalRowsQuantity)
  const maxCurrentOrder = yield select(getMaxGroupOrderState)

  const defaultNewGroup = {
    scenario_id: 1,
    title: payload?.title || 'Default group',
    samples: originalRowNumber,
    to_impute_nans: false,
    to_include_original_data: true,
    to_mitigate_bias: false,
    min_dist: 0,
    order: maxCurrentOrder + 1,
    conditions: {},
  }
  try {
    const createdGroup = yield call(groupsService.createGroup, {
      datasetId,
      group: defaultNewGroup,
    })
    yield put(scenarioActions.didCreateGroup({ group: createdGroup }))
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: 'Group is not created',
        severity: 'error',
      })
    )
  }
}

function* handleDuplicateGroup({ payload }) {
  const originalGroupId = payload.id
  const datasetId = yield select(getProjectId)
  const groups = yield select(selectGroupsById)
  const maxCurrentOrder = yield select(getMaxGroupOrderState)
  const originalGroup = omit(
    groups[originalGroupId],
    'id',
    'dataset_id',
    'status'
  )

  try {
    const createdGroup = yield call(groupsService.createGroup, {
      datasetId,
      group: { ...originalGroup, order: maxCurrentOrder + 1 },
    })
    yield put(scenarioActions.didCreateGroup({ group: createdGroup }))
  } catch (error) {
    // TODO add handler
  }
}

function* handleDeleteGroup({ payload }) {
  const datasetId = yield select(getProjectId)
  try {
    yield put(scenarioActions.didDeleteGroup({ id: payload.id }))
    yield call(groupsService.deleteGroup, { datasetId, id: payload.id })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Group is not deleted',
        severity: 'error',
      })
    )
  }
}

function* handleUpdateGroupPosition({ payload }) {
  const datasetId = yield select(getProjectId)
  const { oldIndex, newIndex } = payload
  try {
    yield put(
      scenarioActions.didUpdateGroupPosition({
        initPosition: oldIndex,
        targetPosition: newIndex,
      })
    )
    const groups = yield select(selectDataGroups)
    const orderedGroups = groups.map((group, i) => ({ ...group, order: i }))
    yield call(groupsService.updateGroupPositions, {
      datasetId,
      groups: orderedGroups,
    })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Orders are not changed',
        severity: 'error',
      })
    )
  }
}

function* handleUpdateGroup({ payload }) {
  try {
    const { key, value, id } = payload
    const groups = yield select(selectGroupsById)
    const group = groups[id]
    const updatedGroup = { ...group, [key]: value }
    yield put(scenarioActions.didGroupUpdate({ group: updatedGroup }))

    const datasetId = yield select(getProjectId)
    yield call(groupsService.updateGroup, {
      datasetId,
      id: updatedGroup.id,
      group: omit(updatedGroup, 'id', 'dataset_id', 'status'),
    })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Group is not updated',
        severity: 'error',
      })
    )
  }
}

function* handleFetchSynthesizeData(action) {
  try {
    yield race([
      call(handleFetchSynthProgress, action),
      take(scenarioActions.stopSynthesizeData),
    ])
  } catch (error) {
    yield put(scenarioActions.didSynthesizeDataFail())
  }
}

function* handleSynthesizeData(action) {
  const { datasetId } = action.payload

  try {
    yield call(groupsService.synthesizeData, { datasetId })
    yield delay(5000)
    const [result] = yield race([
      call(handleFetchSynthProgress, action),
      take(scenarioActions.stopSynthesizeData),
    ])
    if (result?.type === scenarioActions.didSynthesizeData.type) {
      yield put(
        notificationActions.showNotification({
          message:
            'Groups were successfully synthesized. Too see result go to Output tab',
        })
      )
    }
  } catch (error) {
    yield put(scenarioActions.didSynthesizeDataFail())
  }
}

function* handleFetchSynthProgress({ payload }) {
  const { datasetId } = payload

  while (true) {
    const synthesizeData = yield call(groupsService.fetchSynthesizeData, {
      datasetId,
    })

    if (
      synthesizeData.length === 0 ||
      synthesizeData.filter(({ status }) => status === 'STATUS_STOPPED').length
    ) {
      return yield put(scenarioActions.didSynthesizeDataEmpty())
    }

    const synthesizeDataById = synthesizeData.reduce((acc, groupData) => {
      acc[groupData.datagroup_id] = groupData
      return acc
    }, {})

    yield put(
      scenarioActions.didFetchSynthesizeProgress({
        dataById: synthesizeDataById,
      })
    )

    // ONE OF THE TASKS HAS STATUS FAILED
    if (
      synthesizeData.filter(({ status }) => status === 'STATUS_FAILED').length
    ) {
      return yield put(scenarioActions.didSynthesizeDataFail())
    }

    // SYNTHS FOR ALL GROUPS ARE FINISHED
    if (
      synthesizeData.filter(({ status }) => status === 'STATUS_FINISHED')
        .length === synthesizeData.length
    ) {
      return yield put(
        scenarioActions.didSynthesizeData({
          synthGroups: synthesizeData,
        })
      )
    }
    yield delay(5000)
  }
}

function* handleStopSynthesizeData() {
  const datasetId = yield select(getProjectId)
  try {
    yield call(groupsService.stopSynthesizeData, {
      datasetId,
    })
  } catch (error) {
    // TODO add handler
  }
}

function* handleExportGroupFile({ payload }) {
  const { datasetId, id, callback } = payload

  try {
    const result = yield call(groupsService.exportCSVGroup, { datasetId, id })
    if (!result.message) {
      yield put(
        notificationActions.showNotification({
          message: 'File downloaded successfully!',
        })
      )

      callback(result.data, result.filename)
    } else {
      yield put(
        notificationActions.showNotification({
          message: result.message,
          severity: 'error',
        })
      )
    }
  } catch (error) {}
}

export default function* watchScenariosSaga() {
  yield takeLatest(scenarioActions.fetchGroups.type, handleFetchGroup)
  yield takeLatest(scenarioActions.createGroup.type, handleCreateGroup)
  yield takeLatest(
    scenarioActions.updateGroupPosition.type,
    handleUpdateGroupPosition
  )
  yield takeLatest(scenarioActions.deleteGroup.type, handleDeleteGroup)
  yield takeLatest(scenarioActions.duplicateGroup, handleDuplicateGroup)
  yield takeLatest(scenarioActions.updateGroup, handleUpdateGroup)

  yield takeLatest(scenarioActions.synthesizeData, handleSynthesizeData)
  yield takeLatest(scenarioActions.stopSynthesizeData, handleStopSynthesizeData)

  yield takeLatest(
    scenarioActions.fetchSynthesizeData,
    handleFetchSynthesizeData
  )
  yield takeLatest(scenarioActions.exportGroupFile, handleExportGroupFile)
}

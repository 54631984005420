import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { Stepper } from '../../../shared'
import { pushRoute, Routes } from '../../../utils'
import { StepperLabels, BuildingStepsOrder } from './constants'
import { getSelectedDatasetsNames } from './dataset/sandboxDatasetSelectors'
import { getSelectedSandboxOSName } from './os/sandboxOSSelectors'
import { getSelectedSandboxDBName } from './dbServer/sandboxDBSelectors'

const useStyles = makeStyles({
  root: {
    backgroundColor: 'inherit',
  },
})

// const defineSelectedData = (activeStep = 0, selectedData = []) => {
//   return selectedData.slice(0, activeStep);
// };

export const SandboxBuildStepper = (props) => {
  const { activeStep = 0, selectedDatasetNames, selectedOS, selectedDB } = props
  const classes = useStyles()

  return (
    <Stepper
      classes={classes}
      steps={StepperLabels}
      activeStep={activeStep}
      selectedData={[selectedDatasetNames, selectedOS, selectedDB]}
      onClickStep={(stepIndex) =>
        pushRoute(Routes.CleanRoomBuildStep(BuildingStepsOrder[stepIndex]))
      }
    />
  )
}

const mapStateToProps = createStructuredSelector({
  selectedDatasetNames: getSelectedDatasetsNames,
  selectedOS: getSelectedSandboxOSName,
  selectedDB: getSelectedSandboxDBName,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SandboxBuildStepper)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import classnames from 'classnames'

import { Progress } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'

import { scenarioActions } from 'services/project/scenarios/reducer'
import {
  selectSynthesizeProgress,
  selectIsSynthesizing,
  selectDataGroups,
} from 'services/project/scenarios/selectors'
import { getProjectPermissionsForUser } from '../projectSelectors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: '1rem 0rem',
  },
  progressRoot: {
    flexGrow: 1,
    marginLeft: '1rem',
  },
})

const SynthesizeBar = ({
  id,
  synthProgress = {},
  isSynthesizing,
  groups,
  synthesizeData,
  stopSynthesizeData,
  canEdit,
}) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.root)}>
      <Button
        color="secondary"
        loading={isSynthesizing}
        disabled={groups.length === 0 || !canEdit}
        className="tour-synth-step5"
        onClick={() => synthesizeData({ datasetId: id })}
      >
        Synthesize
      </Button>

      {synthProgress && synthProgress.finishedGroups < synthProgress.total ? (
        <>
          <Button
            color="secondary"
            onClick={stopSynthesizeData}
            style={{ marginLeft: '1rem' }}
          >
            Stop Synthesize
          </Button>
          <Progress
            value={synthProgress.progress}
            label={true}
            labelText={`Synthesized ${synthProgress.finishedGroups} of ${synthProgress.total}`}
            classNames={{
              root: classes.progressRoot,
            }}
            width="9rem"
          />
        </>
      ) : null}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  synthProgress: selectSynthesizeProgress,
  isSynthesizing: selectIsSynthesizing,
  groups: selectDataGroups,
  canEdit: getProjectPermissionsForUser,
})

const mapDispatchToProps = {
  synthesizeData: scenarioActions.synthesizeData,
  stopSynthesizeData: scenarioActions.stopSynthesizeData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SynthesizeBar)

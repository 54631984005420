import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '../../../shared'
import { ReactComponent as InfoIcon } from './InfoIcon.svg'

const useStyles = makeStyles({
  root: {
    padding: '1.5rem',
  },
  titleMargin: {
    marginBottom: '1rem',
  },
  smallTitle: {
    display: 'flex',
  },
  smallTitleTypography: {
    marginRight: '.5rem',
  },
  tableLayout: {
    width: '60rem',
  },
  cellRoot: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
})

const useInfoIconStyles = makeStyles({
  status: {
    display: 'inline-flex',
    alignSelf: 'center',
    marginLeft: '4px',
    '& svg': {
      height: '1em',
      width: '1em',
    },
  },
  baseline: {
    '& svg': {
      top: '.05em',
      position: 'relative',
    },
  },
})

interface IBiasScoreProps {
  title: string
  totalFairnessScore: number
  tooltip?: boolean
}

export const BiasScore: React.FunctionComponent<IBiasScoreProps> = ({
  title,
  totalFairnessScore,
  tooltip,
}) => {
  const fairnessScoreTooltip =
    'The Fairness Score ranges from 0% to 100%, where 0% refers to a completely biased dataset and 100% refers to a dataset without any bias. The mathematical expression for computing the fairness score and the bias score corresponds to the definition of bias which the platform assumes and may be different from other definitions of fairness and biases. Note that this version assumes that the entire dataset is a fair representation of the underlying phenomena. It further assumes that the legally protected attributes are distinguishable.'

  const classes = useStyles()
  const iconClasses = useInfoIconStyles()

  return (
    <div className={classnames(classes.titleMargin, classes.smallTitle)}>
      <Typography size="body" className={classes.smallTitleTypography}>
        {title}{' '}
      </Typography>
      <Typography size="bodyBold">{totalFairnessScore}%</Typography>
      {tooltip && (
        <Tooltip
          title={fairnessScoreTooltip}
          arrow
          placement="right"
          interactive
        >
          <div className={classnames(iconClasses.status, iconClasses.baseline)}>
            <InfoIcon />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SuiteResponse } from 'api/generated'

interface ITestingSuitesState {
  loading: boolean
  suites: SuiteResponse[] | []
  files: File[] | []
}

const initialState: ITestingSuitesState = {
  loading: false,
  suites: [],
  files: [],
}
const prefix = 'testingSuites'

const testingSuitesReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchTestingSuites: (state) => {
      state.loading = true
    },
    didFetchTestingSuites: (
      state,
      { payload }: PayloadAction<SuiteResponse[]>
    ) => {
      state.loading = false
      state.suites = payload
    },
    didFetchTestingSuitesFail: (state) => {
      state.loading = false
    },
    deleteTestingSuite: (
      state,
      { payload }: PayloadAction<{ id: number }>
    ) => {},
    didDeleteTestingSuite: (
      state,
      { payload }: PayloadAction<{ id: number }>
    ) => {
      state.suites = state.suites.filter((suite) => suite.id !== payload.id)
    },
  },
})

export const testingSuitesActions = {
  ...testingSuitesReducer.actions,
}

export default testingSuitesReducer.reducer

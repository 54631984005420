import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ISignFormFail,
  IChangePasswordState,
  IChangePassword,
} from '../typings'

const prefix = 'changePassword'

const initialState: IChangePasswordState = {
  isLoading: false,
  serverError: null,
  status: null,
}

const changePasswordReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    changePassword: (state, { payload }: PayloadAction<IChangePassword>) => {
      state.isLoading = true
      state.serverError = null
    },
    didChangePassword: (state) => {
      state.status = 'success'
      state.isLoading = false
      state.serverError = null
    },
    didChangePasswordFail: (
      state,
      { payload }: PayloadAction<ISignFormFail>
    ) => {
      state.isLoading = false
      state.serverError = payload
    },
    resetFormStatus: () => {
      return { ...initialState }
    },
  },
})

export const changePasswordActions = changePasswordReducer.actions

export default changePasswordReducer.reducer

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { createStructuredSelector } from 'reselect'
import Joyride, { Step } from 'react-joyride'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import { Button } from 'UIkit/Button/Button'
import User from '../Account/User'
import { getActiveTip, getIsTourEnabled } from './selectors'
import { productTourActions, Tip } from './reducer'
import { IconButton, Menu, Typography } from '../../shared'

interface IStep extends Step {
  type: string
  pause?: boolean
}

export interface ISteps {
  [key: string]: IStep[]
}

const tooltips: ISteps = {
  '0': [
    {
      title: 'Project Page',
      content: (
        <div>
          Here you'll find all the data projects you have access to. Click on
          any project to begin!
        </div>
      ),
      placement: 'center',
      target: 'body',
      disableBeacon: true,
      type: 'buttonAction',
      pause: true,
    },
    {
      title: 'First tip completed!',
      content: (
        <div>
          Now you know where to find your projects. Let’s get Synthesizing.
        </div>
      ),
      placement: 'center',
      target: 'body',
      disableBeacon: true,
      type: 'lastStep',
    },
  ],
  '1': [
    {
      title: 'Welcome to the second tip!',
      content:
        "First let's take a look at the data to better understand its attributes.",
      placement: 'center',
      target: 'body',
      disableBeacon: true,
      type: 'firstStep',
    },
    {
      title: 'Know your data',
      content: (
        <div>The graphs give you an overview of the selected dataset.</div>
      ),
      offset: 0,
      placement: 'bottom-start',
      target: '.tour-orig-step1',
      disableBeacon: true,
      type: 'buttonAction',
    },
    {
      title: 'Statistics',
      content: (
        <div>
          Beneath each graph you'll find useful statistics to help you to
          understand your dataset.
        </div>
      ),
      placement: 'auto',
      target: '.tour-orig-step2',
      disableBeacon: true,
      type: 'buttonAction',
    },
    {
      title: 'Bias Target Column',
      content: (
        <div>
          If you want to analyze bias in your dataset select a column as the
          Bias Target Column from the dropdown menu.
          <br />
          <br /> If you don't want to analyze bias select "None".
        </div>
      ),
      placement: 'right',
      target: '.tour-orig-step4',
      disableBeacon: true,
      type: 'clickAction',
    },
    {
      title: 'Analyze',
      content: (
        <div>
          To begin profiling the data, just click <b>Analyze</b>!
        </div>
      ),
      placement: 'right',
      target: '.tour-orig-step5',
      disableBeacon: true,
      type: 'clickAction',
    },
    {
      title: 'Taking a while? Don’t worry',
      content: (
        <div>
          Depending on the complexity of your dataset, the analysis may take a
          few minutes.
        </div>
      ),
      placement: 'center',
      target: 'body',
      disableBeacon: true,
      type: 'buttonAction',
      pause: true,
    },
    {
      title: 'Bias and Fairness',
      content: (
        <div>
          Here you can learn about potentially significant biases found within
          your dataset. We provide an overall Fairness score, ranging from 0% to
          100%, for the quick comparison of different datasets.
          <br />
          <br />
          You can also view the breakdown of the Fairness score by groups of
          sensitive attributes in the table. Different groups of attributes can
          have either a positive or negative bias as indicated by the arrows in
          the table.
        </div>
      ),
      offset: -20,
      placement: 'auto',
      target: '.tour-original-bias',
      disableBeacon: true,
      type: 'buttonAction',
    },
    {
      title: 'Second tip completed!',
      content: (
        <div>
          You’ve successfully profiled your data and eliminated potentially
          significant biases.
        </div>
      ),
      placement: 'center',
      target: 'body',
      disableBeacon: true,
      // type: 'lastStep',
      type: 'closeTour',
    },
  ],
  // '2': [
  //   {
  //     title: 'Welcome to the third and final tip of our tour!',
  //     content: (
  //       <div>
  //         Now that you’ve analyzed your data, let’s see how we can take it to
  //         the next level and use your data to its full potential.
  //       </div>
  //     ),
  //     placement: 'center',
  //     target: 'body',
  //     disableBeacon: true,
  //     type: 'firstStep',
  //   },
  //   {
  //     title: 'Enhance the data',
  //     content: (
  //       <div>
  //         Click here on <b>Enhanced Data</b>. This is where you can configure a
  //         modified dataset with better properties such as enhanced privacy,
  //         fairness and data quality.
  //       </div>
  //     ),
  //     placement: 'bottom',
  //     target: '.tour-trained-step4',
  //     disableBeacon: true,
  //     type: 'clickAction',
  //   },
  //   {
  //     title: 'Modify your dataset size',
  //     content: (
  //       <div>
  //         It’s quick and easy to create an enhanced dataset. Just specify the
  //         number of rows you need.
  //       </div>
  //     ),
  //     placement: 'auto',
  //     target: '.tour-synth-step1',
  //     disableBeacon: true,
  //     type: 'buttonAction',
  //   },
  //   {
  //     title: 'Original data',
  //     content: (
  //       <div>
  //         If you want to share the data with external parties, you can exclude
  //         the original data from the final output and mitigate all privacy risks
  //         for PII. For now, just select “No”.
  //       </div>
  //     ),
  //     placement: 'auto',
  //     target: '.tour-synth-step2',
  //     disableBeacon: true,
  //     type: 'buttonAction',
  //   },
  //   {
  //     title: 'Missing values',
  //     content: (
  //       <div>
  //         If there are any missing values, you can choose to keep their pattern
  //         or create a complete new dataset with no missing values. Keep it set
  //         on “No”, to clean the dataset of missing values.
  //       </div>
  //     ),
  //     placement: 'auto',
  //     target: '.tour-synth-step3',
  //     disableBeacon: true,
  //     type: 'buttonAction',
  //   },
  //   {
  //     title: 'Bias mitigation',
  //     content: (
  //       <div>
  //         Finally let's mitigate the biases we found before. Just leave the
  //         option set to “Yes”.
  //       </div>
  //     ),
  //     placement: 'auto',
  //     target: '.tour-synth-step4',
  //     disableBeacon: true,
  //     type: 'buttonAction',
  //   },
  //   {
  //     title: 'Let’s synthesize!',
  //     content: (
  //       <div>
  //         Click here and in just a few moments, your new dataset will be
  //         available.
  //       </div>
  //     ),
  //     placement: 'auto',
  //     target: '.tour-synth-step5',
  //     disableBeacon: true,
  //     type: 'clickAction',
  //   },
  //   {
  //     title: 'Enhanced dataset is ready',
  //     content: (
  //       <div>
  //         Your enhanced dataset is available for you to view under the{' '}
  //         <b>Output</b> tab.
  //       </div>
  //     ),
  //     placement: 'auto',
  //     target: '.tour-synth-step6',
  //     disableBeacon: true,
  //     type: 'clickAction',
  //   },
  //   {
  //     title: 'Your enhanced dataset',
  //     content: (
  //       <div>
  //         This table shows a preview of the enhanced dataset. The top of each
  //         column contains a statistical summary to give you an idea of what this
  //         new dataset looks like.
  //       </div>
  //     ),
  //     placement: 'center',
  //     target: 'body',
  //     disableBeacon: true,
  //     type: 'buttonAction',
  //   },
  //   {
  //     title: 'Well done!',
  //     content: (
  //       <div>
  //         Thank you for completing our quick tour. Now off you go and make the
  //         most of your data!
  //       </div>
  //     ),
  //     placement: 'center',
  //     target: 'body',
  //     disableBeacon: true,
  //     type: 'closeTour',
  //   },
  // ],
}

interface ISelectedProps {
  activeTip: Tip
  isTourEnabled: boolean
}

interface IDispatchProps {
  startNextTip: (p: any) => void
  pauseTip: (p: any) => void
  nextStep: (p: any) => void
  skipTour: (p: any) => void
  setIsTourEnabled: (p: any) => void
  restart: () => void
}

const useStyles = makeStyles({
  triggerButton: {
    padding: 0,
  },
  menu: {
    zIndex: 20000,
  },
})

const TooltipHOC = (props: any) => {
  return ({ index, step, tooltipProps }: any) => (
    <Tooltip {...props} index={index} step={step} tooltipProps={tooltipProps} />
  )
}

const Tooltip = ({
  startNextTip,
  skipTour,
  nextStep,
  pauseTip,
  restart,
  total,
  index,
  step,
  tooltipProps,
}: any) => {
  const classes = useStyles()
  const ButtonContainer = ({ children }: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        {children}
      </div>
    )
  }

  const Header = ({ index, total, skipTour, restart }: any) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography size="small" color="tourLightGrey">{`STEP ${
            index + 1
          } OF ${total}`}</Typography>
        </div>
        <Menu
          items={[
            {
              text: 'Start over',
              onClick: () => restart(),
            },
            {
              text: 'Skip tour',
              onClick: () => skipTour(),
            },
          ]}
          renderTriggerComponent={({ showMenu }: any) => {
            return (
              <IconButton onClick={showMenu} className={classes.triggerButton}>
                <MoreVertIcon />
              </IconButton>
            )
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        />
      </div>
    )
  }

  return (
    <div
      {...tooltipProps}
      style={{
        backgroundColor: '#fff',
        width: '360px',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <div style={{ textAlign: 'left' }}>
        <Header
          index={index}
          total={total}
          skipTour={skipTour}
          restart={restart}
        />
        {step.title && (
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Typography size="subheaderBold">{step.title}</Typography>
          </div>
        )}
        <Typography size="body">{step.content}</Typography>
      </div>
      {step.type === 'lastStep' && (
        <ButtonContainer>
          {/* <Button
            variant="outlined"
            style={{ marginRight: '12px' }}
            onClick={() => skipTour()}
          >
            <div>Ok for now</div>
          </Button> */}

          <Button style={{ marginRight: '6px' }} onClick={() => startNextTip()}>
            <div>Go to the next tip</div>
          </Button>
        </ButtonContainer>
      )}
      {step.type === 'firstStep' && (
        <ButtonContainer>
          {/* <Button
            variant="outlined"
            style={{ marginRight: '12px' }}
            onClick={() => skipTour()}
          >
            <div>Skip for now</div>
          </Button> */}

          <Button style={{ marginRight: '6px' }} onClick={() => nextStep()}>
            <div>Yes, let’s!</div>
          </Button>
        </ButtonContainer>
      )}
      {step.type === 'buttonAction' && (
        <ButtonContainer>
          <Button
            style={{ marginRight: '6px' }}
            onClick={() => (step.pause ? pauseTip({}) : nextStep())}
          >
            <div>OK, got it! </div>
          </Button>
        </ButtonContainer>
      )}
      {step.type === 'closeTour' && (
        <ButtonContainer>
          <Button style={{ marginRight: '6px' }} onClick={() => skipTour()}>
            <div>Close tour</div>
          </Button>
        </ButtonContainer>
      )}
    </div>
  )
}

export const ProductTour: React.FunctionComponent<
  ISelectedProps & IDispatchProps
> = ({
  activeTip,
  isTourEnabled,
  pauseTip,
  skipTour,
  startNextTip,
  nextStep,
  setIsTourEnabled,
  restart,
}) => {
  const { pathname } = useLocation()

  useEffect(() => {
    setIsTourEnabled({ isEnabled: User.show_onboarding })
    return () => {
      if (pathname === '/menu') {
        pauseTip({ tip: '0' })
      }
    }
  }, [pauseTip, setIsTourEnabled])

  if (!isTourEnabled || !activeTip) {
    return null
  }

  return (
    <Joyride
      run={activeTip.run}
      stepIndex={activeTip.step}
      steps={tooltips[activeTip.id]}
      disableCloseOnEsc
      scrollOffset={300}
      spotlightClicks
      disableOverlayClose
      styles={{
        options: {
          zIndex: 1250,
          primaryColor: '#5065FB',
        },
      }}
      floaterProps={{ styles: { floater: { filter: 'none' } } }}
      tooltipComponent={TooltipHOC({
        startNextTip,
        skipTour,
        nextStep,
        pauseTip,
        restart,
        total: tooltips[activeTip.id].length,
      })}
    />
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  activeTip: getActiveTip,
  isTourEnabled: getIsTourEnabled,
})

const mapDispatchToProps = {
  startNextTip: productTourActions.startNextTip,
  pauseTip: productTourActions.pauseTip,
  skipTour: productTourActions.skipTour,
  nextStep: productTourActions.nextStep,
  setIsTourEnabled: productTourActions.setIsTourEnabled,
  restart: productTourActions.restart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTour)

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Button } from 'UIkit/Button/Button'
import { Typography } from '../../shared'

import { productTourActions, Tip } from './reducer'
import { getActiveTip, getIsTourEnabled } from './selectors'

import { pushRoute, Routes } from '../../utils'
import User from '../Account/User'

interface ISelectedProps {
  activeTip: Tip
  isTourEnabled: boolean
}

export const ProductTourNotification = ({
  activeTip,
  isTourEnabled,
  startTip,
  skipTour,
}: any) => {
  if (!isTourEnabled || activeTip) {
    return null
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        width: '400px',
        borderRadius: '4px',
        boxShadow: '4px 4px 10px 0px rgba(59,47,108,0.2)',
        border: '1px #e0e4ed solid',
        padding: '20px',
      }}
    >
      <div
        style={{
          textAlign: 'left',
        }}
      >
        <div style={{ marginBottom: '12px' }}>
          {User.isCETier() ? (
            <Typography size="bodyBold">
              Welcome to the Community Edition
            </Typography>
          ) : (
            <Typography size="bodyBold">Welcome to Synthesized</Typography>
          )}
        </div>

        <Typography size="body" color="tourGrey">
          We've put together a quick tour to help you get up to speed and start
          using the platform in no time.
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Button
          variant="outlined"
          style={{ marginRight: '12px' }}
          onClick={() => skipTour()}
        >
          <div>Skip for now</div>
        </Button>

        <Button
          style={{ marginRight: '6px' }}
          onClick={() => {
            pushRoute(Routes.Projects())
            startTip({ tip: '0' })
          }}
        >
          <div>Yes, let’s start!</div>
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  activeTip: getActiveTip,
  isTourEnabled: getIsTourEnabled,
})

const mapDispatchToProps = {
  startTip: productTourActions.startTip,
  skipTour: productTourActions.skipTour,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTourNotification)

import React from 'react'
import { Container, Draggable } from 'react-smooth-dnd'
import { isEqual } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { Tabs } from 'UIkit'

import { SwitchCase } from 'components/SwitchCase'
import { colors } from 'legacy/shared'

import TableComponent from './tables_components/TableComponent'

import _v from '../../../styles/_variables.scss'

class TablesContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.first_show_switcher = false
    this.state = { activeTab: null }
  }

  componentDidUpdate(prevProps) {
    const { items, query_data } = this.props
    if (
      !this.first_show_switcher &&
      (!isEqual(prevProps.items, items) ||
        !isEqual(prevProps.query_data, query_data))
    ) {
      if (
        Object.keys(query_data).length &&
        Object.keys(query_data.settings).length &&
        query_data.settings.columns.length
      ) {
        const setts_cols = query_data.settings.columns.map((d) => d.table)

        const firstTableName =
          items.length &&
          items.find((table) => table.name === setts_cols[0])?.name
        this.setState({ activeTab: firstTableName })
        this.first_show_switcher = true
      } else {
        this.setState({ activeTab: items[0]?.name })
      }
    }
  }

  getPayload = (index, idx_table) => {
    const { items } = this.props
    const cell = items[idx_table].columns[index]
    return {
      name: cell.name,
      table: items[idx_table].name,
    }
  }

  addColumn = (payload) => {
    const { query_data } = this.props
    const add_idx =
      Object.keys(query_data).length && Object.keys(query_data.settings).length
        ? query_data.settings.columns.length
        : 0
    this.props.onDrop({
      removedIndex: null,
      addedIndex: add_idx,
      payload,
    })
  }

  findUniqueTables = (tables) => {
    const _unique = tables.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    return _unique
  }

  render() {
    const { items, query_data, connected_tables, classes } = this.props
    let is_active_tables = []

    if (
      Object.keys(query_data).length &&
      Object.keys(query_data.settings).length
    ) {
      const setts_cols = query_data.settings.columns.map((d) => d.table)

      // find unique values
      const _tables = this.findUniqueTables(setts_cols)

      let foreign_tables = []
      _tables.forEach((d) => {
        foreign_tables = foreign_tables.concat(connected_tables[d])
      })

      // concat _tables and foreign_tables
      const concat_tables = _tables.concat(foreign_tables)
      is_active_tables = this.findUniqueTables(concat_tables)
    }

    const tabs = []
    const tabsContent = {}

    if (items.length) {
      items.forEach((table, idx_table) => {
        tabs.push({
          id: table.name,
          label: table.name,
          disabled:
            is_active_tables.length &&
            !is_active_tables.some((s) => s === table.name),
        })
        tabsContent[table.name] = (
          <Container
            key={table.name}
            dragClass="opacity-ghost"
            groupName="source_drag_and_drop"
            behaviour="copy"
            getChildPayload={(index) => this.getPayload(index, idx_table)}
            onDrop={this.props.onDrop}
          >
            {table.columns.map((item) => {
              const is_disabled =
                Object.keys(query_data).length &&
                Object.keys(query_data.settings).length
                  ? query_data.settings.columns.some(
                      (s) =>
                        JSON.stringify(s) ===
                        JSON.stringify({
                          column: item.name,
                          table: table.name,
                        })
                    )
                  : false
              const style =
                is_disabled || item.column_type === 'BYTEA'
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.3,
                      minHeight: '173px',
                    }
                  : { minHeight: '173px' }
              return (
                <Draggable key={item.name} style={style}>
                  <TableComponent
                    {...item}
                    table={table.name}
                    addColumn={this.addColumn}
                  />
                </Draggable>
              )
            })}
          </Container>
        )
      })
    }

    return (
      <div
        className="uk-flex uk-flex-column uk-width-expand uk-margin-remove"
        style={{ height: '100%' }}
      >
        <div className="uk-text-left table_text_style">
          <div> Tables</div>
        </div>
        <div
          id="id_table_switcher"
          uk-switcher="animation: uk-animation-fade; toggle: > *;"
          style={{
            borderBottom: `${_v.border_width} solid ${_v.switch_border_color}`,
          }}
        >
          {items.length ? (
            <>
              <Tabs
                tabs={tabs}
                onChange={(e, value) => this.setState({ activeTab: value })}
                value={this.state.activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tables sources"
                tabsClasses={{
                  root: classes.rootTabs,
                }}
                tabClasses={{ root: classes.rootTab, wrapper: classes.wrapper }}
              />
              <SwitchCase value={this.state.activeTab}>
                {tabsContent}
              </SwitchCase>
            </>
          ) : null}
        </div>
      </div>
    )
  }
}

const styles = {
  rootTabs: {
    minHeight: '35px',
    height: '35px',
    padding: 0,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.tableBorder}`,
  },
  rootTab: {
    minHeight: '35px',
    height: '35px',
    minWidth: '100px',
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  wrapper: {
    '& > *:first-child': {
      marginBottom: '0!important',
      marginRight: '.3rem',
    },
  },
  container: {
    height: 'calc(100% - 465px)',
  },
}

export default withStyles(styles)(TablesContainer)

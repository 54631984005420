import { all, takeLatest, put, call } from 'redux-saga/effects'
import { SandboxesDatasetActions, didFetchDatasets } from './actions'
import { dataSetsService } from '../../../../utils/services'

function* handleFetchDatasets() {
  const [datasets, synthDatasets] = yield all([
    call(dataSetsService.getDatasets),
    call(dataSetsService.getSynthDatasets),
  ])
  const datasetsWithId = datasets.map((d) => ({
    ...d,
    id: `dataset-${d.dataset_id}`,
  }))
  const synthDatasetsWithId = synthDatasets.map((d) => ({
    ...d,
    id: `synth-${d.id}`,
  }))
  yield put(didFetchDatasets([...datasetsWithId, ...synthDatasetsWithId]))
}

export default function* watchSandboxesDatasetSaga() {
  yield takeLatest(
    SandboxesDatasetActions.FETCH_DATASETS_LIST,
    handleFetchDatasets
  )
}

import { ProjectSettingsActions } from '../settings/actions'

const defaultProjectState = {
  loading: { name: '', is: false },
  synthProgress: {},
}

export default (state = defaultProjectState, action) => {
  switch (action.type) {
    case ProjectSettingsActions.DID_SYNTH_PROGRESS:
      return {
        ...state,
        synthProgress: {
          ...state.synthProgress,
          [action.id]: action.progress,
        },
      }
    case ProjectSettingsActions.GENERATE_SYNTHETIC_DATA:
      return {
        ...state,
        synthProgress: {
          ...state.synthProgress,
          [action.id]: 0,
        },
      }
    case ProjectSettingsActions.START_LOADING:
      return {
        ...state,
        loading: { ...{ name: action.name, is: true } },
      }
    case ProjectSettingsActions.STOP_LOADING:
      return {
        ...state,
        loading: { ...{ name: '', is: false } },
      }
    default:
      return state
  }
}

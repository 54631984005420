import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { Container, Draggable } from 'react-smooth-dnd'
import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import { Button } from 'UIkit/Button/Button'
import { IconButton, Typography, Paper } from 'legacy/shared'

import { useAsyncState } from '../../../services/hooks'
import { colors } from '../../Colors'
import { getProjectDataState } from '../projectSelectors'
import {
  getProjectBlocksState,
  getProjectLoadingState,
} from './reportSelectors'
import { fetchReport, addBlock, deleteBlock, moveBlock } from './actions'
import { colors as colorsTheme } from '../../../shared/theme'

import RightPanel from '../../Report/RightPanel'
import Modelling from '../../Report/Modelling'
import Correlation from '../../Report/Correlation'
import Selects from './Selects'
import LoaderComponent from './LoaderComponent'

const cor_legend = [
  {
    label: 'Original',
    color: colors[0],
  },
  {
    label: 'Synthetic',
    color: colors[1],
  },
]

const useSelectedLabelStyles = makeStyles(() => ({
  root: {
    borderRadius: '10px',
    padding: '0.15rem 0.7rem',
    display: 'inline-block',
    margin: '2px 0',
  },
}))

const useLegendStyles = makeStyles(() => ({
  circle: {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
  },
}))

const useHeaderStyles = makeStyles(() => ({
  root: {
    textAlign: 'right',
    padding: '0.7rem 1.5rem',
    position: 'relative',
  },
}))

const useContentStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  content: {
    padding: '1rem 0',
    overflow: 'auto',
  },
  cardList: {
    margin: 0,
    width: '100%',
    textAlign: 'start !important',
    padding: '1rem 0',

    '&:first-child': {
      paddingTop: '0.5rem',
    },
  },
  paper: {
    position: 'relative',
  },
  container: {
    display: 'flex',
  },
  leftContent: {
    width: '240px',
    minWidth: '240px',
    padding: '0 1.8rem 0 0',
    borderRight: `1px solid ${colorsTheme.platinum}`,
  },
  rightContent: {
    width: '100%',
    marginLeft: '1.8rem',
    overflow: 'auto',
  },
  rightUpContent: {},
  rightDownContent: {
    overflow: 'auto',
  },
}))

const SelectedLabelComponent = ({ color, label }) => {
  const classes = useSelectedLabelStyles()
  return (
    <Box
      className={classes.root}
      style={{
        backgroundColor: color,
      }}
    >
      <Typography size="smallBold">{label}</Typography>
    </Box>
  )
}

export const LabelComponent = (props) => {
  const { label, size } = props
  return (
    <Box {...props}>
      <Typography size={size}>{label}</Typography>
    </Box>
  )
}

const LegendComponent = ({ color, label }) => {
  const classes = useLegendStyles()
  return (
    <Box display="flex" alignItems="center" mr={1.5}>
      <Box
        component="span"
        mr={0.5}
        className={classes.circle}
        style={{ backgroundColor: color }}
      />
      <Typography size="small">{label}</Typography>
    </Box>
  )
}

const ReportHeader = ({ dnd_list, loadingReport, exportReport }) => {
  const classes = useHeaderStyles()
  const [downloadStatus, setDownloadStatus] = useState('idle')

  const clickHandler = () => {
    setDownloadStatus('loading')
    setTimeout(async () => {
      await exportReport()
      setDownloadStatus('idle')
    }, 500)
  }

  return (
    <Box className={classes.root}>
      {loadingReport ? <LoaderComponent /> : null}

      <Button
        loading={downloadStatus === 'loading'}
        disabled={dnd_list === null || !dnd_list.length}
        onClick={clickHandler}
      >
        Download PDF Report
      </Button>
    </Box>
  )
}

const Report = (props) => {
  const classes = useContentStyles()
  const [dnd_list, dnd_listRef, setDNDList] = useAsyncState([])
  const {
    data,
    blocks,
    loading,
    fetchReport,
    addBlock,
    deleteBlock,
    moveBlock,
  } = props
  const loadingReport = loading === 'save'

  const disabled_access_type = data.access_type === 'RESULTS_ONLY'

  useEffect(() => {
    fetchReport({ id: data.dataset_id })
  }, [])

  useEffect(() => {
    setDNDList(blocks)
  }, [blocks])

  const handlerAddBlocks = (key) => {
    addBlock({ id: data.dataset_id, typeBlock: key.toUpperCase() })
  }

  const handlerRemoveBlock = (id, e) => {
    e.stopPropagation()
    deleteBlock({ id: data.dataset_id, item_id: id })
  }

  // TODO TEMPORARY FUNCTION WITH THIS API.
  const exportReport = async () => {
    const imgWidth = 210
    const pageHeight = 295

    let position = 0 // by Y axis
    let remainHeight = 0 // remain height after add image
    let imgHeight = 0 // height current image

    const canvasBlocks = await Promise.all(
      blocks.map((block) => {
        const blockDom = document.getElementById(block.id)
        return html2canvas(blockDom, {
          width: blockDom.clientWidth,
          height: blockDom.scrollHeight,
        })
      })
    )

    const pdf = new jsPDF({ orientation: 'p', unit: 'mm' })

    canvasBlocks.forEach((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      position += imgHeight
      imgHeight = (canvas.height * imgWidth) / canvas.width

      remainHeight = pageHeight - position
      if (imgHeight < remainHeight) {
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      } else {
        position = 0
        pdf.addPage()
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      }
    })

    pdf.save(`${data.title}-report.pdf`)
  }

  const moveAnalyzeItem = (item_id, index) => {
    moveBlock({ id: data.dataset_id, item_id, new_order: index })
  }

  const renderSelectedLabels = (dt) => {
    return dt
      .map((d, i) => (
        <SelectedLabelComponent
          key={d.label + i}
          label={d.label}
          color={d.color}
        />
      ))
      .reduce((prev, curr, i) => {
        const divider =
          dt.length - 1 === +i ? (
            <LabelComponent key={i} label="and" size="bodyBold" ml={1} mr={1} />
          ) : (
            <LabelComponent key={i} label="," size="bodyBold" ml={0.2} mr={1} />
          )
        return [prev, divider, curr]
      })
  }

  const handleDrop = (dropResult) => {
    const new_dnd_list = applyDrag(dnd_list, dropResult)
    setDNDList(new_dnd_list).then((old) =>
      moveAnalyzeItem(
        dnd_listRef.current[dropResult.addedIndex].id,
        dropResult.addedIndex
      )
    )
  }

  const applyDrag = (items, dropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult
    if (removedIndex === null && addedIndex === null) return items

    const result = Array.from(items)
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    return result
  }

  return (
    <Box
      className={classes.root}
      style={loadingReport ? { pointerEvents: 'none' } : {}}
    >
      <ReportHeader
        dnd_list={dnd_list}
        exportReport={exportReport}
        loadingReport={loadingReport}
      />
      <Box className={classes.content}>
        {dnd_list !== null && dnd_list.length ? (
          <div>
            <Container
              orientation="vertical"
              onDrop={handleDrop}
              lockAxis="y"
              shouldAcceptDrop={() => !disabled_access_type}
              dropPlaceholder={{
                animationDuration: 150,
                showOnTop: true,
                className: 'cards-drop-preview',
              }}
            >
              {dnd_list.map((d, i) => {
                let selectedField
                if (d.name !== 'modelling') {
                  // correlation selected labels
                  const checked = d.cats[0].values[0].values.filter(
                    (d) => d.check
                  )
                  selectedField = checked
                } else {
                  selectedField = {}

                  // modelling active dropdown and selected labels
                  selectedField.active_var = d.cats[0].values.filter(
                    (dd) => dd.active
                  )
                  const checkedArr = d.cats[1].values.map((m) =>
                    m.values.filter((d) => d.check)
                  )
                  selectedField.checked = [...checkedArr[0], ...checkedArr[1]]
                  selectedField.active_model = d.cats[2].values.filter(
                    (dd) => dd.active
                  )
                }

                return (
                  <Draggable
                    key={`dnd_analyze_${d.id}`}
                    style={{ width: '100%' }}
                  >
                    <Box className={classes.cardList}>
                      <Paper className={classes.paper}>
                        <Box id={d.id} className={classes.container}>
                          <Box className={classes.leftContent}>
                            <Selects
                              disabled={disabled_access_type}
                              dataset_id={data.dataset_id}
                              blocks_data={dnd_list}
                              block_id={d.id}
                              index_block={i}
                            />
                          </Box>
                          <Box className={classes.rightContent}>
                            <Box className={classes.rightUpContent}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  paddingRight: '2.2rem',
                                }}
                              >
                                {d.name !== 'modelling' ? (
                                  <>
                                    <LabelComponent
                                      label="Correlating"
                                      size="bodyBold"
                                      mr={1}
                                      mt={0.25}
                                      mb={0.25}
                                    />
                                    {selectedField.length !== 0
                                      ? renderSelectedLabels(selectedField)
                                      : null}
                                  </>
                                ) : (
                                  <>
                                    <LabelComponent
                                      label="Modelling"
                                      size="bodyBold"
                                      mr={1}
                                      mt={0.25}
                                      mb={0.25}
                                    />
                                    <LabelComponent
                                      label={
                                        selectedField.active_var.length
                                          ? selectedField.active_var[0].label
                                          : 'null'
                                      }
                                      size="body"
                                      mr={1}
                                    />
                                    <LabelComponent
                                      label="in terms of"
                                      size="bodyBold"
                                      mr={1}
                                    />
                                    {selectedField.checked.length
                                      ? renderSelectedLabels(
                                          selectedField.checked
                                        )
                                      : null}
                                    <LabelComponent
                                      label="using"
                                      size="bodyBold"
                                      ml={1}
                                      mr={1}
                                    />
                                    <LabelComponent
                                      label={
                                        selectedField.active_model.length
                                          ? selectedField.active_model[0].label
                                          : 'null'
                                      }
                                      size="body"
                                      mr={1}
                                    />
                                  </>
                                )}
                              </div>
                              <IconButton
                                disabled={disabled_access_type}
                                onClick={(e) => handlerRemoveBlock(d.id, e)}
                                data-testid="project-report-close-block-button"
                                style={{
                                  top: '.6rem',
                                  right: '.6rem',
                                  position: 'absolute',
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                            <Box className={classes.rightDownContent}>
                              {d.name === 'correlation' ? (
                                <div className="card-cor-legend">
                                  {cor_legend.map((d) => (
                                    <LegendComponent
                                      key={d.label}
                                      label={d.label}
                                      color={d.color}
                                    />
                                  ))}
                                </div>
                              ) : null}
                              <div
                                style={{
                                  marginTop:
                                    d.name === 'modelling' ? '20px' : '5px',
                                }}
                              >
                                {d.name === 'modelling' ? (
                                  <Modelling
                                    data_item={d}
                                    blocks_data={
                                      dnd_list[
                                        dnd_list.map((d) => d.id).indexOf(d.id)
                                      ]
                                    }
                                  />
                                ) : (
                                  <Correlation
                                    data_item={d}
                                    blocks_data={
                                      dnd_list[
                                        dnd_list.map((d) => d.id).indexOf(d.id)
                                      ]
                                    }
                                  />
                                )}
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Draggable>
                )
              })}
            </Container>
          </div>
        ) : null}
        <RightPanel
          disabled={disabled_access_type || loadingReport}
          dataset_id={data.dataset_id}
          handlerAddBlocks={handlerAddBlocks}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = createStructuredSelector({
  data: getProjectDataState,
  blocks: getProjectBlocksState,
  loading: getProjectLoadingState,
})

const mapDispatchToProps = {
  fetchReport,
  addBlock,
  deleteBlock,
  moveBlock,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report))

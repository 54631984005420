export const CleanRoomBuildingSteps = {
  Dataset: 'dataset',
  OS: 'os',
  DB: 'dbServer',
  Confirm: 'confirm',
}

export const StepperLabels = [
  'Select Dataset',
  'Select Operating System',
  'Select Database Server',
  'Confirm',
]

export const BuildingStepsOrder = [
  CleanRoomBuildingSteps.Dataset,
  CleanRoomBuildingSteps.OS,
  CleanRoomBuildingSteps.DB,
  CleanRoomBuildingSteps.Confirm,
]

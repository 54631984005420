import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'
import { forgotPasswordActions } from 'services/auth/forgotPassword/reducer'
import { getForgotPasswordState } from 'services/auth/forgotPassword/selectors'

import { Form, TextField, Typography, spacing } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'

const ForgotPasswordSchema = {
  email: constraints.email,
}

interface ISelectedProps {
  formState: ReturnType<typeof getForgotPasswordState>
}

type DispatchProps = typeof mapDispatchToProps

type IForgotPasswordProps = ISelectedProps & DispatchProps

const fieldMargin = {
  marginBottom: spacing.gutterLarge,
}

export const ForgotPasswordForm: React.FC<IForgotPasswordProps> = ({
  formState,
  resetPassword,
}) => {
  const { isLoading, serverError } = formState

  return (
    <Form
      onSubmit={resetPassword}
      initialValues={{ email: '' }}
      validate={createValidatorWithSchema(ForgotPasswordSchema)}
    >
      {({ values, errors, handleSubmit, handleChange }: any) => {
        return (
          <>
            <TextField
              name="email"
              label="Email"
              type="email"
              value={values.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            {serverError !== null && (
              <div style={{ marginBottom: '16px' }}>
                <Typography color="red">{serverError.message}</Typography>
              </div>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              fullWidth
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          </>
        )
      }}
    </Form>
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  formState: getForgotPasswordState,
})

const mapDispatchToProps = {
  resetPassword: forgotPasswordActions.resetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { createStructuredSelector } from 'reselect'
import AnalyzeBar from './AnalyzeBar'
import OriginalColumns from './OriginalColumns'
import Quality from './biasAndQuality/Quality'
import OriginalBias from './biasAndQuality/OriginalBias'
import { processStatus } from './biasAndQuality/constants'
import { SpinnerSection } from './biasAndQuality/SpinnerSection'

import { SectionTitle } from './Layouts'
import {
  getProjectDataState,
  getProjectStatusState,
  getProjectPreviewDataState,
  getQualityStatus,
  getBiasStatus,
  getQualityData,
} from './projectSelectors'
import { LegendList } from './legend/LegendList'
import { colors } from '../Colors'
import { SwitchCase } from '../../shared'

import { getDisplayColumnsState } from './projectSettings/selectors'
import { fetchOriginalDataset } from './actions'

const legends = [
  { name: 'Original', color: colors[0] },
  { name: 'Analyzed', color: colors[1] },
]

const useStyles = makeStyles({
  section: {
    paddingTop: 0,
  },
  legendListRoot: {
    // padding: '0 1.5rem',
  },
})

export const OriginalDataView = (props) => {
  const {
    datasetId,
    data,
    previewData,
    status,
    qualityStatus,
    biasFairnessStatus,
    qualityData,
    displayColumns,
    fetchOriginalDataset,
  } = props

  const sampleSize = '10'

  useEffect(() => {
    fetchOriginalDataset({ id: datasetId, sampleSize })
  }, [fetchOriginalDataset])

  const classes = useStyles()
  const [isShowComplexRow, setIsShowComplexRow] = useState({
    isShow: true,
    action: 'bind',
  })

  const handleShowComplexRow = (bool, action, complexType) => {
    const newObj = {
      isShow: bool,
      action,
      complexType: complexType || '',
    }
    setIsShowComplexRow(newObj)
  }

  return data.meta ? (
    <>
      <AnalyzeBar
        handleShowComplexRow={handleShowComplexRow}
        isShowComplexRow={isShowComplexRow}
      />

      <>
        <Box p={0}>
          <LegendList
            legends={legends}
            classNames={{ listRoot: classes.legendListRoot }}
          />
          <OriginalColumns
            data={data}
            displayColumns={displayColumns}
            status={status}
            synth_data={previewData}
            handleShowComplexRow={handleShowComplexRow}
            isShowComplexRow={isShowComplexRow}
          />
        </Box>
        <Box pt={2}>
          <SwitchCase value={biasFairnessStatus}>
            {{
              [processStatus.Ready]: <OriginalBias />,
              [processStatus.Progress]: (
                <>
                  <SectionTitle title="Bias and Fairness" />
                  <SpinnerSection />
                </>
              ),

              [processStatus.Init]: null,
            }}
          </SwitchCase>
        </Box>

        {qualityStatus !== processStatus.Init ? (
          <Box pt={2} pb={2}>
            <Quality status={qualityStatus} qualityData={qualityData} />
          </Box>
        ) : null}
      </>
    </>
  ) : (
    <div style={{ marginTop: '120px' }}>
      <SpinnerSection />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  data: getProjectDataState,
  previewData: getProjectPreviewDataState,
  status: getProjectStatusState,
  qualityStatus: getQualityStatus,
  biasFairnessStatus: getBiasStatus,
  qualityData: getQualityData,
  displayColumns: getDisplayColumnsState,
})

const mapDispatchToProps = {
  fetchOriginalDataset,
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginalDataView)

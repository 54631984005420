import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'
import { DatasetResponse } from 'api/generated/modelPkg/dataset-response'

import { uploadDatasetActions } from 'services/uploadDataset/reducer'

const prefix = 'datasets'

const datasetsReducer = createSlice({
  name: prefix,
  initialState: {
    byId: {},
    pageIsLoading: true,
    deleteProjectState: 'regular',
    updateProjectState: 'regular',
  },
  reducers: {
    fetchDatasets: (state) => {
      state.pageIsLoading = true
    },
    didFetchDatasets: (
      state,
      { payload }: PayloadAction<DatasetResponse[]>
    ) => {
      state.pageIsLoading = false
      state.byId = payload.reduce((acc: any, dataset) => {
        acc[dataset.id] = dataset
        return acc
      }, {})
    },
    didFetchDatasetsFail: (state) => {
      state.pageIsLoading = false
      state.byId = {}
    },
    updateDatasetInfo: (state) => {
      state.updateProjectState = 'saving'
    },
    didUpdateDatasetInfo: (state) => {
      state.updateProjectState = 'regular'
    },
    deleteDataset: (state) => {
      state.deleteProjectState = 'saving'
    },
    didDeleteDataset: (state, { payload }: PayloadAction<any>) => {
      state.deleteProjectState = 'regular'
      state.byId = omit(state.byId, payload)
    },
    didDeleteDatasetFail: (state) => {
      state.deleteProjectState = 'regular'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      uploadDatasetActions.didUploadDataset,
      (state, { payload }: PayloadAction<any>) => {
        const { id } = payload
        state.byId = { ...state.byId, [id]: payload }
      }
    )
  },
})

export const datasetsActions = {
  ...datasetsReducer.actions,
}

export default datasetsReducer.reducer

import React, { useMemo } from 'react'
import { Table } from 'components/Table/Table'
import { Box, styled } from '@material-ui/core'
import {
  CustomHeaderCell,
  DescriptionCell,
  NameCell,
  TimeCell,
  ImageCell,
} from 'components/Table'
import { StatusTag } from 'legacy/components/Datasets/StatusTag'
import { Row } from 'react-table'
import { pushRoute, Routes } from 'legacy/utils'
import { SuiteResponse } from '../../api/generated'

import ActionMenu from './ActionMenu'

interface ITestingSuitesTableProps {
  data: SuiteResponse[]
  searchValue: string
  loading: boolean
}

const TestingSuitesTable = (props: ITestingSuitesTableProps) => {
  const { data, searchValue, loading } = props
  const columns = useMemo(
    () => [
      {
        Header: <CustomHeaderCell title="Name" />,
        accessor: 'name',
        isSorted: true,
        width: 200,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData && (
            <NameCell value={cellProps.cell.value} />
          ),
      },
      {
        Header: <CustomHeaderCell title="Description" />,
        accessor: 'description',
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData && (
            <DescriptionCell value={cellProps.cell.value} />
          ),
      },

      {
        Header: <CustomHeaderCell title="Status" />,
        accessor: 'status',
        disableResizing: true,
        width: 130,
        minWidth: 130,
        maxWidth: 130,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData && (
            <StatusTag status={cellProps.cell.value} />
          ),
      },
      {
        Header: <CustomHeaderCell title="Created" />,
        accessor: 'created_at',
        sortType: 'customDate',
        disableResizing: true,
        width: 190,
        minWidth: 190,
        maxWidth: 190,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData && (
            <TimeCell value={cellProps.cell.value} />
          ),
      },
      {
        Header: <CustomHeaderCell title="Owner" />,
        accessor: 'created_by_avatar_path',
        disableResizing: true,
        width: 70,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        disableSortBy: true,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData && (
            <ImageCell
              cell={cellProps.cell}
              style={{ justifyContent: 'center' }}
            />
          ),
      },
      {
        Header: '',
        accessor: 'action',
        disableResizing: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        align: 'center',
        disableSortBy: true,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData && (
            <ActionMenu cell={cellProps.cell.row.original} />
          ),
      },
    ],
    []
  )

  return (
    <TableContainer>
      <Table
        loading={loading}
        data={
          searchValue
            ? data.filter((item: SuiteResponse) =>
                item.name.toLowerCase().includes(searchValue)
              )
            : data
        }
        columns={columns}
        onRowClick={(event: MouseEvent, row: Row) => {
          event.stopPropagation()
          pushRoute(
            Routes.CreateTestingSuite((row.original as SuiteResponse).id)
          )
        }}
      />
    </TableContainer>
  )
}

const TableContainer = styled(Box)({
  height: 'calc(100% - 64px)', // 64px is height of the action panel
  overflow: 'auto',
})

export default TestingSuitesTable

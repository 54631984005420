import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ImageButton, spacing } from '../../../../shared'
import { SandboxStepHeader } from '../SandboxStepHeader'
import NavButtons from '../NavButtons'
import { ftpPicPath, postgrePicPath, oraclePicPath } from './assets'
import { selectDB } from './actions'
import {
  getIsStepCompleted,
  getSelectedSandboxDBName,
} from './sandboxDBSelectors'

const availableDB = { id: 1, name: 'FTP' }

export const DbServerView = (props) => {
  const { isStepCompleted, selectDB, selectedName } = props
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: spacing.gutter * 4,
        }}
      >
        <SandboxStepHeader label="Available Database Servers" />
        <NavButtons isNextDisable={!isStepCompleted} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <ImageButton
          image={{ url: ftpPicPath }}
          onClick={() => selectDB(availableDB)}
          selected={selectedName === 'FTP'}
        />
        <ImageButton disabled image={{ url: postgrePicPath }} />
        <ImageButton disabled image={{ url: oraclePicPath }} />
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  isStepCompleted: getIsStepCompleted,
  selectedName: getSelectedSandboxDBName,
})

const mapDispatchToProps = { selectDB }

export default connect(mapStateToProps, mapDispatchToProps)(DbServerView)

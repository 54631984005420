import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '../../shared'
import ManageColumns from '../ManageColumns'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginRight: '.7rem',
    display: 'flex',
    alignItems: 'baseline',
  },
})

export const DisplayColumns = (props) => {
  const { data, displayColumns, results_only, statusObj } = props
  const classes = useStyles()

  return (
    <div className={`${classes.root} types-style`}>
      <div className={classes.text}>
        <Typography size="captionBold">
          {(displayColumns.length || 0) +
            (data.meta && data.meta !== null && Object.keys(data.meta).length
              ? `/${data.meta.n_columns}`
              : '')}
        </Typography>
        <Typography className="ml-1">columns selected</Typography>
      </div>
      <ManageColumns
        data={
          data.meta && data.meta !== null && Object.keys(data.meta).length
            ? data
            : null
        }
        disabled={results_only || statusObj.status === 'training'}
        displayColumns={displayColumns}
      />
    </div>
  )
}

import { get } from 'lodash'

export const selectTotalValue = (analyzedRules: number, totalRules: number) =>
  Math.round(analyzedRules / (totalRules / 100))

export const selectGeneratedData = (generatedData: any) => {
  if (generatedData) {
    const tables: any = []
    const dataEntries = Object.entries(generatedData)

    dataEntries.forEach(([title, data]: [string, any]) => {
      const table: any = {
        title,
        rows: [],
      }
      const [firstColumn] = Object.values(data) as any
      const columns = Object.keys(data)

      firstColumn.forEach((_: any, rowIndex: number) => {
        const row: any = {}

        columns.forEach((column: string) => {
          row[column] = data[column][rowIndex]
        })

        table.rows.push(row)
      })

      tables.push(table)
    })
    return tables
  }
  return []
}

const reduce = (data: any[], path: string, initialValue: number = 0) =>
  data
    ? data.reduce(
        (acc: number, el: any) => acc + get(el, path, initialValue),
        initialValue
      )
    : initialValue

const average = (data: any[], path: string) =>
  data ? reduce(data, path) / data.length : 0

export const selectTotalData = (data: any[]) => ({
  target: 'Total',
  coverage_old: {
    coverage: average(data, 'coverage_old.coverage'),
    samples: reduce(data, 'coverage_old.samples'),
  },
  coverage_new: {
    coverage: average(data, 'coverage_new.coverage'),
    samples: reduce(data, 'coverage_new.samples'),
  },
  n_analyzed_rules: reduce(data, 'n_analyzed_rules'),
  n_total_rules: reduce(data, 'n_total_rules'),
})

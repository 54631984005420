/* eslint-disable import/prefer-default-export */

export const constraints = {
  email: {
    presence: true,
    email: {
      message: 'Please enter a valid email address',
    },
  },
  requiredString: {
    presence: true,
    length: {
      minimum: 1,
      message: 'Field is required',
    },
  },
  password: {
    length: {
      minimum: 8,
      message: 'Password must be at least 8 characters',
    },
  },
  phoneNumber: {
    format: {
      pattern: `^$|^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$`,
      flags: 'i',
      message: 'Please enter a valid phone number',
    },
  },
  fullname: {
    fullname: true,
  },
  biasMinDist: {
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 10,
    },
  },
}

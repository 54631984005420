import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { CodeBlock as CodeBlockComponent, a11yLight } from 'react-code-blocks'

interface ICodeBlockProps {
  code: string
  language: string
  howLineNumbers: number
}

export const CodeBlock: FC<ICodeBlockProps> = ({ code, language }) => {
  const classes = useStyles()
  return (
    <Box className={classes.codeBlock}>
      <CodeBlockComponent
        text={code}
        codeBlock
        language={language}
        showLineNumbers
        wrapLines
        theme={a11yLight}
      />
    </Box>
  )
}

const useStyles = makeStyles({
  codeBlock: {
    '& > span': {
      border: '1px solid #DCE0E6',
    },
    '& code:nth-child(1)': {
      backgroundColor: '#F4F6FA',
      whiteSpace: 'pre',
    },
    '& code:nth-child(2)': {
      whiteSpace: 'pre',
      width: '100%',
      backgroundColor: '#fff',
    },
  },
})

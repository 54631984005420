import { takeLatest, put, call } from 'redux-saga/effects'
import { notificationActions } from 'services/notification/reducer'
import { modalActions } from 'services/modal/reducer'

import { TestingSuitesErrors } from 'utils/errors'
import { testingSuitesActions } from 'services/testingSuites/reducer'
import { makeRequest } from 'legacy/utils/services/apiClient'
import { addAvatarsToData } from 'services/avatar/service'
import { SuiteResponse } from 'api/generated'

const getSuites = async function () {
  return makeRequest({
    path: `suites`,
    method: 'GET',
  })
}

export const deleteSuite = async ({ id }: { id: number }) => {
  return makeRequest({
    path: `suites/${id}`,
    method: 'DELETE',
  })
}

function* handleFetchTestingSuites() {
  try {
    const suites: SuiteResponse[] = yield call(getSuites)
    const suitesWithAvatars: SuiteResponse[] = yield addAvatarsToData(
      suites,
      'created_by_avatar_path'
    )

    yield put(testingSuitesActions.didFetchTestingSuites(suitesWithAvatars))
  } catch (error) {
    yield put(testingSuitesActions.didFetchTestingSuitesFail())
    yield put(
      notificationActions.showNotification({
        message: TestingSuitesErrors.TESTING_SUITES_FETCH_FAILED,
        severity: 'error',
      })
    )
  }
}

function* handleDeleteTestingSuites({ payload }: any) {
  try {
    yield put(modalActions.setLoading(true))
    yield call(deleteSuite, { id: payload.id })
    yield put(testingSuitesActions.didDeleteTestingSuite({ id: payload.id }))
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: TestingSuitesErrors.TESTING_SUITE_DELETE_FAILED,
        severity: 'error',
      })
    )
  }
  yield put(modalActions.hide())
}

export default function* watchTestingSuitesSaga() {
  yield takeLatest(
    testingSuitesActions.fetchTestingSuites.type,
    handleFetchTestingSuites
  )
  yield takeLatest(
    testingSuitesActions.deleteTestingSuite.type,
    handleDeleteTestingSuites
  )
}

import React from 'react'
import { connect } from 'react-redux'
import { styled } from '@material-ui/core/styles'

import { IStore } from 'core/rootReducer'

import Alert from '@material-ui/lab/Alert'
import { RequestStatus } from 'utils/constants'

import { pushRoute } from 'legacy/utils'

import { dataSourcesActions } from 'services/datasources/datasources/reducer'
import {
  selectDataSourcesList,
  selectDataSourcesStatus,
} from 'services/datasources/datasources/selectors'

import { Spinner, SwitchCase } from 'legacy/shared'
import { modalActions } from 'services/modal/reducer'

import HeaderComponent from 'layouts/Header'
import { ModalTypes } from 'services/modal/constants'

import { resetTableQueryData } from './actions'
import User from '../Account/User'

import _mysql from '../../images/databases/mysql.png'
import _postgresql from '../../images/databases/postgresql.png'
import _oracle from '../../images/databases/oracle.png'
import _mssql from '../../images/databases/mssql.png'
import _mongodb from '../../images/databases/mongodb.png'
import _system from '../../images/databases/system.png'

import '../Datasets/LegacyDatasetsPreloadPage.scss'
import './Datasources.scss'

const SpinnerSection = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
      }}
    >
      <Spinner size={32} />
    </div>
  )
}

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

class DataSources extends React.Component<SelectedProps & DispatchProps, any> {
  componentDidMount() {
    const { resetTableQueryData, fetchDataSources } = this.props
    resetTableQueryData()
    fetchDataSources()
  }

  handlerCreateDatasource = (dt: any) => {
    pushRoute(`/datasources/${dt.datasource_id}`)
  }

  getNeededImage = (type: any) => {
    let image = ''
    switch (true) {
      case type === 'MYSQL':
        image = _mysql
        break
      case type === 'POSTGRESQL':
        image = _postgresql
        break
      case type === 'ORACLE':
        image = _oracle
        break
      case type === 'MSSQL':
        image = _mssql
        break
      case type === 'MONGODB':
        image = _mongodb
        break
      default:
        image = _system
        break
    }

    return image
  }

  handleEditModalData = (dt: any) => {
    const { selectDatasource, showModal } = this.props
    selectDatasource({ id: dt.id })
    showModal({ modalType: ModalTypes.DataSourceEdit })
  }

  handleCreateModalData = () => {
    const { showModal } = this.props
    showModal({ modalType: ModalTypes.DataSourceCreate })
  }

  renderDataSourcesList = (dataSources: any) => {
    const _roles = User.roles ? User.roles.indexOf('ADMIN') : -1

    return (
      <div className="uk-container">
        <CardContainer>
          {dataSources.length
            ? dataSources.map((d: any) => (
                <div key={d.id} className="link-preload-page custom_card_style">
                  <div
                    className="uk-card uk-card-default uk-card-hover uk-card-body"
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '1rem',
                        left: '1rem',
                      }}
                    >
                      <img
                        src={this.getNeededImage(d.type)}
                        width={50}
                        height={50}
                        alt=""
                      />
                    </div>
                    <div
                      className="uk-grid-small"
                      data-uk-grid
                      style={{ flex: '1 0 auto' }}
                    >
                      <div className="uk-width-auto">
                        <div style={{ marginLeft: '5rem' }}>
                          <div className="uk-margin-small">
                            <span className="_datasources-text-bold">
                              {'Name: '}
                            </span>
                            <span className="_datasources-text-light">
                              {d.name ? d.name : 'test'}
                            </span>
                          </div>
                          <div className="uk-margin-small">
                            <span className="_datasources-text-bold">
                              {'Database: '}
                            </span>
                            <span className="_datasources-text-light">
                              {d.database ? d.database : 'test'}
                            </span>
                          </div>
                          <div className="uk-margin-small">
                            <span className="_datasources-text-bold">
                              {'Type: '}
                            </span>
                            <span className="_datasources-text-light">
                              {d.type ? d.type : 'test'}
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="uk-margin-small">
                            <span className="_datasources-text-bold">
                              {'Host: '}
                            </span>
                            <br style={{ margin: '10px 0' }} />
                            <span
                              className="_datasources-text-light"
                              style={{
                                display: 'flex',
                                wordBreak: 'break-all',
                              }}
                            >
                              {d.host ? d.host : 'test'}
                            </span>
                          </div>
                          <div className="uk-margin-small">
                            <span className="_datasources-text-bold">
                              {'Port: '}
                            </span>
                            <span className="_datasources-text-light">
                              {d.port ? d.port : 'test'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="uk-width-auto"
                      style={{
                        flex: '0 0 auto',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        className="_dsr-edit-button"
                        onClick={(e) => {
                          e.stopPropagation()
                          this.handleEditModalData(d)
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className="_dsr-create-button"
                        onClick={() => this.handlerCreateDatasource(d)}
                      >
                        Browse
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
          {_roles !== -1 ? (
            <div className="link-preload-page custom_card_style">
              <div className="plus_new_datasource">
                <div
                  className="plus_container"
                  onClick={() => this.handleCreateModalData()}
                >
                  <span
                    data-uk-icon="icon: plus; ratio: 2.5"
                    style={{ color: 'green' }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </CardContainer>
      </div>
    )
  }

  render() {
    const { dataSources, status } = this.props

    return (
      <div className="row m-0 datasources">
        <div
          className="col col-12 p-0 d-flex"
          style={{ flexDirection: 'column', height: '100%' }}
        >
          <HeaderComponent title="Datasources" />
          <div className="row p-0 m-0 list-datasets-templates">
            <div className="col col-12 p-0 list-datasets-templates-container _datasources-list">
              <SwitchCase value={status}>
                {{
                  [RequestStatus.Succeeded]:
                    this.renderDataSourcesList(dataSources),
                  [RequestStatus.Loading]: <SpinnerSection />,
                  [RequestStatus.Failed]: (
                    <Alert severity="error">
                      Datasources are not available
                    </Alert>
                  ),
                }}
              </SwitchCase>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: IStore) => ({
  dataSources: selectDataSourcesList(state),
  status: selectDataSourcesStatus(state),
})

const mapDispatchToProps = {
  fetchDataSources: dataSourcesActions.fetchDataSources,
  selectDatasource: dataSourcesActions.selectDatasource,
  showModal: modalActions.show,
  resetTableQueryData,
}

const CardContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
})

export default connect(mapStateToProps, mapDispatchToProps)(DataSources)

import * as React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { spacing } from '../../theme'
import { Typography } from '../typography'

const useStyles = makeStyles({
  root: {
    borderRadius: spacing.gutter,
    padding: `2px ${spacing.gutter * 2}px`,
    display: 'inline-block',
  },
})

const Component = ({ label, className }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.root, className)}>
      <Typography size="smallBold">{label}</Typography>
    </div>
  )
}

export const Tag = (props) => <Component {...props} />

Tag.displayName = 'Tag'

import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const getAuthState = (state: IStore) => state.auth

export const getForgotPasswordState = createSelector(
  getAuthState,
  (auth) => auth.forgotPassword
)

export const getForgotPasswordStatus = createSelector(
  getForgotPasswordState,
  (state) => state.status
)

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { Box } from 'UIkit'
import { Button } from 'UIkit/Button/Button'
import { Typography } from 'legacy/shared'

import { FairnessDataMetafield } from 'api/generated/modelPkg'

import {
  getOriginalBiasData,
  getOriginalNumRows,
  getProjectId,
} from '../projectSelectors'
import { BiasTable } from './BiasTable'
import { SectionTitle } from '../Layouts'
import { BiasScore } from './BiasScore'
import {
  getTotalScore,
  getBiasTarget,
  getIsSignificantBias,
  getDistBiases,
} from './utils'
import { exportPDFFairness } from './actions'

const useStyles = makeStyles({
  root: {
    padding: '1.5rem',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleMargin: {
    marginBottom: '1rem',
  },
  smallTitle: {
    display: 'flex',
  },
  smallTitleTypography: {
    marginRight: '.5rem',
  },
  tableLayout: {
    width: '60rem',
  },
  cellRoot: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
})

interface IOriginBiasFairness {
  biasData?: FairnessDataMetafield
  numRows?: number | string | null
  projectId?: number
}

interface IExport {
  id: number
  callback: (result: any) => any
}

interface IDispatchProps {
  exportPDFFairness: (props: IExport) => any
}

type IBiasFairnessProps = IOriginBiasFairness & IDispatchProps

export const BiasFairness: React.FunctionComponent<IBiasFairnessProps> = (
  props
) => {
  const { biasData, numRows, projectId, exportPDFFairness } = props
  const classes = useStyles()

  if (!biasData) {
    return null
  }

  const isSignificantBias = getIsSignificantBias(biasData)
  const targetColumn = getBiasTarget(biasData)
  const totalFairnessScoreOrig = getTotalScore(biasData)
  const distBiasesData = getDistBiases(biasData)

  const handleExportPdfFairness = () => {
    exportPDFFairness({
      id: projectId,
      callback: ({ data, filename }) => {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
    })
  }

  return (
    <Box p={2} border={1}>
      <div className="tour-original-bias">
        <div className={classes.headerContainer}>
          <SectionTitle title="Bias and Fairness" />
          <Button onClick={() => handleExportPdfFairness()}>Download</Button>
        </div>

        <div className={classnames(classes.titleMargin, classes.smallTitle)}>
          <Typography size="body" className={classes.smallTitleTypography}>
            Target Column:
          </Typography>
          <Typography size="bodyBold">{targetColumn}</Typography>
        </div>

        {totalFairnessScoreOrig !== null && (
          <BiasScore
            title="Original Fairness:"
            totalFairnessScore={totalFairnessScoreOrig}
            tooltip
          />
        )}
      </div>
      <BiasTable
        data={distBiasesData}
        numRows={numRows}
        isSignificantBias={isSignificantBias}
      />
    </Box>
  )
}

const mapStateToProps = createStructuredSelector({
  biasData: getOriginalBiasData,
  numRows: getOriginalNumRows,
  projectId: getProjectId,
})

const mapDispatchToProps = {
  exportPDFFairness,
}

export default connect(mapStateToProps, mapDispatchToProps)(BiasFairness)

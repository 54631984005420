import { SandboxResponse } from 'api/generated/modelPkg'

import { cleanRoomsActions } from 'services/cleanrooms/reducer'
import { ModalTypes } from 'services/modal/constants'

type ItemActions = Pick<
  typeof cleanRoomsActions,
  | 'deleteCleanRoom'
  | 'runCleanRoom'
  | 'stopCleanRoom'
  | 'copyToClipboard'
  | 'setCleanRoomToShare'
>

export function prepareItemHandlers(itemActions: ItemActions, showModal: any) {
  const copyToClipboard = {
    text: 'Copy Credentials',
    onClick: (cleanRoom: SandboxResponse) => {
      if (cleanRoom.access_credentials) {
        itemActions.copyToClipboard({
          accessCredentials: cleanRoom.access_credentials,
        })
      }
    },
  }

  const deleteCleanRoom = {
    text: 'Delete',
    onClick: (cleanRoom: SandboxResponse) =>
      showModal({
        modalType: ModalTypes.Confirmation,
        config: {
          headerText: 'Delete Data Clean Room',
          confirmationConfig: {
            actionDescription: `By selecting Delete, you will remove ${cleanRoom.name} Data Clean Room`,
            confirmButtonText: 'Delete',
            confirmButtonAction: () =>
              itemActions.deleteCleanRoom({ cleanRoomId: cleanRoom.id }),
          },
        },
      }),
  }

  const stopCleanRoom = {
    text: 'Stop',
    onClick: (cleanRoom: SandboxResponse) =>
      showModal({
        modalType: ModalTypes.Confirmation,
        config: {
          headerText: 'Stop Data Clean Room',
          confirmationConfig: {
            actionDescription: `By selecting Stop, you will stop ${cleanRoom.name} Data Clean Room`,
            confirmButtonText: 'Stop',
            confirmButtonAction: () =>
              itemActions.stopCleanRoom({ cleanRoomId: cleanRoom.id }),
          },
        },
      }),
  }

  const runCleanRoom = {
    text: 'Run',
    onClick: (cleanRoom: SandboxResponse) =>
      showModal({
        modalType: ModalTypes.Confirmation,
        config: {
          headerText: 'Run Data Clean Room',
          confirmationConfig: {
            actionDescription: `By selecting Run, you will run ${cleanRoom.name} Data Clean Room`,
            confirmButtonText: 'Run',
            confirmButtonColor: 'primary',
            confirmButtonAction: () =>
              itemActions.runCleanRoom({ cleanRoomId: cleanRoom.id }),
          },
        },
      }),
  }

  const shareCleanRoom = {
    text: 'Share',
    onClick: (cleanRoom: SandboxResponse) => {
      itemActions.setCleanRoomToShare({ id: cleanRoom.id })
      showModal({ modalType: ModalTypes.ShareSandbox })
    },
  }

  return {
    copyToClipboard,
    deleteCleanRoom,
    stopCleanRoom,
    runCleanRoom,
    shareCleanRoom,
  }
}

export type PrepareItemHandlers = ReturnType<typeof prepareItemHandlers>

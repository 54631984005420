import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'
import { SUITE_FILE_TYPES } from './types'

export const getCreateTestingSuiteState = (state: IStore) =>
  state.createTestingSuite

export const selectIsFilesUploading = createSelector(
  getCreateTestingSuiteState,
  (state) => state.isFilesUploading
)

export const selectIsDrawerOpened = createSelector(
  getCreateTestingSuiteState,
  (state) => state.isDrawerOpened
)

export const getFiles = createSelector(
  getCreateTestingSuiteState,
  (state) => state.files
)

export const getDataSourcesState = createSelector(getFiles, (files) =>
  files.filter(({ type }) => type === SUITE_FILE_TYPES.DATABASE)
)

export const getFuntionalMappingState = createSelector(getFiles, (files) =>
  files.filter(({ type }) => type === SUITE_FILE_TYPES.FUNCTIONAL_MAPPING)
)

export const getPrimaryKeysState = createSelector(getFiles, (files) =>
  files.filter(({ type }) => type === SUITE_FILE_TYPES.PRIMARY_KEY)
)

export const getForeignKeysState = createSelector(getFiles, (files) =>
  files.filter(({ type }) => type === SUITE_FILE_TYPES.FOREIGN_KEY)
)

export const selectDBConnectFormValues = createSelector(
  getCreateTestingSuiteState,
  (state) => state.DBConnectFormValues
)

export const selectCurrentSuite = createSelector(
  getCreateTestingSuiteState,
  (state) => state.currentSuite
)

export const selectDataSources = createSelector(
  getCreateTestingSuiteState,
  (state) => state.suiteDataSources
)

export const selectLoading = createSelector(
  getCreateTestingSuiteState,
  (state) => state.loading
)

export const getCreateSuiteState = createSelector(
  getCreateTestingSuiteState,
  ({
    isSynthesizing,
    isSynthesizingFailed,
    title,
    reportLoading,
    reportLoadingFailed,
  }) => ({
    isSynthesizing,
    isSynthesizingFailed,
    reportLoading,
    reportLoadingFailed,
    title,
  })
)

export const selectReportData = createSelector(
  getCreateTestingSuiteState,
  (state) => state.reportData || {}
)

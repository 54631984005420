import { createSelector } from 'reselect'
import { getSandboxBuildState } from '../sandboxBuildSelectors'

export const getSandboxConfirmState = createSelector(
  getSandboxBuildState,
  (state) => state.confirm
)

export const getIsSavingInProgress = createSelector(
  getSandboxConfirmState,
  (state) => state.reducerState === 'saving'
)

export const getServerErrorStatus = createSelector(
  getSandboxConfirmState,
  (state) => state.serverError
)

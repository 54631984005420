import * as d3 from 'd3-legacy'
import { formatNumber } from '../../../services/helper'
import { updateHorizontalYDomain } from './prepareDomains'
import { updateHorizontalYAxis } from './updateAxes'

const origin_color = '#515fe7' // origin data color
const synth_color = '#23a956' // synth data color
const gr_origin_color = '#5f71f2' // origin gradient color
const gr_synth_color = '#39b969' // synth gradient color
const changed_color = '#ff15c2' // changed bar color
const gr_changed_color = '#ff15d2' // changed bar gradient color

const dragDuration = 130

const renderHorizontalBarChart = ({
  g,
  tooltip,
  svgScroll,
  x,
  x1,
  y,
  bandwidth,
  data,
  keys,
  col_name,
  disableDragBars,
  truncated,
  width,
  height,
  margin,
  settings,
  temp_transform_arr,
  temp_keys,
  dataset_id,
  from,
  updateSettingsGroup,
}) => {
  g.select('.barChart').selectAll('path').remove()

  g.select('.lines').selectAll('.line-group').remove()

  g.select('.moveElement').selectAll('rect').remove()

  g.selectAll('linearGradient').remove()

  const bgOrigin = g
    .append('linearGradient')
    .attr('id', `origin-gradient`)
    .attr('gradientTransform', 'rotate(90)')
  bgOrigin
    .append('stop')
    .attr('stop-color', `${gr_origin_color}`)
    .attr('offset', '0%')
  bgOrigin
    .append('stop')
    .attr('stop-color', origin_color)
    .attr('offset', '100%')

  const bgSynth = g
    .append('linearGradient')
    .attr('id', `synth-gradient`)
    .attr('gradientTransform', 'rotate(90)')
  bgSynth
    .append('stop')
    .attr('stop-color', `${gr_synth_color}`)
    .attr('offset', '0%')
  bgSynth.append('stop').attr('stop-color', synth_color).attr('offset', '100%')

  const bgChanged = g
    .append('linearGradient')
    .attr('id', `changed-gradient`)
    .attr('gradientTransform', 'rotate(90)')
  bgChanged
    .append('stop')
    .attr('stop-color', `${gr_changed_color}`)
    .attr('offset', '0%')
  bgChanged
    .append('stop')
    .attr('stop-color', changed_color)
    .attr('offset', '100%')

  const _tooltip = tooltip

  g.select('.barChart')
    .selectAll('g')
    .data(data)
    .join('g')
    .attr('data-testid', (d) => `hor-dist-bar-${d.x}`)
    .attr('transform', (d) => `translate(0, ${x(d.x)})`)
    .on('mouseover', function (d, i) {
      d3.select(this).style('opacity', 0.75)
      const cur_values =
        keys.length > 1
          ? `
            <ul class="t-ul-style">
              <li class="t-li-style" >
                <div class="t-li-legend" style="background-color: ${
                  keys[0].color
                }"></div>
                <div>Original: ${formatNumber(d.origin)}</div>
              </li>
              <li class="t-li-style">
                <div class="t-li-legend" style="background-color: ${
                  keys[1].color
                }"></div>
                <div>Synthetic: ${formatNumber(d.synth)}</div>
              </li>
              <li>
                <div style="margin-left: 15px">${'Bin'}: ${`${d.x}`}</div>
              </li>
            </ul>`
          : `
            <ul class="t-ul-style">
              <li class="t-li-style" >
                <div class="t-li-legend" style="background-color: ${
                  keys[0].color
                }"></div>
                <div>${keys[0].label}: ${formatNumber(d[keys[0].name])}</div>
              </li>
              <li>
                <div style="margin-left: 15px">${'Bin'}: ${`${d.x}`}</div>
              </li>
            </ul>`

      const tpl = cur_values

      _tooltip.transition().duration(200).style('opacity', 0.9)
      _tooltip
        .html(tpl)
        .style('left', `${d3.event.pageX + 8}px`)
        .style('top', `${d3.event.pageY - 48}px`)
    })
    .on('mouseout', function (d) {
      d3.select(this).style('opacity', 1)
      _tooltip.transition().duration(500).style('opacity', 0)
    })
    .selectAll('path')
    .data((d) =>
      keys.map((key) => {
        return { key: key.name, color: key.color, value: d[key.name] }
      })
    )
    .join('path')
    .transition()
    .duration(dragDuration)
    .ease(d3.easeLinear)
    .attr('fill', (d) => {
      let _urlKey = d.key

      if (
        d.key === 'synth' &&
        from === 'scenario' &&
        Object.keys(settings).length &&
        settings.conditions &&
        Object.keys(settings.conditions).includes(col_name)
      ) {
        _urlKey = 'changed'
      }

      return `url(#${_urlKey}-gradient)`
    })
    .attr('d', (d) => {
      let _radius = 0

      // if bar narrow and low
      if (x1.bandwidth() <= 3 && y(d.value) <= 3) {
        if (x1.bandwidth() > y(d.value) * 2) {
          _radius = y(d.value)
        } else {
          _radius = x1.bandwidth() / 2
        }
      } else if (x1.bandwidth() <= 3) {
        // if narrow
        _radius = x1.bandwidth() / 2
      } else if (y(d.value) <= 3) {
        // if low
        _radius = y(d.value)
      } else {
        _radius = 3
      }

      return `
        M 0, ${x1(d.key)}
        h${y(d.value) - _radius}
        a${_radius},${_radius} 0 0 1 ${_radius},${_radius}
        v${x1.bandwidth() - 2 * _radius}
        a${_radius},${_radius} 0 0 1 ${-_radius},${_radius}
        h${_radius - y(d.value)}
        Z
      `
    })

  if (data.length && Object.keys(data[0]).includes('synth')) {
    const new_data = JSON.parse(JSON.stringify(data))

    g.select('.moveElement')
      .selectAll('g')
      .data(new_data)
      .join('g')
      .attr('transform', (d) => `translate(0, ${x(d.x)})`)
      .selectAll('rect')
      .data((d) => {
        return [
          {
            key: 'synth',
            xValue: d.x,
            col_name,
            value: d.synth,
          },
        ]
      })
      .join('rect')
      .attr('x', (d) => y(d.value) - 5)
      .attr('y', (d) => x1(d.key))
      .attr('height', x1.bandwidth())
      .attr('width', 10)
      .style('fill', '#fff')
      .style('fill-opacity', 0.0001)
      .style('cursor', 'ew-resize')
      .style('pointer-events', disableDragBars ? 'none' : '')
      .call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', (dt, i, nodes) =>
            dragged({
              dt,
              i,
              nodes,
              temp_transform_arr,
              temp_keys,
              svgScroll,
              g,
              x,
              x1,
              y,
              margin,
              height,
              settings,
              from,
            })
          )
          .on('end', (dt, i, nodes) =>
            dragended({
              dt,
              i,
              nodes,
              temp_transform_arr,
              y,
              settings,
              dataset_id,
              updateSettingsGroup,
            })
          )
      )
  }

  if (truncated) {
    g.append('text')
      .attr(
        'transform',
        `translate(${width / 2},${data.length * bandwidth + 15})`
      )
      .attr('font-size', '15px')
      .text('...')
      .style('fill', '#999')
  }
}

const dragstarted = (dt, i, nodes) => {
  if (d3.event.defaultPrevented) return

  d3.select(nodes[i]).raise().classed('active', true)
}

const dragged = ({
  dt,
  i,
  nodes,
  temp_transform_arr,
  temp_keys,
  svgScroll,
  g,
  x,
  x1,
  y,
  margin,
  height,
  settings,
  from,
}) => {
  d3.select(nodes[i])
    .attr('x', (dt.x = d3.event.x))
    .transition()
    .duration(dragDuration)
    .ease(d3.easeLinear)

  if (d3.event.defaultPrevented) return

  const new_shallow_data = recalculateBarsRatio({ dt, temp_transform_arr, y })

  updateHorizontalYDomain(new_shallow_data, temp_keys, y)

  updateHorizontalYAxis({ svgScroll, y, margin, height })

  redrawHorizontalBarChart({
    g,
    x,
    x1,
    y,
    data: new_shallow_data,
    keys: temp_keys,
    col_name: dt.col_name,
    settings,
    from,
  })
}

const dragended = ({
  dt,
  i,
  nodes,
  temp_transform_arr,
  y,
  settings,
  dataset_id,
  updateSettingsGroup,
}) => {
  d3.select(nodes[i]).classed('active', false)

  if (d3.event.defaultPrevented) return

  if (dt.x) {
    const new_shallow_data = recalculateBarsRatio({ dt, temp_transform_arr, y })

    updateBars({
      dt: new_shallow_data,
      colName: dt.col_name,
      bar_name: dt.x,
      settings,
      dataset_id,
      updateSettingsGroup,
    })
  }
}

const recalculateBarsRatio = ({ dt, temp_transform_arr, y }) => {
  let shallowData = JSON.parse(JSON.stringify(temp_transform_arr))
  const idx_bar = shallowData.map((d) => d.x).indexOf(dt.xValue)
  const selected_bar_ratio =
    y.invert(dt.x) > 1
      ? 1
      : y.invert(dt.x) < 0
      ? 0
      : +y.invert(dt.x).toFixed(10)

  const new_classes = getNewClassesArrayChangeRatio(
    shallowData,
    idx_bar,
    selected_bar_ratio
  )

  shallowData = new_classes

  shallowData[idx_bar].synth = selected_bar_ratio
  const _sum = shallowData.reduce(
    (acc, cur) => +(acc + cur.synth).toFixed(10),
    0
  )
  if (_sum !== 0) {
    shallowData[idx_bar].synth =
      _sum > 0
        ? selected_bar_ratio - +(_sum - 1).toFixed(10)
        : selected_bar_ratio + +(1 - _sum).toFixed(10)
  }

  return shallowData
}

const getNewClassesArrayChangeRatio = (cur_classes, idx, selected_ratio) => {
  const shallow_classes = [...cur_classes]

  const sum_without_selected_bar_ratio = 1 - shallow_classes[idx].synth
  const sum_after_change_selected_bar_ratio = 1 - selected_ratio

  const new_classes = shallow_classes.map((d) => {
    let new_ratio = 0
    if (shallow_classes[idx].synth === 1) {
      new_ratio =
        sum_after_change_selected_bar_ratio / (shallow_classes.length - 1)
    } else {
      const part_of_number = sum_without_selected_bar_ratio / d.synth
      new_ratio = sum_after_change_selected_bar_ratio / part_of_number
    }

    return { x: d.x, origin: d.origin, synth: +new_ratio.toFixed(10) }
  })

  return new_classes
}

const redrawHorizontalBarChart = ({
  g,
  x,
  x1,
  y,
  data,
  keys,
  col_name,
  settings,
  from,
}) => {
  g.select('.barChart')
    .selectAll('g')
    .data(data)
    .join('g')
    .attr('transform', (d) => `translate(0, ${x(d.x)})`)
    .selectAll('path')
    .data((d) =>
      keys.map((key) => {
        return { key: key.name, color: key.color, value: d[key.name] }
      })
    )
    .join('path')
    .transition()
    .duration(dragDuration)
    .ease(d3.easeLinear)
    .attr('fill', (d) => {
      let _urlKey = d.key

      if (
        d.key === 'synth' &&
        from === 'scenario' &&
        Object.keys(settings).length &&
        settings.conditions &&
        Object.keys(settings.conditions).includes(col_name)
      ) {
        _urlKey = 'changed'
      }

      return `url(#${_urlKey}-gradient)`
    })
    .attr('d', (d) => {
      let _radius = 0

      // if bar narrow and low
      if (x1.bandwidth() <= 3 && y(d.value) <= 3) {
        if (x1.bandwidth() > y(d.value) * 2) {
          _radius = y(d.value)
        } else {
          _radius = x1.bandwidth() / 2
        }
      } else if (x1.bandwidth() <= 3) {
        // if narrow
        _radius = x1.bandwidth() / 2
      } else if (y(d.value) <= 3) {
        // if low
        _radius = y(d.value)
      } else {
        _radius = 3
      }

      return `
        M 0, ${x1(d.key)}
        h${y(d.value) - _radius}
        a${_radius},${_radius} 0 0 1 ${_radius},${_radius}
        v${x1.bandwidth() - 2 * _radius}
        a${_radius},${_radius} 0 0 1 ${-_radius},${_radius}
        h${_radius - y(d.value)}
        Z
      `
    })

  if (data.length && Object.keys(data[0]).includes('synth')) {
    const new_data = JSON.parse(JSON.stringify(data))

    g.select('.moveElement')
      .selectAll('g')
      .data(new_data)
      .join('g')
      .attr('transform', (d) => `translate(0, ${x(d.x)})`)
      .selectAll('rect')
      .data((d) => {
        return [
          {
            key: 'synth',
            xValue: d.x,
            col_name,
            value: d.synth,
          },
        ]
      })
      .join('rect')
      .transition()
      .duration(dragDuration)
      .ease(d3.easeLinear)
      .attr('x', (d) => y(d.value) - 5)
      .attr('y', (d, i) => x1(d.key))
      .attr('height', x1.bandwidth())
  }
}

const updateBars = ({
  dt,
  colName,
  bar_name,
  settings,
  dataset_id,
  updateSettingsGroup,
}) => {
  const _conditions = {}
  dt.forEach((d) => {
    _conditions[d.x] = d.synth
  })

  const updatedSettings = { ...settings.conditions, [colName]: _conditions }

  // updateDataset({ id: dataset_id, settings: updatedSettings })
  updateSettingsGroup(updatedSettings)
}

export { renderHorizontalBarChart }

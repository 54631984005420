import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const selectDataSourcesState = (state: IStore) =>
  state.dataSources.dataSources.present

export const selectDataSourcesById = createSelector(
  selectDataSourcesState,
  (state) => state.byId
)

export const selectDataSourcesList = createSelector(
  selectDataSourcesById,
  (byId) => Object.values(byId)
)

export const selectDataSourcesStatus = createSelector(
  selectDataSourcesState,
  (state) => state.status
)

export const selectDataSourceCheckStatus = createSelector(
  selectDataSourcesState,
  (state) => state.checkStatus
)

export const selectDataSourceSaveStatus = createSelector(
  selectDataSourcesState,
  (state) => state.saveStatus
)

export const selectDataSourceModalId = createSelector(
  selectDataSourcesState,
  (state) => state.selectedId
)
export const selectDataSourceForModal = createSelector(
  selectDataSourcesById,
  selectDataSourceModalId,
  (byId, id) => byId[id]
)

import { takeLatest, fork, put, call, delay, select } from 'redux-saga/effects'
import { notificationActions } from 'services/notification/reducer'
import {
  ProjectActions,
  didFetchSynth,
  didFetchSynthFail,
  updateDataset,
} from '../actions'
import {
  ProjectSettingsActions,
  didSynthProgress,
  startLoadingSettings,
  stopLoadingSettings,
} from '../settings/actions'

import { initData } from '../../../services/helper'
import { dataSetsService } from '../../../utils/services'
import { getProjectDataState, getProjectSettings } from '../projectSelectors'
import {
  getSynthesisSettingsState,
  getTargetColumnState,
  getBiasMitigationDisabled,
} from '../projectSettings/selectors'

import { trackEvent } from '../../../utils'

const synthesizeMessages = {
  success: 'Synthesizing completed successfully!',
  error: 'Synthesizing failed...',
}

function* handleFetchSynthData({ id }) {
  try {
    // TODO think how to handle 404 in more explicit way
    const synthDataset = yield call(dataSetsService.getSyntheticData, { id })
    if (synthDataset.status === 'STATUS_STARTED') {
      yield put(startLoadingSettings({ name: '' }))
      yield put(didSynthProgress(synthDataset.progress, id))
      yield fork(handleGeneratePooling, { id, progress: synthDataset.progress })
    }
    if (synthDataset.status === 'STATUS_FINISHED') {
      const currentDataset = yield call(dataSetsService.getCurrentDatasetById, {
        id,
      })
      const data = initData(currentDataset)
      yield put(didFetchSynth({ data }))
    }
  } catch (error) {
    yield put(stopLoadingSettings())
    // TODO current placeholder action -> implement real behaviour
    yield put(didFetchSynthFail())
  }
}

export function* handleGeneratePooling({ id }) {
  while (true) {
    yield delay(5000)
    try {
      const synthData = yield call(dataSetsService.getSyntheticData, { id })

      if (synthData.status === 'STATUS_FINISHED') {
        const dataState = yield select(getProjectDataState)
        const currentDataset = yield call(
          dataSetsService.getCurrentDatasetById,
          {
            id,
          }
        )
        const data = initData(currentDataset)
        yield put(
          updateDataset({
            id: dataState.dataset_id,
            settings: { ...dataState.settings, conditions: {} },
          })
        )
        // TODO combine in one action
        yield put(didFetchSynth({ data }))
        yield put(stopLoadingSettings())
        yield put(didSynthProgress(null, id))
        yield put(
          notificationActions.showNotification({
            message: synthesizeMessages.success,
          })
        )

        // Segment analytics
        trackEvent('Synthesis Completed')
        //
        return
      }
      const progress = synthData.progress || 5
      yield put(didSynthProgress(progress, id))
    } catch (error) {
      yield put(stopLoadingSettings())
      yield put(
        notificationActions.showNotification({
          message: synthesizeMessages.error,
          severity: 'error',
        })
      )
      return yield put(didSynthProgress(null, id))
    }
  }
}

const transformSynthesisSettings = (
  synthesisSettings,
  targetColumn,
  biasMitigationDisabled
) => {
  const obj = {}

  obj.to_include_original_data = synthesisSettings.isOriginalDataIncluded
  obj.to_produce_nans = synthesisSettings.isProduceNans

  if (synthesisSettings.isOriginalDataIncluded) {
    obj.to_impute_nans = synthesisSettings.isImputeNans
  }

  obj.num_rows = Number(synthesisSettings.rowNumber)

  if (!biasMitigationDisabled.isDisabled) {
    obj.to_mitigate_bias = synthesisSettings.isMitigateBias
    if (synthesisSettings.isMitigateBias) {
      obj.target = targetColumn
      obj.min_dist = synthesisSettings.biasMitigationMinDist
    }
  }
  return obj
}

function* handleGenerateSyntheticData({ id }) {
  try {
    yield put(startLoadingSettings({ name: id }))
    const settings = yield select(getProjectSettings)
    const synthesisSettings = yield select(getSynthesisSettingsState)
    const biasMitigationDisabled = yield select(getBiasMitigationDisabled)

    const targetColumn = yield select(getTargetColumnState)
    yield call(dataSetsService.generateSyntheticData, {
      id,
      data: {
        conditions: settings.conditions,
        ...transformSynthesisSettings(
          synthesisSettings,
          targetColumn,
          biasMitigationDisabled
        ),
      },
    })
    yield fork(handleGeneratePooling, { id })
  } catch (error) {
    yield put(stopLoadingSettings())
    yield put(
      notificationActions.showNotification({
        message: synthesizeMessages.error,
        severity: 'error',
      })
    )
  }
}

function* handleExportCSVDataset({ id, callback }) {
  try {
    const result = yield call(dataSetsService.exportCSVDataset, id)
    if (!result.message) {
      yield put(
        notificationActions.showNotification({
          message: 'File downloaded successfully!',
        })
      )
      // Segment analytics
      trackEvent('Download Completed')
      //
      callback(result.data, result.filename)
    } else {
      yield put(
        notificationActions.showNotification({
          message: result.message,
          severity: 'error',
        })
      )
    }
  } catch (error) {}
}

export default function* watchSettingsSaga() {
  yield takeLatest(
    ProjectSettingsActions.GENERATE_SYNTHETIC_DATA,
    handleGenerateSyntheticData
  )
  yield takeLatest(ProjectSettingsActions.EXPORT_CSV, handleExportCSVDataset)
  yield takeLatest(ProjectActions.FETCH_SYNTH, handleFetchSynthData)
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'legacy/shared'
import { TaskStatus } from 'utils/constants'

const useStyles = makeStyles(() => ({
  statusIcon: {
    minWidth: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 8,
  },
}))

const statusColor: { [key: string]: string } = {
  [TaskStatus.STATUS_STOPPED]: colors.textYellow,
  [TaskStatus.STATUS_FINISHED]: colors.green,
  [TaskStatus.STATUS_FAILED]: colors.red,
}

export const GroupStatus = ({ synthStatus }: { synthStatus: TaskStatus }) => {
  const classes = useStyles()
  const color = statusColor[synthStatus] || colors.disabledGrey

  return <div className={classes.statusIcon} style={{ background: color }} />
}

import React from 'react'
import { Route } from 'react-router-dom'
import User from 'legacy/components/Account/User'

const TierRoute = ({
  component: Component,
  componentFreeTier: ComponentFreeTier,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (User.isCETier()) {
        return <ComponentFreeTier {...props} />
      }
      return <Component {...props} />
    }}
  />
)

export default TierRoute

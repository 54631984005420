import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { colors } from 'legacy/shared'
import classNames from 'classnames'
import { IFilePreview } from 'services/createTestingSuite/types'
import { humanFileSize } from 'pages/CreateTestingSuite/utils'
import { CloseButton } from '../CloseButton'

interface IFilePreviewProps {
  file: File
  icon?: React.ReactElement
  handleDeleteFile: (arg: File | IFilePreview) => void
  className?: string
}

export const FilePreview: FC<IFilePreviewProps> = ({
  file,
  icon,
  handleDeleteFile,
  className,
}) => {
  const styles = useStyles()
  return (
    <div className={classNames(styles.box, className)}>
      <Box mr={1} className={styles.icon}>
        {icon}
      </Box>
      <div className={styles.contentWrapper}>
        <div className={styles.fileName}>{file?.name}</div>
        <div className={styles.description}>{humanFileSize(file?.size)}</div>
      </div>
      <Box ml={1} className={styles.after}>
        <CloseButton size="small" onClick={() => handleDeleteFile(file)} />
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    border: `1px solid ${colors.creame}`,
    borderRadius: 4,
    fontFamily: 'Lato',
    fontSize: 12,
  },
  contentWrapper: {
    textAlign: 'left' as const,
  },
  fileName: {
    fontWeight: 700,
  },
  description: {
    fontSize: 10,
    lineHeight: 1,
    color: colors.lightGrey,
  },
  after: {
    marginLeft: 'auto',
  },
  icon: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(0.5),
  },
}))

import { makeRequest } from './apiClient'

export default {
  async registerUnapproved(data) {
    const response = await makeRequest({
      path: 'users/register/unapproved',
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },
  async userAuthenticate(data) {
    const session = await makeRequest({
      path: 'users/login',
      method: 'POST',
      params: JSON.stringify(data),
    })
    return session
  },

  async resetPassword(data) {
    await makeRequest({
      path: 'users/reset',
      method: 'POST',
      params: JSON.stringify(data),
    })
  },

  async changePassword(data) {
    await makeRequest({
      path: 'users/reset/change',
      method: 'POST',
      params: JSON.stringify(data),
    })
  },

  async sendContactUs() {
    await makeRequest({
      path: 'users/register/contactus',
      method: 'POST',
    })
  },

  async userGetInfo() {
    const user = makeRequest({
      path: 'profile',
      method: 'GET',
    })
    return user
  },

  async updateAccessToken() {
    const token = await makeRequest({
      path: 'users/refresh',
      method: 'POST',
      params: 'refresh',
    })
    return token
  },

  async getInviteCode(hours) {
    const inviteCode = await makeRequest({
      path: `invite_code?hours_valid=${hours}`,
      method: 'GET',
    })
    return inviteCode
  },
}

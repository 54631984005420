import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const DEFAULT_PROGRESS_WIDTH = '12rem'
const DEFAULT_PROGRESS_HEIGHT = '1.2rem'

interface LinearProgressWithLabelProps extends LinearProgressProps {
  label?: boolean
  labelText?: string
}

function LinearProgressWithLabel(
  props: LinearProgressWithLabelProps & { labelText: string; classNames: any }
) {
  const { labelText, classNames } = props
  const classes = useStyles()
  return (
    <Box
      display="flex"
      alignItems="center"
      className={classnames(classes.progressBox, classes.withLabelBox)}
    >
      <LinearProgressComponent {...props} />
      <Box
        minWidth={35}
        className={classnames(classes.labelBox, classNames.labelContainer)}
      >
        <Typography variant="body2" color="textSecondary">
          {labelText}
        </Typography>
      </Box>
    </Box>
  )
}

function LinearProgressComponent(props: LinearProgressWithLabelProps) {
  const { variant = 'determinate' } = props
  const classes = useStyles()
  return (
    <Box className={classes.progressBox}>
      <LinearProgress
        className={classes.progress}
        variant={variant}
        {...props}
      />
    </Box>
  )
}

const Component = ({
  id = '',
  value = 0,
  width = DEFAULT_PROGRESS_WIDTH,
  height = DEFAULT_PROGRESS_HEIGHT,
  label = false,
  labelText = '',
  classNames = { root: '', labelContainer: '' },
}) => {
  const classes = useStyles()

  return (
    <div className={classnames(classes.root, classNames.root)}>
      <Box width={width} height={height}>
        {label ? (
          <LinearProgressWithLabel
            value={value}
            labelText={labelText}
            classNames={classNames}
          />
        ) : (
          <LinearProgressComponent id={id} value={value} />
        )}
      </Box>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBox: {
    width: '100%',
    height: '100%',
  },
  progress: {
    height: '100%',
    borderRadius: 10,
  },
  withLabelBox: {
    position: 'relative',
  },
  labelBox: {
    width: '100%',
    position: 'absolute',
    paddingLeft: '1em',
  },
})

export const Progress: FC<LinearProgressWithLabelProps> = (props) => (
  <Component {...props} />
)


import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'profile'

interface IProfileState {
  image: { imageUrl: string; isLoading: boolean; serverError: any | null }
  form: { isLoading: boolean; serverError: any | null }
}

const initialState: IProfileState = {
  image: { imageUrl: '', isLoading: false, serverError: null },
  form: { isLoading: false, serverError: null },
}

const profileReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchImage: () => {},
    didFetchImage: (
      state,
      { payload }: PayloadAction<{ imageUrl: string }>
    ) => {
      state.image.imageUrl = payload.imageUrl
    },
    uploadImage: (state, { payload }: PayloadAction<{ file: any }>) => {
      state.image.isLoading = true
      state.image.serverError = null
    },
    didUploadImage: (
      state,
      { payload }: PayloadAction<{ imageUrl: string }>
    ) => {
      state.image.isLoading = false
      state.image.imageUrl = payload.imageUrl
    },
    changeUserInfo: (state, { payload }: PayloadAction<any>) => {
      state.form.isLoading = true
      state.form.serverError = null
    },
    didChangeUserInfo: (state) => {
      state.form.isLoading = false
      state.form.serverError = null
    },
    didChangeUserInfoFail: (state, { payload }: PayloadAction<any>) => {
      state.form.isLoading = false
      state.form.serverError = payload
    },
  },
})

export const profileActions = profileReducer.actions

export default profileReducer.reducer

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { last } from 'lodash'
import _ from './Constants'
import { CleanRoomBuildingSteps } from '../Sandboxes/build/constants'
import { Routes } from '../../utils'
import User from '../Account/User'

export const RouteWithRedirectSandboxBuild = ({
  children,
  editableBuildSandboxSteps = [],
  ...rest
}) => {
  const { path } = rest
  const urlStep = last(path.split('/'))
  return (
    <Route
      {...rest}
      render={({ location }) =>
        editableBuildSandboxSteps.includes(urlStep) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.CleanRoomBuildStep(
                CleanRoomBuildingSteps.Dataset
              ),
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const TierRoute = ({
  component: Component,
  componentFreeTier: ComponentFreeTier,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (User.isFreeTier() || User.isCETier()) {
        return <ComponentFreeTier {...props} />
      }
      return <Component {...props} />
    }}
  />
)

import { takeLatest, call, put, delay, select } from 'redux-saga/effects'
import { productTourActions } from './reducer'
import { getIsTourEnabled, getActiveTip } from './selectors'
import { profileService } from '../../utils/services'
import User from '../Account/User'
import { ProjectActions } from '../Projects/actions'
import { datasetsActions } from '../Datasets/reducer'
import { ProjectSettingsActions } from '../Projects/projectSettings/actions'
import { QualityAndBiasActions } from '../Projects/biasAndQuality/actions'
import { getProjectStatusState } from '../Projects/projectSelectors'
import { pushRoute, Routes } from '../../utils'
import { ProjectSettingsActions as SynthDatasetActions } from '../Projects/settings/actions'
import { getTargetColumnState } from '../Projects/projectSettings/selectors'

function* handleProductTourSkip() {
  try {
    const body = {
      settings: { show_onboarding: false },
    }
    const user = yield call(profileService.userUpdateInfo, body)
    User.update_user_info(user)
    yield put(productTourActions.setIsTourEnabled({ isEnabled: false }))
  } catch (error) {}
}

function* handleProductTourRestart() {
  try {
    const body = {
      settings: { show_onboarding: true },
    }
    const user = yield call(profileService.userUpdateInfo, body)
    User.update_user_info(user)
    yield put(productTourActions.didRestart())
    pushRoute(Routes.Projects())
  } catch (error) {}
}

function* handleProductTour(action: any) {
  const isEnabled = yield select(getIsTourEnabled)
  const activeTip = yield select(getActiveTip)
  if (!isEnabled || !activeTip) {
    return
  }
  if (action.type === datasetsActions.didFetchDatasets.type) {
    return yield put(productTourActions.switchStep({ tip: '0', step: 0 }))
  }
  const projectStatus = yield select(getProjectStatusState)

  if (action.type === ProjectActions.DID_FETCH_DATASET) {
    return yield put(productTourActions.switchStep({ tip: '0', step: 1 }))
  }

  if (action.type === ProjectSettingsActions.UPDATE_BIAS_SETTINGS) {
    if (projectStatus === 'ready') {
      return yield put(productTourActions.switchStep({ tip: '1', step: 5 }))
    }
    return yield put(productTourActions.switchStep({ tip: '1', step: 4 }))
  }

  if (action.type === ProjectActions.START_TRAINING) {
    return yield put(productTourActions.switchStep({ tip: '1', step: 5 }))
  }

  const targetColumn = yield select(getTargetColumnState)
  if (action.type === ProjectActions.DID_TRAINING && targetColumn === 'none') {
    return yield put(productTourActions.switchStep({ tip: '1', step: 7 }))
  }

  if (action.type === QualityAndBiasActions.DID_FETCH_BIAS_DATA) {
    yield delay(300)
    return yield put(productTourActions.switchStep({ tip: '1', step: 6 }))
  }

  if (action.type === ProjectActions.FETCH_SYNTH) {
    yield delay(1000)
    return yield put(productTourActions.switchStep({ tip: '2', step: 2 }))
  }

  if (action.type === SynthDatasetActions.GENERATE_SYNTHETIC_DATA) {
    return yield put(productTourActions.pauseTip({}))
  }

  if (action.type === ProjectActions.DID_FETCH_SYNTH) {
    return yield put(productTourActions.switchStep({ tip: '2', step: 7 }))
  }
}

export default function* watchProductTourSaga() {
  yield takeLatest(productTourActions.skipTour.type, handleProductTourSkip)
  yield takeLatest(productTourActions.restart.type, handleProductTourRestart)
  yield takeLatest(
    [
      ProjectActions.DID_TRAINING,
      ProjectActions.START_TRAINING,
      ProjectActions.DID_FETCH_DATASET,
      ProjectSettingsActions.UPDATE_BIAS_SETTINGS,
      datasetsActions.didFetchDatasets.type,
      QualityAndBiasActions.DID_FETCH_BIAS_DATA,
      ProjectActions.FETCH_SYNTH,
      ProjectActions.DID_FETCH_SYNTH,
      SynthDatasetActions.GENERATE_SYNTHETIC_DATA,
    ],
    handleProductTour
  )
}

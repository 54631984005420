import * as React from 'react'
import { Form as FormikForm, Formik } from 'formik'

// interface PropsForm<T> {
//   initialValues: T;
//   onSubmit: (values: T, formikActions: any) => void;
//   validate?: (values: T) => void | object;
//   children: (props: FormikProps<T>) => React.ReactNode;
// }

export function Form({ initialValues, onSubmit, validate, children }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      children={(props) => <FormikForm>{children(props)}</FormikForm>}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
    />
  )
}

Form.displayName = 'Form'

import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import ProductTourInit from 'legacy/components/ProductTour/ProductTourInit'

// eslint-disable-next-line import/no-extraneous-dependencies
// import globalAxios from 'axios'

import User from 'legacy/components/Account/User'
import _ from 'legacy/components/App/Constants'
import Layout from 'layouts/AppLayout'
import SideBar from 'layouts/Sidebar'
import { cloudTabs } from 'layouts/Sidebar/SideBarConfig'
import ModalContainer from 'components/Modals/ModalContainer'

import Main from 'layouts/Main'
import { NotificationContainer } from 'layouts/Notification'

import InviteCode from 'pages/InviteCode'
import TemplatePage from 'pages/Templates'
import ProfilePage from 'pages/Profile'
import ForgotPasswordPage from 'pages/ForgotPassword'
import ContactUs from 'pages/ContactUs'
import CleanRoomPage from 'pages/CleanRooms'
import CleanRoomPageCE from 'pages/CleanRooms/CleanRoomsCE'
import TestingSuites from 'pages/TestingSuites'
import CreateTestingSuite from 'pages/CreateTestingSuite'

import { VersionApi, AuthApi, Configuration } from 'api/generated'

import AdminRoute from './AdminRoute'
import TierRoute from './TierRoute'

// globalAxios.defaults.baseURL = window.location.origin

const AppRoutes = () => {
  // TODO: Shitty design of route control
  // Should be done with useLocation and comparison, store intrusion is
  // const data = useSelector((state: any) => state.data)

  useEffect(() => {
    const c = new Configuration({
      basePath: window.location.origin,
    })

    const Version = new VersionApi(c)
    Version.apiVersionGet()

    c.baseOptions = {
      headers: {
        Authorization: `Bearer ${User.isAuth()}`,
      },
    }

  }, [])

  return (
    <Layout>
      <SideBar tabs={cloudTabs.filter((tab) => tab.enabled)} />
      <Main>
        <Switch>
          <Route path="/templates" component={TemplatePage} />
          <Route path="/profile" component={ProfilePage} exact />
          <Route
            path="/profile/reset-password"
            component={ForgotPasswordPage}
          />
          <Route path="/contactus" component={ContactUs} />

          <TierRoute
            path="/datacleanrooms"
            component={CleanRoomPage}
            componentFreeTier={CleanRoomPageCE}
            exact
          />

          {_.IS_TESTING_SUIT_ENABLED && (
            <Route exact path="/testingsuites" component={TestingSuites} />
          )}
          {_.IS_TESTING_SUIT_ENABLED && (
            <Route path="/testingsuites/:id" component={CreateTestingSuite} />
          )}
          <AdminRoute path="/invitecode" component={InviteCode} />
        </Switch>
      </Main>
      <div style={{ position: 'absolute', bottom: 20, right: 120 }}>
        <ProductTourInit />
      </div>
      <ModalContainer />
      <NotificationContainer />
    </Layout>
  )
}

export default AppRoutes

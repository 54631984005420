import React from 'react'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { colors } from 'legacy/shared'

interface IBarCellProps {
  height?: number
  value: number
  color: string
  textColor: string
}

export const BarCell: React.FunctionComponent<IBarCellProps> = React.memo(
  ({
    height = 54,
    value = 100,
    color = colors.black,
    textColor = colors.white,
  }: IBarCellProps) => {
    const classes = useStyles()

    return (
      <div className={cn(classes.cell)} style={{ height }}>
        <div
          className={cn(classes.bar)}
          style={{ width: `${value}%`, backgroundColor: color }}
        />
        <div className={classes.value} style={{ color: textColor }}>
          {value.toLocaleString()}%
        </div>
      </div>
    )
  },
  (prev, next) => prev.value === next.value
)

const useStyles = makeStyles({
  bar: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 0,
    height: '100%',
  },
  value: {
    position: 'relative',
    zIndex: 1,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    height: '100%',
    width: '100%',
  },
})

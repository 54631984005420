import * as React from 'react'
import { FC } from 'react'
import {
  Card,
  CardContent,
  Grid,
  Modal as MUIModal,
  ModalProps,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Typography } from '../../legacy/shared/components/typography'
import { colors, spacing } from '../../legacy/shared/theme'

export interface MUIModalProps extends ModalProps {
  hideModal?: () => void
  headerClassName?: any
  contentClassName?: any
  headerText?: string
}

export const Modal: FC<MUIModalProps> = ({
  open,
  hideModal,
  headerClassName,
  contentClassName,
  headerText,
  children = null
}) => {
  const classes = useModalStyles()

  return (
    <MUIModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={hideModal}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        classes={{
          container: classes.gridContainer,
        }}
      >
        <Card
          classes={{
            root: classes.card,
          }}
        >
          <CardContent
            classes={{
              root: classNames(classes.cardContent, contentClassName),
            }}
          >
            <div className={classNames(classes.header, headerClassName)}>
              <Typography size="subheaderBold">{headerText}</Typography>
            </div>
            <div className={classes.content}>{children}</div>
          </CardContent>
        </Card>
      </Grid>
    </MUIModal>
  )
}

const useModalStyles = makeStyles(() => ({
  gridContainer: {
    minHeight: '335px',
    maxWidth: '543px',
    margin: '0 auto',
    marginTop: '12vh',
    outline: 'none',
  },
  card: {
    width: 'auto',
    height: 'auto',
    paddingTop: 0,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  cardContent: {
    paddingTop: spacing.gutter * 2,
    maxHeight: '45em',
  },
  cardContentNoPadding: {
    paddingTop: 0,
    paddingBottom: '0px !important',
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingTop: spacing.gutterLarge,
  },
  contentNoPadding: {
    padding: 0,
  },
  header: {
    display: 'block',
    position: 'static',
    height: 'auto',
    paddingTop: 20,
    paddingBottom: 10,
    backgroundColor: 'transparent',
    textAlign: 'left',
    zIndex: 10,
  },
}))

Modal.displayName = 'Modal'

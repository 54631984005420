import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'
import { IconButton, Menu, Divider, spacing } from '../../shared'
import { datasetsActions } from './reducer'
import { startShareProject } from '../Projects/shareProject/actions'
import { selectIsLoadingDeleteProjectState } from './datasetsSelectors'

const useStyles = makeStyles({
  triggerButton: {
    padding: 0,
    marginLeft: spacing.gutter,
    marginRight: spacing.gutter,
  },
  verDivider: {
    color: '#C1C6CC',
  },
})

const eventPrevent = (e) => {
  e.stopPropagation()
  e.preventDefault()
  e.target.blur()
}

export const ActionMenu = ({
  cell,
  startShareProject,
  deleteDataset,
  showModal,
  isLoadingDeleteProject,
}) => {
  const classes = useStyles()
  return (
    <div onClick={(e) => eventPrevent(e)}>
      <Menu
        items={[
          {
            text: 'Share',
            onClick: () => {
              startShareProject(cell.id)
            },
          },
          {
            text: 'Edit',
            onClick: (e) =>
              showModal({
                modalType: ModalTypes.ProjectEdit,
                config: {
                  headerText: 'Edit Project',
                  contentConfig: {
                    data: cell,
                  },
                },
              }),
          },
          {
            text: 'Delete',
            onClick: () =>
              showModal({
                modalType: ModalTypes.Confirmation,
                config: {
                  headerText: 'Delete Project',
                  confirmationConfig: {
                    actionDescription: (
                      <>
                        Are you sure you want to permanently delete{' '}
                        <strong>{cell.title}</strong>?
                      </>
                    ),
                    confirmButtonText: 'Delete',
                    confirmButtonAction: () =>
                      deleteDataset({
                        id: cell.dataset_id,
                        datasetType: cell.type,
                      }),
                    isLoading: isLoadingDeleteProject,
                  },
                },
              }),
          },
        ].filter(Boolean)}
        renderTriggerComponent={({ showMenu }) => (
          <div style={{ display: 'flex' }} onClick={(e) => eventPrevent(e)}>
            {/* <Divider
                orientation="vertical"
                flexItem
                className={classes.verDivider}
              /> */}
            <IconButton
              onClick={(e) => {
                eventPrevent(e)
                showMenu(e)
              }}
              className={classes.triggerButton}
              data-testid="projects-action-menu-button"
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        )}
        data-testid="projects-action-menu"
      />
    </div>
  )
}
const mapStateToProps = (state) => ({
  isLoadingDeleteProject: selectIsLoadingDeleteProjectState(state),
})
const mapDispatchToProps = {
  showModal: modalActions.show,
  deleteDataset: (data) => datasetsActions.deleteDataset(data),
  startShareProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionMenu)

import { createSelector } from 'reselect'

export const getDatasetsState = (state: any) => state.datasets

export const getDatasetsList = createSelector(getDatasetsState, (datasets) =>
  Object.values(datasets.byId)
)

export const getDatasetsGrouped = createSelector(
  getDatasetsState,
  (datasets) => datasets.byId
)

export const getPageIsLoadingState = createSelector(
  getDatasetsState,
  (datasets) => datasets.pageIsLoading
)

export const selectIsLoadingDeleteProjectState = createSelector(
  getDatasetsState,
  (datasets) => datasets.deleteProjectState === 'saving'
)

export const getIsLoadingUpdateProjectState = createSelector(
  getDatasetsState,
  (datasets) => datasets.updateProjectState === 'saving'
)

export const getIsLoadingUploadState = createSelector(
  getDatasetsState,
  (datasets) => datasets.uploadState === 'saving'
)

export const getIsLoadingUploadLimitsState = createSelector(
  getDatasetsState,
  (datasets) => datasets.uploadLimitsState === 'saving'
)

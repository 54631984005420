import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'

import { Routes, pushRoute } from 'legacy/utils'
import { authService } from 'legacy/utils/services'

import { changePasswordActions } from './reducer'
import { IChangePassword } from '../typings'

function* handleChangePassword({ payload }: PayloadAction<IChangePassword>) {
  const body = { password: payload.password, code: payload.code }
  try {
    yield call(authService.changePassword, body)
    yield put(changePasswordActions.didChangePassword())
    pushRoute(Routes.SignIn())
  } catch (error) {
    yield put(
      changePasswordActions.didChangePasswordFail({ message: error.message })
    )
  }
}

export default function* () {
  yield takeLatest(
    changePasswordActions.changePassword.type,
    handleChangePassword
  )
}

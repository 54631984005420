import React from 'react'
import { makeStyles } from '@material-ui/core'

export const NameCell: React.FunctionComponent<{ value: string }> = React.memo(
  ({ value }: { value: string }) => {
    const classes = useStyles()

    return <div className={classes.nameColumn}>{value.toLocaleString()}</div>
  },
  (prev, next) => prev.value === next.value
)

const useStyles = makeStyles({
  nameColumn: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 700,
  },
})

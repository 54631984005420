import { takeLatest, put, call, select } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ActionCreators } from 'redux-undo'

import { SandboxResponse } from 'api/generated/modelPkg'

import { copyToClipboard } from 'legacy/utils'

import { sandBoxesService } from 'legacy/utils/services'

import { notificationActions } from 'services/notification/reducer'
import { cleanRoomsActions } from './reducer'
import { CleanRoomIdPayload } from './types'
import { getSharedCleanRoom } from './selectors'
import { modalActions } from '../modal/reducer'

function* handleFetchCleanRooms() {
  try {
    const cleanRooms: SandboxResponse[] = yield call(
      sandBoxesService.getSandboxes
    )
    yield put(
      cleanRoomsActions.didFetchCleanRooms({
        cleanRooms,
      })
    )
  } catch (error) {
    yield put(cleanRoomsActions.didFetchCleanRoomsFail())
  }
}

function* handleDeleteCleanRoom({
  payload,
}: PayloadAction<CleanRoomIdPayload>) {
  const { cleanRoomId } = payload
  try {
    yield put(modalActions.hide())
    yield call(sandBoxesService.deleteSandbox, { id: cleanRoomId })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Clean room is not deleted',
        severity: 'error',
      })
    )
  }
  yield put(cleanRoomsActions.actionIsFinished())
}

function* handleRunCleanRoom({ payload }: PayloadAction<CleanRoomIdPayload>) {
  const { cleanRoomId } = payload
  try {
    yield put(modalActions.hide())
    yield call(sandBoxesService.runSandbox, { id: cleanRoomId })
  } catch (error) {
    yield put(ActionCreators.undo())
    yield put(
      notificationActions.showNotification({
        message: 'Clean room is not started',
        severity: 'error',
      })
    )
  }
  yield put(cleanRoomsActions.actionIsFinished())
}

function* handleStopCleanRoom({ payload }: PayloadAction<CleanRoomIdPayload>) {
  const { cleanRoomId } = payload
  try {
    yield put(modalActions.hide())
    yield call(sandBoxesService.stopSandbox, { id: cleanRoomId })
  } catch (error) {
    yield put(ActionCreators.undo())
    notificationActions.showNotification({
      message: 'Clean room is not stopped',
      severity: 'error',
    })
  }
  yield put(cleanRoomsActions.actionIsFinished())
}

function* handleCopyToClipboard({
  payload,
}: PayloadAction<{ accessCredentials: any }>) {
  if (typeof payload.accessCredentials === 'string') {
    copyToClipboard(payload.accessCredentials)
  } else {
    const { host, password, port, username } = payload.accessCredentials
    const portString = port ? `\nport: ${port}` : ''
    const stringToCopy = `host: ${host}${portString}\nusername: ${username}\npassword: ${password}`
    copyToClipboard(stringToCopy)
  }

  yield put(
    notificationActions.showNotification({
      message: 'Credentials are copied',
    })
  )
}

function* handleShareCleanRoom({ payload }: any) {
  const { email } = payload

  const { id } = yield select(getSharedCleanRoom)

  try {
    yield call(sandBoxesService.shareSandbox, {
      id,
      email,
    })
    yield put(cleanRoomsActions.didShareCleanRoom())
    yield put(modalActions.hide())
    yield put(
      notificationActions.showNotification({ message: 'Credentials were sent' })
    )
  } catch (error) {
    yield put(
      cleanRoomsActions.didShareCleanRoomFail({
        message: error.message || 'Server error occurred',
      })
    )
  }
}

export default function* watchCleanRoomsSaga() {
  yield takeLatest(cleanRoomsActions.fetchCleanRooms, handleFetchCleanRooms)
  yield takeLatest(cleanRoomsActions.deleteCleanRoom, handleDeleteCleanRoom)
  yield takeLatest(cleanRoomsActions.runCleanRoom, handleRunCleanRoom)
  yield takeLatest(cleanRoomsActions.stopCleanRoom, handleStopCleanRoom)
  yield takeLatest(cleanRoomsActions.copyToClipboard, handleCopyToClipboard)

  yield takeLatest(cleanRoomsActions.shareCleanRoom, handleShareCleanRoom)
}

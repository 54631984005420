import { all } from 'redux-saga/effects'
import globalSaga from 'services/global/sagas'
import authSaga from 'services/auth/sagas'
import uploadDatasetSaga from 'services/uploadDataset/sagas'
import templateSaga from 'services/templates/sagas'
import dataSourcesSaga from 'services/datasources/sagas'
import profileSaga from 'services/profile/sagas'
import inviteCodeSaga from 'services/inviteCode/sagas'
import cleanRoomsSaga from 'services/cleanrooms/sagas'
import suitesSaga from 'services/testingSuites/sagas'
import createSuiteSaga from 'services/createTestingSuite/sagas'
import { legacySagas } from 'legacy/store/legacySaga'

export default function* rootSaga() {
  yield all([
    globalSaga(),
    authSaga(),
    profileSaga(),
    templateSaga(),
    dataSourcesSaga(),
    uploadDatasetSaga(),
    cleanRoomsSaga(),
    inviteCodeSaga(),
    suitesSaga(),
    createSuiteSaga(),
    ...legacySagas,
  ])
}

import { all } from 'redux-saga/effects'
import watchReportSaga from './reportSagas'
import watchProjectSaga from './projectSagas'
import watchProjectSettingsSaga from '../projectSettings/sagas'
import watchProjectPermissionsSaga from '../projectPermissions/sagas'
import watchSynthDatasetSaga from './synthDatasetSagas'
import watchBiasAndQualitySaga from '../biasAndQuality/sagas'
import watchShareProjectSaga from '../shareProject/sagas'
import watchScenariosSaga from './scenariosSagas'

export default function* watchProjectAllSaga() {
  yield all([
    watchProjectSaga(),
    watchReportSaga(),
    watchSynthDatasetSaga(),
    watchBiasAndQualitySaga(),
    watchShareProjectSaga(),
    watchProjectSettingsSaga(),
    watchScenariosSaga(),
    watchProjectPermissionsSaga(),
  ])
}

import React, { useCallback } from 'react'
import Select, { components } from 'react-select'
import { connect } from 'react-redux'

import { updateDisplayColumns } from 'legacy/components/Projects/projectSettings/actions'
import { useAsyncState } from '../services/hooks'

import _v from '../styles/_variables.scss'

const styleControl = (styles, focus) => {
  const style = {
    alignItems: styles.alignItems,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'pointer',
    display: styles.display,
    flexWrap: styles.flexWrap,
    justifyContent: styles.justifyContent,
    outline: styles.outline,
    position: styles.position,
    boxSizing: styles.boxSizing,
    boxShadow: 'none',
    backgroundColor: '#fff',
    height: _v['input-height'],
    minHeight: _v['input-height'],
    transition: '.5s',
    '&: focus-within': { borderColor: _v.blue_royal },
  }
  return focus
    ? {
        ...style,
        width: '10rem',
        paddingLeft: '8px',
        borderColor: '#2684FF',
        '&: hover': { borderColor: '#2684FF' },
      }
    : {
        ...style,
        width: '2rem',
        paddingLeft: 0,
        borderColor: _v.b_color,
        '&: hover': { borderColor: 'hsl(0, 0%, 70%)' },
      }
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span data-uk-icon="icon: search" style={{ marginTop: '-2px' }} />
    </components.DropdownIndicator>
  )
}

const MultiValueContainer = () => {
  return null
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      <input
        className="uk-checkbox"
        type="checkbox"
        checked={
          props
            .getValue()
            .map((d) => d.value)
            .indexOf(props.value) !== -1
        }
        readOnly
        style={{
          marginTop: '-3px',
          marginRight: '7px',
          height: '14px',
          width: '14px',
        }}
      />
      <span style={{ lineHeight: '14px' }}>{props.label}</span>
    </components.Option>
  )
}

function ManageColumns(props) {
  const { data, displayColumns, disabled, updateDisplayColumns } = props
  const [inputValue, inputValueRef, setInputValue] = useAsyncState('')

  const columnOptions =
    data !== null
      ? data.meta.columns.map((d) => ({
          label: d.name,
          value: d.name.replace(' ', '_').toLowerCase(),
        }))
      : []

  const handlerCheckOption = useCallback(
    (values) => {
      updateDisplayColumns(
        data.dataset_id,
        values.map((elem) => elem.label)
      )
    },
    [updateDisplayColumns]
  )

  const handleInputChange = useCallback((query, action) => {
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') setInputValue(query)
  }, [])

  const handleKeyDown = useCallback((e) => {
    switch (e.keyCode) {
      case 8: // backspace
        if (!inputValueRef.current) {
          e.preventDefault()
        }
        return
      case 9: // tab
        e.stopPropagation()
        e.preventDefault()
      case 13: // enter
        e.stopPropagation()
        e.preventDefault()
        break
      case 46: // backspace
        if (!inputValueRef.current) {
          e.preventDefault()
        }

      default:
    }
  }, [])

  return (
    <Select
      closeMenuOnSelect={false}
      components={{ DropdownIndicator, MultiValueContainer, Option }}
      styles={{
        container: (base) => ({
          ...base,
          pointerEvents: disabled ? 'none' : '',
          opacity: disabled ? 0.5 : 1,
        }),
        valueContainer: (base, state) => {
          return {
            ...base,
            padding: state.selectProps.menuIsOpen ? '2px 8px 2px 0' : 0,
          }
        },
        option: (base, state) => ({
          ...base,
          backgroundColor: '#fff',
          color: '#000',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ':active': {
            backgroundColor: state.isSelected ? 'yellow' : '',
          },
          ':hover': {
            backgroundColor: '#DEEBFF',
          },
        }),
        control: (a, b) => {
          return styleControl(a, !!(b.selectProps.menuIsOpen || b.isFocused))
        },
      }}
      placeholder=""
      isMulti
      isClearable={false}
      hideSelectedOptions={false}
      options={columnOptions}
      inputValue={inputValue}
      value={
        displayColumns.length
          ? data.meta.columns
              .filter((f) => [...displayColumns].includes(f.name))
              .map((d) => ({
                label: d.name,
                value: d.name.replace(' ', '_').toLowerCase(),
              }))
          : []
      }
      defaultValue={
        displayColumns
          ? data.meta.columns
              .filter((f) => [...displayColumns].includes(f.name))
              .map((d) => ({
                label: d.name,
                value: d.name.replace(' ', '_').toLowerCase(),
              }))
          : []
      }
      onKeyDown={(e) => handleKeyDown(e)}
      onChange={(val) => handlerCheckOption(val)}
      onInputChange={(query, { action }) => handleInputChange(query, action)}
    />
  )
}

const mapDispatchToProps = { updateDisplayColumns }

export default connect(null, mapDispatchToProps)(ManageColumns)

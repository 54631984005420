import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const pushRoute = (route) => history.push(route)

export const Routes = {
  SignIn: () => '/login',
  SignUp: () => '/signup',
  SignUpConfirmed: () => '/signup/confirmed',
  ForgotPassword: () => '/forgot-password',
  ResetPassword: () => '/reset-password',
  Root: () => '/',
  Menu: () => '/menu',
  Upload: () => '/upload',
  Projects: () => '/projects',
  Project: (id) => `/projects/${id}/synthesis`,
  Templates: () => '/templates',
  CleanRooms: () => '/datacleanrooms',
  CleanRoomBuild: () => '/datacleanrooms/build',
  CleanRoomBuildStep: (step) => `/datacleanrooms/build/${step}`,
  Profile: () => '/profile',
  ProfileResetPassword: () => '/profile/reset-password',
  Design: () => '/design',
  DataSources: () => '/datasources',
  InviteCode: () => '/invitecode',
  TestingSuites: () => '/testingsuites',
  CreateTestingSuite: (id) => `/testingsuites/${id}`,
  ContactUs: () => '/contactus',
}

// turn on Segment analytics
let prevPath = null

// listen and notify Segment of client-side page updates
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname
    if (window.analytics) {
      window.analytics.page()
    }
  }
})

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'

import { changePasswordActions } from 'services/auth/changePassword/reducer'
import { getChangePasswordState } from 'services/auth/changePassword/selectors'

import { Form, TextField, Typography, spacing } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'

const ChangePasswordSchema = {
  password: constraints.password,
}

interface ISelectedProps {
  formState: ReturnType<typeof getChangePasswordState>
}

type DispatchProps = typeof mapDispatchToProps

type IChangePasswordProps = ISelectedProps & DispatchProps

const fieldMargin = {
  marginBottom: spacing.gutterLarge,
}

export const ChangePasswordForm: React.FC<IChangePasswordProps> = ({
  formState,
  changePassword,
  resetForm,
}) => {
  const { isLoading, serverError } = formState

  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [resetForm])

  const handleChangePassword = (values: any) => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    changePassword({ password: values.password, code })
  }

  return (
    <Form
      onSubmit={handleChangePassword}
      initialValues={{ password: '' }}
      validate={createValidatorWithSchema(ChangePasswordSchema)}
    >
      {({ values, errors, handleSubmit, handleChange }: any) => {
        return (
          <>
            <TextField
              name="password"
              label="New password"
              type="password"
              value={values.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            {serverError !== null && (
              <Typography color="red">{serverError.message}</Typography>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              fullWidth
              onClick={handleSubmit}
            >
              Change Password
            </Button>
          </>
        )
      }}
    </Form>
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  formState: getChangePasswordState,
})

const mapDispatchToProps = {
  changePassword: changePasswordActions.changePassword,
  resetForm: changePasswordActions.resetFormStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)

import React, { useEffect, useState, useCallback, useRef } from 'react'
import Select, { components } from 'react-select'
import { connect } from 'react-redux'
import { useAsyncState } from '../services/hooks'

import _v from '../styles/_variables.scss'
import './Header.scss'

const styleControl = (styles, error) => {
  const style = {
    alignItems: styles.alignItems,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'pointer',
    display: styles.display,
    flexWrap: styles.flexWrap,
    justifyContent: styles.justifyContent,
    outline: styles.outline,
    position: styles.position,
    boxSizing: styles.boxSizing,
    boxShadow: 'none',
    backgroundColor: '#fff',
    minHeight: _v['input-height'],
    transition: '.5s',
    '&: focus-within': { borderColor: _v.blue_royal },
  }
  return {
    ...style,
    ...{
      minWidth: '10rem',
      paddingLeft: '8px',
      borderColor: !error ? _v.b_color : 'red',
      '&: hover': { borderColor: 'hsl(0, 0%, 70%)' },
    },
  }
}

const MultiValueContainer = (props) => {
  return <components.MultiValueContainer {...props} />
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      <input
        className="uk-checkbox"
        type="checkbox"
        checked={
          props.getValue().findIndex((d) => d.label === props.label) !== -1
        }
        readOnly
        style={{
          marginTop: '-3px',
          marginRight: '7px',
          height: '14px',
          width: '14px',
        }}
      />
      <span style={{ lineHeight: '14px' }}>{props.label}</span>
    </components.Option>
  )
}

function MultiSelectCouplings(props) {
  const {
    dItem,
    data,
    disabled,
    errorsComplexSelects,
    complexSelects,
    handleSetColumnComplexType,
  } = props
  const _data = useRef()
  const [searchData, setSearchData] = useState([])
  const [inputValue, inputValueRef, setInputValue] = useAsyncState('')
  useEffect(() => {
    _data.current = data
    setSearchData(
      data.meta.columns.map((d) => {
        return { value: d.name, label: d.name }
      })
    )
  }, [data, complexSelects])

  const handlerCheckOption = useCallback((value, action) => {
    if (
      action === 'select-option' ||
      action === 'deselect-option' ||
      action === 'remove-value' ||
      action === 'clear'
    ) {
      handleSetColumnComplexType(value, dItem, action)
    }
  }, [])

  const handleInputChange = useCallback((query, action) => {
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') setInputValue(query)
  }, [])

  const handleKeyDown = useCallback((e) => {
    switch (e.keyCode) {
      case 8:
        if (!inputValueRef.current) {
          e.preventDefault()
        }
        break
      case 9: // tab
      case 13: // enter
        e.stopPropagation()
        e.preventDefault()
        break
      case 46:
        if (!inputValueRef.current) {
          e.preventDefault()
        }
        break
      default:
        break
    }
  }, [])

  const handleDisabledComplexSelects = (option, name) => {
    let columns_in_selects = [] // current columns in complex selects
    if (complexSelects.length) {
      complexSelects.forEach((m) => {
        m.selects.forEach((f) => {
          if (f.name !== name && f.selectedColumn.length) {
            columns_in_selects = columns_in_selects.concat([
              ...f.selectedColumn,
            ])
          }
        })
      })
    }
    return columns_in_selects.findIndex((f) => f === option.label) !== -1
  }

  return (
    <Select
      name={dItem.name}
      closeMenuOnSelect={false}
      components={{ MultiValueContainer, Option }}
      styles={{
        container: (base) => ({
          ...base,
          pointerEvents: disabled ? 'none' : '',
          opacity: disabled ? 0.5 : 1,
        }),
        valueContainer: (base, state) => {
          return {
            ...base,
            padding: state.selectProps.menuIsOpen ? '2px 8px 2px 0' : 0,
          }
        },
        option: (base, state) => ({
          ...base,
          backgroundColor: '#fff',
          color: '#000',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          pointerEvents: state.isDisabled ? 'none' : '',
          opacity: state.isDisabled ? 0.5 : 1,
          whiteSpace: 'nowrap',
          ':active': {
            backgroundColor: state.isSelected ? 'yellow' : '',
          },
          ':hover': {
            backgroundColor: '#DEEBFF',
          },
        }),
        control: (a) => {
          return styleControl(
            a,
            errorsComplexSelects.length &&
              errorsComplexSelects.findIndex((f) => f.name === dItem.name) !==
                -1
          )
        },
      }}
      placeholder={dItem.name}
      isMulti
      isClearable={true}
      hideSelectedOptions={false}
      options={searchData}
      inputValue={inputValue}
      defaultValue={dItem.selectedColumn.map((d) => ({ label: d, value: d }))}
      isOptionDisabled={(option) =>
        handleDisabledComplexSelects(option, dItem.name)
      }
      onKeyDown={(e) => handleKeyDown(e)}
      onChange={(val, { action }) => handlerCheckOption(val, action)}
      onInputChange={(query, { action }) => handleInputChange(query, action)}
    />
  )
}

export default connect(null, null)(MultiSelectCouplings)

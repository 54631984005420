import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from 'UIkit'
import { Typography } from 'legacy/shared'

import { selectGroupSynthProgressById } from 'services/project/scenarios/selectors'

import { colors } from '../../Colors'
import { LegendList } from '../legend/LegendList'
import ScenarioChartContainer from './ScenarioChartContainer'
import { getProjectDataState } from '../projectSelectors'
import { getDisplayColumnsState } from '../projectSettings/selectors'

const legends = [
  { name: 'Original', color: colors[0] },
  { name: 'Analyzed', color: colors[1] },
  { name: 'Rebalanced', color: colors[2] },
]

const useStyles = makeStyles({
  legendsContainer: {
    display: 'inline-block',
    padding: '.5rem',
    borderRadius: '4px',
  },
  legendsList: {
    marginBottom: 0,
  },
  chartsSection: {
    maxWidth: 'calc(100vw - 210px)',
    marginTop: '1rem',
    display: 'flex',
    overflowX: 'auto',
  },
  groupTitle: {
    marginBottom: '10px',
  },
})

const GroupsCharts = ({
  originalData,
  synthDataById,
  group,
  displayColumns,
  canEdit,
}) => {
  const classes = useStyles()

  const initValueSynthData = canEdit ? { ...originalData } : {}
  const synthData = synthDataById[group.id]?.preview
    ? synthDataById[group.id].preview
    : initValueSynthData

  const visibleColumns = synthData?.meta?.columns
    ? synthData.meta.columns.filter((column) =>
        displayColumns.includes(column.name)
      )
    : []

  // TODO refactor that part as Chart component will be updated
  const originalColumns = originalData?.meta?.columns
  const displayOriginalColumns = originalColumns
    ? originalColumns.filter((column) => displayColumns.includes(column.name))
    : []
  const fOriginalData = canEdit
    ? {
        ...originalData,
        meta: { ...originalData.meta, columns: displayOriginalColumns },
      }
    : {}

  return (
    <Box pt={2}>
      <Typography size="bodyBold">Target distribution</Typography>
      <div className={classes.chartsSection}>
        {visibleColumns.map((d, i) => (
          <ScenarioChartContainer
            key={d.name}
            last={i === visibleColumns.length - 1}
            group={group}
            data={{ ...fOriginalData }}
            synth_data={{ ...synthData }}
            d={d}
            index={i}
            disableDragBars={!canEdit}
            canEdit={canEdit}
          />
        ))}
      </div>
      <Box pt={2} pb={2}>
        <LegendList
          legends={legends}
          classNames={{ listRoot: classes.legendsList }}
          from="scenario"
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = createStructuredSelector({
  originalData: getProjectDataState,
  synthDataById: selectGroupSynthProgressById,
  displayColumns: getDisplayColumnsState,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsCharts)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { IStore } from 'core/rootReducer'
import { createTestingSuiteActions } from 'services/createTestingSuite/reducer'
import { selectIsLoading, selectSuites } from 'services/testingSuites/selectors'
import { testingSuitesActions } from 'services/testingSuites/reducer'
import {
  selectIsDrawerOpened,
  selectIsFilesUploading,
} from 'services/createTestingSuite/selectors'

import HeaderComponent, { SearchComponent } from 'layouts/Header'
import PageContainer from 'layouts/PageContainer'
import { Button, Drawer, TextField } from 'UIkit'
import UploadFileForm from 'components/UploadFileForm/UploadFileForm'
import TestingSuitesTable from 'pages/TestingSuites/TestingSuitesTable'
import { ExcelIcon, DatabaseIcon } from 'pages/TestingSuites/icons'
import ConnectDBForm from 'pages/TestingSuites/ConnectDBForm'

import { Tabs } from 'legacy/shared'
import { IDBConfig } from 'services/createTestingSuite/types'

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const TestingSuites = ({
  fetchTestingSuites,
  uploadSuite,
  isFilesUploading,
  isDrawerOpened,
  openDrawer,
  closeDrawer,
  suites,
  loading,
  createSuite,
}: SelectedProps & DispatchProps) => {
  const [selectedTab, setTab] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [newTestSuitName, setNewTestSuitName] = useState('New testing suite')

  useEffect(() => {
    fetchTestingSuites()
  }, [fetchTestingSuites])

  const classes = useStyles()
  const tabs = [
    {
      id: 0,
      label: 'Upload CSV file',
      icon: <ExcelIcon style={{ margin: '0 0.5rem 0 0' }} />,
    },
    {
      id: 1,
      label: 'Connect database',
      icon: <DatabaseIcon style={{ margin: '0 0.5rem 0 0' }} />,
    },
  ]
  const handleCancel = () => {
    closeDrawer()
  }
  const handleUpload = (formData: FormData) => {
    formData.append('name', newTestSuitName)
    uploadSuite({ formData })
  }

  return (
    <>
      <HeaderComponent title="Testing suites" />
      <PageContainer>
        <Box pb={3} className={classes.actionPanel}>
          <SearchComponent
            searchValue={searchValue}
            handleSetSearchValue={(value: string) => setSearchValue(value)}
          />
          <Button onClick={openDrawer}>Create testing suite</Button>
        </Box>
        <TestingSuitesTable
          data={suites}
          loading={loading}
          searchValue={searchValue}
        />
      </PageContainer>
      <Drawer
        anchor="right"
        open={isDrawerOpened}
        onClose={closeDrawer}
        title="Create a new test suite"
      >
        <Box mb={2} mx={5}>
          <TextField
            label="Test suit name"
            variant="outlined"
            size="small"
            value={newTestSuitName}
            onChange={(e) => setNewTestSuitName(e.target.value)}
          />
        </Box>
        {/* TODO: Move tabs to common components */}
        <Tabs
          tabs={tabs}
          onChange={(e: any, newValue: number) => setTab(newValue)}
          value={selectedTab}
          tabsClasses={{
            root: classes.rootTabs,
            indicator: classes.indicator,
          }}
          tabClasses={{
            root: classes.rootTab,
            selected: classes.selected,
          }}
        />
        <Box p={3} className={classes.uploadFormContainer}>
          {selectedTab === 0 ? (
            <UploadFileForm
              isFileUploading={isFilesUploading}
              multiple
              handleUpload={handleUpload}
              handleCancel={handleCancel}
            />
          ) : (
            <ConnectDBForm
              handleCancel={handleCancel}
              handleCreate={(values: IDBConfig) => {
                createSuite(values)
              }}
            />
          )}
        </Box>
      </Drawer>
    </>
  )
}

const mapStateToProps = (state: IStore) => ({
  suites: selectSuites(state),
  loading: selectIsLoading(state),
  isFilesUploading: selectIsFilesUploading(state),
  isDrawerOpened: selectIsDrawerOpened(state),
})
const mapDispatchToProps = {
  fetchTestingSuites: testingSuitesActions.fetchTestingSuites,
  updateCreateSuiteInfo: createTestingSuiteActions.updateCreateSuiteInfo,
  uploadSuite: createTestingSuiteActions.uploadSuite,
  openDrawer: createTestingSuiteActions.openDrawer,
  closeDrawer: createTestingSuiteActions.closeDrawer,
  createSuite: createTestingSuiteActions.createFromDB,
}
const useStyles = makeStyles({
  rootTabs: {
    backgroundColor: '#eff0f6',
    minHeight: '44px',
    paddingTop: '.5rem',
  },
  rootTab: {
    marginRight: '.5rem',
    backgroundColor: '#fcfcff',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    minHeight: '36px',
    '& > span': {
      zIndex: 2,
      flexDirection: 'row',
    },
  },
  selected: {
    backgroundColor: '#fff',
  },
  indicator: {
    display: 'none',
  },
  uploadFormContainer: {
    height: '100%',
  },
  actionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TestingSuites)

import React, { Suspense, ComponentType } from 'react'
import { Provider, ConnectedComponent } from 'react-redux'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
// TODO discuss how to use history object

import LegacyApp from 'legacy/components/App/LegacyApp'
import { SignInPage } from 'pages/SignIn'

import SignUpPage from 'pages/SignUp'
import { SignUpConfirmation } from 'pages/SignUp/SignUpConfirmed'
import ForgotPasswordPage from 'pages/ForgotPassword'
import { ChangePasswordPage } from 'pages/ChangePassword'

import { NotFoundPage } from 'legacy/components/NotFoundPage'
import { history, Routes } from 'legacy/utils/router'
import { ThemeProvider } from 'legacy/shared'

import PrivateRoute from './PrivateRoute'
import AppRoutes from './App'
import { createStore } from './store'

// LEGACY
import 'legacy/global.scss'
import 'legacy/index.css'
//
// LEGACY
import 'legacy/components/Datasources/uikit.min.css'
//

const publicRoutes: [
  string,
  React.ComponentType | ConnectedComponent<any, any>
][] = [
  [Routes.SignIn(), SignInPage],
  [Routes.SignUp(), SignUpPage],
  [Routes.SignUpConfirmed(), SignUpConfirmation],
  [Routes.ForgotPassword(), ForgotPasswordPage],
  [Routes.ResetPassword(), ChangePasswordPage],
]

const Root: React.FC = () => {
  return (
    <Provider store={createStore()}>
      <ThemeProvider>
        <Router history={history}>
          <Switch>
            {publicRoutes.map(([route, Comp]: [string, any]) => (
              <Route path={route} component={Comp} key={route} exact />
            ))}

            {/* legacy app */}
            <PrivateRoute
              path={[
                Routes.Projects(),
                Routes.DataSources(),
                Routes.CleanRoomBuild(),
              ]}
              component={
                <Suspense fallback={<div>Loading...</div>}>
                  <LegacyApp />
                </Suspense>
              }
            />

            {/* new app */}
            <PrivateRoute
              path={[
                Routes.Templates(),
                Routes.Profile(),
                Routes.ProfileResetPassword(),
                Routes.InviteCode(),
                Routes.TestingSuites(),
                Routes.CleanRooms(),
                Routes.CreateTestingSuite(),
                Routes.ContactUs(),
              ]}
              component={
                <Suspense fallback={<div>Loading...</div>}>
                  <AppRoutes />
                </Suspense>
              }
            />

            <Redirect from={Routes.Root()} to={Routes.Projects()} exact />
            <Route path="*">
              <div style={{ paddingTop: '100px' }}>
                <NotFoundPage />
              </div>
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default Root

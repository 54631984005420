export const ProjectActions = {
  FETCH_DATASET: 'project/FETCH_DATASET',
  DID_FETCH_DATASET: 'project/DID_FETCH_DATASET',
  UPDATE_DATASET: 'project/UPDATE_DATASET',
  DID_UPDATE_DATASET: 'project/DID_UPDATE_DATASET',
  DID_UPDATE_DATASET_FAIL: 'project/DID_UPDATE_DATASET_FAIL',
  UPDATE_DATASET_COLUMN_NAME: 'project/UPDATE_DATASET_COLUMN_NAME',
  DID_UPDATE_DATASET_COLUMN_NAME: 'project/DID_UPDATE_DATASET_COLUMN_NAME',

  UPDATE_TITLE: 'project/UPDATE_TITLE',
  DID_UPDATE_TITLE: 'project/DID_UPDATE_TITLE',

  FETCH_SYNTH: 'project/FETCH_SYNTH',
  DID_FETCH_SYNTH: 'project/DID_FETCH_SYNTH',
  DID_FETCH_SYNTH_FAIL: 'project/DID_FETCH_SYNTH_FAIL',

  FETCH_PREVIEW_DATA: 'project/FETCH_PREVIEW_DATA',
  DID_FETCH_PREVIEW_DATA: 'project/DID_FETCH_PREVIEW_DATA',

  START_TRAINING: 'project/START_TRAINING',

  STOP_TRAINING: 'project/STOP_TRAINING',
  DID_STOP_TRAINING: 'project/DID_STOP_TRAINING',
  DID_STOP_TRAINING_FAIL: 'project/DID_STOP_TRAINING_FAIL',

  DID_TRAINING: 'project/DID_TRAINING',
  DID_TRAINING_FAIL: 'project/DID_TRAINING_FAIL',

  UPDATE_STATUS: 'project/UPDATE_STATUS',
  SET_PREPROCESSING_STATUS: 'project/SET_PREPROCESSING_STATUS',

  UPDATE_TAB: 'project/UPDATE_TAB',

  DID_LOADING_DATASET: 'project/DID_LOADING_DATASET',
  DID_LOADING_DATASET_FAIL: 'project/DID_LOADING_DATASET_FAIL',

  SET_NOT_FOUND_PAGE: 'project/SET_NOT_FOUND_PAGE', // TODO move into /store

  RESET_STATE: 'project/RESET_STATE',
}

export const fetchOriginalDataset = ({ id, sampleSize }) => ({
  type: ProjectActions.FETCH_DATASET,
  id,
  sampleSize,
})

export const didFetchOriginalDataset = (data, status, previewData) => ({
  type: ProjectActions.DID_FETCH_DATASET,
  data,
  status,
  previewData,
})

export const updateTitle = (title) => ({
  type: ProjectActions.UPDATE_TITLE,
  title,
})

export const didUpdateTitle = (title) => ({
  type: ProjectActions.DID_UPDATE_TITLE,
  title,
})

export const updateDataset = ({
  id,
  settings,
  loaderName = '',
  callback = () => {},
}) => ({
  type: ProjectActions.UPDATE_DATASET,
  id,
  settings,
  loaderName,
  callback,
})

export const didUpdateDataset = ({ settings }) => ({
  type: ProjectActions.DID_UPDATE_DATASET,
  settings,
})

export const didUpdateDatasetFail = () => ({
  type: ProjectActions.DID_UPDATE_DATASET_FAIL,
})

export const updateDatasetColumnName = ({ id, names }) => ({
  type: ProjectActions.UPDATE_DATASET_COLUMN_NAME,
  id,
  names,
})

export const didUpdateDatasetColumnName = ({ names }) => ({
  type: ProjectActions.DID_UPDATE_DATASET_COLUMN_NAME,
  names,
})

export const fetchSynth = ({ id, sample_size }) => ({
  type: ProjectActions.FETCH_SYNTH,
  id,
  sample_size,
})

export const didFetchSynth = ({ data, loadingDataset }) => ({
  type: ProjectActions.DID_FETCH_SYNTH,
  data,
  loadingDataset,
})

export const didFetchSynthFail = () => ({
  type: ProjectActions.DID_FETCH_SYNTH_FAIL,
})

export const fetchPreviewData = ({ id, sample_size }) => ({
  type: ProjectActions.FETCH_PREVIEW_DATA,
  id,
  sample_size,
})

export const didFetchPreviewData = ({
  data,
  estimatedTime,
  loadingDataset,
}) => ({
  type: ProjectActions.DID_FETCH_PREVIEW_DATA,
  data,
  estimatedTime,
  loadingDataset,
})

export const updateStatus = (status) => ({
  type: ProjectActions.UPDATE_STATUS,
  status,
})

export const setPreprocessingStatus = (status) => ({
  type: ProjectActions.SET_PREPROCESSING_STATUS,
  status,
})

export const updateTab = (id) => ({
  type: ProjectActions.UPDATE_TAB,
  id,
})

export const startTraining = (id) => ({
  type: ProjectActions.START_TRAINING,
  id,
})

export const stopTraining = () => ({
  type: ProjectActions.STOP_TRAINING,
})

export const didStopTraining = () => ({
  type: ProjectActions.DID_STOP_TRAINING,
})

export const didStopTrainingFail = () => ({
  type: ProjectActions.DID_STOP_TRAINING_FAIL,
})

export const didTraining = () => ({
  type: ProjectActions.DID_TRAINING,
})

export const didTrainingFail = () => ({
  type: ProjectActions.DID_TRAINING_FAIL,
})

export const didLoadingDataset = () => ({
  type: ProjectActions.DID_LOADING_DATASET,
})

export const didLoadingDatasetFail = () => ({
  type: ProjectActions.DID_LOADING_DATASET_FAIL,
})

export const setNotFoundPage = () => ({
  type: ProjectActions.SET_NOT_FOUND_PAGE,
})

export const resetProjectState = () => ({
  type: ProjectActions.RESET_STATE,
})

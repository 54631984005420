import React, { useEffect, useMemo } from 'react'
import { Table } from 'components/Table/Table'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { useHistory } from 'react-router-dom'
import { datasetsActions } from 'legacy/components/Datasets/reducer'
import { productTourActions } from 'legacy/components/ProductTour/reducer'
import {
  getDatasetsList,
  getPageIsLoadingState,
} from 'legacy/components/Datasets/datasetsSelectors'
import { Box, styled } from '@material-ui/core'
import {
  CustomHeaderCell,
  DescriptionCell,
  NameCell,
  TimeCell,
  ImageCell,
} from 'components/Table'
import { SourceTag } from 'legacy/components/Datasets/SourceTag'
import { Tags } from 'legacy/components/Datasets/Tags'
import { StatusTag } from 'legacy/components/Datasets/StatusTag'
import ActionMenu from 'legacy/components/Datasets/ActionMenu'

type ISelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

interface IProjectsTableProps {
  searchValue: string
}

const ProjectsTable = (
  props: ISelectedProps & DispatchProps & IProjectsTableProps
) => {
  const {
    listDatasets,
    pageIsLoading,
    fetchDatasets,
    finishFirstTip,
    searchValue,
  } = props

  useEffect(() => {
    fetchDatasets()
  }, [fetchDatasets])

  const history = useHistory()
  const columns = useMemo(
    () => [
      {
        Header: <CustomHeaderCell title="Data Product Name" />,
        accessor: 'title',
        isSorted: true,
        width: 200,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <NameCell value={cellProps.cell.value} />
          ) : null,
      },
      {
        Header: <CustomHeaderCell title="Description" />,
        accessor: 'description',
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <DescriptionCell value={cellProps.cell.value} />
          ) : null,
      },
      {
        Header: <CustomHeaderCell title="Source" />,
        accessor: 'source_name',
        disableResizing: true,
        width: 130,
        minWidth: 130,
        maxWidth: 130,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <SourceTag source={cellProps.cell.value} />
          ) : null,
      },
      {
        Header: <CustomHeaderCell title="Tags" />,
        accessor: 'tags',
        disableResizing: true,
        width: 130,
        minWidth: 130,
        maxWidth: 130,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <Tags tags={cellProps.cell.value || []} />
          ) : null,
      },
      {
        Header: <CustomHeaderCell title="Status" />,
        accessor: 'status',
        disableResizing: true,
        width: 130,
        minWidth: 130,
        maxWidth: 130,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <StatusTag status={cellProps.cell.value} />
          ) : null,
      },
      {
        Header: <CustomHeaderCell title="Created" />,
        accessor: 'created_at',
        sortType: 'customDate',
        disableResizing: true,
        width: 190,
        minWidth: 190,
        maxWidth: 190,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <TimeCell value={cellProps.cell.value} />
          ) : null,
      },
      {
        Header: <CustomHeaderCell title="Owner" />,
        accessor: 'created_by_avatar_path',
        disableResizing: true,
        width: 70,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        disableSortBy: true,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <ImageCell
              cell={cellProps.cell}
              style={{ justifyContent: 'center' }}
            />
          ) : null,
      },
      {
        Header: '',
        accessor: 'action',
        disableResizing: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        align: 'center',
        disableSortBy: true,
        Cell: (cellProps: any) =>
          !cellProps.row.original.noData ? (
            <ActionMenu cell={cellProps.cell.row.original} />
          ) : null,
      },
    ],
    []
  )

  const handleRowClick = (event: MouseEvent, row: any) => {
    finishFirstTip()
    if (event.altKey || event.metaKey) {
      event.preventDefault()
      // TODO: have a service layer for it and service with all http calls inside (with URLs)
      window.open(
        `${window.location.origin}/projects/${row.original.dataset_id}/synthesis`,
        '_blank'
      )
    } else {
      event.stopPropagation()
      event.preventDefault()
      ;(event.target as HTMLTextAreaElement).blur()
      history.push(`/projects/${row.original.dataset_id}/synthesis`)
    }
  }

  return (
    <TableContainer>
      <Table
        loading={pageIsLoading}
        data={
          listDatasets?.length
            ? listDatasets.filter((row: any) => {
                return !searchValue
                  ? true
                  : row.title.toLowerCase().includes(searchValue)
              })
            : []
        }
        columns={columns}
        onRowClick={handleRowClick}
      />
    </TableContainer>
  )
}

const mapStateToProps = createStructuredSelector({
  listDatasets: getDatasetsList,
  pageIsLoading: getPageIsLoadingState,
})
const mapDispatchToProps = {
  fetchDatasets: datasetsActions.fetchDatasets,
  finishFirstTip: () => productTourActions.pauseTip({ tip: '0' }),
}
const TableContainer = styled(Box)({
  height: 'calc(100% - 64px)', // 64px is height of the action panel
  overflow: 'auto',
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTable)

import React from 'react'
import ReactDOM from 'react-dom'
import * as d3 from 'd3-legacy'
import {
  formatNumber,
  transformCorrelationChartData,
} from '../../services/helper'

import './ScatterPlot.scss'

class ScatterPlot extends React.Component {
  componentDidMount() {
    const { data_item, width } = this.props
    const transform_data_item = transformCorrelationChartData(data_item)

    const data = this.shuffle(transform_data_item)

    this.initGraph(data, width)
  }

  shuffle = (data) => {
    for (let i = data.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[data[i], data[j]] = [data[j], data[i]]
    }
    return data
  }

  initGraph(data, width) {
    this.svg = d3.select(ReactDOM.findDOMNode(this)).append('svg')

    this.updateSvg(data, width)

    this.tooltip = d3.select('.tooltip')

    if (!this.tooltip.node()) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
    }

    this.g = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

    // add scatter plot
    this.g.append('g').attr('class', 'scatter')

    this.renderScatterPlot(data)
  }

  updateSvg = (data, width) => {
    this.margin = { top: 0, right: 0, bottom: 0, left: 0 }
    this.width = width - this.margin.left - this.margin.right
    this.height = 120 - this.margin.top - this.margin.bottom

    this.svg
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)

    this.prepareDomains(data)
    this.updateGrid()
  }

  prepareDomains = (data) => {
    const xDomain = d3.extent(data, (d) => d.x)
    const xDelta = Math.abs(xDomain[1] - xDomain[0]) / 20
    const yDomain = d3.extent(data, (d) => d.y)
    const yDdelta =
      ((Math.abs(yDomain[1] - yDomain[0]) / 20) * this.width) / this.height

    this.x = d3
      .scaleLinear()
      .domain([xDomain[0] - xDelta, xDomain[1] + xDelta])
      .range([0, this.width])

    this.y = d3
      .scaleLinear()
      .domain([yDomain[0] - yDdelta, yDomain[1] + yDdelta])
      .range([this.height, 0])
  }

  updateGrid = () => {
    const deltaXGrid = (this.x.domain()[1] - this.x.domain()[0]) / 4
    const deltaYGrid = (this.y.domain()[1] - this.y.domain()[0]) / 4
    const gridXValues = []
    const gridYValues = []

    for (let i = 1; i < 4; i++) {
      gridXValues.push(this.x.domain()[0] + i * deltaXGrid)
      gridYValues.push(this.y.domain()[0] + i * deltaYGrid)
    }

    this.svg.select('.grid-x').remove()
    this.svg.select('.grid-y').remove()

    const xGrid = this.svg
      .append('g')
      .attr('class', 'grid-x')
      .attr('transform', `translate(0, ${this.height})`)
      .call(
        d3
          .axisTop(this.x)
          .ticks(4)
          .tickSize(this.height)
          .tickValues(gridXValues)
          .tickFormat('')
      )

    xGrid.select('.domain').remove()
    xGrid
      .selectAll('.tick line')
      .attr('stroke', '#e0e4ed')
      .attr('stroke-width', 0.5)

    const yGrid = this.svg
      .append('g')
      .attr('class', 'grid-y')
      .attr('transform', `translate(0, 0)`)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(4)
          .tickSize(-this.width)
          .tickValues(gridYValues)
          .tickFormat('')
      )

    yGrid.select('.domain').remove()
    yGrid
      .selectAll('.tick line')
      .attr('stroke', '#e0e4ed')
      .attr('stroke-width', 0.5)
  }

  renderScatterPlot(data) {
    this.g.select('.scatter').selectAll('circle').remove()

    const { tooltip } = this

    const circles = this.g.select('.scatter').selectAll('circle')

    circles
      .data(data)
      .join('circle')
      .attr('fill', (d) => d.color)
      .attr('cx', (d) => this.x(d.x))
      .attr('cy', (d) => this.y(d.y))
      .attr('r', 3)
      .on('mouseover', function (d, i) {
        d3.select(this).style('opacity', 0.75)
        const tpl = `
        <ul class="t-ul-style">
          <li class="t-li-style" ><div class="t-li-legend" style="background-color: ${
            d.color
          }"></div><div>${d.x_label}: ${formatNumber(d.x)}</div></li>
          <li class="t-li-style" ><div class="t-li-legend" style="background-color: ${
            d.color
          }"></div><div>${d.y_label}: ${formatNumber(d.y)}</div></li>
        </ul>`

        tooltip.transition().duration(200).style('opacity', 0.9)
        tooltip
          .html(tpl)
          .style('left', `${d3.event.pageX + 8}px`)
          .style('top', `${d3.event.pageY - 48}px`)
      })
      .on('mouseout', function (d) {
        d3.select(this).style('opacity', 1)
        tooltip.transition().duration(500).style('opacity', 0)
      })
  }

  redrawScatterPlot = (data) => {
    const circles = this.svg.select('.scatter').selectAll('circle')

    circles
      .data(data)
      .join('circle')
      .attr('fill', (d) => d.color)
      .attr('cx', (d) => this.x(d.x))
      .attr('cy', (d) => this.y(d.y))

    this.svg.select('.grid-x').lower()
    this.svg.select('.grid-y').lower()
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillReceiveProps(next) {
    if (
      next.width !== this.props.width ||
      JSON.stringify(next.data_item) !== JSON.stringify(this.props.data_item)
    ) {
      const { data_item, width } = next
      const transform_data_item = transformCorrelationChartData(data_item)

      this.updateSvg(transform_data_item, width)
      this.redrawScatterPlot(transform_data_item)
    }
  }

  render() {
    return (
      <div className="scatterPlot" id={0}>
        {this.props.children}
      </div>
    )
  }
}

export default ScatterPlot

import * as d3 from 'd3-legacy'
import { formatNumber } from '../../../services/helper'

const axisDuration = 200

const updateAxes = ({ svg, margin, height, x, y, plot_type }) => {
  const for_rotate = svg
    .select('.axis-x')
    .attr('transform', `translate(${margin.left}, ${height + margin.top})`)
    .call(
      d3
        .axisBottom(x)
        .ticks(4)
        .tickValues(
          plot_type === 'density'
            ? null
            : x.domain().length > 10
            ? x.domain().filter((d, i) => i % 2 === 0)
            : x.domain().length > 20
            ? x.domain().filter((d, i) => i % 3 === 0)
            : x.domain()
        )
        .tickSize(2)
        .tickFormat(
          x.domain().filter((d) => typeof d === 'string').length === 0
            ? (d) => formatNumber(d)
            : (d) => d
        )
    )
    .selectAll('text')
    .style('font-size', (d) => (d === '...' ? '15px' : '11px'))
    .style('font-weight', 400)
    .style('fill', '777c8d')

  if (
    x.domain().filter((d) => typeof d === 'string').length !== 0 &&
    x.domain().filter((d) => d.length > 4).length !== 0
  ) {
    for_rotate
      .style('text-anchor', 'end')
      .attr('dx', '-.2em')
      .attr('dy', '.6em')
      .attr('transform', 'rotate(-35)')
  }

  // y axis
  svg
    .select('.axis-y')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .call(
      d3
        .axisLeft(y)
        .ticks(3)
        .tickSize(0)
        .tickFormat((d) => formatNumber(d))
    )
    .selectAll('text')
    .style('font-size', '11px')
    .style('font-weight', 400)
    .style('fill', '777c8d')
}

const updateHorizontalAxes = ({ svg, svgScroll, margin, height, x, y }) => {
  // x - y-axis, y - x-axis
  svgScroll
    .select('.axis-x')
    .attr('transform', `translate(${margin.left}, ${height + margin.top})`)
    .call(
      d3
        .axisBottom(y)
        .ticks(3)
        .tickSize(0)
        .tickFormat((d) => formatNumber(d))
    )
    .selectAll('text')
    .attr('dy', 10)
    .style('font-weight', 400)
    .style('fill', '777c8d')

  // y axis
  svg
    .select('.axis-y')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .call(
      d3
        .axisLeft(x)
        .ticks(4)
        .tickValues(
          x.domain().length > 10
            ? x.domain().filter((d, i) => i % 2 === 0)
            : x.domain().length > 20
            ? x.domain().filter((d, i) => i % 3 === 0)
            : x.domain()
        )
        .tickSize(2)
        .tickFormat(
          x.domain().filter((d) => typeof d === 'string').length === 0
            ? (d) => formatNumber(d)
            : (d) => d
        )
    )
    .selectAll('text')
    .style('font-size', (d) => (d === '...' ? '15px' : '11px'))
    .style('font-weight', 400)
    .style('fill', '777c8d')
    .attr('dy', 0.5)
    .text((d) =>
      typeof d === 'string' && d.length > 8 ? `${d.slice(0, 8)}...` : d
    )
}

const updateYAxis = ({ svg, y, margin }) => {
  // y axis
  svg
    .select('.axis-y')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .transition()
    .duration(axisDuration)
    .ease(d3.easeLinear)
    .call(
      d3
        .axisLeft(y)
        .ticks(3)
        .tickSize(0)
        .tickFormat((d) => formatNumber(d))
    )
}

const updateHorizontalYAxis = ({ svgScroll, y, margin, height }) => {
  // x axis
  svgScroll
    .select('.axis-x')
    .attr('transform', `translate(${margin.left}, ${height + margin.top})`)
    .call(
      d3
        .axisBottom(y)
        .ticks(3)
        .tickSize(0)
        .tickFormat((d) => formatNumber(d))
    )
    .selectAll('text')
    .attr('dy', 10)
}

export { updateAxes, updateHorizontalAxes, updateYAxis, updateHorizontalYAxis }

import React, { FC } from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AccordionBox } from 'UIkit/AccordionBox/AccordionBox'
import { CodeBlock } from 'UIkit/CodeBlock/CodeBlock'
import { DataGeneratedTable } from './DataGeneratedTable'
import { getColor } from './utils'
import { DataViewGroupCounter } from './DataViewGroupCounter'

interface DataCounter {
  value: number
  label: string
  dataValue: number
}

interface IDataViewGroupProps {
  index?: number
  sqlOnly?: boolean
  data: {
    title: string
    generatedData: any
    sqlData: any
    counters: DataCounter[]
  }
}

export const DataViewGroup: FC<IDataViewGroupProps> = ({
  data = {},
  index = 0,
  sqlOnly,
}) => {
  const { title, generatedData, sqlData } = data
  const styles = useStyles()

  const formatter = (value: number) => Math.round(value * 10) / 10

  return (
    <Box mt={4} mb={4}>
      <div className={styles.head}>
        <Typography className={styles.title}>
          {title} #{index + 1}
        </Typography>
        <Box pb={2.5} pt={2.5} display="flex" className={styles.counters}>
          {!sqlOnly && sqlData && (
            <>
              <DataViewGroupCounter
                label="Old coverage"
                formatValue={formatter}
                key={`counter-${sqlData.coverage_old}`}
                value={sqlData.coverage_old}
                color={getColor(sqlData.coverage_old, 3, 2).color}
              />
              <DataViewGroupCounter
                label="New coverage"
                formatValue={formatter}
                key={`counter-${sqlData.coverage_new}`}
                value={sqlData.coverage_new}
                color={getColor(sqlData.coverage_new, 3, 2).color}
              />
              <DataViewGroupCounter
                label="Number of rules"
                formatValue={formatter}
                key={`counter-${sqlData.number_of_subrules}`}
                value={sqlData.number_of_subrules}
                color={getColor(sqlData.number_of_subrules, 3, 2).color}
              />
              <DataViewGroupCounter
                label="Analyzed rules"
                formatValue={formatter}
                key={`counter-${sqlData.analyzed_subrules}`}
                value={sqlData.analyzed_subrules}
                color={getColor(sqlData.analyzed_subrules, 3, 2).color}
              />
            </>
          )}
        </Box>
      </div>
      <div className={styles.body}>
        {sqlData && (
          <CodeBlock
            code={sqlData.rule_raw}
            language="SQL"
            howLineNumbers={7}
          />
        )}
        {generatedData && !sqlOnly && (
          <Box ml={-1.2} mt={2.5}>
            <AccordionBox embedded title="Generated data">
              <DataGeneratedTable data={generatedData.rows} />
            </AccordionBox>
          </Box>
        )}
      </div>
    </Box>
  )
}
// fix styles
const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
  },
  body: {},
  head: {},
  counters: {},
}))

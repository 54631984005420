import {
  ProjectsIcon,
  TemplatesIcon,
  DatasourcesIcon,
  SandboxesIcon,
  TestingSuitesIcon,
} from 'assets/sidebar'
import { Routes } from 'legacy/utils'
import _ from '../../legacy/components/App/Constants'

export const cloudTabs = [
  {
    label: 'Data Products',
    className: 'sidebar-projects',
    path: Routes.Projects(),
    IconComponent: ProjectsIcon,
    enabled: true,
  },
  {
    label: 'Product Catalogue',
    path: '/templates',
    IconComponent: TemplatesIcon,
    enabled: true,
  },
  {
    label: 'Datasources',
    path: Routes.DataSources(),
    IconComponent: DatasourcesIcon,
    enabled: true,
  },
  {
    label: 'Data Clean Rooms',
    path: Routes.CleanRooms(),
    IconComponent: SandboxesIcon,
    enabled: true,
  },
  {
    label: 'Testing Suite',
    path: Routes.TestingSuites(),
    IconComponent: TestingSuitesIcon,
    enabled: _.IS_TESTING_SUIT_ENABLED,
  },
]

import React from 'react'

import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import { Heading, TextField, Typography } from 'legacy/shared'

import { HeaderLayout } from './HeaderLayout'
import HeaderMenu from './HeaderMenu'

const useStyles = makeStyles({
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  spanIcon: {
    marginLeft: '.5rem',
    marginRight: '0.3rem',
  },
  backButton: {
    marginRight: '1.2rem',
  },
  subTitle: {
    cursor: 'pointer',
  },
})

export const SearchComponent: React.FunctionComponent<{
  searchValue: string
  handleSetSearchValue: any
}> = ({ searchValue, handleSetSearchValue }) => {
  const { callback } = useDebouncedCallback(handleSetSearchValue, 300)

  return (
    <TextField
      defaultValue={searchValue}
      onChange={(error: any) => callback(error.target.value)}
      label=""
      placeholder="Search..."
      autoComplete="off"
      variant="outlined"
    />
  )
}

export const HeaderLeftLayout: React.FunctionComponent = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.header}>{children}</div>
}

interface IHeaderComponentProps {
  title: string
  subTitle?: string
  backPath?: string
  classNames?: any
  onClickBack?: any
}

const HeaderComponent: React.FunctionComponent<IHeaderComponentProps> = ({
  children,
  title = '',
  subTitle = '',
  backPath = '',
  classNames = { root: {} },
  onClickBack,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <HeaderLayout className={classNames.root}>
      <HeaderLeftLayout>
        {backPath || onClickBack ? (
          <IconButton
            aria-label="delete"
            className={classes.backButton}
            size="small"
            onClick={() =>
              onClickBack ? onClickBack() : history.replace(backPath)
            }
          >
            <KeyboardBackspaceIcon color="disabled" />
          </IconButton>
        ) : null}
        <Heading size="title">{title}</Heading>
        {subTitle ? (
          <>
            <span className={classes.spanIcon}>
              <ChevronRightIcon color="disabled" />
            </span>
            {typeof subTitle === 'string' ? (
              <Typography
                size="subTitle"
                variant="h2"
                className={classes.subTitle}
              >
                {subTitle}
              </Typography>
            ) : (
              subTitle
            )}
          </>
        ) : null}
        {children}
      </HeaderLeftLayout>
      <HeaderMenu />
    </HeaderLayout>
  )
}

export default HeaderComponent

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import User from 'legacy/components/Account/User'
import _ from 'legacy/components/App/Constants'

const AdminRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (User.isAdmin() && _.IS_INVITES_ENABLED) {
        return <Component {...props} />
      }
      return (
        <Redirect
          to={{
            pathname: '/menu',
            state: { from: props.location },
          }}
        />
      )
    }}
  />
)

export default AdminRoute

import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { Typography } from 'legacy/shared'

const useStyles = makeStyles({
  titleContainer: {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    padding: '.5rem',
  },
  title: {
    marginRight: '.5rem',
    lineHeight: '22px',
    maxWidth: 200,
  },
})

export const ColumnTitleLayout: FC<any> = ({ title, className, children }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.titleContainer, className)}>
      <Typography size="captionBold" className={classes.title} noWrap>
        {title}
      </Typography>
      {children}
    </div>
  )
}

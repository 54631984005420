import React, { FC, useMemo, useState } from 'react'
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TablePagination,
  IconButton,
} from '@material-ui/core'
import SpinnerSample from 'legacy/components/Projects/SpinnerSample'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { useTable, useExpanded, TableState } from 'react-table'
import { colors, fonts } from 'legacy/shared'
import { isEqual } from 'lodash'
import cn from 'classnames'

export type HandlerRowProps = {
  row: any
  index: number
  state?: TableState<any>
  onRowClick: (_: MouseEvent, row: any) => void
}

interface SimpleTableProps {
  columns: any[]
  footerData?: any
  data: any[]
  asDiv?: boolean
  loading?: boolean
  expandable?: boolean
  withPagination?: boolean
  onRowClick?: (_: MouseEvent, row: any) => void
  rowRender?: any
  renderRowSubComponent?: any
}

export const SimpleTable: FC<SimpleTableProps> = React.memo(
  ({
    columns: inputColumns,
    data: inputData,
    asDiv,
    loading,
    expandable,
    footerData,
    withPagination,
    rowRender,
    renderRowSubComponent,
    onRowClick,
  }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const count = page * rowsPerPage
    const pages = page * rowsPerPage + rowsPerPage

    const handleChangePage = (_: any, newPage: any) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const styles = useStyles()
    const data = useMemo(
      () => (footerData ? [...inputData, footerData] : inputData),
      [inputData]
    )
    const columns = useMemo(
      () =>
        expandable
          ? [
              {
                id: 'expander',
                Header: '',
                Cell: ({ row }: any) => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <IconButton className={styles.openButton}>
                        <ExpandMoreIcon
                          className={cn(styles.iconBox, {
                            [styles.iconRotate]: row.isExpanded,
                          })}
                        />
                      </IconButton>
                    </div>
                  )
                },
              },
              ...inputColumns,
            ]
          : inputColumns,
      [inputColumns, expandable]
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
    } = useTable(
      {
        columns,
        data,
      },
      useExpanded
    )

    const asDivProp = asDiv ? { component: 'div' } : {}

    const defaultRowRender = ({ row, index }: HandlerRowProps) => {
      const isLastRow = data.length - 1 === index

      return (
        <React.Fragment {...row.getRowProps()}>
          <TableRow
            {...asDivProp}
            {...row.getToggleRowExpandedProps()}
            className={cn(styles.tr, {
              [styles.footerRow]: isLastRow && footerData,
            })}
          >
            {row.cells.map((cell: any, idx: number) => {
              return (
                <TableCell
                  {...cell.getCellProps()}
                  {...asDivProp}
                  className={cn(styles.td, {
                    [styles.tdSelected]: row.isExpanded,
                  })}
                >
                  {isLastRow && footerData && !idx ? null : cell.render('Cell')}
                </TableCell>
              )
            })}
          </TableRow>
          {renderRowSubComponent ? (
            <TableRow>
              <TableCell
                className={styles.contentRowTd}
                colSpan={columns.length}
              >
                <div
                  className={cn(styles.contentRow, {
                    [styles.expandedRow]: row.isExpanded,
                  })}
                >
                  {row.isExpanded &&
                    !isLastRow &&
                    renderRowSubComponent({ row })}
                </div>
              </TableCell>
            </TableRow>
          ) : null}
        </React.Fragment>
      )
    }

    const rowRenderFn = (row: any, index: number) => {
      prepareRow(row)
      const handlerProps = { row, onRowClick, state, index }
      return rowRender
        ? rowRender(handlerProps)
        : defaultRowRender(handlerProps)
    }

    return (
      <>
        <TableContainer
          component={Paper}
          elevation={0}
          className={styles.container}
        >
          {loading && <SpinnerSample classNameSpinner={styles.spinner} />}
          {!loading && (
            <Table {...asDivProp} {...getTableProps()}>
              <TableHead {...asDivProp}>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    {...asDivProp}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        {...column.getHeaderProps()}
                        className={styles.headCell}
                      >
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...asDivProp} {...getTableBodyProps()}>
                {rows.slice(count, pages).map(rowRenderFn)}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {!loading && withPagination && (
          // @ts-ignore
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </>
    )
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.data, nextProps.data) &&
    prevProps.loading === nextProps.loading
)

const useStyles = makeStyles({
  container: {
    '& th': {
      backgroundColor: colors.tableBackground,
      display: 'table-cell!important',
    },
    '& th: first-child': {
      borderRadius: '4px 0px 0px 0px',
    },
    '& th: last-child': {
      borderRadius: '0px 4px 0px 0px',
    },
    '& td': {
      backgroundColor: colors.white,
      color: colors.tableTextColor,
      fontSize: '12px',
    },
    '& .MuiTablePagination-spacer': {
      flex: 0,
    },
  },
  tr: {
    '&:hover td': {
      transition: 'ease 0.2s background-color',
      backgroundColor: colors.tableSelectedRow,
    },
  },
  tdSelected: {
    backgroundColor: colors.tableSelectedRow,
  },
  contentRowTd: {
    border: 'none',
    padding: 0,
  },
  footerRow: {
    '& *': {
      fontWeight: 'bold!important',
    },
  },
  headCell: {
    fontFamily: fonts.primary,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    backgroundColor: colors.tableHeaderBackground,
    borderLeft: 'none',
    borderRight: 'none',
    '&:last-child': {
      borderRight: `1px solid ${colors.tableBorder}`,
    },
    '&:first-child': {
      borderLeft: `1px solid ${colors.tableBorder}`,
    },
  },
  td: {
    borderBottom: `1px solid ${colors.platinum}`,
    padding: 0,
  },
  iconBox: {
    color: colors.expander,
    display: 'block',
    width: '16px',
    height: '16px',
    borderRadius: '4px',
    padding: 0,
    backgroundColor: colors.expanderBackground,
    transition: '0.3s',
  },
  openButton: {
    margin: 'auto',
  },
  contentRow: {
    maxHeight: 0,
  },
  expandedRow: {
    transition: 'ease 0.5s max-height',
    maxHeight: '10000px',
    overflow: 'hidden',
  },
  spinner: { marginTop: '2.25rem' },
  iconRotate: {
    transform: 'rotate(180deg)',
    transition: '0.3s',
  },
})

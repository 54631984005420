export const ShareProjectActions = {
  START_SHARE_PROJECT: 'project/START_SHARE_PROJECT',
  DID_ACTIVE_ROW: 'project/DID_ACTIVE_ROW', // TODO temporary. delete when upgrade share modal
}

export const startShareProject = (id) => ({
  type: ShareProjectActions.START_SHARE_PROJECT,
  id,
})

// TODO temporary. delete when upgrade share modal
export const didActiveRow = (id) => ({
  type: ShareProjectActions.DID_ACTIVE_ROW,
  id,
})

import React, { FC, useState, useMemo } from 'react'
import { Tabs, Typography, SwitchCase, colors, fonts } from 'legacy/shared'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { Box } from '@material-ui/core'
import { DataView } from './DataView'
import { selectGeneratedData } from './selectors'

interface IDataTabsProps {
  data: any
}

const tabs = [
  {
    id: 'functionalMapping',
    label: <Typography>Functional mapping</Typography>,
  },
  {
    id: 'sql',
    label: <Typography>Sql join statement</Typography>,
  },
]

export const DataTabs: FC<IDataTabsProps> = ({ data: rowData }) => {
  const [selectedTab, updateTab] = useState('functionalMapping')
  const classesTabs = useStyles()
  const handleChange = (_: MouseEvent, newValue: string) => {
    updateTab(newValue)
  }

  const { data: generatedData, rule_result_list: sqlData } = rowData

  const generatedTableData: any = useMemo(
    () => selectGeneratedData(generatedData),
    [generatedData]
  )

  const commonData = useMemo(() => {
    return Array.isArray(sqlData)
      ? sqlData.map((el: any, idx: number) => ({
          title: generatedTableData[idx] ? generatedTableData[idx].title : '',
          sqlData: el,
          generatedData: generatedTableData[idx],
        }))
      : []
  }, [rowData, generatedTableData])

  return (
    <Box py={1} px={5} className={classesTabs.dataTabs}>
      <Tabs
        tabs={tabs}
        onChange={handleChange}
        classes={{ root: classesTabs.rootTabs }}
        value={selectedTab}
      />
      <SwitchCase value={selectedTab}>
        {{
          functionalMapping: <DataView data={commonData} />,
          sql: <DataView sqlOnly data={commonData} />,
          error: <Alert severity="error">Data is not available</Alert>,
        }}
      </SwitchCase>
    </Box>
  )
}

const useStyles = makeStyles({
  dataTabs: {
    font: fonts.primary,
    backgroundColor: colors.lightBlue,
  },
  rootTabs: {
    backgroundColor: colors.lightBlue,
  },
})

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import HeaderComponent from 'layouts/Header'

import PageContainer from 'layouts/PageContainer'
import { Button } from 'UIkit/Button/Button'

import { pushRoute, Routes } from 'legacy/utils'
import CleanRooms from './CleanRooms'

const useStyles = makeStyles({
  headerButton: {
    marginLeft: '1.5rem',
  },
})

const CleanRoomsPage = () => {
  const classes = useStyles()
  return (
    <>
      <HeaderComponent title="Data Clean Rooms">
        <Button
          className={classes.headerButton}
          onClick={() => pushRoute(Routes.CleanRoomBuildStep('dataset'))}
        >
          Build New Data Clean Room
        </Button>
      </HeaderComponent>
      <PageContainer>
        <CleanRooms />
      </PageContainer>
    </>
  )
}

export default CleanRoomsPage

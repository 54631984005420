import React, { memo, useEffect, useRef, useCallback } from 'react'
import _ from 'lodash'
import * as d3 from 'd3-legacy'
import { connect } from 'react-redux'
import { usePrevious } from '../services/hooks'
import { updateDataset } from './Projects/actions'
import {
  prepareDomains,
  updateAxes,
  renderBarChart,
  renderLineChart,
  buildData,
} from './Projects/chartComponents'

import './Chart.scss'

const Chart = memo(
  (props) => {
    const {
      _from,
      index,
      height: __height,
      transform_data,
      xAxisData,
      density_support,
      plot_type,
      density_arr,
      name,
      truncated,
      settings,
      dataset_id,
    } = props.config
    const {
      disableDragBars,
      isBrush,
      brushValue,
      activeTab,
      updateDataset,
      handleChangeBrush,
      from = '',
    } = props

    const _height = __height || 200
    const margin = {
      top: 10,
      right: 25,
      bottom: 25,
      left: 40,
    }
    const width = 300 - margin.left - margin.right
    const height = _height - margin.top - margin.bottom

    const propsConfigRef = usePrevious(props.config)
    const svgRef = useRef()
    const svg = useRef()
    const g = useRef()
    const x = useRef()
    const x1 = useRef()
    const y = useRef()
    const bandwidth = useRef()
    const temp_transform_arr = useRef()
    const temp_keys = useRef()
    const tooltip = useRef()
    const brush = useRef()

    useEffect(() => {
      initGraph()
    }, [])

    useEffect(() => {
      if (isBrush && _.isEqual(propsConfigRef, props.config)) {
      } else {
        // build data
        const { transform_arr, keys } = _buildData({
          transform_data,
          xAxisData,
          plot_type,
        })
        temp_transform_arr.current = transform_arr
        temp_keys.current = keys

        // update domains
        _prepareDomains({
          x,
          x1,
          y,
          width,
          height,
          bandwidth,
          truncated,
          transform_arr,
          keys,
          plot_type,
          xAxisData,
          density_support,
          density_arr,
        })
        _renderBarChart({
          data: transform_arr,
          col_name: name,
          svg: svg.current,
          g: g.current,
          tooltip: tooltip.current,
          x: x.current,
          x1: x1.current,
          y: y.current,
          keys,
          activeTab,
          plot_type,
          disableDragBars,
          truncated,
          width,
          height,
          margin,
          settings,
          temp_transform_arr: temp_transform_arr.current,
          temp_keys: temp_keys.current,
          dataset_id,
          updateDataset,
        })
        if (plot_type === 'density' && density_support) {
          _renderLineChart({
            g,
            x,
            y,
            density_arr,
            density_support,
            bandwidth,
          })
        }
        // update axes
        _updateAxes({
          svg: svg.current,
          margin,
          height,
          x: x.current,
          y: y.current,
          plot_type,
        })
      }
    }, [props.config])

    useEffect(() => {
      if (isBrush && brushValue?.min && brushValue?.max) updateBrush()
    }, [brushValue])

    const _buildData = useCallback((props) => buildData(props))
    const _prepareDomains = useCallback((props) => prepareDomains(props))
    const _renderBarChart = useCallback((props) => renderBarChart(props))
    const _renderLineChart = useCallback((props) => renderLineChart(props))
    const _updateAxes = useCallback((props) => updateAxes(props))

    const initGraph = useCallback(() => {
      tooltip.current = d3.select('.tooltip')

      if (!tooltip.current.node()) {
        tooltip.current = d3
          .select('body')
          .append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
      }

      svg.current = d3
        .select(svgRef.current)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)

      // x axis
      svg.current.append('g').attr('class', 'axis axis-x')

      // y axis
      svg.current.append('g').attr('class', 'axis axis-y')

      g.current = svg.current
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      // add bar chart
      g.current.append('g').attr('class', 'barChart')

      // add line chart
      g.current.append('g').attr('class', 'lines')

      if (isBrush) {
        renderBrush()
      }
    })

    const renderBrush = () => {
      brush.current = d3
        .brushX()
        .extent([
          [0, 0],
          [width, height],
        ])
        .on('brush end', updateChart)

      g.current
        .append('defs')
        .append('svg:clipPath')
        .attr('id', 'clip')
        .append('svg:rect')
        .attr('width', width)
        .attr('height', height)
        .attr('x', 1)
        .attr('y', 1)

      const zone = g.current.append('g').attr('clip-path', 'url(#clip)')

      zone
        .append('rect')
        .attr('width', width)
        .attr('height', height)
        .style('fill', 'transparent')

      zone.append('g').attr('class', 'brush').call(brush.current)

      function updateChart() {
        const extent = d3.event.selection
        // If no selection, back to initial coordinate. Otherwise, update X axis domain
        if (!extent) {
          handleChangeBrush(0, 0)
        } else {
          handleChangeBrush(
            x.current.invert(extent[0]),
            x.current.invert(extent[1])
          )
        }
      }
    }

    const updateBrush = useCallback(() => {
      const brushSelect = g.current.select('.brush')
      if (+brushValue?.min < +brushValue?.max) {
        brushSelect.call(
          brush.current.move,
          [brushValue.min, brushValue.max].map(x.current)
        )
      }
      brushSelect.select('rect.selection').style('fill', '#fb034e')
    })

    return (
      <div
        className="rtable-charts"
        style={{
          height: _from && _from === 'drop_component' ? 130 : _height,
          position: 'relative',
        }}
        id={index || ''}
      >
        <svg ref={svgRef} />
      </div>
    )
  },
  (prev, next) => _.isEqual(prev, next)
)

const mapDispatchToProps = { updateDataset }

export default connect(null, mapDispatchToProps)(Chart)

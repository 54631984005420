import React, { FC } from 'react'
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { SimpleCell } from 'components/SimpleCell/SimpleCell'
import { randomColor } from './utils'

const useStyles = makeStyles(() => ({
  userMail: {
    fontWeight: 1000,
  },
  cellPadding: {
    padding: '10px 12px',
  },
}))

interface IUserPermissionCellProps {
  id: string
  userMail: string
  status: string
  className?: string
}

export const UserPermissionCell: FC<IUserPermissionCellProps> = ({
  id,
  userMail,
  status,
  className,
}) => {
  const classes = useStyles()

  return (
    <SimpleCell
      className={classnames(className, classes.cellPadding)}
      description={status}
      before={
        <Avatar style={{ backgroundColor: randomColor(id) }}>
          {userMail.substring(0, 2)}
        </Avatar>
      }
    >
      <div className={classes.userMail}>{userMail}</div>
    </SimpleCell>
  )
}

// Hook for measure a DOM node
import { useCallback, useLayoutEffect, useState } from 'react'

export function useClientRect(ref: any) {
  const [rect, setRect] = useState(ref ? ref.current : null)

  const handleResize = useCallback(() => {
    if (!ref.current) {
      return
    }

    // Update client rect
    setRect(ref.current.getBoundingClientRect())
  }, [ref])

  useLayoutEffect(() => {
    const element = ref.current
    if (!element) {
      return
    }

    handleResize()

    // @ts-ignore
    if (typeof ResizeObserver === 'function') {
      // @ts-ignore
      let resizeObserver = new ResizeObserver(() => handleResize())
      resizeObserver.observe(element)

      return () => {
        if (!resizeObserver) {
          return
        }

        resizeObserver.disconnect()
        resizeObserver = null
      }
    }
    // Browser support, remove freely
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref.current])
  return [rect]
}

import React from 'react'
import * as d3 from 'd3'

const tooltipView = ({ lineColor, x, y }) =>
  `
    <ul class="t-ul-style">
      <li class="t-li-style" ><div class="t-li-legend" style="background-color: ${lineColor}"></div><div>x: ${x}</div></li>
      <li class="t-li-style" ><div class="t-li-legend" style="background-color: ${lineColor}"></div><div>y: ${y}</div></li>
    </ul>
  `

export const LinerPlot = ({
  data,
  area,
  showArea,
  axesLabels = null,
  lineColor = 'black',
  lineAreaColor = '#69b3a2',
  plotBackgroundColor = '',
  ticksize = 1,
}) => {
  const ref = React.useRef()

  const margin = {
    top: 20,
    right: 30,
    bottom: 30,
    left: axesLabels !== null && axesLabels.y ? 70 : 60,
  }
  const width = 990 - margin.left - margin.right
  const height = 400 - margin.top - margin.bottom

  const { x: xMin, y: yMin } = data[0]
  const { x: xMax, y: yMax } = data[data.length - 1]

  const xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, width])
  const yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0])

  const areaGenerator = d3
    .area()
    .x(function (d) {
      return xScale(d.x)
    })
    .y0(function (d) {
      return yScale(d.y0)
    })
    .y1(function (d) {
      return yScale(d.y1)
    })

  const line = d3
    .line()
    .x(function (d) {
      return xScale(d.x)
    })
    .y(function (d) {
      return yScale(d.y)
    })

  React.useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // right plot border
    svg
      .append('line')
      .style('stroke', 'rgba(0, 0, 0, 0.05)')
      .style('stroke-width', 1)
      .attr('x1', width + 1)
      .attr('y1', 0)
      .attr('x2', width + 1)
      .attr('y2', height)

    // top plot border
    svg
      .append('line')
      .style('stroke', 'rgba(0, 0, 0, 0.05)')
      .style('stroke-width', 1)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', 0)

    const tooltip = d3 // create tooltip
      .select('#linerPlot')
      .append('div')
      .attr('id', 'tooltip_' + 'linerPlot')
      .attr('class', 'tooltip')
      .style('opacity', 0)

    const xAxis = svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale).tickSize(ticksize))

    // xAxis tick text styles
    xAxis.selectAll('text').attr('dy', '1em')

    if (axesLabels !== null && axesLabels.x) {
      xAxis
        .append('text')
        .attr('transform', `translate(${width / 2} ,${margin.bottom})`)
        .style('text-anchor', 'middle')
        .style('fill', 'black')
        .style('font-size', '.7rem')
        .style('font-weight', 600)
        .text(axesLabels.x)
    }

    const yAxis = svg.append('g').call(d3.axisLeft(yScale).tickSize(ticksize))

    // yAxis tick text styles
    yAxis.selectAll('text').attr('dx', '-.2em')

    if (axesLabels !== null && axesLabels.y) {
      yAxis
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 10 - margin.left)
        .attr('x', 0 - height / 2)
        .style('text-anchor', 'middle')
        .style('fill', 'black')
        .style('font-size', '.7rem')
        .style('font-weight', 600)
        .text(axesLabels.y)
    }

    const clip = svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', 1)
      .attr('y', 1)

    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height],
      ])
      .on('end', updateChart)

    const zone = svg.append('g').attr('clip-path', 'url(#clip)')

    zone
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .style('fill', plotBackgroundColor)

    if (showArea) {
      zone
        .append('path')
        .datum(area)
        .attr('id', 'area1')
        .attr('fill', lineAreaColor)
        .attr('fill-opacity', 1)
        .attr('stroke', 'none')
        .attr('d', areaGenerator)
    }

    zone
      .append('g')
      .append('path')
      .datum(data)
      .attr('id', 'line1')
      .attr('d', line)
      .style('fill', 'none')
      .style('stroke', lineColor)
      .attr('stroke-width', 1.5)

    const focus = zone
      .append('g')
      .attr('class', 'focus')
      .style('display', 'none')

    focus
      .append('line')
      .attr('stroke-dasharray', '3, 3')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', height)
      .attr('stroke', lineColor)

    focus.append('circle').attr('r', 5).attr('fill', lineColor)
    focus.append('circle').attr('r', 4).attr('fill', '#fff')

    zone
      .append('g')
      .attr('class', 'brush')
      .call(brush)
      .on('mouseover', function (d, i) {
        focus.style('display', null)
        tooltip.transition().duration(200).style('opacity', 0.9)
      })
      .on('mouseout', function (d) {
        focus.style('display', 'none')
        tooltip.transition().duration(500).style('opacity', 0)
      })
      .on('mousemove', mousemove)

    function mousemove(evt) {
      const bisectDate = d3.bisector(function (d) {
        return d.x
      }).left
      const x0 = xScale.invert(d3.pointer(evt, this)[0])
      const i = bisectDate(data, x0, 1)
      const d0 = data[i - 1]
      const d1 = data[i]
      const d = x0 - d0.x > d1.x - x0 ? d1 : d0
      focus.attr('transform', `translate(${xScale(d.x)},${yScale(d.y)})`)

      const tpl = tooltipView({ lineColor, x: d.x, y: d.y })

      const left =
        width - xScale(d.x) < 100
          ? xScale(d.x) + margin.left - 110
          : xScale(d.x) + margin.left + 10

      const top =
        height - yScale(d.y) < 20
          ? yScale(d.y) + margin.top - 45
          : yScale(d.y) < 20
          ? yScale(d.y) + margin.top + 5
          : yScale(d.y) + margin.top - 20

      tooltip.html(tpl).style('left', `${left}px`).style('top', `${top}px`)
    }

    let idleTimeout
    function idled() {
      idleTimeout = null
    }

    function updateChart(evt) {
      const extent = evt.selection
      // If no selection, back to initial coordinate. Otherwise, update X axis domain
      if (!extent) {
        if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)) // This allows to wait a little bit
        xScale.domain([xMin, xMax])
      } else {
        xScale.domain([xScale.invert(extent[0]), xScale.invert(extent[1])])
        zone.select('.brush').call(brush.move, null) // This remove the grey brush area as soon as the selection has been done
      }

      // Update axis and circle position
      xAxis
        .transition()
        .duration(1000)
        .call(d3.axisBottom(xScale).tickSize(ticksize))
      zone.select('#area1').transition().duration(1000).attr('d', areaGenerator)
      zone.select('#line1').transition().duration(1000).attr('d', line)
    }
  }, [])

  React.useEffect(() => {
    if (!showArea) {
      d3.select('#area1').attr('fill-opacity', 0)
    } else {
      d3.select('#area1').attr('fill-opacity', 1)
    }
  }, [showArea])

  return (
    <div id="linerPlot" style={{ position: 'relative' }}>
      <svg ref={ref} />
    </div>
  )
}

import { takeLatest, put, call } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'

import { pushRoute, Routes } from 'legacy/utils'
import { dataSourceService } from 'legacy/utils/services'

import { notificationActions } from 'services/notification/reducer'

import { newProjectActions } from './reducer'

function* handleCreateProject({ payload }: PayloadAction<{ id: string }>) {
  try {
    const newProject = yield call(dataSourceService.createDatasourceProject, {
      id: payload.id,
    })
    yield put(newProjectActions.didCreateProject())
    pushRoute(Routes.Project(newProject.id))
  } catch (error) {
    yield put(newProjectActions.didCreateProjectFail())
    yield put(
      notificationActions.showNotification({
        message: error.message || error,
        severity: 'warning',
      })
    )
  }
}

export default function* watchCreateProjectSaga() {
  yield takeLatest(newProjectActions.createProject, handleCreateProject)
}

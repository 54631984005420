import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { selectModalContentConfig } from 'services/modal/modalSelectors'
import { Button } from 'UIkit/Button/Button'
import { modalActions } from 'services/modal/reducer'
import { TextField, Typography, Form, spacing } from '../../shared'
import { ModalButtonContainer } from '../../../components/Modals/ModalButtonContainer'
import { getIsLoadingUpdateProjectState } from './datasetsSelectors'
import { datasetsActions } from './reducer'
import { createValidatorWithSchema } from '../../../utils/validate'

const useStyles = makeStyles({
  errorMsg: {
    marginTop: spacing.gutter * 2,
  },
})

const getConstraints = () => ({
  title: {
    presence: true,
    length: {
      minimum: 1,
      message: 'Empty project name',
    },
  },
  description: {
    presence: true,
    length: {
      minimum: 1,
      message: 'Empty project description',
    },
  },
})

export const ProjectEdit = ({
  contentConfig,
  isLoading,
  hideModal,
  updateDatasetInfo,
}) => {
  const { id, title, description } = contentConfig.data
  const classes = useStyles()
  const loading = isLoading

  const validate = createValidatorWithSchema(getConstraints())

  const handleSubmit = (values) => {
    if (values.title !== title || values.description !== description) {
      updateDatasetInfo({
        id,
        data: {
          title: values.title,
          description: values.description,
        },
      })
    } else {
      hideModal()
    }
  }

  return (
    <>
      <Form
        initialValues={{
          title: title || '',
          description: description || '',
        }}
        onSubmit={(values) => handleSubmit(values)}
        validate={validate}
      >
        {({ values, errors, handleChange, submitForm }) => (
          <>
            <TextField
              name="title"
              label="Name"
              fullWidth
              value={values.title}
              onChange={handleChange}
              error={Boolean(errors.title)}
              style={{ marginBottom: '1rem' }}
            />
            <TextField
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={values.description}
              onChange={handleChange}
              error={Boolean(errors.description)}
            />
            <ModalButtonContainer>
              <Button variant="outlined" onClick={hideModal}>
                Cancel
              </Button>
              <Button loading={loading} onClick={submitForm}>
                Save
              </Button>
            </ModalButtonContainer>
            {errors.title || errors.description ? (
              <Typography color="red" className={classes.errorMsg}>
                {errors.title || errors.description}
              </Typography>
            ) : null}
          </>
        )}
      </Form>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  contentConfig: selectModalContentConfig,
  isLoading: getIsLoadingUpdateProjectState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
  updateDatasetInfo: (data) => datasetsActions.updateDatasetInfo(data),
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEdit)

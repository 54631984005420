import { SandboxesOSActions } from './actions'
import { SandboxesBuildActions } from '../actions'
import { SandboxesConfirmActions } from '../confirm/actions'

const getSandboxOSDefaultState = () => ({
  selectedOSById: {},
})

export default (state = getSandboxOSDefaultState(), action) => {
  switch (action.type) {
    case SandboxesOSActions.SELECT_OS: {
      return { ...state, selectedOSById: action.os }
    }
    case SandboxesBuildActions.CANCEL_BUILD_SANDBOX:
    case SandboxesConfirmActions.DID_BUILD_SANDBOX: {
      return getSandboxOSDefaultState()
    }

    default:
      return state
  }
}

import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { globalActions } from 'services/global/reducer'
import { selectProfileImageUrl } from 'services/profile/selectors'
import { notificationActions } from 'services/notification/reducer'

import { dataSetsService } from 'legacy/utils/services'
import { trackEvent } from 'legacy/utils'

import { uploadDatasetActions } from './reducer'
import { UploadDatasetPayload } from './types'

function* handleUploadDataset({
  payload,
}: PayloadAction<UploadDatasetPayload>) {
  try {
    const dataset = yield call(dataSetsService.uploadNewDataset, {
      data: payload,
    })

    const avatarUrl = yield select(selectProfileImageUrl)
    // turn on Segment analytics
    trackEvent('Dataset Uploaded')
    //
    yield put(uploadDatasetActions.didUploadDataset({ ...dataset, avatarUrl }))
    yield put(globalActions.increaseUploadedProjectNum())
    yield delay(400)
    yield put(uploadDatasetActions.reset())
    yield put(
      notificationActions.showNotification({
        message: 'Your dataset was successfully uploaded',
      })
    )
  } catch (error) {
    yield put(uploadDatasetActions.didUploadDatasetFail())
    yield delay(400)
    yield put(uploadDatasetActions.reset())
    yield put(
      notificationActions.showNotification({
        message: error.message,
        severity: 'error',
      })
    )
  }
}

export default function* watchUploadDatasetSaga() {
  yield takeLatest(uploadDatasetActions.uploadDataset.type, handleUploadDataset)
}

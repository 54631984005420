import { createSelector } from 'reselect'
import { last } from 'lodash'
import { BuildingStepsOrder } from './constants'

export const getSandboxState = (state) => state.sandboxes

const defineCurrentStepIndex = (order) => {
  const urlStep = last(window.location.pathname.split('/'))
  return order.indexOf(urlStep)
}

const defineNextStep = (order) => {
  const currentStepIndex = defineCurrentStepIndex(order)
  return order[currentStepIndex + 1]
}

const definePrevStep = (order) => {
  const currentStepIndex = defineCurrentStepIndex(order) || 1
  return order[currentStepIndex - 1]
}

export const getPrevStep = () => definePrevStep(BuildingStepsOrder)

export const getNextStep = () => defineNextStep(BuildingStepsOrder)

export const getSandboxBuildState = createSelector(
  getSandboxState,
  (state) => state.build
)

export const getSandboxBuildStepsState = createSelector(
  getSandboxBuildState,
  (state) => state.steps
)

export const getEditableSteps = createSelector(
  getSandboxBuildStepsState,
  (state) => Object.keys(state).filter((step) => state[step].isEditable)
)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { Typography } from '../../shared'

const useStyles = makeStyles({
  root: {},
  rootBorder: {
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: '1.5rem',
  },
  titleMargin: {
    marginBottom: '1rem',
  },
  borderTop: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
})

export const SectionLayout = ({
  children,
  className = '',
  borderTop = false,
  borderBottom = false,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(
        classes.root,
        className,
        { [classes.borderTop]: borderTop },
        { [classes.borderBottom]: borderBottom }
      )}
    >
      {children}
    </div>
  )
}

export const SectionLayoutBorder = ({
  children,
  className = '',
  style = {},
}) => {
  const classes = useStyles()
  return (
    <div
      className={classnames(classes.rootBorder, className)}
      style={{ ...style }}
    >
      {children}
    </div>
  )
}

export const SectionTitle = ({ title = '', className = '' }) => {
  const classes = useStyles()
  return (
    <Typography
      size="bodyBold"
      className={classnames(classes.titleMargin, className)}
    >
      {title}
    </Typography>
  )
}

import * as d3 from 'd3-legacy'

const prepareDomains = ({
  x,
  x1,
  y,
  width,
  height,
  bandwidth,
  truncated,
  transform_arr,
  keys,
  plot_type,
  xAxisData,
  density_support,
  density_arr,
}) => {
  if (plot_type === 'density') {
    const allData = xAxisData.concat(density_support || [])
    const xDomain = d3.extent(allData, (d) => d)
    const xDelta = Math.abs(xDomain[1] - xDomain[0]) / 20
    const xDomainHist = d3.extent(xAxisData, (d) => d)
    const k = (xDomainHist[1] - xDomainHist[0]) / (xDomain[1] - xDomain[0])

    x.current = d3
      .scaleLinear()
      // .domain([xDomain[0] - xDelta, xDomain[1] + xDelta])
      .domain([xDomain[0], xDomain[1] + xDelta])
      .rangeRound([0, width])
    // x.current.bandwidth = () => (this.width * k) / xAxisData.length;
    bandwidth.current = (width * k) / xAxisData.length
  } else {
    const shallowXAxisData = truncated ? [...xAxisData, '...'] : [...xAxisData]
    x.current = d3.scaleBand().domain(shallowXAxisData).rangeRound([0, width])

    bandwidth.current = x.current.bandwidth()
  }

  x1.current = d3
    .scaleBand()
    .rangeRound([0, bandwidth.current])
    .domain(keys.map((d) => d.name))
    .padding(0.2)

  y.current = d3.scaleLinear().rangeRound([height, 0])

  const max_bar_values = d3.max(transform_arr, (d) =>
    d3.max(keys, (key) => d[key.name])
  )
  const min_bar_values = d3.min(transform_arr, (d) =>
    d3.min(keys, (key) => d[key.name])
  )

  if (plot_type === 'density' && density_arr.length) {
    let max_values = ''
    let min_values = ''
    if (density_arr.length > 1) {
      max_values = Math.max(
        ...density_arr[0].values,
        ...density_arr[1].values,
        max_bar_values
      )
      min_values = Math.min(
        ...density_arr[0].values,
        ...density_arr[1].values,
        min_bar_values
      )
    } else {
      max_values = Math.max(...density_arr[0].values, max_bar_values)
      min_values = Math.min(...density_arr[0].values, min_bar_values)
    }

    const _min_value = min_values < 0 ? min_values : 0
    y.current.domain([_min_value, max_values]).nice()
  } else {
    const _min_value = min_bar_values < 0 ? min_bar_values : 0
    y.current.domain([_min_value, max_bar_values])
  }
}

const prepareDomainsHorizontal = ({
  svg,
  x,
  x1,
  y,
  width,
  height,
  margin,
  bandwidth,
  truncated,
  transform_arr,
  keys,
  xAxisData,
}) => {
  const shallowXAxisData = truncated ? [...xAxisData, '...'] : [...xAxisData]
  // Y-axis
  x.current = d3
    .scaleBand()
    .domain(shallowXAxisData.reverse())
    .rangeRound([height, 0])

  if (x.current.bandwidth() < 30) {
    const _bandwidth = 25
    const overHeight = shallowXAxisData.length * _bandwidth + 5
    x.current.rangeRound([overHeight, 0])

    svg.attr('height', overHeight + margin.top + margin.bottom - 20)
    bandwidth.current = _bandwidth
  } else {
    svg.attr('height', height)
    bandwidth.current = x.current.bandwidth()
  }

  // Y-axis
  x1.current = d3
    .scaleBand()
    .rangeRound([0, bandwidth.current])
    .domain(keys.map((d) => d.name))
    .padding(0.2)

  // X-axis
  y.current = d3.scaleLinear().rangeRound([0, width])

  const max_bar_values = d3.max(transform_arr, (d) =>
    d3.max(keys, (key) => d[key.name])
  )
  const min_bar_values = d3.min(transform_arr, (d) =>
    d3.min(keys, (key) => d[key.name])
  )

  const _min_value = min_bar_values < 0 ? min_bar_values : 0
  y.current.domain([_min_value, max_bar_values])
}

const updateYDomain = (transform_arr, keys, y) => {
  const max_bar_values = d3.max(transform_arr, (d) =>
    d3.max(keys, (key) => d[key.name])
  )
  const min_bar_values = d3.min(transform_arr, (d) =>
    d3.min(keys, (key) => d[key.name])
  )

  const _min_value = min_bar_values < 0 ? min_bar_values : 0
  y.domain([_min_value, max_bar_values])
}

const updateHorizontalYDomain = (transform_arr, keys, y) => {
  const max_bar_values = d3.max(transform_arr, (d) =>
    d3.max(keys, (key) => d[key.name])
  )
  const min_bar_values = d3.min(transform_arr, (d) =>
    d3.min(keys, (key) => d[key.name])
  )

  const _min_value = min_bar_values < 0 ? min_bar_values : 0
  y.domain([_min_value, max_bar_values])
}

export {
  prepareDomains,
  prepareDomainsHorizontal,
  updateYDomain,
  updateHorizontalYDomain,
}

import React, { useMemo } from 'react'
import { pick, isEqual } from 'lodash'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import MUITable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import { Spinner } from '../spinner'
import { Typography } from '../typography'

export const useTableStyles = makeStyles({
  root: {
    position: 'relative',
    minHeight: '150px',
    '& .MuiTableRow-root': {
      backgroundColor: '#fff',
    },
    '& .MuiTableRow-hover': {
      '&:hover': {
        backgroundColor: '#f7f8fe',
      },
    },
  },
  selected: {
    backgroundColor: '#e4e7f7!important',

    '&:hover': {
      backgroundColor: '#e4e7f7!important',
    },
  },
})

function descendingComparator(a1, b1, orderBy) {
  let a = a1[orderBy]
  let b = b1[orderBy]

  if (orderBy === 'created_at') {
    a = new Date(a1[orderBy]).getTime()
    b = new Date(b1[orderBy]).getTime()
  }
  if (b < a) {
    return -1
  }
  if (b > a) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const MemoTableCell = React.memo(
  (props) => {
    const { style = {} } = props
    return (
      <TableCell
        classes={{ root: props.tableClasses.cell.root }}
        style={{ ...style }}
      >
        {props.children(props)}
      </TableCell>
    )
  },
  (prevP, nextP) => {
    if (isEqual(prevP.row, nextP.row) && isEqual(prevP.style, nextP.style)) {
      if (
        prevP.checked !== nextP.checked ||
        (!isEqual(prevP.customProps, nextP.customProps) &&
          nextP.row.id === nextP.customProps.id)
      ) {
        return false
      }
      return true
    }
    return false
  }
)

export const filterData = (data, searchString, filterKeys) => {
  if (searchString === '') {
    return data
  }
  return data.filter((item) => {
    return Object.values(pick(item, filterKeys))
      .filter((val) => typeof val === 'string')
      .some((val) => val.toLowerCase().includes(searchString.toLowerCase()))
  })
}

export const TableSpinner = () => (
  <div
    style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      zIndex: 1,
      opacity: 1,
      backgroundColor: 'hsla(0,0%,100%,.75)',
    }}
  >
    <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
      <Spinner size={24} />
    </div>
  </div>
)

export const EmptyMessage = ({ emptyMessage }) => (
  <div
    style={{
      padding: '12px',
      border: '1px rgb(224, 224, 224) solid',
      borderTop: 'none',
    }}
  >
    <Typography size="body">{emptyMessage}</Typography>
  </div>
)

export const Table = (props) => {
  const {
    headerRow,
    bodyRow,
    rows,
    loading,
    filterString = '',
    selectedRows = {},
    rowIdKey = 'id',
    defaultSort = { name: 'name', order: 'asc' },
    cellClasses = {},
    className = '',
    emptyMessage = '',
    selectedRow,
    hover = false,
    onClickRow = () => {},
    customProps = {},
    dataTestidPrefix = '',
  } = props
  const classes = useTableStyles()
  const [order, setOrder] = React.useState(defaultSort.order)
  const [orderBy, setOrderBy] = React.useState(defaultSort.name)

  const handleSort = (column) => {
    const isAsc = orderBy === column && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(column)
  }

  const filterKeys = useMemo(
    () => headerRow.map((header) => header.id).filter(Boolean),
    [headerRow]
  )
  const tableClasses = {
    cell: { root: cellClasses.root ? cellClasses.root : '' },
  }

  return (
    <div className={classnames(classes.root, className)}>
      <TableContainer>
        <MUITable aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerRow.map((header) =>
                header.sortable ? (
                  <TableCell
                    key={header.id}
                    sortDirection={orderBy === header.id ? order : false}
                    classes={{
                      root: classnames(
                        tableClasses.cell.root,
                        header.align ? header.align : '',
                        header.width ? header.width : ''
                      ),
                      head: header.class,
                    }}
                    style={header.width ? { width: header.width } : {}}
                    align={header.align || 'left'}
                  >
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={() => handleSort(header.id)}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={header.id}
                    classes={{
                      root: classnames(
                        tableClasses.cell.root,
                        header.align ? header.align : '',
                        header.width ? header.width : ''
                      ),
                      head: header.class,
                    }}
                    align={header.align || 'left'}
                  >
                    {header.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              filterData(rows, filterString, filterKeys),
              getComparator(order, orderBy)
            ).map((row) => {
              const isSelectedRow = selectedRow === row.id
              return (
                <TableRow
                  data-testid={`${dataTestidPrefix}-table-row`}
                  key={row[rowIdKey]}
                  hover={hover}
                  selected={isSelectedRow}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => onClickRow(event, row)}
                >
                  {bodyRow.map((key, i) => {
                    if (typeof key === 'string') {
                      return (
                        <MemoTableCell
                          key={`cell-${row[rowIdKey]}-${key}`}
                          row={{ ...row }}
                          accessor={key}
                          tableClasses={tableClasses}
                        >
                          {() => row[key]}
                        </MemoTableCell>
                      )
                    }
                    const CustomElem = key
                    return (
                      <MemoTableCell
                        key={`cell-${row[rowIdKey]}-${i}`}
                        row={{ ...row }}
                        checked={Boolean(selectedRows[row[rowIdKey]])}
                        tableClasses={tableClasses}
                        customProps={{ ...customProps }}
                      >
                        {({ row, checked }) => (
                          <CustomElem row={row} checked={checked} />
                        )}
                      </MemoTableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MUITable>
        {loading ? <TableSpinner /> : null}
      </TableContainer>
      {emptyMessage && !rows.length && (
        <EmptyMessage emptyMessage={emptyMessage} />
      )}
    </div>
  )
}

import React, { FC } from 'react'
import { Card, Grid, Modal as MUIModal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { colors, spacing } from '../../legacy/shared/theme'
import { MUIModalProps } from './Modal'

export const ModalCustom: FC<MUIModalProps> = ({
  open,
  hideModal,
  children
}) => {
  const classes = useModalStyles()

  return (
    <MUIModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={hideModal}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        classes={{
          container: classes.gridContainer,
        }}
      >
        <Card
          classes={{
            root: classes.card,
          }}
        >
          {children}
        </Card>
      </Grid>
    </MUIModal>
  )
}

const useModalStyles = makeStyles(() => ({
  gridContainer: {
    minHeight: '335px',
    maxWidth: '543px',
    margin: '0 auto',
    marginTop: '12vh',
    outline: 'none',
  },
  card: {
    width: 'auto',
    height: 'auto',
    paddingTop: 0,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  cardContent: {
    paddingTop: spacing.gutter * 2,
    maxHeight: '45em',
  },
  cardContentNoPadding: {
    paddingTop: 0,
    paddingBottom: '0px !important',
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingTop: spacing.gutterLarge,
  },
  contentNoPadding: {
    padding: 0,
  },
  header: {
    display: 'block',
    position: 'static',
    height: 'auto',
    paddingTop: 20,
    paddingBottom: 10,
    backgroundColor: 'transparent',
    textAlign: 'center',
    zIndex: 10,
  },
}))

ModalCustom.displayName = 'ModalCustom'

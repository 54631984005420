import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Typography } from 'legacy/shared'
import { Box } from 'UIkit'

const useStyles = makeStyles({
  sampleRow: {
    minHeight: '30px',
    padding: '.3rem .6rem',
    textAlign: 'end',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

export const SampleList = ({ sample = [] }: any) => {
  const classes = useStyles()
  return (
    <div>
      {sample.map((value: any, index: number) => (
        <Box borderTop={1} key={index}>
          <Typography className={classes.sampleRow}>{value}</Typography>
        </Box>
      ))}
    </div>
  )
}

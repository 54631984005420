import { createSelector } from 'reselect'
import { trainingStatus } from './constants'
import { processStatus } from './biasAndQuality/constants'
import { PERMISSION_IDS } from './projectPermissions/constants'
import User from '../Account/User'
import { getDatasetsGrouped } from '../Datasets/datasetsSelectors'

// TODO refactor not to use state.data slice
export const getProjectState1 = (state: any) => state.data
export const getProjectLoadingReportState = createSelector(
  getProjectState1,
  (state) => state.loadingReport
)
//

export const getProjectState = (state: any) => state.project

export const getProjectListState = createSelector(
  getProjectState,
  (state) => state.project
)

export const getProjectDataState = createSelector(
  getProjectListState,
  (state) => state.data
)

export const getProjectLoadingState = createSelector(
  getProjectListState,
  (state) => state.loadingDataset
)

export const getProjectId = createSelector(
  getProjectDataState,
  (data) => data.dataset_id
)

export const getProjectTitle = createSelector(
  getProjectDataState,
  (data) => data.title || ''
)

export const getProjectSettings = createSelector(
  getProjectDataState,
  (data) => data.settings || {}
)

export const getIsFromDatasource = createSelector(
  getProjectDataState,
  (data) => data.source_name === 'Datasource'
)

export const getProjectSynthDataState = createSelector(
  getProjectListState,
  (state) => state.synthData
)

export const getProjectPreviewDataState = createSelector(
  getProjectListState,
  (state) => state.previewData
)

export const getProjectStatusState = createSelector(
  getProjectListState,
  (state) => state.status
)

export const getSynthTabIsEnable = createSelector(
  getProjectStatusState,
  getProjectPreviewDataState,
  (status, previewData) => {
    if (status === trainingStatus.Ready && previewData && previewData.meta) {
      return true
    }
    return false
  }
)

export const getProjectPreprocessingStatusState = createSelector(
  getProjectListState,
  (state) => state.preprocessingStatus
)

export const getProjectPreviewIterationState = createSelector(
  getProjectPreviewDataState,
  (previewData) => {
    if (previewData && previewData.iteration && previewData.total_iterations) {
      return (previewData.iteration / previewData.total_iterations) * 100
    }
    return null
  }
)

export const getEstimatedTimeState = createSelector(
  getProjectListState,
  (state) => state.estimatedTime
)

export const getBiasStatus = createSelector(
  getProjectListState,
  (state) => state.biasStatus
)

export const getBiasProgress = createSelector(
  getProjectListState,
  (state) => state.biasProgress
)

export const getQualityStatus = createSelector(
  getProjectListState,
  (state) => state.qualityStatus
)

export const getQualityProgress = createSelector(
  getProjectListState,
  (state) => state.qualityProgress
)

export const getNotFoundPage = createSelector(
  getProjectListState,
  (state) => state.notFoundPage
)

// TODO temporary. delete when upgrade share modal
export const getActiveRow = createSelector(
  getProjectListState,
  (state) => state.activeRow
)

export const getQualityData = createSelector(getProjectDataState, (data) => {
  if (data.meta && data.meta.quality_data) {
    return data.meta.quality_data.quality
  }
  return null
})

export const getOriginalBiasData = createSelector(
  getProjectDataState,
  (data) => {
    if (data.meta && data.meta.fairness_data) {
      return data.meta.fairness_data
    }
    return null
  }
)

export const getSynthBiasData = createSelector(
  getProjectSynthDataState,
  (synthData) => {
    if (synthData.meta && synthData.meta.fairness_data) {
      return synthData.meta.fairness_data
    }
    return null
  }
)

export const getSubTabState = createSelector(
  getProjectSynthDataState,
  User.isCETier,
  (synthData, isCETier) => {
    const tabState = {
      dataset: { disabled: true },
      report: { disabled: true },
    }
    if (synthData.meta) {
      tabState.dataset = { disabled: false }
      if (!isCETier) {
        tabState.report = { disabled: false }
      }
    }
    return tabState
  }
)

export const getIsTargetColumnsAreTheSame = createSelector(
  getOriginalBiasData,
  getSynthBiasData,
  (originalBias, synthBias) => {
    if (originalBias && synthBias) {
      return originalBias.target === synthBias.target || false
    }
    return false
  }
)

export const getIsSettingEnable = createSelector(
  getProjectListState,
  (state) => {
    if (
      state.status !== trainingStatus.Training &&
      state.qualityStatus !== processStatus.Progress &&
      state.biasStatus !== processStatus.Progress
    ) {
      return true
    }

    return false
  }
)

export const getOriginalRowsQuantity = createSelector(
  getProjectDataState,
  (data) => {
    return Object.keys(data) && data.total_size ? data.total_size : 1000
  }
)

export const getOriginalNumRows = createSelector(
  getProjectDataState,
  (data) => {
    if (data.meta) {
      return data.meta.n_rows
    }
    return null
  }
)

export const getSynthNumRows = createSelector(
  getProjectDataState,
  (synthData) => {
    if (synthData.meta) {
      return synthData.meta.n_rows
    }
    return null
  }
)

export const getProjectPermissionState = createSelector(
  getProjectState,
  (project) => {
    return project.projectPermissions || {}
  }
)

export const getProjectPermissionsStateLoading = createSelector(
  getProjectPermissionState,
  (state) => state.isLoading
)

export const getProjectPermissions = createSelector(
  getProjectPermissionState,
  (state) => state.permissions || []
)

export const getProjectPermissionsForUser = createSelector(
  getProjectPermissions,
  (permissions = []) =>
    Array.isArray(permissions) &&
    permissions.find(
      ({ user_email: userMail, permission }: any) =>
        userMail === User.email && permission >= PERMISSION_IDS.CONSUMER
    )
)

export const getProjectSelectedTabState = createSelector(
  getProjectListState,
  (state) => state.selectedTab
)

export const getProjectIdModal = createSelector(
  getProjectId,
  getActiveRow,
  (projectId, activeRowId) => projectId || activeRowId
)

export const getDataset = createSelector(
  getProjectDataState,
  getDatasetsGrouped,
  getActiveRow,
  (dataset, datasets, activeRowId) => {
    if (Object.keys(dataset).length) {
      return dataset
    }
    return datasets[activeRowId]
  }
)

export const DataSourceActions = {
  FETCH_SOURCE_TABLES: 'dataSource/FETCH_SOURCE_TABLES',
}

export const fetchDataSource = (id) => ({
  type: DataSourceActions.FETCH_SOURCE_TABLES,
  id,
})

export const didFetchDataSource = (payload) => ({
  type: 'INIT_SOURCE_TABLES',
  payload,
})

export const didFetchDataSourceFail = () => ({
  type: 'INIT_SOURCE_TABLES',
  payload: [],
})

export const setQueryData = (payload) => ({
  type: 'INIT_QUERY_DATA',
  payload,
})

export const resetTableQueryData = () => ({
  type: 'dataSource/RESET_TABLE_QUERY_DATA',
})

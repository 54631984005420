import _ from '../App/Constants'
import { SandboxIcon, ProjectIcon, TemplateIcon, DataSourceIcon } from './Icons'
import { Routes } from '../../utils'
import { TestingSuitesIcon } from '../../../assets/sidebar'

const premiseTabs = [
  {
    label: 'TEMPLATES',
    path: '/templates',
    prefix: 'temp',
    enabled: true,
  },
  {
    label: 'PROJECTS',
    path: '/projects',
    prefix: 'prj',
    enabled: true,
  },
  {
    label: 'DATASOURCES',
    path: '/datasources',
    prefix: 'dts',
    enabled: true,
  },
  {
    label: 'TESTING_SUITE',
    path: '/testingsuite',
    prefix: 'ts',
    enabled: false,
  },
]

const cloudTabs = [
  {
    label: 'Data Products',
    className: 'sidebar-projects',
    path: Routes.Projects(),
    IconComponent: ProjectIcon,
    enabled: true,
  },
  {
    label: 'Product Catalogue',
    path: '/templates',
    IconComponent: TemplateIcon,
    enabled: true,
  },
  {
    label: 'Datasources',
    path: Routes.DataSources(),
    IconComponent: DataSourceIcon,
    enabled: true,
  },
  {
    label: 'Data Clean Rooms',
    path: Routes.CleanRooms(),
    IconComponent: SandboxIcon,
    enabled: true,
  },
  {
    label: 'Testing Suite',
    path: Routes.TestingSuites(),
    IconComponent: TestingSuitesIcon,
    enabled: _.IS_TESTING_SUIT_ENABLED,
  },
]

export const getSideBarTabs = (targetVersion) => {
  return (targetVersion === 'CLOUD' ? cloudTabs : premiseTabs).filter(
    (tab) => tab.enabled
  )
}

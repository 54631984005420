import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Slider as MUISlider } from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
}))

export function Slider({
  value,
  step = 0.001,
  min = 0,
  max = 0.1,
  marks = [],
  valueLabelDisplay = 'auto',
  getAriaValueText,
  disabled = false,
  className = '',
  valueLabelFormat,
  onChange = () => {},
}) {
  const classes = useStyles()

  return (
    <div className={classnames(classes.root, className)}>
      <MUISlider
        // defaultValue={10}
        value={value}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={getAriaValueText}
        aria-labelledby="discrete-slider-always"
        step={step}
        marks={marks}
        min={min}
        max={max}
        valueLabelDisplay={valueLabelDisplay}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

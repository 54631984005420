import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import User from 'legacy/components/Account/User'

import {
  TableRow,
  TableCell,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { PERMISSION_IDS } from 'legacy/components/Projects/projectPermissions/constants'
import { SimpleCell } from 'components/SimpleCell/SimpleCell'
import { ActionMenu } from './ActionMenu'
import { UserPermissionCell } from './UserPermissionCell'

const modifyStatus = (email: string, ownerEmail: string) => {
  if (email === ownerEmail) {
    return 'Owner'
  }
  if (email === User.email) {
    return 'You'
  }
  return null
}

const useStyles = makeStyles(() => ({
  cellPadding: {
    padding: '10px 12px',
  },
  spacer: {
    width: '30px',
    height: '30px',
  },
  roleSelect: {
    padding: '6px 32px',
  },
  selectRoot: {
    width: '150px',
    boxSizing: 'border-box',
  },
}))

export const PermissionRow = ({
  row,
  index,
  ownerEmail,
  rowActions,
  handleSelectRole,
}: any) => {
  const classes = useStyles()

  const { id, user_email: email, permission, status } = row

  const deleteHandler = {
    label: 'Delete',
    action: () => rowActions.deletePermission(row, index),
  }

  const acceptRequestHandler = {
    disabled: row.accepted,
    label: 'Accept request',
    action: () => rowActions.acceptRequest({ ...row, accepted: true }, index),
  }

  const resendInviteHandler = {
    label: 'Resend Invite',
    action: () => rowActions.resendInvite(row, index),
  }

  const statusToHandlers: { [key: string]: any } = {
    Pending: [acceptRequestHandler, deleteHandler],
    'Invitation accepted': [resendInviteHandler, deleteHandler],
    You: [deleteHandler],
    Owner: [],
  }

  const modifiedStatus: string = modifyStatus(email, ownerEmail) || status

  const handlers = statusToHandlers[modifiedStatus]

  const roles =
    modifiedStatus === 'Owner'
      ? [{ value: PERMISSION_IDS.OWNER, label: 'Owner' }]
      : [
          { value: PERMISSION_IDS.VIEWER, label: 'Viewer' },
          { value: PERMISSION_IDS.CONSUMER, label: 'Consumer' },
        ]

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={email}>
      <TableCell align="left">
        <UserPermissionCell id={id} userMail={email} status={modifiedStatus} />
      </TableCell>
      <TableCell align="right">
        <SimpleCell
          className={classes.cellPadding}
          after={
            handlers.length === 0 ? (
              <div className={classes.spacer} />
            ) : (
              <ActionMenu actions={handlers}>
                <IconButton
                  size="small"
                  data-testid="projects-action-menu-button"
                >
                  <MoreVertIcon />
                </IconButton>
              </ActionMenu>
            )
          }
        >
          <Select
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            disabled={modifiedStatus === 'Owner' || modifiedStatus === 'You'}
            classes={{ select: classes.roleSelect, root: classes.selectRoot }}
            onChange={(_: any, value: any) =>
              handleSelectRole(value, row, index)
            }
            variant="outlined"
            value={permission || PERMISSION_IDS.VIEWER}
          >
            {roles.map((role) => (
              <MenuItem value={role.value} key={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </SimpleCell>
      </TableCell>
    </TableRow>
  )
}

import { createSelector } from 'reselect'
import { getSandboxBuildState } from '../sandboxBuildSelectors'

export const getSandboxOSState = createSelector(
  getSandboxBuildState,
  (state) => state.os
)

export const getSandboxOS = createSelector(
  getSandboxOSState,
  (state) => state.selectedOSById
)

export const getSelectedSandboxOSName = createSelector(
  getSandboxOS,
  (state) => state.name
)

export const getIsStepCompleted = createSelector(getSandboxOS, (state) =>
  Boolean(state.id)
)

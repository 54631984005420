import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const getUploadDatasetState = (state: IStore) => state.uploadDataset

export const getIsDrawerOpened = createSelector(
  getUploadDatasetState,
  (upload) => upload.isDrawerOpened
)

export const getIsFileUploading = createSelector(
  getUploadDatasetState,
  (upload) => upload.isFileUploading
)

export const getUploadingFile = createSelector(
  getUploadDatasetState,
  (upload) => upload.file
)

import { createSelector } from 'reselect'
import { ProductTourState } from './reducer'

export const getProductTourState = (state: any): ProductTourState =>
  state.productTour

export const getActiveTip = createSelector(
  getProductTourState,
  (tourState) => tourState.tips[tourState.activeTipId]
)

export const getIsTourEnabled = createSelector(
  getProductTourState,
  (tourState) => tourState.isTourEnabled
)

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ImageButton, spacing } from '../../../../shared'
import { SandboxStepHeader } from '../SandboxStepHeader'
import NavButtons from '../NavButtons'
import { ubuntuPicPath, centOSPicPath, redHatPicPath } from './assets'
import { selectOS } from './actions'
import {
  getIsStepCompleted,
  getSelectedSandboxOSName,
} from './sandboxOSSelectors'

const availableOS = { id: 1, name: 'Ubuntu' }

export const OsView = (props) => {
  const { selectOS, isStepCompleted, selectedName } = props
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: spacing.gutter * 4,
        }}
      >
        <SandboxStepHeader label="Available Operating Systems" />
        <NavButtons isNextDisable={!isStepCompleted} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <ImageButton
          image={{ url: ubuntuPicPath }}
          onClick={() => selectOS(availableOS)}
          selected={selectedName === 'Ubuntu'}
        />
        <ImageButton disabled image={{ url: centOSPicPath }} />
        <ImageButton disabled image={{ url: redHatPicPath }} />
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  isStepCompleted: getIsStepCompleted,
  selectedName: getSelectedSandboxOSName,
})

const mapDispatchToProps = { selectOS }

export default connect(mapStateToProps, mapDispatchToProps)(OsView)

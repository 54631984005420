import { omit } from 'lodash'
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'

import { SandboxResponse } from 'api/generated/modelPkg'
import { RequestStatus } from 'utils/constants'

import { arrayToObjectById } from 'utils/arrayToObjectById'

import { CleanRoomsState, CleanRoomIdPayload } from './types'

import { CleanRoomStatuses } from './constants'

const prefix = 'cleanRooms'

const initialState: CleanRoomsState = {
  byId: {},
  searchString: '',
  status: RequestStatus.Idle,
  cleanRoomIdToShare: null,
  shareStatus: RequestStatus.Idle,
  shareErrorMessage: null,
}

const cleanRoomsReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchCleanRooms: (state) => {
      state.status = RequestStatus.Loading
    },
    didFetchCleanRooms: (
      state,
      { payload }: PayloadAction<{ cleanRooms: SandboxResponse[] }>
    ) => {
      state.status = RequestStatus.Succeeded
      state.byId = arrayToObjectById(payload.cleanRooms, 'id')
    },
    didFetchCleanRoomsFail: (state) => {
      state.status = RequestStatus.Failed
    },
    setSearchValue: (state, { payload }) => {
      state.searchString = payload.searchString
    },
    deleteCleanRoom: (
      state,
      { payload }: PayloadAction<CleanRoomIdPayload>
    ) => {
      state.status = RequestStatus.Loading
      state.byId = omit(state.byId, payload.cleanRoomId)
    },
    runCleanRoom: (state, { payload }: PayloadAction<CleanRoomIdPayload>) => {
      state.status = RequestStatus.Loading
      state.byId[payload.cleanRoomId].status = CleanRoomStatuses.RUNNING
    },
    stopCleanRoom: (state, { payload }: PayloadAction<CleanRoomIdPayload>) => {
      state.status = RequestStatus.Loading
      state.byId[payload.cleanRoomId].status = CleanRoomStatuses.STOPPED
    },
    actionIsFinished: (state) => {
      state.status = RequestStatus.Succeeded
    },

    setCleanRoomToShare: (
      state,
      { payload }: PayloadAction<{ id: number }>
    ) => {
      state.cleanRoomIdToShare = payload.id
      state.shareStatus = RequestStatus.Idle
      state.shareErrorMessage = null
    },
    shareCleanRoom: (state, { payload }: PayloadAction<{ email: string }>) => {
      state.shareStatus = RequestStatus.Loading
      state.shareErrorMessage = null
    },
    didShareCleanRoom: (state) => {
      state.shareStatus = RequestStatus.Succeeded
    },
    didShareCleanRoomFail: (
      state,
      { payload }: PayloadAction<{ message: string }>
    ) => {
      state.shareStatus = RequestStatus.Failed
      state.shareErrorMessage = payload.message
    },
  },
})

const copyToClipboard = createAction<any>(`${prefix}/copyToClipboard`)

export const cleanRoomsActions = {
  ...cleanRoomsReducer.actions,
  copyToClipboard,
}

export default cleanRoomsReducer.reducer

import React from 'react'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Typography } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'
import { Box } from '@material-ui/core'

interface IDropDownFile {
  multiple: boolean
  handleDrop: (files: File[]) => void
  className?: string
  formats?: string
  inputId?: string
}

export const DropZone: React.FunctionComponent<IDropDownFile> = ({
  multiple,
  handleDrop,
  className,
  formats = 'XLS, CSV, TSV',
  inputId,
}) => {
  const styles = useStylesDropZone()

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: (uploadedFiles) => handleDrop(uploadedFiles),
      multiple,
      noClick: true,
    })

  return (
    <Box
      {...getRootProps({
        className: cn(styles.dragZone, className, {
          [styles.dragZoneActive]: isDragAccept,
          [styles.dragZoneReject]: isDragReject,
        }),
      })}
    >
      <input {...getInputProps()} data-testid={inputId} />
      <CloudUploadIcon style={{ width: '50px', height: '50px' }} />
      <Typography size="bodyBold" className={styles.item}>
        {`Drag and Drop File${multiple ? 's' : ''} Here`}
      </Typography>
      <Typography size="caption" color="lightGrey" className={styles.item}>
        or
      </Typography>

      <Button className={styles.item} onClick={open}>
        {`Upload File${multiple ? 's' : ''}`}
      </Button>
      <Typography size="caption" color="lightGrey" className={styles.lastLine}>
        Supported file types: {formats}.
      </Typography>
    </Box>
  )
}
export const useStylesDropZone = makeStyles({
  item: {
    marginTop: '10px',
  },
  lastLine: {
    padding: 0,
    position: 'absolute',
    bottom: '10px',
  },
  dragZone: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 280,
    outline: 'none',
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: '#e0e4ed',
    borderRadius: '4px',
  },
  dragZoneActive: {
    borderStyle: 'solid',
    borderColor: '#45cb85',
    backgroundColor: '#e0e4ed',
  },
  dragZoneReject: {
    borderStyle: 'solid',
    borderColor: '#ff0000',
    backgroundColor: '#e0e4ed',
  },
})

export default DropZone

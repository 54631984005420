export const SandboxesBuildActions = {
  CANCEL_BUILD_SANDBOX: 'sandboxes/CANCEL_BUILD_SANDBOX',
  UNLOCK_NEXT_STEP: 'sandboxes/UNLOCK_NEXT_STEP',
  DID_UNLOCK_NEXT_STEP: 'sandboxes/DID_UNLOCK_NEXT_STEP',
}

export const cancelBuildSandbox = () => ({
  type: SandboxesBuildActions.CANCEL_BUILD_SANDBOX,
})

export const unLockNextStep = () => ({
  type: SandboxesBuildActions.UNLOCK_NEXT_STEP,
})

export const didUnlockNextStep = (step) => ({
  type: SandboxesBuildActions.DID_UNLOCK_NEXT_STEP,
  step,
})

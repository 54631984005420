import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IPermission {
  dataset_id: number
  id: number
  permission: number
  user_email: string
  user_id: number
}

export interface IProjectPermissions {
  permissions: IPermission[]
  isLoading: boolean
  serverError: any
}

const prefix = 'projectPermissions'

const initialState: IProjectPermissions = {
  permissions: [],
  isLoading: false,
  serverError: {},
}

const projectPermissionsReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    requestProjectPermissions: (state) => {
      state.isLoading = true
      state.serverError = {}
    },
    didRequestProjectPermissions: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.permissions = payload
      state.serverError = {}
    },
    didRequestProjectPermissionsFail: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.serverError = payload
    },
    getProjectPermissions: (state) => {
      state.isLoading = true
      state.serverError = {}
    },
    didGetProjectPermissions: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.permissions = payload
      state.serverError = {}
    },
    didGetProjectPermissionsFail: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.serverError = payload
    },
  },
})

export const projectPermissionsActions = projectPermissionsReducer.actions

export default projectPermissionsReducer.reducer

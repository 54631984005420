import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from 'UIkit'
import { Typography } from 'legacy/shared'

import DataGroupOutputColumnData from 'legacy/components/Projects/ColumnData/DataGroupOutputColumnData'
import { TotalRows } from 'legacy/components/Projects/ColumnData/TotalRows'

const useStyles = makeStyles({
  table: {
    overflow: 'auto',
    display: 'flex',
    paddingTop: '8px',
  },
  noData: {
    textAlign: 'center',
  },
  totalRow: {
    padding: '0.6rem 0',
  },
})

export const OutputTable = ({ synthData }) => {
  const classes = useStyles()

  if (!synthData?.preview?.meta || !synthData?.sample) {
    return (
      <Box pt={2} className={classes.noData} data-testid="empty-output-result">
        <Typography size="body">No output data produced.</Typography>
      </Box>
    )
  }

  const { meta } = synthData.preview
  return (
    <>
      <div className={classes.table}>
        {meta.columns.map((synthColumn) => (
          <DataGroupOutputColumnData
            key={synthColumn.name}
            synthColumn={synthColumn}
            sampleColumn={synthData.sample[synthColumn.name]}
          />
        ))}
      </div>
      <TotalRows
        showingRows={Object.values(synthData.sample)[0].length}
        totalRows={meta.n_rows}
      />
    </>
  )
}

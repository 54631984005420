import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import EditIcon from '@material-ui/icons/Edit'

import { Box } from 'UIkit'
import { IconButton } from 'legacy/shared'
import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'

import { HeaderChart } from '../ProjectDistPlot'
import { ColumnTitleLayout } from './ColumnTitle'
import { ColumnsStatistics } from './ColumnStatistics'
import { SampleList } from './SampleList'

import { selectColumn } from '../modals/actions'
import { getProjectSettingsLoadingState } from '../settings/settingsSelector'
import icon_2 from '../../../images/icons/annotate_icon.png'

const useStyles = makeStyles({
  box: {
    width: 300,
  },
  titleContainer: {
    '&:hover': {
      '& $iconButton': {
        opacity: 1,
      },
    },
  },
  labelContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    paddingRight: '1.5rem',
  },
  label: {
    padding: '0 0.3rem',
    borderRadius: '10px',
    fontWeight: 700,
    fontSize: 12,
  },
  iconButton: {
    padding: 0,
    opacity: 0,
  },
  annotationIcon: {
    marginLeft: 'auto',
  },
})

const isRenameButtonDisabled = (
  results_only: any,
  status: any,
  loading: any
) => {
  return results_only || status === 'training' || loading.is
}

const isAnnotationIconDisabled = (
  results_only: any,
  isShowComplexRow: any,
  status: any,
  loading: any
) => {
  return (
    results_only ||
    status === 'training' ||
    loading.is ||
    isShowComplexRow.action === 'edit' ||
    (isShowComplexRow.action === 'bind' &&
      isShowComplexRow.isShow &&
      isShowComplexRow.complexType)
  )
}

const OriginalColumnData = (props: any) => {
  const {
    d,
    data,
    status,
    results_only,
    loading,
    isShowComplexRow,
    selectColumn,
    showModal,
    handleShowComplexRow,
  } = props
  const classes = useStyles()
  const idx_complex = data.settings.couplings.findIndex((f: any) =>
    f.name.includes(d.name)
  )

  return (
    <div>
      <Box border={1} className={classes.box}>
        <>
          <ColumnTitleLayout title={d.name} className={classes.titleContainer}>
            <>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  selectColumn({ name: d.name })
                  showModal({ modalType: ModalTypes.ProjectEditColumnName })
                }}
                disabled={isRenameButtonDisabled(results_only, status, loading)}
              >
                <EditIcon fontSize="small" />
              </IconButton>

              {idx_complex !== null && idx_complex !== -1 ? (
                <IconButton
                  className={classnames(
                    classes.iconButton,
                    classes.annotationIcon
                  )}
                  onClick={() =>
                    handleShowComplexRow(
                      true,
                      'edit',
                      data.settings.couplings[idx_complex].complexType
                    )
                  }
                  disabled={isAnnotationIconDisabled(
                    results_only,
                    isShowComplexRow,
                    status,
                    loading
                  )}
                >
                  <img src={icon_2} width={20} height={20} alt="" />
                </IconButton>
              ) : null}
            </>
          </ColumnTitleLayout>
          {idx_complex !== null && idx_complex !== -1 ? (
            <div className={classes.labelContainer}>
              <div
                className={classes.label}
                style={{
                  backgroundColor: data.settings.couplings[idx_complex].color,
                }}
              >
                {data.settings.couplings[idx_complex].complexType}.
                {data.settings.couplings[idx_complex].complexSelect}
              </div>
            </div>
          ) : null}
          <HeaderChart {...props} />
          <ColumnsStatistics columnData={d} />
          <SampleList sample={data.sample[d.name]} />
        </>
      </Box>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  loading: getProjectSettingsLoadingState(state),
})

const mapDispatchToProps = {
  selectColumn,
  showModal: modalActions.show,
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginalColumnData)

import * as React from 'react'
import { IconButton as MUIIconButton } from '@material-ui/core'

export const IconButton = (props) => {
  const {
    className,
    style = {},
    onClick,
    backgroundColor,
    disabled = false,
    children,
  } = props
  return (
    <MUIIconButton
      disabled={disabled}
      className={className}
      onClick={onClick}
      style={{
        ...style,
        backgroundColor,
      }}
      data-testid={props['data-testid']}
    >
      {children}
    </MUIIconButton>
  )
}

IconButton.displayName = 'IconButton'

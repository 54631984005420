import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import GroupsTable from './GroupsTable'
import ScenarioHeader from './ScenarioHeader'
import {
  selectDataGroups,
  selectSynthErrorMessage,
} from 'services/project/scenarios/selectors'
import { scenarioActions } from 'services/project/scenarios/reducer'
import { getProjectPermissionsForUser } from '../projectSelectors'

const useStyles = makeStyles({
  groupsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
})

const ScenarioSettingsView = ({
  datasetId,
  canEdit,
  errorMessage,
  fetchGroups,
  fetchSynthesizeData,
}) => {
  const classes = useStyles()

  useEffect(() => {
    fetchGroups({ datasetId })
    fetchSynthesizeData({ datasetId })
  }, [])

  return (
    <>
      {errorMessage ? (
        <div>
          <Alert severity="warning">{errorMessage}</Alert>
        </div>
      ) : null}
      <ScenarioHeader datasetId={datasetId} />
      <div className={classes.groupsContainer}>
        <GroupsTable canEdit={canEdit} />
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  groups: selectDataGroups,
  errorMessage: selectSynthErrorMessage,
  canEdit: getProjectPermissionsForUser,
})

const mapDispatchToProps = {
  fetchGroups: scenarioActions.fetchGroups,
  fetchSynthesizeData: scenarioActions.fetchSynthesizeData,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioSettingsView)

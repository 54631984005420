import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const selectDataSourcesState = (state: IStore) => state.dataSources

export const selectNewProject = createSelector(
  selectDataSourcesState,
  (state) => state.newProject
)

export const selectCreateStatus = createSelector(
  selectNewProject,
  (state) => state.createStatus
)

import * as React from 'react'
import MUIBox, { BoxProps } from '@material-ui/core/Box'

const hasBorders = ({
  border,
  borderTop,
  borderBottom,
  borderRight,
  borderLeft,
}: BoxProps) => {
  return (
    [border, borderTop, borderBottom, borderRight, borderLeft].filter(
      (value) => value && value > 0
    ).length > 0
  )
}

export const Box = (props: BoxProps) => {
  let boxProps = { ...props }
  if (hasBorders(boxProps)) {
    boxProps = {
      ...boxProps,
      borderColor: props.borderColor || 'rgba(224, 224, 224, 1)', // TODO move in constants when we define them
      borderRadius: props.borderRadius || 4,
    }
  }
  return <MUIBox {...boxProps}>{boxProps.children}</MUIBox>
}

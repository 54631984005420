import React from 'react'
import { connect } from 'react-redux'

import { dataSourcesActions } from 'services/datasources/datasources/reducer'
import {
  selectDataSourceCheckStatus,
  selectDataSourceSaveStatus,
} from 'services/datasources/datasources/selectors'

import { Button } from 'UIkit/Button/Button'
import { modalActions } from 'services/modal/reducer'

export const DataSourceCreateButtons = ({
  values,
  handleSubmit,
  checkStatus,
  saveStatus,
  createDataSource,
  hideModal,
}: any) => {
  const handlerCreateDatasource = (dataSource: any) => {
    createDataSource(dataSource)
  }

  const isChecking = checkStatus === 'loading'
  const isChecked = checkStatus === 'succeeded'

  const isSaving = saveStatus === 'loading'

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button
        color="secondary"
        onClick={() => {
          hideModal()
        }}
        disabled={isChecking}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={() => {
          handleSubmit(values)
        }}
        loading={isChecking}
      >
        Check
      </Button>
      <Button
        onClick={() => {
          handlerCreateDatasource(values)
        }}
        disabled={!isChecked}
        loading={isSaving}
      >
        Create
      </Button>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  checkStatus: selectDataSourceCheckStatus(state),
  saveStatus: selectDataSourceSaveStatus(state),
})

const mapDispatchToProps = {
  createDataSource: dataSourcesActions.createDataSource,
  hideModal: modalActions.hide,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSourceCreateButtons)

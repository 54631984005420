import { makeRequest } from './apiClient'

export default {
  async getDataSources() {
    const response = await makeRequest({
      path: `datasources`,
      method: 'GET',
    })
    return response
  },

  async createDataSource({ data }) {
    const response = makeRequest({
      path: `datasources`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  async updateDataSource({ id, data }) {
    const response = makeRequest({
      path: `datasources/${id}`,
      method: 'PUT',
      params: JSON.stringify(data),
    })
    return response
  },

  async deleteDataSource({ id }) {
    const response = makeRequest({
      path: `datasources/${id}`,
      method: 'DELETE',
    })
    return response
  },

  async checkDatasourceConnection({ data }) {
    const response = makeRequest({
      path: `datasources/check`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  async getSourceTables(id) {
    const response = await makeRequest({
      path: `datasources/${id}/dir`,
      method: 'GET',
      type: 'datasource',
    })
    return response.tables
  },

  async getQuery(id) {
    const response = await makeRequest({
      path: `queries/${id}`,
      method: 'GET',
    })

    return response
  },

  async postQueries(data) {
    const response = await makeRequest({
      path: `queries`,
      method: 'POST',
      params: JSON.stringify(data),
    })

    return response
  },

  async createDatasourceProject({ id }) {
    const response = await makeRequest({
      path: `queries/${id}/createproject`,
      method: 'POST',
    })
    return response
  },

  async updateQuerySettings({ id, data }) {
    const response = await makeRequest({
      path: `queries/${id}/updatesettings`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },
}

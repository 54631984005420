import { takeLatest, put, call, race, take } from 'redux-saga/effects'
import { notificationActions } from 'services/notification/reducer'
import { dataSourceService } from '../../utils/services'
import User from '../Account/User'
import {
  DataSourceActions,
  didFetchDataSource,
  didFetchDataSourceFail,
  setQueryData,
} from './actions'
import {
  actionStartLoadingDatasource,
  actionStopLoadingDatasource,
} from '../../store/actions'

export function* handleSourceTables(action) {
  const dataSourceId = action.id

  let _queries = JSON.parse(User.queries)
  if (_queries === null) {
    _queries = []
  }
  try {
    yield put(actionStartLoadingDatasource())
    const sourceTables = yield call(
      dataSourceService.getSourceTables,
      dataSourceId
    )
    yield put(didFetchDataSource(sourceTables))

    const idx_curr_query = _queries
      .map((d) => d.datasource_id)
      .indexOf(dataSourceId)
    if (idx_curr_query === -1) {
      try {
        const dt = yield call(dataSourceService.postQueries, {
          datasource_id: dataSourceId,
        })
        if (dt) {
          _queries.push({
            datasource_id: dataSourceId,
            query_id: dt.query_id,
          })
          User.initQueries(_queries)
          yield put(setQueryData({}))
        }
      } catch (error) {
        // TODO add some handler
      }
    } else {
      try {
        const queryId = _queries[idx_curr_query].query_id
        const queries = yield call(dataSourceService.getQuery, queryId)
        yield put(setQueryData(queries))
      } catch (error) {
        // TODO add some handler
      }
    }
    yield put(actionStopLoadingDatasource())
  } catch (error) {
    yield put(didFetchDataSourceFail())
    yield put(actionStopLoadingDatasource())
  }
}

export function* handleUpdateQuerySettings({id, data}){
  try {
    yield call(dataSourceService.updateQuerySettings, { id, data })
    const queryData = yield call(dataSourceService.getQuery, id)
    yield put(setQueryData(queryData))
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: error.message,
        severity: 'error',
      })
    )
  } finally {
    yield put(actionStopLoadingDatasource())
  }
}

export default function* watchDataSourceSaga() {
  yield takeLatest(DataSourceActions.FETCH_SOURCE_TABLES, function* (action) {
    yield race([
      call(handleSourceTables, action),
      take('dataSource/RESET_TABLE_QUERY_DATA'),
    ])
  })
}

import React from 'react'
import { Typography, Spacer } from 'legacy/shared'

import Logo from 'assets/logos/AuthLogo.png'

export const Header = ({ heading, subTitle, subTitleSize = 'body2' }: any) => (
  <header
    style={{
      textAlign: 'center',
      marginBottom: '3rem',
    }}
  >
    <img
      width="190"
      alt="Synthesized"
      src={Logo}
      style={{
        marginBottom: '1rem',
      }}
    />
    <Typography size="title" color="stone" gutterBottom>
      {heading}
    </Typography>
    {subTitle ? (
      <>
        <Spacer />
        <Typography size={subTitleSize} color="grey">
          {subTitle}
        </Typography>
      </>
    ) : null}
  </header>
)

import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import classnames from 'classnames'

interface SelectProps {
  label: string
  size: 'small' | 'medium'
  disabled: boolean
  value: {label: string; value: string}
  values: { label: string; value: string }[]
  style: any
  from: string
  handleChange: (arg: string) => {}
}

const Component: FC<SelectProps> = ({
  label = '',
  size = 'small',
  disabled = false,
  value = '',
  values,
  style = {},
  from = '',
  handleChange,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root} style={style}>
      <TextField
        select
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        label={label}
        variant="outlined"
        className={classnames(
          classes.inputRoot,
          { [classes.customStyles]: from === 'analyzeBar' },
          { [classes.noLabel]: !value && from === 'analyzeBar' }
        )}
        disabled={disabled}
        size={size}
        SelectProps={{
          MenuProps: {
            classes: {
              paper: classes.paper,
              list: classes.list,
            },
          },
        }}
      >
        {values.map((d: { label: string; value: any }) => (
          <MenuItem key={d.label} value={d.value || d.label}>
            {d.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 120,
    width: '100%',
  },
  inputRoot: {
    width: '100%',
  },
  customStyles: {
    '& .MuiFormLabel-root': {
      // transition: '200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, -18px) scale(0.75)',
    },
    '& legend': {
      width: 0,
    },
    '& .MuiSelect-iconOutlined': {
      right: 0,
      top: 0,
      height: '100%',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      backgroundColor: '#eff0f6',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px',
    },
  },
  noLabel: {
    '& .MuiInputLabel-root': {
      display: 'none',
    },
    '& .Mui-focused.MuiInputLabel-root': {
      display: 'block',
    },
  },
  paper: {
    width: '210px',
  },
  list: {
    maxHeight: '20rem',
    '& li': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
}))

export const SelectSimple: FC<SelectProps> = (props) => <Component {...props} />

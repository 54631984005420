import React, { FC, useState } from 'react'
import { Checkbox, Menu, MenuItem } from '@material-ui/core'
import { SimpleCell } from 'components/SimpleCell/SimpleCell'

interface IActionItem {
  label: string
  action: any
  disabled?: boolean
  hasCheckbox?: boolean
  [key: string]: any
}

interface IActionMenuProps {
  actions: IActionItem[]
  MenuItemProps?: any
}

export const ActionMenu: FC<IActionMenuProps> = ({
  actions,
  children,
  MenuItemProps = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div onClick={handleClick}>{children || 'Open menu'}</div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map(
          ({ label, value, action, disabled, hasCheckbox }: IActionItem) => (
            <MenuItem
              {...MenuItemProps}
              key={label}
              disabled={disabled}
              onClick={action}
            >
              <SimpleCell before={hasCheckbox && <Checkbox checked={value} />}>
                {label}
              </SimpleCell>
            </MenuItem>
          )
        )}
      </Menu>
    </>
  )
}

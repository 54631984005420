import React from 'react'
import ReactDOM from 'react-dom'
import * as d3 from 'd3'
import {
  formatNumber,
  transformCorrelationChartData,
} from '../../services/helper'

class Axes extends React.Component {
  componentDidMount() {
    const { data_item, show_axis } = this.props
    const transform_data_item = transformCorrelationChartData(data_item)

    this.initGraph(transform_data_item, show_axis)
  }

  initGraph(data, show_axis) {
    this.svg = d3.select(ReactDOM.findDOMNode(this)).append('svg')

    this.updateSvg(show_axis)
    this.prepareDomains(data)
    this.updateAxis(show_axis)
  }

  updateSvg = (show_axis) => {
    this.margin = {
      top: 0,
      right: 0,
      bottom: show_axis.x ? 16 : 0,
      left: show_axis.y ? 40 : 0,
    }
    this.width = show_axis.width - this.margin.left - this.margin.right
    this.height = show_axis.height - this.margin.top - this.margin.bottom

    this.svg
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
  }

  prepareDomains = (data) => {
    const xDomain = d3.extent(data, (d) => d.x)
    const xDelta = Math.abs(xDomain[1] - xDomain[0]) / 20
    const yDomain = d3.extent(data, (d) => d.y)
    const yDdelta =
      ((Math.abs(yDomain[1] - yDomain[0]) / 20) * this.props.width) /
      this.height

    this.x = d3
      .scaleLinear()
      .domain([xDomain[0] - xDelta, xDomain[1] + xDelta])
      .range([0, this.width])

    this.y = d3
      .scaleLinear()
      .domain([yDomain[0] - yDdelta, yDomain[1] + yDdelta])
      .range([this.height, 0])
  }

  updateAxis = (show_axis) => {
    const deltaXGrid = (this.x.domain()[1] - this.x.domain()[0]) / 4
    const deltaYGrid = (this.y.domain()[1] - this.y.domain()[0]) / 4
    const gridXValues = []
    const gridYValues = []

    for (let i = 1; i < 4; i++) {
      gridXValues.push(this.x.domain()[0] + i * deltaXGrid)
      gridYValues.push(this.y.domain()[0] + i * deltaYGrid)
    }

    this.svg.select('.axis-x').remove()
    this.svg.select('.axis-y').remove()

    // x axis
    if (show_axis.x) {
      this.svg
        .append('g')
        .attr('class', 'axis axis-x')
        .attr(
          'transform',
          `translate(${this.margin.left}, ${this.height + this.margin.top})`
        )
        .call(
          d3
            .axisBottom(this.x)
            .ticks(4)
            .tickSize(2)
            .tickValues(gridXValues)
            .tickFormat(
              this.x.domain().filter((d) => typeof d === 'string').length === 0
                ? (d) => formatNumber(d)
                : (d) => d
            )
        )
        .selectAll('text')
        .style('font-size', '8px')
    }

    // y axis
    if (show_axis.y) {
      this.svg
        .append('g')
        .attr('class', 'axis axis-y')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .call(
          d3
            .axisLeft(this.y)
            .ticks(4)
            .tickSize(2)
            .tickValues(gridYValues)
            .tickFormat((d) => formatNumber(d))
        )
        .selectAll('text')
        .style('font-size', '8px')
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillReceiveProps(next) {
    if (next.show_axis.width !== this.props.show_axis.width) {
      const { data_item, show_axis } = next
      const transform_data_item = transformCorrelationChartData(data_item)

      this.updateSvg(show_axis)
      this.prepareDomains(transform_data_item)
      this.updateAxis(show_axis)
    }
  }

  render() {
    const { show_axis } = this.props
    return (
      <div
        className="cor-first-axes"
        style={{ alignSelf: show_axis.x ? 'flex-start' : '' }}
        id={0}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Axes

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  container: {
    margin: 'auto',
    textAlign: 'center',
  },
})

export const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h3>Page does not exist</h3>
      <Link to="/menu">Go to Menu page</Link>
    </div>
  )
}

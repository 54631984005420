import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { IconButton, Menu, spacing } from 'legacy/shared'

import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'

import { testingSuitesActions } from 'services/testingSuites/reducer'

const useStyles = makeStyles({
  triggerButton: {
    padding: 0,
    marginLeft: spacing.gutter,
    marginRight: spacing.gutter,
  },
  verDivider: {
    color: '#C1C6CC',
  },
})

const eventPrevent = (e: any) => {
  e.stopPropagation()
  e.preventDefault()
  e.target.blur()
}

type DispatchProps = typeof mapDispatchToProps
type OwnProps = { cell: any }

export const ActionMenu: React.FC<DispatchProps & OwnProps> = ({
  cell,
  showModal,
  deleteTestingSuite,
}) => {
  const classes = useStyles()

  return (
    <div onClick={(e) => eventPrevent(e)}>
      <Menu
        items={[
          {
            text: 'Delete',
            onClick: () =>
              showModal({
                modalType: ModalTypes.Confirmation,
                config: {
                  headerText: 'Delete Testing Suite',
                  confirmationConfig: {
                    actionDescription: (
                      <>
                        Are you sure you want to permanently delete{' '}
                        <strong>{cell.name}</strong>?
                      </>
                    ),
                    confirmButtonText: 'Delete',
                    confirmButtonAction: () =>
                      deleteTestingSuite({ id: cell.id }),
                  },
                },
              }),
          },
        ].filter(Boolean)}
        renderTriggerComponent={({ showMenu }: any) => (
          <div style={{ display: 'flex' }}>
            <IconButton
              onClick={(e: any) => {
                showMenu(e)
              }}
              className={classes.triggerButton}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        )}
      />
    </div>
  )
}

const mapDispatchToProps = {
  showModal: modalActions.show,
  deleteTestingSuite: testingSuitesActions.deleteTestingSuite,
}

export default connect(null, mapDispatchToProps)(ActionMenu)

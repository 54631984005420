import { createSelector } from 'reselect'
import { getProjectState, getOriginalBiasData } from '../projectSelectors'
import { validateRowNumber } from './utils'

export const getProjectsSettingState = createSelector(
  getProjectState,
  (state) => state.projectSetting
)

export const getSynthesisSettingsState = createSelector(
  getProjectsSettingState,
  (settings) => settings.synthesisSettings
)

export const getIsInvalidRowNumber = createSelector(
  getSynthesisSettingsState,
  (synthesisSettings) => {
    const { rowNumber } = synthesisSettings
    return validateRowNumber(rowNumber)
  }
)

export const getDisplayColumnsState = createSelector(
  getProjectsSettingState,
  (settings) => settings.displayColumns
)

export const getBiasSettingsState = createSelector(
  getProjectsSettingState,
  (settings) => settings.biasSettings
)

export const getTargetColumnState = createSelector(
  getBiasSettingsState,
  (biasSettings) => biasSettings.targetColumn
)

export const getBiasMitigationDisabled = createSelector(
  getOriginalBiasData,
  getTargetColumnState,
  (originalBias, targetColumn) => {
    if (targetColumn === 'none') {
      return {
        isDisabled: true,
        message: 'Bias mitigation disabled as target column is not selected',
      }
    }
    if (!originalBias?.dist_biases?.count?.length) {
      return {
        isDisabled: true,
        message:
          'Bias mitigation is disabled as no biases with respect to the selected target column were detected in the original dataset.',
      }
    }
    return { isDisabled: false, message: null }
  }
)

import React, { FC, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem, Tooltip } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import classnames from 'classnames'
import { IInviteItem, IInviteItemGroup, IPermission } from './typings'
import { PERMISSIONS } from './contsants'
import { validateEmail } from './utils'

const chipSyles = {
  chipWrapper: {},
  chipBody: {},
  chipText: {},
}

const useStyles = makeStyles({
  ...chipSyles,
  inviteRow: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  item: {
    '&:first-child': {
      flexGrow: 1,
      marginRight: '12px',
      maxWidth: '388px',
    },
  },
  chip: {
    fontSize: 14,
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '28px',
    border: '1px solid #4E5FC3',
    maxWidth: '360px',
    backgroundColor: 'rgba(193, 201, 250, 0.2)',
    '&:hover': {
      border: '1px solid #4E5FC3',
      backgroundColor: 'rgba(193, 201, 250, 0.2)',
    },
  },
  chipDeleteIcon: {
    color: '#4E5FC3',
  },
  chipDeleteErrorIcon: {
    color: '#CC3E4A',
  },
  chipContainer: {
    minHeight: '44px',
  },
  chipError: {
    backgroundColor: '#FFEAEB',
    border: '1px solid #CC3E4A',
    '&:hover': {
      backgroundColor: '#FFEAEB',
      border: '1px solid #CC3E4A',
    },
  },
  chipInput: {
    minHeight: '44px',
    width: '100%',
  },
  input: {
    marginBottom: '2px!important',
    marginTop: '0!important',
    fontSize: '14px',
  },
  inputRoot: {
    paddingTop: '8px!important',
    paddingLeft: '12px!important',
  },
  select: {
    height: '44px',
    width: '120px',
  },
  selectRoot: {
    padding: '6px 18px',
  },
})

interface IInviteRowProps {
  onChange: any
  value: IInviteItemGroup
  index: number
}

const emailValidStates: { [key: string]: any } = {
  valid: { status: 'valid', error: null },
  invalid: {
    status: 'invalid',
    error: 'Please enter a valid email address',
  },
  existed: {
    status: 'existed',
    error: 'Email already added',
  },
}

export const InvitesRow: FC<IInviteRowProps> = ({ onChange, value, index }) => {
  const classes = useStyles()
  const [position, updatePosition] = useState(value)

  const handleAddChip = (text: string) => {
    updatePosition((pos: IInviteItemGroup) => {
      const email = text.trim()

      const newItem: IInviteItem = {
        id: pos.items.length,
        email,
        ...emailValidStates.valid,
      }

      const isValidEmail = validateEmail(email)
      if (!isValidEmail) {
        return {
          ...pos,
          items: [
            ...pos.items,
            {
              ...newItem,
              ...emailValidStates.invalid,
            },
          ],
        }
      }

      const isUniqEmail =
        pos.items.filter((item: IInviteItem) => item.email === email).length ===
        0

      if (!isUniqEmail) {
        return {
          ...pos,
          items: [
            ...pos.items,
            {
              ...newItem,
              ...emailValidStates.existed,
            },
          ],
        }
      }

      return {
        ...pos,
        items: [...pos.items, newItem],
      }
    })
  }

  const handleDeleteChip = (row: any, idx: number) => {
    updatePosition((pos: IInviteItemGroup) => {
      const modifiedItems = pos.items.filter(
        (_, itemIdex: number) => itemIdex !== idx
      )

      const dupEmails = modifiedItems.filter((item) => item.email === row.email)
      if (dupEmails.length === 1) {
        const updatedItems = modifiedItems.map((item) => {
          if (item.status === 'existed') {
            return { ...item, ...emailValidStates.valid }
          }
          return item
        })

        return {
          ...pos,
          items: updatedItems,
        }
      }

      return {
        ...pos,
        items: modifiedItems,
      }
    })
  }

  const handlePermissionChange = (_: any, params: any) => {
    const { value: permId } = params.props
    updatePosition((pos: IInviteItemGroup) => ({
      ...pos,
      permission: PERMISSIONS.find(
        ({ permission }: IPermission) => permission === permId
      ),
    }))
  }

  useEffect(() => {
    onChange(position, index)
  }, [position])

  const renderChip = ({
    value,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  }: any) => {
    const isError = value.error
    const chip = (
      <Chip
        clickable={isReadOnly}
        label={value.email}
        onDelete={handleDelete}
        onClick={handleClick}
        classes={{
          deleteIcon: classnames(classes.chipDeleteIcon, {
            [classes.chipDeleteErrorIcon]: isError,
          }),
        }}
        className={classnames(className, {
          [classes.chipError]: isError,
        })}
      />
    )
    return isError ? (
      <Tooltip title={value.error} color="darkPurple" arrow>
        {chip}
      </Tooltip>
    ) : (
      chip
    )
  }

  return (
    <div className={classes.inviteRow}>
      <div className={classes.item}>
        <ChipInput
          fullWidth
          classes={{
            chip: classes.chip,
            input: classes.input,
            inputRoot: classes.inputRoot,
            chipContainer: classes.chipContainer,
          }}
          placeholder="name@email.com, ..."
          blurBehavior="add"
          variant="outlined"
          chipRenderer={renderChip}
          onAdd={handleAddChip}
          onDelete={handleDeleteChip}
          value={position?.items}
          className={classes.chipInput}
        />
      </div>
      <div className={classes.item}>
        <Select
          fullWidth
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          classes={{
            root: classes.selectRoot,
          }}
          variant="outlined"
          value={position.permission?.permission}
          onChange={handlePermissionChange}
          className={classes.select}
        >
          {PERMISSIONS.filter(({ id }) => id !== 0).map((item) => (
            <MenuItem key={item.type} value={item.permission}>
              {item.type}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}

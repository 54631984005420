import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { IDBConfig } from 'services/createTestingSuite/types'
import { SuiteResponse } from 'api/generated'

const prefix = 'createTestingSuite'

interface ICreateTestingSuiteState {
  loading: boolean
  isSynthesizing: boolean
  isSynthesizingFailed: boolean
  reportLoading: boolean
  reportLoadingFailed: boolean
  reportData: any
  title: string
  files: File[]
  isFilesUploading: boolean
  isFilesDeleting: boolean
  isDrawerOpened: boolean
  currentSuite: SuiteResponse
  DBConnectFormValues: IDBConfig
  suiteDataSources: Array<any>
}

export const initialDBConnectFormValues = {
  name: '',
  type: '',
  host: '',
  port: '',
  userName: '',
  password: '',
  database: '',
}
const initialState: ICreateTestingSuiteState = {
  loading: false,
  reportLoading: false,
  reportLoadingFailed: false,
  isSynthesizing: false,
  isSynthesizingFailed: false,
  title: 'New testing suite',
  reportData: null,
  files: [],
  isFilesUploading: false,
  isFilesDeleting: false,
  isDrawerOpened: false,
  currentSuite: {} as SuiteResponse,
  DBConnectFormValues: initialDBConnectFormValues,
  suiteDataSources: [],
}

const createTestingSuiteReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.isDrawerOpened = true
    },
    closeDrawer: (state) => {
      state.isDrawerOpened = false
    },
    updateCreateSuiteInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        title: string
      }>
    ) => {
      const { title } = payload
      state.title = title
    },
    getSuite: (state, { payload }: PayloadAction<number>) => {
      // TODO: loading
    },
    didGetSuite: (state, { payload }: PayloadAction<any>) => {
      const { suite, dataSources } = payload

      state.currentSuite = suite
      state.suiteDataSources = dataSources
    },
    didGetSuiteFail: () => {},
    getSuiteDataSources: (state, { payload }: PayloadAction<any>) => {},
    didGetSuiteDataSources: (state, { payload }: PayloadAction<any>) => {
      state.suiteDataSources = payload
    },
    didGetSuiteDataSourcesFail: (state) => {},
    updateSuiteDataSources: (state, { payload }: PayloadAction<any>) => {
      state.suiteDataSources = payload
    },
    uploadSuite: (
      state,
      { payload }: PayloadAction<{ formData: FormData }>
    ) => {
      state.isFilesUploading = true
    },
    didUploadSuite: (state, { payload }: PayloadAction<SuiteResponse>) => {
      state.isFilesUploading = false
      state.currentSuite = payload
      state.files = []
      state.isDrawerOpened = false
    },
    didUploadSuiteFail: (state) => {
      state.isFilesUploading = false
    },
    updateSuite: (
      state,
      {
        payload,
      }: PayloadAction<{ id: number; formData: FormData; updateType: string }>
    ) => {
      state.isFilesUploading = true
    },
    didUpdateSuite: (state, { payload }: any) => {
      state.isFilesUploading = false
      state.currentSuite = payload
    },
    didUpdateSuiteFail: (state) => {
      state.isFilesUploading = false
    },
    deleteSuiteConfigFile: (state, { payload }: PayloadAction<any>) => {
      state.isFilesDeleting = true
    },
    didDeleteSuiteConfigFile: (state) => {
      state.isFilesDeleting = false
    },
    didDeleteSuiteConfigFileFailed: (state) => {
      state.isFilesDeleting = false
    },
    createFromDB: (state, { payload }: PayloadAction<IDBConfig>) => {
      state.loading = true
    },
    didCreateFromDB: (state, { payload }: PayloadAction<SuiteResponse>) => {
      state.loading = false
      state.currentSuite = payload
    },
    didCreateFromDBFailed: (state) => {
      state.loading = false
    },
    startSynthesizeSuite: (state) => {
      state.isSynthesizing = true
      state.reportData = null
    },
    stopSynthesizeSuite: (state, { payload }: PayloadAction<any>) => {
      state.isSynthesizing = false
      state.reportData = null
    },
    didSynthesizeSuite: (state, { payload }: PayloadAction<any>) => {
      state.isSynthesizing = false
      state.isSynthesizingFailed = false
      state.reportLoading = false
      state.reportLoadingFailed = false
      state.reportData = payload
    },
    didSynthesizeSuiteFailed: (state) => {
      state.isSynthesizing = false
      state.isSynthesizingFailed = true
      state.reportData = null
    },
    setDBConnectFormValues: (state, { payload }: PayloadAction<IDBConfig>) => {
      state.DBConnectFormValues = payload
    },
    getReport: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.reportLoading = true
    },
    didGetReport: (state, { payload }: PayloadAction<any>) => {
      state.reportLoading = false
      state.reportLoadingFailed = false
      state.reportData = payload
    },
    didGetReportFailed: (state) => {
      state.isSynthesizing = false
      state.reportLoading = false
      state.reportLoadingFailed = true
      state.reportData = null
    },
  },
})

export const downloadData = createAction<any>(`${prefix}/downloadData`)
export const downloadReport = createAction<any>(`${prefix}/downloadReport`)
const fetchSynthProgress = createAction<any>(`${prefix}/fetchSynthProgress`)
const synthesizeSuite = createAction<{ id: number }>(
  `${prefix}/synthesizeSuite`
)

export const createTestingSuiteActions = {
  ...createTestingSuiteReducer.actions,
  synthesizeSuite,
  fetchSynthProgress,
  downloadData,
  downloadReport,
}

export default createTestingSuiteReducer.reducer

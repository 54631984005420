import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

const selectProfileState = (state: IStore) => state.profile

export const selectProfileImageState = createSelector(
  selectProfileState,
  (state) => state.image
)

export const selectProfileImageUrl = createSelector(
  selectProfileImageState,
  (state) => state.imageUrl
)

export const selectProfileFormState = createSelector(
  selectProfileState,
  (state) => state.form
)

export const selectProfileLoadingState = createSelector(
  selectProfileFormState,
  (state) => state.isLoading
)

import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import update from 'immutability-helper'
import Select from 'react-select'

import { Button } from 'UIkit/Button/Button'
import { Whisper, Popover } from 'rsuite'
import { updateDataset } from './actions'
import {
  getProjectDataState,
  getProjectSynthDataState,
  getProjectStatusState,
} from './projectSelectors'
import { getProjectSettingsLoadingState } from './settings/settingsSelector'
import { getComplexTypes } from './helper'

import MultiSelectCouplings from '../MultiSelectCouplings'

import '../Header.scss'
import '../Modal.scss'

import _v from '../../styles/_variables.scss'

import icon_1 from '../../images/icons/annotate_icon.png'

const useStyles = makeStyles({
  generalContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  couplingsButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    marginTop: '0.7rem',
  },
  buttons: {
    marginLeft: '1rem',
  },
})

const SelectComponent = ({
  data,
  d,
  complexSelects,
  errorsComplexSelects,
  handleSetColumnComplexType,
}) => {
  return (
    <div
      className="types-style"
      style={{ marginLeft: 0, marginRight: '1.5rem' }}
    >
      <label>
        <div className="label_star">
          {`${d.label}:`}
          <span className="required_star"> {d.required ? '*' : ''}</span>
        </div>
      </label>
      <MultiSelectCouplings
        data={data}
        dItem={d}
        complexSelects={complexSelects}
        errorsComplexSelects={errorsComplexSelects}
        handleSetColumnComplexType={handleSetColumnComplexType}
      />
    </div>
  )
}

const EntityAnnotation = ({
  children,
  data,
  results_only,
  disabled,
  status,
  loading,
  isShowComplexRow = { isShow: true, action: 'bind', complexType: '' },
  updateDataset,
  handleShowComplexRow,
}) => {
  const classes = useStyles()
  const startComplexSelects = useRef([]) // remember start complex selects when click bind icon in column
  const [complexSelects, setComplexSelects] = useState([]) // array selects in complex row
  const [complexType, setComplexType] = useState('')
  const [isShowComplexSelect, setIsShowComplexSelect] = useState(false)
  const [errorsComplexSelects, setErrorsComplexSelects] = useState([]) // validation complex

  useEffect(() => {
    const arr = []
    data.settings &&
      data.settings.couplings.forEach((d) => {
        const idx = arr.findIndex((f) => f.complexType === d.complexType)
        if (idx !== -1) {
          const idx_select = arr[idx].selects.findIndex(
            (fi) => fi.name === d.complexSelect
          )
          arr[idx].selects[idx_select].selectedColumn = [...d.name]
        } else {
          let _selects = []
          const idx_type = getComplexTypes().findIndex(
            (fi) => fi.label === d.complexType
          )
          const idx_select = getComplexTypes()[idx_type].selects.findIndex(
            (fi) => fi.name === d.complexSelect
          )
          _selects = JSON.parse(
            JSON.stringify(getComplexTypes()[idx_type].selects)
          )
          _selects[idx_select].selectedColumn = [...d.name]
          arr.push({
            complexType: d.complexType,
            color: d.color,
            selects: _selects,
          })
        }
      })

    setComplexSelects(arr)
  }, [data])

  useEffect(() => {
    if (isShowComplexRow.action === 'edit') {
      const idx_type = getComplexTypes().findIndex(
        (d) => d.label === isShowComplexRow.complexType
      )
      const idx_type_in_selects = complexSelects.findIndex(
        (d) => d.complexType === isShowComplexRow.complexType
      )
      startComplexSelects.current = [
        ...complexSelects[idx_type_in_selects].selects,
      ]
      setComplexType(getComplexTypes()[idx_type])
      setIsShowComplexSelect(true)
    }
  }, [isShowComplexRow])

  useEffect(() => {
    const BreakException = {}
    try {
      data.settings &&
        data.settings.couplings.forEach((d, i) => {
          if (
            d.complexSelect === data.settings.couplings[i].complexSelect &&
            d.name !== data.settings.couplings[i].name
          ) {
            const idx_type = complexSelects.findIndex(
              (f) => f.complexType === d.complexType
            )
            const idx_select = complexSelects[idx_type].selects.findIndex(
              (f) => f.name === d.complexSelect
            )
            setComplexSelects((old) =>
              update(old, {
                [idx_type]: {
                  selects: {
                    [idx_select]: {
                      selectedColumn: {
                        $set: [...data.settings.couplings[i].name],
                      },
                    },
                  },
                },
              })
            )
            throw BreakException
          }
        })
    } catch (e) {
      if (e !== BreakException) throw e
    }
  }, [data.settings ? data.settings.couplings : ''])

  const handleSelectComplexType = (value, action) => {
    if (value !== null) {
      setComplexType(value)
      setComplexSelects((old) =>
        update(old, {
          $push: [
            {
              complexType: value.label,
              color: value.color,
              selects: [...value.selects],
            },
          ],
        })
      )
      setErrorsComplexSelects([])
      handleShowComplexRow(true, 'bind', value.label)
    }
  }

  const handleSetColumnComplexType = (value, dt, action) => {
    const idx_type = complexSelects.findIndex(
      (d) => d.complexType === complexType.label
    )
    const idx_select = complexSelects[idx_type].selects.findIndex(
      (d) => d.name === dt.name
    )
    const idx_error = errorsComplexSelects.length
      ? errorsComplexSelects.findIndex((d) => d.name === dt.name)
      : -1
    const idx_value_deselect =
      action === 'deselect-option' || action === 'remove-value'
        ? complexSelects[idx_type].selects[idx_select].selectedColumn.findIndex(
            (d) => ![...value].map((m) => m.label).includes(d)
          )
        : null

    setComplexSelects((old) =>
      update(old, {
        [idx_type]: {
          selects: {
            [idx_select]: {
              selectedColumn:
                action === 'clear'
                  ? { $set: [] }
                  : action === 'select-option'
                  ? { $push: [value[value.length - 1].label] }
                  : { $splice: [[idx_value_deselect, 1]] },
            },
          },
        },
      })
    )

    setErrorsComplexSelects((old) =>
      idx_error !== -1
        ? update(old, {
            $splice: [[idx_error, 1]],
          })
        : []
    )
  }

  const handleBindColumns = (button) => {
    const idx_type = complexSelects.findIndex(
      (d) => d.complexType === complexType.label
    )
    const _notSelected = complexSelects[idx_type].selects.filter(
      (d) => d.selectedColumn.length
    )
    const _errors = complexSelects[idx_type].selects.filter(
      (d) => !d.selectedColumn.length && d.required
    )
    if (_errors.length) {
      setErrorsComplexSelects(_errors)
    } else if (_notSelected.length) {
      const setts = JSON.parse(JSON.stringify(data.settings))
      const obj = {}

      complexSelects.forEach((d) => {
        d.selects.forEach((dd) => {
          if (dd.selectedColumn.length) {
            obj[dd.label] = [...dd.selectedColumn]
          }
        })
      })
      setts.couplings = obj

      updateDataset({
        id: data.dataset_id,
        settings: setts,
        loaderName: 'bind',
        callback: () => {
          setComplexType('')
          setIsShowComplexSelect(false)
          handleShowComplexRow(false, 'bind', '')
        },
      })
    }
  }

  const handleDeleteComplexColumns = () => {
    const idx_type = complexSelects.findIndex(
      (d) => d.complexType === complexType.label
    )
    const setts = JSON.parse(JSON.stringify(data.settings))
    const _selects = JSON.parse(JSON.stringify(complexSelects))
    const obj = {}

    _selects.splice(idx_type, 1)

    _selects.forEach((d) => {
      d.selects.forEach((dd) => {
        if (dd.selectedColumn.length) {
          obj[dd.label] = [...dd.selectedColumn]
        }
      })
    })
    setts.couplings = obj

    updateDataset({
      id: data.dataset_id,
      settings: setts,
      loaderName: 'delete',
      callback: () => {
        setComplexType('')
        setComplexSelects(_selects)
        setErrorsComplexSelects([])
        setIsShowComplexSelect(false)
        handleShowComplexRow(false, 'bind', '')
      },
    })
  }

  const handleCancelBindColumns = (action) => {
    const idx_type = complexSelects.findIndex(
      (d) => d.complexType === complexType.label
    )

    if (action === 'bind') {
      setComplexType('')
      setComplexSelects((old) =>
        update(old, {
          $splice: [[idx_type, 1]],
        })
      )
      setErrorsComplexSelects([])
      setIsShowComplexSelect(false)
      handleShowComplexRow(false, 'bind', '')
    } else if (action === 'edit') {
      setComplexType('')
      setComplexSelects((old) =>
        update(complexSelects, {
          [idx_type]: {
            selects: { $set: startComplexSelects.current },
          },
        })
      )
      setErrorsComplexSelects([])
      setIsShowComplexSelect(false)
      handleShowComplexRow(false, 'bind', '')
    }
  }

  const handleDisabledComplexTypeSelect = (option) => {
    return (
      complexSelects.length &&
      complexSelects.findIndex((fi) => fi.complexType === option.label) !== -1
    )
  }

  const idx_type = complexSelects.length
    ? complexSelects.findIndex((d) => d.complexType === complexType.label)
    : -1

  const speaker = (
    <Popover
      className="bind_popover_class"
      style={{ marginTop: '9px', marginLeft: '2rem' }}
    >
      <div
        className="m-0"
        style={{
          textTransform: 'none',
          color: 'black',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        Entity Annotation
      </div>
    </Popover>
  )

  return (
    <>
      <div className={classes.generalContainer}>
        <div
          className="types-style"
          style={{ marginRight: '1.5rem', height: 'auto' }}
        >
          <Whisper placement="bottom" speaker={speaker}>
            <div
              className={`header_bind-icon ${
                isShowComplexSelect ? ' open_complex_select ' : ''
              }${
                results_only ||
                isShowComplexRow.action === 'edit' ||
                (isShowComplexRow.action === 'bind' &&
                  isShowComplexRow.isShow &&
                  isShowComplexRow.complexType) ||
                status === 'training' ||
                loading.is ||
                disabled
                  ? ' disabled '
                  : ''
              }`}
              onClick={() => setIsShowComplexSelect(!isShowComplexSelect)}
            >
              <img src={icon_1} alt="" />
            </div>
          </Whisper>
          {isShowComplexSelect ? (
            <Select
              hidden
              name="complextype"
              id="complexType"
              placeholder="Type..."
              className={
                isShowComplexRow.action === 'edit' ||
                (isShowComplexRow.action === 'bind' &&
                  isShowComplexRow.isShow &&
                  isShowComplexRow.complexType) ||
                status === 'training' ||
                loading.is
                  ? 'disabled'
                  : ''
              }
              isClearable={false}
              autoFocus={isShowComplexRow.action !== 'edit'}
              blurInputOnSelect={true}
              defaultMenuIsOpen={isShowComplexRow.action !== 'edit'}
              value={complexType}
              options={getComplexTypes()}
              isOptionDisabled={(option) =>
                handleDisabledComplexTypeSelect(option)
              }
              onChange={(val, { action }) =>
                handleSelectComplexType(val, action)
              }
            />
          ) : null}
        </div>
        {children}
      </div>
      {isShowComplexRow.isShow && complexSelects.length && idx_type !== -1 ? (
        <div
          className={`header-complex-row ${
            status === 'training' || loading.is ? 'disabled' : ''
          }`}
          style={{ padding: 0, marginTop: '.5rem', width: '100%' }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            {complexSelects[idx_type].selects.map((d) => (
              <SelectComponent
                key={`complex_selects_${d.name}`}
                data={data}
                d={d}
                complexSelects={complexSelects}
                errorsComplexSelects={errorsComplexSelects}
                handleSetColumnComplexType={handleSetColumnComplexType}
              />
            ))}
          </div>
          <div className={classes.couplingsButtons}>
            {isShowComplexRow.action === 'edit' ? (
              <Button
                className={classes.buttons}
                color="secondary"
                loading={loading.name === 'delete' && loading.is}
                onClick={() => handleDeleteComplexColumns()}
              >
                Delete
              </Button>
            ) : null}
            <Button
              className={classes.buttons}
              loading={loading.name === 'bind' && loading.is}
              onClick={() => handleBindColumns(isShowComplexRow.action)}
            >
              {isShowComplexRow.action === 'bind' ? 'Annotate' : 'Save'}
            </Button>
            <Button
              color="primary"
              className={classes.buttons}
              onClick={() => handleCancelBindColumns(isShowComplexRow.action)}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
    </>
  )
  // }
}

const mapStateToProps = createStructuredSelector({
  data: getProjectDataState,
  synthData: getProjectSynthDataState,
  status: getProjectStatusState,
  loading: getProjectSettingsLoadingState,
})

const mapDispatchToProps = { updateDataset }

export default connect(mapStateToProps, mapDispatchToProps)(EntityAnnotation)

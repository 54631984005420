import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Routes } from '../../utils'

const useStyles = makeStyles({
  container: {
    margin: 'auto',
    textAlign: 'center',
  },
})

export const ProjectNotFound = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h3>Project does not exist</h3>
      <Link to={Routes.Projects}>Go to Projects list</Link>
    </div>
  )
}

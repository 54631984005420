import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { Spinner } from '../../shared'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: 1,
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default function SpinnerSample({
  classNameRoot = '',
  classNameSpinner = '',
}) {
  const classes = useStyles()
  return (
    <div className={classnames(classes.root, classNameRoot)}>
      <Spinner className={classnames(classNameSpinner)} />
    </div>
  )
}

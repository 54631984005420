import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '../../../shared'

const useStyles = makeStyles({
  header: {
    lineHeight: '36px',
  },
})

export const SandboxStepHeader = ({ label }) => {
  const classes = useStyles()
  return (
    <Typography size="bodyBold" className={classes.header}>
      {label}
    </Typography>
  )
}

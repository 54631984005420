import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tag } from '../../../../shared'

const useStyles = makeStyles({
  DATASET: {
    backgroundColor: '#e3f7ed',
    color: '#45cb85',
  },
  DESIGN: {
    backgroundColor: '#fff5da',
    color: '#ffbe0b',
  },
  SYNTHESIS: {
    backgroundColor: '#dae9ff',
    color: '#3d8afc',
  },
})

export const DatasetTag = ({ label }) => {
  const classes = useStyles()
  return <Tag label={label} className={classes[label]} />
}

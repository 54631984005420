import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'

import { globalActions } from 'services/global/reducer'
import { notificationActions } from 'services/notification/reducer'

import { DataProductsErrors } from 'utils/errors'

import { DatasetResponse } from 'api/generated/modelPkg'
import { dataSetsService } from 'legacy/utils/services'
import { modalActions } from 'services/modal/reducer'
import { addAvatarsToData } from 'services/avatar/service'
import { datasetsActions } from './reducer'

function* handleFetchDatasets() {
  yield put(globalActions.getUserLimits())
  try {
    const datasets: DatasetResponse[] = yield call(dataSetsService.getDatasets)
    const datasetsWithAvatars = yield addAvatarsToData(
      datasets,
      'created_by_avatar_path'
    )

    yield put(datasetsActions.didFetchDatasets(datasetsWithAvatars))
  } catch (error) {
    yield put(datasetsActions.didFetchDatasetsFail())
    yield put(
      notificationActions.showNotification({
        message: DataProductsErrors.DATA_PRODUCTS_FETCH_FAILED,
        severity: 'error',
      })
    )
  }
}

function* handleDeleteDataset({ payload }: PayloadAction<any>) {
  const { id } = payload
  try {
    yield call(dataSetsService.deleteDataset, { id })
    yield put(modalActions.hide())
    yield put(datasetsActions.didDeleteDataset(id))
    // TODO remove api call and replace with store update when proper source_name will be done
    yield put(globalActions.getUserLimits())
  } catch (error) {
    yield put(datasetsActions.didDeleteDatasetFail())
    yield put(
      notificationActions.showNotification({
        message: DataProductsErrors.DATA_PRODUCT_DELETE_FAILED,
        severity: 'error',
      })
    )
  }
}

function* handleUpdateDatasetInfo({ payload }: PayloadAction<any>) {
  const { id, data } = payload
  try {
    yield call(dataSetsService.updateDatasetInfo, { id, data })
    yield call(handleFetchDatasets)
    yield put(datasetsActions.didUpdateDatasetInfo())
    yield put(modalActions.hide())
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: DataProductsErrors.DATA_PRODUCT_UPDATE_FAILED,
        severity: 'error',
      })
    )
    yield put(datasetsActions.didUpdateDatasetInfo())
  }
}

export default function* watchDatasetsSaga() {
  yield takeLatest(datasetsActions.fetchDatasets.type, handleFetchDatasets)
  yield takeLatest(datasetsActions.deleteDataset.type, handleDeleteDataset)
  yield takeLatest(
    datasetsActions.updateDatasetInfo.type,
    handleUpdateDatasetInfo
  )
}

import React from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

export const Notification = (props) => {
  const { text, severity = 'success', open, duration = 6000, hideNotification } = props
  const handleClose = () => hideNotification()

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {text}
      </Alert>
    </Snackbar>
  )
}

Notification.displayName = 'Notification'

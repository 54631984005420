import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IModal {
  isOpened: boolean
  modalType: string | null
  config: any
  isLoading: boolean
}

const initialState: IModal = {
  isOpened: false,
  modalType: null,
  config: {},
  isLoading: false,
}

const modalReducer = createSlice({
  name: 'Modal',
  initialState,
  reducers: {
    show: (
      state,
      { payload }: PayloadAction<{ modalType: any; config?: any }>
    ) => {
      const { modalType, config } = payload
      state.isOpened = true
      state.modalType = modalType
      state.config = config
    },
    hide: (state) => {
      state.isOpened = false
      state.modalType = null
      state.config = {}
      state.isLoading = false
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
  },
})
export const modalActions = {
  ...modalReducer.actions,
}

export default modalReducer.reducer

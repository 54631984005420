import { createSelector } from 'reselect'
import { getProjectState } from '../projectSelectors'

export const getProjectListState = createSelector(
  getProjectState,
  (state) => state.report
)

export const getProjectBlocksState = createSelector(
  getProjectListState,
  (state) => state.blocks
)

export const getProjectLoadingState = createSelector(
  getProjectListState,
  (state) => state.loading
)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Spinner } from '../../../shared'

const spinnerSize = 30

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 10,
    left: '50%',
    marginLeft: `-${spinnerSize / 2}px`,
    width: `${spinnerSize * 2}px`,
    height: '36px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    opacity: '0.9',
  },
}))

const LoaderComponent = () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Spinner size={spinnerSize} />
    </Box>
  )
}

export default LoaderComponent

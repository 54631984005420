import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { uploadDatasetActions } from 'services/uploadDataset/reducer'
import UploadFileForm from 'components/UploadFileForm/UploadFileForm'
import { createStructuredSelector } from 'reselect'
import { getIsFileUploading } from 'services/uploadDataset/selectors'

type DispatchProps = typeof mapDispatchToProps
interface ISelectedProps {
  isFileUploading: boolean
}

export const UploadDatasetForm: React.FunctionComponent<
  DispatchProps & ISelectedProps
> = ({ isFileUploading, uploadDataset, reset, closeDrawer }) => {
  const classes = useStyles()
  const handleCancel = () => {
    reset()
    closeDrawer()
  }

  return (
    <Box p={2} className={classes.formContainer}>
      <UploadFileForm
        isFileUploading={isFileUploading}
        multiple={false}
        handleCancel={handleCancel}
        handleUpload={uploadDataset}
      />
    </Box>
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  isFileUploading: getIsFileUploading,
})

const mapDispatchToProps = {
  uploadDataset: uploadDatasetActions.uploadDataset,
  reset: uploadDatasetActions.reset,
  closeDrawer: uploadDatasetActions.closeDrawer,
}

const useStyles = makeStyles({
  formContainer: {
    height: '100%',
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(UploadDatasetForm)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { CleanRoomStatuses } from 'services/cleanrooms/constants'
import { SwitchCase } from 'legacy/shared/components'

const RunningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <circle cx="12" cy="12" r="12" fill="#d3fddd" />
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M8 5v14l11-7z" fill="#168F3C" />
  </svg>
)

const StopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <circle cx="12" cy="12" r="12" fill="#EAECF0" />
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M6 6h12v12H6z" fill="#464648" />
  </svg>
)

const PendingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <circle cx="12" cy="12" r="12" fill="#FEF2A7" />
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"
      fill="#A36217"
    />
  </svg>
)

const FailedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <circle cx="12" cy="12" r="12" fill="#FE4E5C" />
  </svg>
)

const useStyles = makeStyles({
  status: {
    display: 'inline-flex',
    alignSelf: 'center',
    marginRight: '6px',
    '& svg': {
      height: '1em',
      width: '1em',
    },
  },
  baseline: {
    '& svg': {
      top: '.125em',
      position: 'relative',
    },
  },
})

export const CleanRoomStatus = ({ status }: { status: CleanRoomStatuses }) => {
  const classes = useStyles()
  return (
    <SwitchCase value={status}>
      {{
        [CleanRoomStatuses.RUNNING]: (
          <>
            <div className={classnames(classes.status, classes.baseline)}>
              <RunningIcon />
            </div>
            Running
          </>
        ),
        [CleanRoomStatuses.STOPPED]: (
          <>
            <div className={classnames(classes.status, classes.baseline)}>
              <StopIcon />
            </div>
            Stopped
          </>
        ),
        [CleanRoomStatuses.PENDING]: (
          <>
            <div className={classnames(classes.status, classes.baseline)}>
              <PendingIcon />
            </div>
            Pending
          </>
        ),
        [CleanRoomStatuses.FAILED]: (
          <>
            <div className={classnames(classes.status, classes.baseline)}>
              <FailedIcon />
            </div>
            Failed
          </>
        ),
      }}
    </SwitchCase>
  )
}

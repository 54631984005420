import React, { memo, useEffect, useRef, useCallback } from 'react'
import { isEqual } from 'lodash'
import * as d3 from 'd3-legacy'
import { connect } from 'react-redux'
import { scenarioActions } from 'services/project/scenarios/reducer'

import {
  prepareDomainsHorizontal,
  updateHorizontalAxes,
  renderHorizontalBarChart,
  buildData,
} from '../../../components/Projects/chartComponents'

import '../../../components/Chart.scss'

const margin = {
  top: 0,
  right: 25,
  bottom: 25,
  left: 70,
}

const HorizontalBarChart = memo(
  (props) => {
    const {
      index,
      height: __height,
      transform_data,
      xAxisData,
      plot_type,
      name,
      truncated,
      settings,
      dataset_id,
    } = props.config
    const { status, disableDragBars, updateGroup, group, from } = props

    const _height = __height || 190
    const width = 300 - margin.left - margin.right
    const height = _height - margin.top - margin.bottom

    const svgRef = useRef()
    const svgScrollRef = useRef()
    const svg = useRef()
    const svgScroll = useRef()
    const g = useRef()
    const x = useRef()
    const x1 = useRef()
    const y = useRef()
    const bandwidth = useRef()
    const temp_transform_arr = useRef()
    const temp_keys = useRef()
    const tooltip = useRef()

    useEffect(() => {
      initGraph()
    }, [])

    useEffect(() => {
      // build data
      const { transform_arr, keys } = _buildData({
        transform_data,
        xAxisData,
        plot_type,
      })
      temp_transform_arr.current = transform_arr
      temp_keys.current = keys

      // update domains
      _prepareDomainsHorizontal({
        svg: svg.current,
        x,
        x1,
        y,
        width,
        height,
        margin,
        bandwidth,
        truncated,
        transform_arr,
        keys,
        xAxisData,
      })

      _renderHorizontalBarChart({
        data: transform_arr,
        col_name: name,
        svgScroll: svgScroll.current,
        g: g.current,
        tooltip: tooltip.current,
        x: x.current,
        x1: x1.current,
        y: y.current,
        bandwidth: bandwidth.current,
        keys,
        disableDragBars,
        truncated,
        width,
        height,
        margin,
        settings,
        temp_transform_arr: temp_transform_arr.current,
        temp_keys: temp_keys.current,
        dataset_id,
        from,
        updateSettingsGroup: (settings) =>
          updateGroup({
            id: group.id,
            value: settings,
            key: 'conditions',
          }),
      })
      // update axes
      _updateHorizontalAxes({
        svg: svg.current,
        svgScroll: svgScroll.current,
        margin,
        height,
        x: x.current,
        y: y.current,
      })
    }, [props.config, group])

    useEffect(() => {
      // disable drag bar
      if (status === 'training' || disableDragBars) {
        disabledDragMoveElement()
      }
    }, [status, disableDragBars])

    const _buildData = useCallback((props) => buildData(props))
    const _prepareDomainsHorizontal = useCallback((props) =>
      prepareDomainsHorizontal(props)
    )
    const _renderHorizontalBarChart = useCallback((props) =>
      renderHorizontalBarChart(props)
    )
    const _updateHorizontalAxes = useCallback((props) =>
      updateHorizontalAxes(props)
    )

    const initGraph = useCallback(() => {
      tooltip.current = d3.select('.tooltip')

      if (!tooltip.current.node()) {
        tooltip.current = d3
          .select('body')
          .append('div')
          .attr('class', 'tooltip')
          .style('opacity', 0)
      }

      svg.current = d3
        .select(svgRef.current)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)

      // x axis
      svg.current.append('g').attr('class', 'axis axis-x')

      // y axis
      svg.current.append('g').attr('class', 'axis axis-y')

      g.current = svg.current
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      // add bar chart
      g.current.append('g').attr('class', 'barChart')

      // add bar chart move element
      g.current.append('g').attr('class', 'moveElement')

      svgScroll.current = d3.select(svgScrollRef.current)
      svgScroll.current
        .attr('width', width + margin.left + margin.right - 1)
        .attr('height', height + margin.top + margin.bottom)
        .style('position', 'absolute')
        .style('pointer-events', 'none')
      svgScroll.current.append('g').attr('class', 'axis axis-x')
    })

    const disabledDragMoveElement = useCallback(() => {
      svg.current
        .select('.moveElement')
        .selectAll('rect')
        .style('pointer-events', 'none')
    })

    return (
      <div
        style={{
          height: _height,
          position: 'relative',
          display: 'block',
          marginTop: '10px',
        }}
        id={index || ''}
      >
        <svg ref={svgScrollRef} />
        <div
          style={{
            overflowY: 'auto',
            height,
          }}
        >
          <svg ref={svgRef} />
        </div>
      </div>
    )
  },
  (prev, next) => isEqual(prev, next)
)

const mapDispatchToProps = {
  updateGroup: scenarioActions.updateGroup,
}

export default connect(null, mapDispatchToProps)(HorizontalBarChart)

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notificationActions } from 'services/notification/reducer'
import { dataSourceService } from 'legacy/utils/services'
import User from '../Account/User'
import {
  actionInitQueryData,
  actionStartLoadingDatasource,
  actionStopLoadingReport,
} from '../../store/actions'

import DropContainer from './drag_drop_components/DropContainer'
import TablesContainer from './drag_drop_components/TablesContainer'

import './Source.scss'

class SourceDragDrop extends React.Component {
  state = {
    local_selected_cells: [],
  }

  componentDidMount() {
    const { query_data } = this.props

    if (
      Object.keys(query_data).length &&
      query_data.meta !== null &&
      Object.keys(query_data.meta).length
    ) {
      this.setState({
        local_selected_cells: this.props.query_data.meta.columns,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.query_data) !==
        JSON.stringify(this.props.query_data) &&
      Object.keys(nextProps.query_data).length
    ) {
      if (
        nextProps.query_data.meta !== null &&
        Object.keys(nextProps.query_data.meta).length
      ) {
        this.setState({
          local_selected_cells: nextProps.query_data.meta.columns,
        })
      } else if (nextProps.query_data.meta === null) {
        this.setState({
          local_selected_cells: [],
        })
      }
    }
  }

  checkConnectivity = (table, t_names) => {
    const { connected_tables } = this.props
    const table_names = [...t_names] // make copy names
    const idx = table_names.findIndex((d) => d === table) // find index recieved table in props
    table_names.splice(idx, 1) // delete this table from from tables array

    // if array tables empty mean the remaining tables are connected
    if (table_names.length === 0) {
      return true
    }
    // else goes through each foreign table and see if there are relations with the array tables
    for (let i = 0; i < connected_tables[table].length; i++) {
      let q = ''
      // if find the connection continue the search
      if (table_names.indexOf(connected_tables[table][i]) !== -1) {
        q = this.checkConnectivity(connected_tables[table][i], table_names)
      }
      if (q) return true
    }

    return false
  }

  findUniqueTables = (tables) => {
    const _unique = tables.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    return _unique
  }

  handleDrop = (dropResult) => {
    const { query_data } = this.props
    const { local_selected_cells } = this.state
    let is_connectivity = true

    // if make delete table and number of tables > 2
    if (
      local_selected_cells.length > 2 &&
      dropResult.removedIndex !== null &&
      dropResult.addedIndex === null
    ) {
      const cells_copy = JSON.parse(JSON.stringify(local_selected_cells)) // make copy current state
      const removedTable = cells_copy[dropResult.removedIndex].table // get name removed table
      cells_copy.splice(dropResult.removedIndex, 1) // delete selected table from state copy
      const table_names = cells_copy.map((d) => d.table) // get table names only
      const rem_tables = table_names.indexOf(removedTable) // check removed table in remaining tables
      if (rem_tables === -1) {
        const _uniqueTables = this.findUniqueTables(table_names) // unique values
        is_connectivity = this.checkConnectivity(table_names[0], _uniqueTables) // call function that check on connectivity
      } else {
        is_connectivity = true
      }
    }

    // check connectivity tables
    if (is_connectivity) {
      const drop_zone_cols = this.applyDrag(
        this.state.local_selected_cells,
        dropResult
      )

      const datasourceId = +this.props.match.params.id
      const idx_curr_query = JSON.parse(User.queries)
        .map((d) => d.datasource_id)
        .indexOf(datasourceId)

      const settings = drop_zone_cols.map((d) => {
        return { table: d.table, column: d.name }
      })

      this.props.actionStartLoadingDatasource()
      const id = JSON.parse(User.queries)[idx_curr_query].query_id
      this.setState(
        {
          local_selected_cells: drop_zone_cols,
        },
        () => {
          dataSourceService
            .updateQuerySettings({
              id,
              data: { settings: { columns: settings } },
            })
            .then(() => {
              return dataSourceService.getQuery(id)
            })
            .then((dt) => {
              this.props.actionInitQueryData(dt)
              this.props.actionStopLoadingReport()
            })
            .catch((e) => {
              this.props.showNotification({
                message: e.message,
                severity: 'error',
              })
              if (
                Object.keys(query_data).length &&
                query_data.meta !== null &&
                Object.keys(query_data.meta).length
              ) {
                this.setState(
                  {
                    local_selected_cells: this.props.query_data.meta.columns,
                  },
                  () => this.props.actionStopLoadingReport()
                )
              } else {
                this.setState(
                  {
                    local_selected_cells: [],
                  },
                  () => this.props.actionStopLoadingReport()
                )
              }
            })
        }
      )
    } else {
      this.props.showNotification({
        message: 'Something went wrong...',
        severity: 'error',
      })
    }
  }

  applyDrag = (items, dropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult
    if (removedIndex === null && addedIndex === null) return items

    const result = Array.from(items)
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    return result
  }

  render() {
    const { source_tables, query_data, connected_tables } = this.props
    return (
      <div className="uk-width-expand main_source custom_height_style">
        <div
          className="uk-text-center custom_height_style"
          data-uk-grid
          style={{ flexDirection: 'column' }}
        >
          <div className="drop_container custom_width_style">
            <div className="uk-width-expand custom_width_style">
              <DropContainer
                items={this.state.local_selected_cells}
                onDrop={this.handleDrop}
              />
            </div>
          </div>
          <div
            className="uk-margin-remove-top uk-width-expand tables_container custom_height_style"
            style={{ overflow: 'hidden' }}
          >
            <div className="uk-width-expand custom_height_style">
              <TablesContainer
                items={source_tables}
                query_data={query_data}
                connected_tables={connected_tables}
                onDrop={this.handleDrop}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { source_tables, query_data, connected_tables } = state.data
  return {
    ...state,
    source_tables: source_tables || [],
    connected_tables: connected_tables || {},
    query_data: query_data || {},
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionInitQueryData: (data) => dispatch(actionInitQueryData(data)),
    actionStartLoadingDatasource: () =>
      dispatch(actionStartLoadingDatasource()),
    actionStopLoadingReport: () => dispatch(actionStopLoadingReport()),
    showNotification: notificationActions.showNotification,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SourceDragDrop))

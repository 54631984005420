import React from 'react'

import PlaceholderCE from 'components/placeholderCE'
import sandboxes_upgrade_image from 'legacy/images/icons_svg/sandboxes_free_tier.svg'

const data = {
  url: sandboxes_upgrade_image,
  headerTitle: 'Data clean rooms',
  title: 'Upgrade your account to access Data Clean Rooms',
  subtitles: [
    {
      title:
        'Data Clean Rooms empower secure data sharing and collaboration across internal groups, remote teams and partners to increase productivity.',
    },
    {
      title:
        'Data Clean Rooms are isolated environments ready for use within minutes.',
    },
    {
      title: 'Risk-free and without any delays.',
    },
    {
      title:
        'The perfect solution for sharing sensitive data, enabling you to:',
    },
  ],
  checks: [
    {
      title:
        'Securely partner up on proof-of-concepts with 3rd party organizations.',
    },
    {
      title: 'Distribute safely large volumes of test data to remote teams.',
    },
    {
      title: 'Collaborate with your team on any data project.',
    },
  ],
}

const CleanRoomsCE = () => {
  return <PlaceholderCE data={data} />
}

export default CleanRoomsCE

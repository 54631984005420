import { SandboxesConfirmActions } from './actions'
import { SandboxesBuildActions } from '../actions'

const getSandboxConfirmDefaultState = () => ({
  reducerState: 'regular',
  serverError: null,
})

export default (state = getSandboxConfirmDefaultState(), action) => {
  switch (action.type) {
    case SandboxesConfirmActions.BUILD_SANDBOX: {
      return { ...state, serverError: null, reducerState: 'saving' }
    }

    case SandboxesConfirmActions.DID_BUILD_SANDBOX_FAIL: {
      return { ...state, reducerState: 'regular', serverError: action.message }
    }

    case SandboxesBuildActions.CANCEL_BUILD_SANDBOX:
    case SandboxesConfirmActions.DID_BUILD_SANDBOX: {
      return { ...state, reducerState: 'regular', serverError: null }
    }

    default:
      return state
  }
}

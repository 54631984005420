import { createSelector } from 'reselect'
import { IStore } from 'core/rootReducer'

export const selectTemplatesState = (state: IStore) => state.templates

export const selectTemplateList = createSelector(
  selectTemplatesState,
  (templates) => Object.values(templates.byId)
)

export const selectTemplatesStatus = createSelector(
  selectTemplatesState,
  (templates) => templates.status
)

export const selectIsDatasetCreating = createSelector(
  selectTemplatesState,
  (templates) => templates.isDatasetCreating
)

export const selectSelectedTemplate = createSelector(
  selectTemplatesState,
  (templates) => templates.selectedTemplateId
)

export const selectServerError = createSelector(
  selectTemplatesState,
  (templates) => templates.serverError
)

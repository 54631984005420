import { colors } from 'legacy/shared'
import { VALUE_COLORS } from 'services/createTestingSuite/constants'

export function humanFileSize(bytesInput: number, precision: number = 1) {
  const threshold = 1024
  let bytes = bytesInput
  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let position = -1
  const r = 10 ** position

  do {
    bytes /= threshold
    position += 1
  } while (
    Math.round(Math.abs(bytes) * r) / r >= threshold &&
    position < units.length - 1
  )

  return `${bytes.toFixed(precision)} ${units[position]}`
}

export const getColor = (
  value: number,
  good: number = VALUE_COLORS.GOOD,
  normal: number = VALUE_COLORS.NORMAL
) => {
  if (value < normal) {
    return { color: colors.lightPink, textColor: colors.textRed }
  }
  if (value < good) {
    return { color: colors.lightYellow, textColor: colors.textYellow }
  }
  return { color: colors.lightGreen, textColor: colors.textGreen }
}

export const selectGeneratedData = (generatedData: any) => {
  if (generatedData) {
    const tables: any = []
    Object.entries(generatedData).forEach(([title, data]: [string, any]) => {
      const table: any = {
        title,
        rows: [],
      }
      const [firstColumn] = Object.values(data) as any
      const columns = Object.keys(data)
      const { length } = firstColumn

      for (let rowIndex = 0; rowIndex < length; ++rowIndex) {
        const row: any = {}

        columns.forEach((column: string) => {
          row[column] = data[column][rowIndex]
        })

        table.rows.push(row)
      }

      tables.push(table)
    })
    return tables
  }
  return []
}

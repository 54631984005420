import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import { IconButton, IconButtonProps } from '@material-ui/core'

export const CloseButton: FC<IconButtonProps> = (props) => {
  const classes = useStyles()
  return (
    <IconButton {...props}>
      <Close className={classes.closeIcon} />
    </IconButton>
  )
}

const useStyles = makeStyles({
  closeIcon: {
    width: 12,
    height: 12,
  },
})

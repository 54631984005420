import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    paddingLeft: '1rem',
  },
  primary: {
    borderLeftColor: theme.palette.primary.light,
  },
  secondary: {
    borderLeftColor: theme.palette.secondary.light,
  },
  error: {
    borderLeftColor: theme.palette.error.light,
  },
  warning: {
    borderLeftColor: theme.palette.warning.light,
  },
  info: {
    borderLeftColor: theme.palette.info.light,
  },
  success: {
    borderLeftColor: theme.palette.success.light,
  },
}))

export const HighlightSection = ({
  children,
  color = 'info',
  className = '',
}) => {
  const classes = useStyles()
  const highlightSectionClassName = classnames(
    classes.root,
    classes[color],
    className
  )
  return <div className={highlightSectionClassName}>{children}</div>
}

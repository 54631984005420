import { takeLatest, put, call } from 'redux-saga/effects'

import { dataSetsService } from 'legacy/utils/services'
import { globalActions } from './reducer'

function* handleGetUserLimits() {
  try {
    const response = yield call(dataSetsService.getUserLimits)
    yield put(globalActions.didGetUserLimits(response))
  } catch (error) {
    // TODO set to community edition limit
  }
}

export default function* watchGlobalSaga() {
  yield takeLatest(globalActions.getUserLimits.type, handleGetUserLimits)
}

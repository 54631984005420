import React, { FC } from 'react'
import { Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'UIkit/Button/Button'
import { colors } from 'legacy/shared'

interface IAddButton {
  title: string
  handleClick: () => void
}

export const AddButton: FC<IAddButton> = ({ title, handleClick }) => {
  const classes = useStyles()

  return (
    <Button
      disableElevation
      startIcon={<Add />}
      color="primary"
      className={classes.controlButton}
      onClick={handleClick}
      variant="outlined"
    >
      {title}
    </Button>
  )
}

const useStyles = makeStyles(() => ({
  controlButton: {
    color: colors.buttonDefault,
  },
}))

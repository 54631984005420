import { makeRequest } from './apiClient'

const concatDatasetsNames = (datasets) => {
  return datasets
    .map((dataset) => dataset.title || '')
    .join(', ')
    .trim()
}

export default {
  async getSandboxes() {
    const response = await makeRequest({
      path: `sandbox`,
      method: 'GET',
    })
    const { sandboxes } = response
    return sandboxes.map((sandbox) => ({
      ...sandbox,
      ...{
        dataset: sandbox.dataset_list
          ? concatDatasetsNames([
              ...sandbox.dataset_list,
              ...sandbox.synthesis_list,
            ])
          : '',
        dbServer: 'FTP',
        os: 'Ubuntu',
        owner: sandbox.user
          ? `${sandbox.user.first_name} ${sandbox.user.last_name}`
          : '-',
      },
    }))
  },

  async createSandbox(sandbox) {
    const response = await makeRequest({
      path: `sandbox`,
      method: 'POST',
      params: JSON.stringify(sandbox),
    })
    return response
  },

  async shareSandbox({ id, email }) {
    await makeRequest({
      path: `sandbox/${id}/email`,
      method: 'POST',
      params: JSON.stringify({ recipient_list: [email] }),
    })
  },

  async deleteSandbox({ id }) {
    const response = await makeRequest({
      path: `sandbox/${id}`,
      method: 'DELETE',
    })
    return response
  },

  async stopSandbox({ id }) {
    const response = await makeRequest({
      path: `sandbox/${id}/stop`,
      method: 'POST',
    })
    return response
  },

  async runSandbox({ id }) {
    const response = await makeRequest({
      path: `sandbox/${id}/continue`,
      method: 'POST',
    })
    return response
  },
}

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { useDebouncedCallback } from 'use-debounce'
import { IStore } from 'core/rootReducer'

import { RequestStatus } from 'utils/constants'
import { TextField, Table, Spinner, SwitchCase } from 'legacy/shared'

import { cleanRoomsActions } from 'services/cleanrooms/reducer'
import {
  getCleanRoomsList,
  getCleanRoomPageState,
  getSearchStringValue,
} from 'services/cleanrooms/selectors'

import { modalActions } from 'services/modal/reducer'
import { Alert } from 'UIkit'
import { prepareItemHandlers } from './prepareItemHandlers'
import { ActionMenu } from './ActionMenu'
import { CleanRoomStatus } from './CleanRoomStatus'

const useStyles = makeStyles({
  tableLayout: {
    paddingBottom: '40px',
  },
  statusWidth: {
    minWidth: '110px',
  },
  actionWidth: {
    minWidth: '60px',
  },
})

const SpinnerSection = (props: { 'data-testid': string }) => {
  return (
    <div
      data-testid={props['data-testid']}
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
      }}
    >
      <Spinner size={32} />
    </div>
  )
}

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

export const Sandboxes = ({
  cleanRoomsList,
  pageStatus,
  searchString,
  fetchCleanRooms,
  setSearchValue,
  showModal,
  ...itemActions
}: SelectedProps & DispatchProps) => {
  useEffect(() => {
    fetchCleanRooms()
  }, [fetchCleanRooms])

  const { callback } = useDebouncedCallback(
    (value) => setSearchValue({ searchString: value }),
    300
  )

  const classes = useStyles()

  const itemHandlers = prepareItemHandlers(itemActions, showModal)

  return (
    <SwitchCase value={pageStatus}>
      {{
        [RequestStatus.Succeeded]: (
          <>
            <TextField
              id="cleanroom_search"
              defaultValue={searchString}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                callback(e.target.value)
              }
              label="Search"
              name="search"
              placeholder="Search..."
              autoComplete="off"
              variant="outlined"
            />
            <div className={classes.tableLayout}>
              <Table
                searchString={searchString}
                headerRow={[
                  { id: 'name', label: 'NAME', sortable: true },
                  { id: 'dataset', label: 'DATASET(S)', sortable: true },
                  { id: 'dbServer', label: 'DATABASE SERVER', sortable: true },
                  { id: 'os', label: 'OPERATING SYSTEM', sortable: true },
                  {
                    id: 'status',
                    label: 'STATUS',
                    class: classes.statusWidth,
                    sortable: true,
                  },
                  { id: 'created_at', label: 'DATE', sortable: true },
                  { id: 'owner', label: 'OWNER', sortable: true },
                  { id: 'actions', label: '', class: classes.actionWidth },
                ]}
                bodyRow={[
                  'name',
                  'dataset',
                  'dbServer',
                  'os',
                  ({ row }: any) => <CleanRoomStatus status={row.status} />,
                  'created_at',
                  'owner',
                  ({ row }: any) => (
                    <ActionMenu itemHandlers={itemHandlers} cleanRoom={row} />
                  ),
                ]}
                filterString={searchString}
                rows={cleanRoomsList}
                defaultSort={{ name: 'created_at', order: 'desc' }}
                dataTestidPrefix="cleanroom"
                emptyMessage="Create your first cleanroom"
              />
            </div>
          </>
        ),
        [RequestStatus.Loading]: (
          <SpinnerSection data-testid="cleanrooms_loading" />
        ),
        [RequestStatus.Failed]: (
          <Alert severity="error">Cleanrooms are not available</Alert>
        ),
      }}
    </SwitchCase>
  )
}

const mapStateToProps = (state: IStore) => ({
  cleanRoomsList: getCleanRoomsList(state),
  searchString: getSearchStringValue(state),
  pageStatus: getCleanRoomPageState(state),
})

const mapDispatchToProps = {
  fetchCleanRooms: cleanRoomsActions.fetchCleanRooms,
  setSearchValue: cleanRoomsActions.setSearchValue,

  deleteCleanRoom: cleanRoomsActions.deleteCleanRoom,
  runCleanRoom: cleanRoomsActions.runCleanRoom,
  stopCleanRoom: cleanRoomsActions.stopCleanRoom,
  copyToClipboard: cleanRoomsActions.copyToClipboard,
  setCleanRoomToShare: cleanRoomsActions.setCleanRoomToShare,

  showModal: modalActions.show,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sandboxes)

import { makeRequest } from './apiClient'

export default {
  async userGetAvatar({ avatarPath }) {
    const avatarFile = await makeRequest({
      path: avatarPath,
      method: 'GET',
      type: 'image',
    })
    return avatarFile
  },

  async userUploadAvatar(formData) {
    await makeRequest({
      path: 'profile/avatar',
      method: 'PUT',
      params: formData,
      formData: true,
    })
  },

  async userUpdateInfo(data) {
    const user = await makeRequest({
      path: 'profile',
      method: 'PUT',
      params: JSON.stringify(data),
    })
    return user
  },
}

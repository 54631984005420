import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { DataViewGroup } from './DataViewGroup'

interface IDataViewProps {
  data: any
  sqlOnly?: boolean
}

export const DataView: FC<IDataViewProps> = ({ sqlOnly, data }) => (
  <Box>
    {Array.isArray(data) &&
      data.map((dataGroup: any, index: number) => (
        <DataViewGroup
          sqlOnly={sqlOnly}
          key={dataGroup.title}
          data={dataGroup}
          index={index}
        />
      ))}
  </Box>
)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'

import { DatasourceResponse } from 'api/generated/modelPkg'
import { RequestStatus } from 'utils/constants'
import { arrayToObjectById } from 'utils/arrayToObjectById'

import { IDataSourcesState } from '../types'

const prefix = 'dataSources'

const initialState: IDataSourcesState = {
  byId: {},
  selectedId: null,
  status: RequestStatus.Idle,
  saveStatus: RequestStatus.Idle,
  checkStatus: RequestStatus.Idle,
}

const dataSourcesReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchDataSources: (state) => {
      state.status = RequestStatus.Loading
    },
    didFetchDataSources: (
      state,
      { payload }: PayloadAction<DatasourceResponse[]>
    ) => {
      state.byId = arrayToObjectById(payload, 'id')
      state.status = RequestStatus.Succeeded
    },
    didFetchDataSourcesFail: (state) => {
      state.byId = {}
      state.status = RequestStatus.Failed
    },
    deleteDataSource: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.byId = omit(state.byId, payload.id)
      state.selectedId = null
    },
    updateDataSource: (
      state,
      { payload }: PayloadAction<DatasourceResponse>
    ) => {
      state.byId[payload.id] = payload
    },
    createDataSource: (state) => {
      state.saveStatus = RequestStatus.Loading
    },
    didCreateDataSource: (
      state,
      { payload }: PayloadAction<DatasourceResponse>
    ) => {
      state.byId[payload.id] = payload
      state.saveStatus = RequestStatus.Idle
    },
    didCreateDataSourceFail: (state) => {
      state.saveStatus = RequestStatus.Idle
    },
    checkDataSource: (state) => {
      state.checkStatus = RequestStatus.Loading
    },
    didCheckDataSource: (state) => {
      state.checkStatus = RequestStatus.Succeeded
    },
    initCheckStatus: (state) => {
      state.checkStatus = RequestStatus.Idle
    },
    selectDatasource: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.selectedId = payload.id
    },
  },
})

export const dataSourcesActions = dataSourcesReducer.actions

export default dataSourcesReducer.reducer

import React from 'react'

export const TemplateIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 12H12C10.8954 12 10 12.8954 10 14V34C10 35.1046 10.8954 36 12 36H16C17.1046 36 18 35.1046 18 34V14C18 12.8954 17.1046 12 16 12Z" />
    <path d="M36 12H24C22.8954 12 22 12.8954 22 14V16C22 17.1046 22.8954 18 24 18H36C37.1046 18 38 17.1046 38 16V14C38 12.8954 37.1046 12 36 12Z" />
    <path d="M36 22H24C22.8954 22 22 22.8954 22 24V34C22 35.1046 22.8954 36 24 36H36C37.1046 36 38 35.1046 38 34V24C38 22.8954 37.1046 22 36 22Z" />
  </svg>
)

import { useEffect, useState, useRef } from 'react'

// Hook for getting async state
function useAsyncState(initialValue) {
  const [state, setState] = useState(initialValue)
  const stateRef = useRef(state)
  const setter = (x) =>
    new Promise((resolve) => {
      setState(x)
      resolve(x)
    })
  stateRef.current = state
  return [state, stateRef, setter]
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export { useAsyncState, usePrevious }

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const prefix = 'productTour'

export interface Tip {
  id: string
  step: number
  run: boolean
  next?: string
}

interface Tips {
  [key: string]: Tip
}
export interface ProductTourState {
  tips: Tips
  activeTipId: string
  isTourEnabled: boolean
}

const initialState: ProductTourState = {
  tips: {
    '0': { id: '0', step: 0, run: false, next: '1' },
    '1': { id: '1', step: 0, run: false, next: '2' },
    '2': { id: '2', step: 0, run: false },
  },
  activeTipId: '',
  isTourEnabled: false,
}

const productTourReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setIsTourEnabled: (
      state,
      { payload }: PayloadAction<{ isEnabled: boolean }>
    ) => {
      state.isTourEnabled = payload.isEnabled
    },
    startTip: (state, { payload }: PayloadAction<{ tip: string }>) => {
      state.tips[payload.tip].run = true
      state.tips[payload.tip].step = 0
      state.activeTipId = payload.tip
    },
    startNextTip: (state) => {
      state.tips[state.activeTipId].run = false
      state.activeTipId = state.tips[state.activeTipId].next
      state.tips[state.activeTipId].run = true
      state.tips[state.activeTipId].step = 0
    },
    pauseTip: (state, { payload }: PayloadAction<{ tip?: string }>) => {
      if (state.activeTipId) {
        state.tips[payload.tip || state.activeTipId].run = false
      }
    },
    nextStep: (state) => {
      state.tips[state.activeTipId].step =
        state.tips[state.activeTipId].step + 1
    },
    switchStep: (
      state,
      { payload }: PayloadAction<{ tip: string; step?: number }>
    ) => {
      state.tips[payload.tip].run = true
      state.tips[payload.tip].step = payload.step
    },
    skipTour: (state) => {},
    restart: (state) => {},
    didRestart: (state) => {
      state.activeTipId = ''
      state.tips = initialState.tips
      state.isTourEnabled = true
    },
  },
})

export const productTourActions = productTourReducer.actions

export default productTourReducer.reducer

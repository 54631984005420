import {
  takeLatest,
  fork,
  put,
  call,
  delay,
  race,
  take,
  select,
} from 'redux-saga/effects'

import { globalActions } from 'services/global/reducer'
import { getProjectRunningTaskListState } from 'services/global/selectors'

import { modalActions } from 'services/modal/reducer'
import {
  ProjectActions,
  didFetchOriginalDataset,
  didUpdateTitle,
  didUpdateDataset,
  didUpdateDatasetFail,
  didUpdateDatasetColumnName,
  didFetchPreviewData,
  updateStatus,
  setPreprocessingStatus,
  didLoadingDatasetFail,
  didStopTraining,
  didStopTrainingFail,
  didTraining,
  didTrainingFail,
  setNotFoundPage,
} from '../actions'

import { trainingStatus } from '../constants'
import { startBiasAndQuality } from '../biasAndQuality/actions'
import { handleResumeTasks } from '../biasAndQuality/sagas'
import { submitAction, didSubmitActionFail } from '../modals/actions'
import { dataSetsService } from '../../../utils/services'
import { initData } from '../../../services/helper'
import { transformToBECoupling } from '../helper'
import { getProjectId, getEstimatedTimeState } from '../projectSelectors'
import { startLoadingSettings, stopLoadingSettings } from '../settings/actions'

import { updateSynthesisSetting } from '../projectSettings/actions'
import { getProjectsSettingState } from '../projectSettings/selectors'
import { trackEvent } from '../../../utils'
import { notificationActions } from '../../../../services/notification/reducer'

const TimeEstimator = {
  recalculationDone: false,
  iterations: [],

  checkRecalculateConditions(current, total) {
    if (!this.recalculationDone && current >= total / 2) {
      this.recalculationDone = true
      return true
    }
  },

  estimateTime(prevTime, previewData) {
    this.iterations.push(previewData.iteration)
    if (this.iterations.length < 2 || !previewData.iteration) {
      return null
    }
    const interval = 5000
    const step = 100 // TODO add more smart logic here

    const remainingIterations =
      previewData.total_iterations - previewData.iteration

    const isRecalculation = this.checkRecalculateConditions(
      previewData.iteration,
      previewData.total_iterations
    )

    if (prevTime === null || isRecalculation) {
      return (remainingIterations / step) * interval
    }

    return prevTime - interval
  },
}

function* generatePreprocessingStatuses() {
  yield 'Understanding types...'
  while (true) {
    yield 'Building a model...'
  }
}

function* handleStartTraining({ id }) {
  try {
    yield call(dataSetsService.startModelTraining, id)
    yield put(globalActions.updateTrainTask({ mode: 'add' }))
    yield fork(handleContinueTraining, { id })
  } catch (error) {
    if (error?.message?.includes('Too many tasks running for free tier')) {
      yield put(
        notificationActions.showNotification({
          message: `${error}. Please try again later.`,
          severity: 'warning',
        })
      )
    }
    yield put(updateStatus(trainingStatus.Init))
  }
}

export function* handleStatusPooling(id) {
  const getPreprocessStatus = generatePreprocessingStatuses()
  TimeEstimator.iterations = []
  while (true) {
    yield delay(5000)
    try {
      const modelInfo = yield call(dataSetsService.getModelInfo, id)

      if (
        modelInfo?.status === 'STATUS_QUEUED' ||
        modelInfo?.status === 'STATUS_STARTED'
      ) {
        if (modelInfo.preview) {
          const time = yield select(getEstimatedTimeState)
          const estimatedTime = TimeEstimator.estimateTime(
            time,
            modelInfo.preview
          )

          yield put(
            didFetchPreviewData({
              data: initData(modelInfo.preview),
              estimatedTime,
            })
          )
        } else {
          const { value } = getPreprocessStatus.next()
          yield put(setPreprocessingStatus(value))
        }
      }

      if (modelInfo?.status === 'STATUS_FINISHED') {
        yield put(didTraining())
        yield put(globalActions.updateTrainTask({ mode: 'reset' }))
        yield put(startBiasAndQuality(id))
        trackEvent('Analysis Completed')
        return
      }
    } catch (error) {
      yield put(globalActions.updateTrainTask({ mode: 'reset' }))
      return yield put(didTrainingFail())
    }
  }
}

function* handleContinueTraining({ id }) {
  const { task, cancel } = yield race({
    task: call(handleStatusPooling, id),
    cancel: take([ProjectActions.STOP_TRAINING, ProjectActions.RESET_STATE]),
  })
  if (cancel) {
    if (cancel.type === ProjectActions.STOP_TRAINING) {
      try {
        yield put(didStopTraining())
        yield put(globalActions.updateTrainTask({ mode: 'reset' }))
        yield call(dataSetsService.stopModelTraining, id)
      } catch (error) {
        return yield put(didStopTrainingFail())
      }
    }
  }
}

function* handleFetchDataset(action) {
  const { id, sampleSize } = action
  try {
    const dataset = yield call(dataSetsService.getOriginalDatasetById, {
      id,
      sampleSize,
    })
    const data = initData(dataset, 'origin')

    const runningTaskListState = yield select(getProjectRunningTaskListState)
    if (!runningTaskListState.train) {
      const runningList = yield call(dataSetsService.getRunningTaskList)
      yield put(globalActions.updateRunningTaskList({ list: runningList }))
    }

    const modelInfo = yield call(dataSetsService.getModelInfo, id)

    if (modelInfo === null || modelInfo?.status === 'STATUS_STOPPED') {
      return yield put(didFetchOriginalDataset(data, trainingStatus.Init))
    }

    // Model is ready
    if (modelInfo?.status === 'STATUS_FINISHED') {
      const preview = initData(modelInfo.preview)
      yield put(didFetchOriginalDataset(data, trainingStatus.Ready, preview))

      // Bias and Quality
      yield fork(handleResumeTasks, { id })
    }

    // Model is in progress
    if (
      modelInfo?.status === 'STATUS_QUEUED' ||
      modelInfo?.status === 'STATUS_STARTED'
    ) {
      yield put(didFetchOriginalDataset(data, trainingStatus.Training))
      yield fork(handleContinueTraining, { id })
    }

    // #region init synthSettings
    if (!data.settings.synthesisSettings && data.meta) {
      const initSynthSettings = {
        isOriginalDataIncluded: data.source_name !== 'Datasource',
        rowNumber: data.meta.n_rows,
      }
      yield put(updateSynthesisSetting(id, initSynthSettings))
    }
    // #endregion
  } catch (error) {
    yield put(didLoadingDatasetFail())
    if (error.status === 404) yield put(setNotFoundPage())
  }
}

// Update dataset settings
function* handleUpdateDataset({ id, settings, loaderName, callback }) {
  try {
    if (loaderName) {
      yield put(startLoadingSettings({ name: loaderName }))
    }
    // settings from new place
    const datasetSettings = yield select(getProjectsSettingState)
    const transformedSettings = {
      ...settings,
      couplings: transformToBECoupling(settings.couplings),
      ...datasetSettings,
    }
    yield call(dataSetsService.updateDatasetSettings, {
      id,
      settings: transformedSettings,
    })
    yield put(didUpdateDataset({ settings: transformedSettings }))
    if (loaderName) {
      yield put(stopLoadingSettings())
    }
    callback()
  } catch (error) {
    yield put(didUpdateDatasetFail())
    if (loaderName) yield put(stopLoadingSettings())
  }
}

// Update dataset column name
function* handleUpdateDatasetColumnName({ id, names }) {
  try {
    if (names.old_name !== names.new_name) {
      yield put(submitAction())
      yield call(dataSetsService.updateDatasetColumnName, { id, names })
      yield put(didUpdateDatasetColumnName({ names }))
    }
    yield put(modalActions.hide())
  } catch (error) {
    yield put(didSubmitActionFail('Server error occurred'))
  }
}

function* handleUpdateTitle({ title }) {
  const data = { title }
  const id = yield select(getProjectId)
  try {
    yield put(submitAction())
    const updatedDataset = yield call(dataSetsService.updateDatasetInfo, {
      id,
      data,
    })
    yield put(didUpdateTitle(updatedDataset.title))
    yield put(modalActions.hide())
  } catch (error) {
    // TODO
    yield put(didSubmitActionFail('Server error occurred'))
  }
}

const cancelSaga = (saga, cancelAction) => {
  return function* cancelableSaga(action) {
    yield race([call(saga, action), take(cancelAction)])
  }
}

export default function* watchProjectSaga() {
  yield takeLatest(
    ProjectActions.FETCH_DATASET,
    cancelSaga(handleFetchDataset, ProjectActions.RESET_STATE)
  )
  yield takeLatest(ProjectActions.START_TRAINING, handleStartTraining)
  yield takeLatest(ProjectActions.UPDATE_TITLE, handleUpdateTitle)
  yield takeLatest(ProjectActions.UPDATE_DATASET, handleUpdateDataset)
  yield takeLatest(
    ProjectActions.UPDATE_DATASET_COLUMN_NAME,
    handleUpdateDatasetColumnName
  )
}

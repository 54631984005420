import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import hljs from 'highlight.js'
import sql from 'highlight.js/lib/languages/sql'

import 'highlight.js/styles/atom-one-light.css'

import { Button } from 'UIkit/Button/Button'
import { RequestStatus } from 'utils/constants'

import { newProjectActions } from 'services/datasources/project/reducer'
import { selectCreateStatus } from 'services/datasources/project/selectors'

hljs.registerLanguage('sql', sql)

const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginBottom: '1.5rem',
    width: '200px',
    flexDirection: 'column',
    border: '1px solid #e0e4ed',
    borderRadius: '10px',
    padding: '0.5rem',
  },
  header: {
    marginBottom: 0,
    fontWeight: 700,
    textAlign: 'start',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
})

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const QueryBuilder: React.FC<SelectedProps & DispatchProps> = ({
  queryData,
  createStatus,
  createProject,
}) => {
  const classes = useStyles()
  const nodeRef = useRef()

  const highlight = () => {
    if (nodeRef.current) {
      hljs.highlightBlock(nodeRef.current)
    }
  }

  useEffect(() => {
    highlight()
  })

  const handleCreateProject = () => {
    createProject({ id: queryData.query_id })
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>Query Builder</div>

      {queryData.query ? (
        <div className={classes.body}>
          <pre
            style={{
              margin: '0.5em',
              marginBottom: 0,
              padding: 0,
              maxHeight: '100%',
              whiteSpace: 'pre-wrap',
            }}
          >
            <code
              ref={nodeRef}
              style={{
                overflowX: 'unset',
                textAlign: 'left',
                fontSize: '12px',
              }}
            >
              {queryData.query}
            </code>
          </pre>
          <div
            style={{
              display: 'flex',
              marginLeft: '.5rem',
              marginRight: '.5rem',
            }}
          >
            <Button
              onClick={() => handleCreateProject()}
              loading={createStatus === RequestStatus.Loading}
            >
              Create
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { query_data } = state.data
  return { queryData: query_data, createStatus: selectCreateStatus(state) }
}

const mapDispatchToProps = { createProject: newProjectActions.createProject }

export default connect(mapStateToProps, mapDispatchToProps)(QueryBuilder)

export const getUtmFromUrl = (search: string) => {
  const processedSearch = search
    .substring(1)
    .replace(/&/g, '","')
    .replace(/=/g, '":"')

  try {
    const parsed = JSON.parse(`{"${processedSearch}"}`, (key, value) =>
      key === '' ? value : decodeURIComponent(value)
    )
    return parsed
  } catch (e) {
    return undefined
  }
}

import React from 'react'

export const SandboxIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.5 26.5H9.5V38.5H21.5V26.5Z" />
    <path d="M38.5 9.5H26.5V21.5H38.5V9.5Z" />
    <path d="M21.5 21.5H9.5L15.5 9.5L21.5 21.5Z" />
    <path d="M32.5 38.5C35.8137 38.5 38.5 35.8137 38.5 32.5C38.5 29.1863 35.8137 26.5 32.5 26.5C29.1863 26.5 26.5 29.1863 26.5 32.5C26.5 35.8137 29.1863 38.5 32.5 38.5Z" />
  </svg>
)

import React from 'react'
import { Grid } from '@material-ui/core'

const gridStyle = {
  maxWidth: '400px',
  margin: '0 auto',
  padding: '3rem 1rem',
}

export const Layout: React.FunctionComponent = ({ children }) => (
  <Grid
    container
    style={gridStyle}
    direction="column"
    justify="center"
    alignItems="stretch"
  >
    {children}
  </Grid>
)

import { takeLatest, call, put } from 'redux-saga/effects'
import { profileService } from 'legacy/utils/services'
import User from 'legacy/components/Account/User'

import { profileActions } from './reducer'
import { notificationActions } from '../notification/reducer'

import defaultAvatar from '../../assets/avatar.png'

function* handleFetchImage() {
  try {
    // TODO remove when BE will be fixed (should return user/avatar)
    const avatarPath = User.avatar_path ? User.avatar_path.slice(5) : ''
    if (!avatarPath) {
      throw Error('Profile does not have avatar')
    }
    const avatarFile = yield call(profileService.userGetAvatar, { avatarPath })
    const avatarUrl = URL.createObjectURL(avatarFile)
    yield put(profileActions.didFetchImage({ imageUrl: avatarUrl }))
  } catch (error) {
    yield put(profileActions.didFetchImage({ imageUrl: defaultAvatar }))
  }
}

// TODO move to createObjectURL
const readAsDataUrl = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = () => reject(Error('Image preview is not available'))
    reader.readAsDataURL(file)
  })
}

function* handleUploadImage({ payload }: any) {
  try {
    const formData = new FormData()
    formData.append('file', payload.file)
    yield call(profileService.userUploadAvatar, formData)

    const result = yield call(readAsDataUrl, payload.file)
    yield put(profileActions.didUploadImage({ imageUrl: result }))

    yield put(
      notificationActions.showNotification({ message: 'Avatar updated' })
    )
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: error.message,
        severity: 'error',
      })
    )
  }
}

function* handleChangeUserInfo({ payload }: any) {
  const [firstName, lastName] = payload.fullname.trim().split(' ')
  const settings = { show_onboarding: payload.showProductTour }
  const body = {
    first_name: firstName,
    last_name: lastName,
    phone_number: payload.phoneNumber,
    job_title: payload.title,
    company: payload.company,
    settings,
  }
  try {
    const user = yield call(profileService.userUpdateInfo, body)
    User.update_user_info(user)
    yield put(profileActions.didChangeUserInfo())

    yield put(
      notificationActions.showNotification({
        message: 'Profile info updated',
      })
    )
  } catch (error) {
    yield put(profileActions.didChangeUserInfoFail(error))
  }
}

export default function* watchProfileSagas() {
  yield takeLatest(profileActions.fetchImage.type, handleFetchImage)
  yield takeLatest(profileActions.uploadImage.type, handleUploadImage)
  yield takeLatest(profileActions.changeUserInfo.type, handleChangeUserInfo)
}

import React, { useState } from 'react'
import classnames from 'classnames'
import { useLocation, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

import { colors, Typography } from 'legacy/shared'

import { ProfileIcon, OpenMenuIcon } from 'assets/sidebar'
import { ReactComponent as LogoImg } from 'assets/logos/LogoImg.svg'
import { ReactComponent as LogoText } from 'assets/logos/LogoText.svg'

import { Routes } from 'legacy/utils'

import User from 'legacy/components/Account/User'

const drawerWidth = 210
const narrowDrawerWidth = 60

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#00001C',
    color: 'white',
    fontWeight: 400,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: narrowDrawerWidth,
  },
  list: { height: '100%', display: 'flex', flexDirection: 'column' },
  link: {
    color: colors.white,
    fill: colors.white,
    '&:hover': {
      color: '#8A99F9',
      fill: '#8A99F9',
      textDecoration: 'none',
    },
    '&:focus': {
      color: '#8A99F9',
      fill: '#8A99F9',
      textDecoration: 'none',
    },
  },
  listItem: {
    overflow: 'hidden',
    height: 60,
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
  },
  active: {
    fill: '#6377F8',
    color: '#6377F8',
  },
  listItemIcon: { minWidth: '48px' },
  label: {},
  menuItem: {
    overflow: 'hidden',
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    paddingTop: '20px',
    opacity: 0.9,
    '&:hover': {
      color: colors.white,
      fill: colors.white,
      opacity: 1,
    },
  },
  profileItem: {
    marginTop: 'auto',
  },
  collapseIcon: {
    transform: 'rotate(180deg)',
  },
  logoTextImg: {
    minWidth: 121,
  },
}))

const SideBar = (props: any) => {
  const { tabs = [] } = props

  const classes = useStyles()
  const { pathname } = useLocation()

  const [open, setOpen] = useState(User.isSideBarOpen)
  return (
    <Drawer
      className={classnames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classnames(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <List
        component="nav"
        classes={{ root: classes.list }}
        aria-label="navigation menu"
      >
        <Link to="/" className={classes.link}>
          <ListItem key="menu" className={classes.menuItem}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <LogoImg />
            </ListItemIcon>
            <div className={classes.logoTextImg}>
              <LogoText />
            </div>
          </ListItem>
        </Link>
        <div style={{ height: '40px' }} />
        {tabs.map((tab: any) => (
          <Link to={tab.path} className={classes.link} key={tab.path}>
            <ListItem
              key={tab.label}
              classes={{
                root: classes.listItem,
                selected: classes.active,
              }}
              className={tab.className || ''}
              selected={tab.path === pathname}
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <tab.IconComponent />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.label}>{tab.label}</Typography>
                }
                disableTypography
              />
            </ListItem>
          </Link>
        ))}
        <Link
          to={Routes.Profile()}
          className={classnames(classes.link, classes.profileItem)}
        >
          <ListItem
            key="profile"
            classes={{
              root: classes.listItem,
            }}
          >
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography className={classes.label}>Account</Typography>
              }
              disableTypography
            />
          </ListItem>
        </Link>
        <ListItem
          key="collapse"
          onClick={() => {
            User.setSideBarOpen(!open)
            setOpen(!open)
          }}
          classes={{
            root: classes.listItem,
          }}
        >
          <ListItemIcon>
            {open ? (
              <OpenMenuIcon className={classes.collapseIcon} />
            ) : (
              <OpenMenuIcon />
            )}
          </ListItemIcon>
        </ListItem>
      </List>
    </Drawer>
  )
}

export default SideBar

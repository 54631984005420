import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  ProjectReportActions,
  fetchReport,
  didFetchReport,
  updateBlockSettings,
  didDeleteBlock,
  startLoading,
  stopLoading,
} from '../report/actions'
import { reportService } from '../../../utils/services'
import { getProjectDataState } from '../projectSelectors'
import { getProjectLoadingState } from '../report/reportSelectors'
import {
  getCorrelationObject,
  getModellingObject,
} from '../../../services/helper'

// Get report list blocks
export const fetchReportSagaFactory = (reportService) =>
  function* handleFetchReport({ id }) {
    try {
      const loading = yield select(getProjectLoadingState)
      if (loading === 'regular') {
        yield put(startLoading())
      }
      const data = yield call(reportService.getReport, { id })
      const transform_data = Object.keys(data).length
        ? data.items.map((d, i) => {
            if (d.type === 'CORRELATION') {
              return getCorrelationObject(d)
            }
            return getModellingObject(d)
          })
        : []

      yield put(didFetchReport(transform_data))
      yield put(stopLoading())
    } catch (error) {
      yield put(stopLoading())
    }
  }

// Add new block
export const addBlockSagaFactory = (reportService) =>
  function* handleAddBlock({ id, typeBlock }) {
    try {
      yield put(startLoading())
      const response = yield call(reportService.addBlock, {
        id,
        type: typeBlock,
      })
      if (typeBlock === 'MODELLING') {
        const data = yield select(getProjectDataState)
        const columns = data.meta.columns.map((d) => ({ name: d.name }))
        const settings = {
          response_variable: columns[0].name,
          explanatory_variables: new Array(columns[1].name),
          model: 'Linear',
        }
        yield put(updateBlockSettings({ id, item_id: response.id, settings }))
        yield put(stopLoading())
      } else {
        yield put(fetchReport({ id }))
      }
    } catch (error) {
      yield put(stopLoading())
    }
  }

// Update block settings
export const updateBlockSettingsSagaFactory = (reportService) =>
  function* handleUpdateBlockSettings({ id, item_id, settings }) {
    try {
      yield put(startLoading())
      yield call(reportService.updateBlockSettings, { id, item_id, settings })
      yield put(fetchReport({ id }))
      yield put(stopLoading())
    } catch (error) {
      yield put(stopLoading())
    }
  }

// Delete block
export const deleteBlockSagaFactory = (reportService) =>
  function* handleDeleteBlock({ id, item_id }) {
    try {
      yield put(startLoading())
      yield call(reportService.deleteBlock, { id, item_id })
      yield put(didDeleteBlock(item_id))
      yield put(stopLoading())
    } catch (error) {
      yield put(stopLoading())
    }
  }

// Move block
export const moveBlockSagaFactory = (reportService) =>
  function* handleMoveBlock({ id, item_id, new_order }) {
    try {
      yield put(startLoading())
      yield call(reportService.moveBlock, { id, item_id, new_order })
      yield put(fetchReport({ id }))
      yield put(stopLoading())
    } catch (error) {
      yield put(stopLoading())
    }
  }

const handleFetchReport = fetchReportSagaFactory(reportService)
const handleAddBlock = addBlockSagaFactory(reportService)
const handleUpdateBlockSettings = updateBlockSettingsSagaFactory(reportService)
const handleDeleteBlock = deleteBlockSagaFactory(reportService)
const handleMoveBlock = moveBlockSagaFactory(reportService)

export default function* watchReportSaga() {
  yield takeLatest(ProjectReportActions.FETCH_REPORT, handleFetchReport)
  yield takeLatest(ProjectReportActions.ADD_BLOCK, handleAddBlock)
  yield takeLatest(
    ProjectReportActions.UPDATE_BLOCK_SETTINGS,
    handleUpdateBlockSettings
  )
  yield takeLatest(ProjectReportActions.DELETE_BLOCK, handleDeleteBlock)
  yield takeLatest(ProjectReportActions.MOVE_BLOCK, handleMoveBlock)
}

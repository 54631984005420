import { SandboxesDBActions } from './actions'
import { SandboxesBuildActions } from '../actions'
import { SandboxesConfirmActions } from '../confirm/actions'

const getSandboxDBtDefaultState = () => ({
  selectedDBById: {},
})

export default (state = getSandboxDBtDefaultState(), action) => {
  switch (action.type) {
    case SandboxesDBActions.SELECT_DB: {
      return { ...state, selectedDBById: action.db }
    }
    case SandboxesBuildActions.CANCEL_BUILD_SANDBOX:
    case SandboxesConfirmActions.DID_BUILD_SANDBOX: {
      return getSandboxDBtDefaultState()
    }

    default:
      return state
  }
}

import { takeLatest, put, call, delay } from 'redux-saga/effects'

import User from 'legacy/components/Account/User'
import { authService } from 'legacy/utils/services'
import { Routes, pushRoute } from 'legacy/utils'

import { globalActions } from 'services/global/reducer'
import { signInActions } from './reducer'

function* handleRefreshToken() {
  try {
    const dataToken = yield call(authService.updateAccessToken)
    User.update_access_token(dataToken)
    yield delay(600000)
    yield put(signInActions.refreshToken())
  } catch (error) {
    yield put(signInActions.signOut())
  }
}

function* handleSignOut() {
  User.logout()
  pushRoute(Routes.SignIn())
  yield put(globalActions.resetStore())
}

export default function* watchSignInSaga() {
  yield takeLatest(signInActions.signOut.type, handleSignOut)
  yield takeLatest(signInActions.refreshToken.type, handleRefreshToken)
}

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { zipObject } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { Typography, Table, colors } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'

const variablesColorBox = '#eff0fe' // variables cell label color

const useStyles = makeStyles({
  root: {
    padding: '1.5rem',
  },
  titleMargin: {
    marginBottom: '1rem',
  },
  smallTitle: {
    display: 'flex',
  },
  smallTitleTypography: {
    marginRight: '.5rem',
  },
  tableLayout: {
    // width: '60rem',
  },
  cellRoot: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  widthSizeColumn: {
    width: 160,
  },
  widthBiasColumn: {
    width: 130,
  },
})

const useVariableStyles = makeStyles({
  cell: {
    display: 'flex',
  },
  box: {
    backgroundColor: variablesColorBox,
    borderRadius: '4px',
  },
})

const useSizeStyles = makeStyles({
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  typography: {
    marginRight: '.2rem',
  },
})

const useBiasStyles = makeStyles({
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  status: {
    display: 'inline-flex',
    alignSelf: 'center',
    marginRight: '.2rem',
    '& svg': {
      height: '1em',
      width: '1em',
    },
  },
  posBias: {
    '& svg': {
      fill: colors.posBias,
    },
  },
  negBias: {
    '& svg': {
      fill: colors.negBias,
    },
  },
  typography: {
    marginRight: '.2rem',
  },
})

const UpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <rect fill="none" height="24" width="24" />
    <path d="M5,9l1.41,1.41L11,5.83V22H13V5.83l4.59,4.59L19,9l-7-7L5,9z" />
  </svg>
)

const DownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <rect fill="none" height="24" width="24" />
    <path d="M19,15l-1.41-1.41L13,18.17V2H11v16.17l-4.59-4.59L5,15l7,7L19,15z" />
  </svg>
)

const HeaderCell = ({ label }) => (
  <Typography size="captionBold">{label}</Typography>
)

const VariableBox = ({ label, value }) => {
  const classes = useVariableStyles()
  return (
    <Box p={1} mr={1} className={classes.box}>
      <Typography size="captionBold">
        {label} = {value}
      </Typography>
    </Box>
  )
}

const VariableCell = ({ children }) => {
  const classes = useVariableStyles()
  return <div className={classes.cell}>{children}</div>
}

const SizeCell = ({ size, totalSize }) => {
  const classes = useSizeStyles()
  return (
    <div className={classes.cell}>
      <Typography size="captionBold" className={classes.typography}>
        {size} ({((size / totalSize) * 100).toFixed(2)} %)
      </Typography>
    </div>
  )
}

const BiasCell = ({ bias }) => {
  const classes = useBiasStyles()
  const isPositive = bias > 0

  return (
    <div
      className={classes.cell}
      style={{ color: isPositive ? colors.posBias : colors.negBias }}
    >
      <div
        className={classnames(
          classes.status,
          isPositive ? classes.posBias : classes.negBias
        )}
      >
        {isPositive ? <UpIcon /> : <DownIcon />}
      </div>
      <Typography size="captionBold" className={classes.typography}>
        {(bias * 100).toFixed(2)} %
      </Typography>
    </div>
  )
}
export const convertBiasDataToTable = (biasData) => {
  if (Object.keys(biasData).length) {
    const { name, value, distance, count } = biasData
    if (name) {
      return name.map((n, i) => {
        return {
          id: i + 1,
          variables: zipObject(n, value[i]),
          size: count[i],
          bias: distance[i],
        }
      })
    }
    return []
  }
  return []
}

export const BiasTable = ({ data, numRows, isSignificantBias }) => {
  const defaultRows = 10
  const tableData = convertBiasDataToTable(data)

  const [isRendering, setIsRendering] = useState(false)
  const [isShowAll, setIsShowAll] = useState(false)
  const [isButtonHide, setIsButtonHide] = useState(false)

  useEffect(() => {
    if (isRendering) {
      // TODO replace. Delay added to show spinner while big table is rendering
      setTimeout(() => setIsShowAll(true), 200)
    }
    if (isShowAll) {
      setIsButtonHide(true)
    }
  }, [isRendering, isShowAll])

  const emptyMessage = isSignificantBias
    ? ''
    : 'No significant biases with respect to the selected target column were detected in this dataset'

  const classes = useStyles()
  return (
    <>
      {' '}
      <div className={classes.tableLayout}>
        <Table
          headerRow={[
            { id: 'id', label: '' },
            {
              id: 'variables',
              label: <HeaderCell label="Group of variables" />,
            },
            {
              id: 'size',
              label: <HeaderCell label="Group size" />,
              align: classes.alignRight,
              width: classes.widthSizeColumn,
            },
            {
              id: 'bias',
              label: <HeaderCell label="Bias" />,
              align: classes.alignRight,
              width: classes.widthBiasColumn,
            },
          ]}
          bodyRow={[
            ({ row }) => <Typography size="captionBold">{row.id}</Typography>,
            ({ row }) => (
              <VariableCell>
                {Object.keys(row.variables).map((name) => (
                  <VariableBox label={name} value={row.variables[name]} />
                ))}
              </VariableCell>
            ),
            ({ row }) => <SizeCell size={row.size} totalSize={numRows} />,
            ({ row }) => <BiasCell bias={row.bias} />,
          ]}
          rows={
            isShowAll
              ? tableData
              : tableData.length
              ? tableData.slice(0, defaultRows)
              : []
          }
          cellClasses={{ root: classes.cellRoot }}
          emptyMessage={emptyMessage}
        />
      </div>
      {tableData.length > 10 &&
        (isButtonHide || (
          <Button loading={isRendering} onClick={() => setIsRendering(true)}>
            {`Show all ${tableData.length} rows`}
          </Button>
        ))}
    </>
  )
}

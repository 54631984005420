import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from 'UIkit'

import { SpinnerSection } from './SpinnerSection'
import { QualityPlot } from '../../QualityPlot/QualityPlot'
import { SectionTitle } from '../Layouts'

const useStyles = makeStyles({
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
})

const Quality = ({ qualityData, status }) => {
  const classes = useStyles()
  return (
    <Box p={2} border={1}>
      <SectionTitle title="Quality Assessment" className={classes.title} />
      {status === 'progress' ? (
        <SpinnerSection />
      ) : status === 'ready' ? (
        <QualityPlot qualityData={qualityData} />
      ) : (
        <div>Error</div>
      )}
    </Box>
  )
}

export default Quality

import * as React from 'react'
import { spacing } from '../../theme'

export interface SpacerProps {
  gutterMultiplier?: number
  'data-test-id'?: string
}

export const Spacer: React.FunctionComponent<SpacerProps> = ({
  gutterMultiplier = 1,
  'data-test-id': testId,
}): JSX.Element => {
  const paddingValue = spacing.gutter * gutterMultiplier
  return (
    <div
      style={{
        paddingTop: paddingValue,
        paddingBottom: paddingValue,
      }}
      data-test-id={testId}
    />
  )
}

Spacer.displayName = 'Spacer'

import React, { FC } from 'react'
import { Box, LinearProgress } from '@material-ui/core'
import { Button } from 'UIkit/Button/Button'
import { makeStyles } from '@material-ui/core/styles'
import { colors, fonts } from '../../legacy/shared'

interface IHeaderControlPanel {
  isSynthesizing: boolean
  handleSynthesize: () => void
  handleStopSynthesize: () => void
  progress: number
}

const HeaderControlPanel: FC<IHeaderControlPanel> = ({
  isSynthesizing,
  handleSynthesize,
  handleStopSynthesize,
  progress,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.controlPanel}>
        {isSynthesizing ? (
          <Button
            className={classes.buttonSynthesize}
            onClick={handleStopSynthesize}
          >
            Stop
          </Button>
        ) : (
          <Button
            className={classes.buttonSynthesize}
            disabled={progress === 100}
            onClick={handleSynthesize}
          >
            Synthesize
          </Button>
        )}
      </Box>
      {isSynthesizing && (
        <div className={classes.progressbar}>
          <div className={classes.progressbarLabel}>Synthesizing data...</div>
          <LinearProgress
            color="primary"
            variant={progress ? 'determinate' : 'indeterminate'}
            value={progress}
          />
        </div>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonSynthesize: {
    backgroundColor: colors.red,
  },
  controlPanel: {
    display: 'flex',
    justifyContent: 'start',
    '& > button': {
      margin: theme.spacing(2.5),
      marginLeft: 0,
    },
  },
  progressbarLabel: {
    textAlign: 'center',
    fontSize: '11px',
    paddingBottom: theme.spacing(1),
    fontFamily: fonts.primary,
    color: colors.lightPurple,
  },
  progressbar: {
    marginLeft: theme.spacing(2.2),
    width: '300px',
  },
}))

export default HeaderControlPanel

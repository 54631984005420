import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { IconButton, Menu, Divider, spacing } from 'legacy/shared'
import { SandboxResponse } from 'api/generated/modelPkg/sandbox-response'

import { CleanRoomStatuses } from 'services/cleanrooms/constants'
import { PrepareItemHandlers } from './prepareItemHandlers'

const useStyles = makeStyles({
  triggerButton: {
    padding: 0,
    marginLeft: spacing.gutter,
    marginRight: spacing.gutter,
  },
  verDivider: {
    color: '#C1C6CC',
  },
})

export const ActionMenu = ({
  itemHandlers,
  cleanRoom,
}: {
  cleanRoom: SandboxResponse
  itemHandlers: PrepareItemHandlers
}) => {
  const classes = useStyles()

  let items = [itemHandlers.copyToClipboard, itemHandlers.deleteCleanRoom]

  if (cleanRoom.status === CleanRoomStatuses.RUNNING) {
    items = [itemHandlers.stopCleanRoom, itemHandlers.shareCleanRoom, ...items]
  }

  if (cleanRoom.status === CleanRoomStatuses.STOPPED) {
    items = [itemHandlers.runCleanRoom, ...items]
  }

  return (
    <Menu
      value={cleanRoom}
      items={items}
      renderTriggerComponent={({ showMenu }: any) => (
        <div style={{ display: 'flex' }}>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.verDivider}
          />
          <IconButton
            onClick={showMenu}
            className={classes.triggerButton}
            data-testid={`action-${cleanRoom.status}`}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      )}
    />
  )
}

import { useState } from 'react'
import { RequestStatus } from 'utils/constants'

interface IStatus {
  status: RequestStatus
  message?: string
}

export const useApi = (call: any): [IStatus, (data?: any) => Promise<any>] => {
  const [status, setStatus] = useState<IStatus>({
    status: RequestStatus.Idle,
  })
  const apiCall = async (data: any) => {
    try {
      setStatus({ status: RequestStatus.Loading })
      const result = await call(data)
      setStatus({ status: RequestStatus.Succeeded })
      return result
    } catch (error) {
      setStatus({ status: RequestStatus.Failed, message: error.message })
      throw error
    }
  }

  return [status, apiCall]
}

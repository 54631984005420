import React from 'react'

import OriginalColumnData from 'legacy/components/Projects/ColumnData/OriginalColumnData'
import { TotalRows } from 'legacy/components/Projects/ColumnData/TotalRows'

export const filterDisplayColumns = ({ dt, displayColumns }) => {
  return dt.meta.columns.filter((d) => displayColumns.includes(d.name))
}

const OriginalColumns = ({
  data,
  displayColumns,
  synth_data,
  status,
  loadingReport = 0,
  isShowComplexRow,
  handleShowComplexRow,
}) => {
  const renderHeader = (cur_data, synth_data, results_only) => {
    const current_data = results_only ? synth_data : cur_data
    return (
      <>
        <div
          style={{ display: 'flex', overflowY: 'auto' }}
          className="tour-orig-step1"
        >
          {current_data.meta &&
            current_data.meta.columns.map((d, i) => {
              return (
                <OriginalColumnData
                  key={d.name}
                  activeTab="origin"
                  i={i}
                  d={d}
                  data={data}
                  status={status}
                  cur_data={cur_data}
                  synth_data={synth_data}
                  results_only={results_only}
                  loadingReport={loadingReport}
                  disableDragBars={true}
                  isShowComplexRow={isShowComplexRow}
                  handleShowComplexRow={handleShowComplexRow}
                />
              )
            })}
        </div>
        <TotalRows
          showingRows={Object.values(data.sample)[0].length}
          totalRows={current_data.meta.n_rows}
        />
      </>
    )
  }

  const cur_data = JSON.parse(JSON.stringify(data))
  const curr_synth_data = JSON.parse(JSON.stringify(synth_data))
  const results_only = data.access_type && data.access_type === 'RESULTS_ONLY'

  if (data.meta && data.meta !== null) {
    cur_data.meta.columns = filterDisplayColumns({
      dt: cur_data,
      displayColumns,
    })

    return (results_only && synth_data.meta && synth_data.meta !== null) ||
      data.meta ? (
      renderHeader(cur_data, curr_synth_data, results_only)
    ) : (
      <div>no results</div>
    )
  }
}

export default OriginalColumns

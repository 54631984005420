import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'
import {
  CheckBox,
  Table,
  TextField,
  Typography,
  spacing,
} from '../../../../shared'
import {
  getTransformedDatasets,
  getSandboxDatasetReducerState,
  getSelectedDatasetsById,
  getSearchStringValue,
  getIsStepCompleted,
} from './sandboxDatasetSelectors'
import { fetchDatasets, changeSelection, setSearchValue } from './actions'
import NavButtons from '../NavButtons'
import { DatasetTag } from './DatasetTag'

const useStyles = makeStyles({
  tableHeader: {
    lineHeight: '36px',
  },
  tableCheckbox: {
    padding: '0px 9px',
  },
  checkBoxLabel: {
    margin: 'auto',
  },
  checkBox: { padding: '0', marginRight: spacing.gutter },
})

export const DatasetView = (props) => {
  const {
    datasets,
    reducerState,
    selectedDatasetsById,
    searchString,
    isStepCompleted,
    fetchDatasets,
    changeSelection,
    setSearchValue,
  } = props
  const classes = useStyles()

  useEffect(() => {
    fetchDatasets()
  }, [])

  const { callback } = useDebouncedCallback(
    (value) => setSearchValue(value),
    300
  )

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: spacing.gutter * 3,
        }}
      >
        <Typography size="bodyBold" className={classes.tableHeader}>
          Available Datasets
        </Typography>
        <NavButtons isNextDisable={!isStepCompleted} isBackHidden={true} />
      </div>
      <TextField
        defaultValue={searchString}
        onChange={(e) => callback(e.target.value)}
        label=""
        placeholder="Search..."
        autoComplete="off"
        variant="outlined"
      />
      <Table
        selectedRows={selectedDatasetsById}
        loading={reducerState === 'saving'}
        headerRow={[
          { label: '' },
          { id: 'title', label: 'DATASET NAME(S)', sortable: true },
          { id: 'description', label: 'DESCRIPTION', sortable: true },
          { id: 'created_at', label: 'DATE', sortable: true },
          { id: 'type', label: 'TYPE', sortable: true },
          { id: 'created_by_name', label: 'OWNER', sortable: true },
        ]}
        bodyRow={[
          ({ row, checked }) => (
            <CheckBox
              checked={checked}
              onChange={() => changeSelection(row)}
              labelClassName={classes.checkBoxLabel}
              className={classes.checkBox}
            />
          ),
          'title',
          'description',
          'created_at',
          ({ row }) => <DatasetTag label={row.type} />,
          'created_by_name',
        ]}
        rows={datasets}
        filterString={searchString}
        defaultSort={{ name: 'created_at', order: 'desc' }}
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  datasets: getTransformedDatasets,
  reducerState: getSandboxDatasetReducerState,
  selectedDatasetsById: getSelectedDatasetsById,
  searchString: getSearchStringValue,
  isStepCompleted: getIsStepCompleted,
})

const mapDispatchToProps = {
  fetchDatasets,
  changeSelection,
  setSearchValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetView)

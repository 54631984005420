import React from 'react'
import { Box, styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    height: 'calc(100% - 72px)',
  },
  zeroTopContainer: {
    height: 'fit-content',
  },
})

export default ({ children, pt = 5 }: any) => {
  const classes = useStyles()
  if (pt === 0) {
    return (
      <PageContainer p={5} pt={pt} pb={0} className={classes.zeroTopContainer}>
        {children}
      </PageContainer>
    )
  }

  return (
    <PageContainer p={5} pt={pt} className={classes.container}>
      {children}
    </PageContainer>
  )
}

const PageContainer = styled(Box)({
  width: '100%',
  minHeight: 'calc(100% - 72px)', // 72px - header height
})

import { combineReducers } from 'redux'
import dataset from './dataset/reducer'
import os from './os/reducer'
import db from './dbServer/reducer'
import confirm from './confirm/reducer'
import { SandboxesBuildActions } from './actions'
import { CleanRoomBuildingSteps } from './constants'

const getDefaultStepsState = () => ({
  [CleanRoomBuildingSteps.Dataset]: { isEditable: true },
  [CleanRoomBuildingSteps.OS]: { isEditable: false },
  [CleanRoomBuildingSteps.DB]: { isEditable: false },
  [CleanRoomBuildingSteps.Confirm]: { isEditable: false },
})

const stepsReducer = (state = getDefaultStepsState(), action) => {
  switch (action.type) {
    case SandboxesBuildActions.DID_UNLOCK_NEXT_STEP: {
      return { ...state, ...{ [action.step]: { isEditable: true } } }
    }

    default:
      return state
  }
}

export default combineReducers({
  dataset,
  os,
  db,
  confirm,
  steps: stepsReducer,
})

import React, { useState, useRef, useLayoutEffect } from 'react'
import { Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cl from 'classnames'

import { TemplateResponse } from 'api/generated'
import { Typography } from 'legacy/shared'
import { Button } from 'UIkit/Button/Button'

const useStyles = makeStyles({
  createButton: {
    marginLeft: '1rem',
  },
  description: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  longDesc: {
    cursor: 'pointer',
  },
  fullDesc: {
    whiteSpace: 'break-spaces',
  },
})

const CardTitle: React.FC<{ template: TemplateResponse }> = ({ template }) => {
  const title = template.title ? template.title : `(datasets_id_${template.id})`
  return <Typography size="bodyBold">{title}</Typography>
}

export const TemplateCard: React.FC<{
  template: TemplateResponse
  isDatasetCreating: boolean
  selectedTemplate: number
  pageWidth: number
  handlerCreateDataset: (id: number) => void
}> = ({
  template,
  isDatasetCreating,
  selectedTemplate,
  pageWidth,
  handlerCreateDataset,
}) => {
  const classes = useStyles()
  const [openDescId, setOpenDescId] = useState(null)
  const [isLongDesc, setIsLongDesc] = useState(false)

  const descElem = useRef(null)

  useLayoutEffect(() => {
    const isTextOverflow =
      descElem.current.scrollWidth > descElem.current.clientWidth
    if (openDescId === null) {
      setIsLongDesc(isTextOverflow)
    }
  }, [pageWidth, openDescId])

  const handleExpand = () => {
    if (openDescId === template.id) {
      return setOpenDescId(null)
    }
    setOpenDescId(template.id)
  }

  return (
    <Card>
      <CardContent>
        <div key={template.id}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <CardTitle template={template} />
            <Button
              color="primary"
              className={classes.createButton}
              disabled={isDatasetCreating}
              loading={selectedTemplate === template.id}
              onClick={() => handlerCreateDataset(template.id)}
            >
              Create
            </Button>
          </div>
          <div onClick={isLongDesc ? handleExpand : () => {}}>
            <Typography size="caption" color="tourGrey">
              <p
                className={cl(classes.description, {
                  [classes.longDesc]: isLongDesc,
                  [classes.fullDesc]: openDescId === template.id,
                })}
                ref={descElem}
              >
                {template.description
                  ? template.description
                  : `Description (datasets_id_${template.id})`}
              </p>
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

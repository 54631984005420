import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Box } from 'UIkit'

import {
  getOriginalBiasData,
  getProjectPermissionsForUser,
} from '../../projectSelectors'

import { SynthBias } from '../../biasAndQuality/SynthBias'

export const GroupBiasTables = ({
  title,
  synthData,
  originalBiasData,
  canEdit,
}) => {
  const { fairness_data } = synthData.preview.meta
  if (canEdit) {
    const isTargetColumnAreTheSame =
      originalBiasData.target === fairness_data.target

    return (
      <Box pt={2}>
        <SynthBias
          groupTitle={title}
          originalFairness={originalBiasData}
          synthFairness={fairness_data}
          numRowsSynth={synthData.preview.meta.n_rows}
          isTargetColumnAreTheSame={isTargetColumnAreTheSame}
        />
      </Box>
    )
  }
  return null
}

const mapStateToProps = createStructuredSelector({
  originalBiasData: getOriginalBiasData,
  canEdit: getProjectPermissionsForUser,
})

export default connect(mapStateToProps, null)(GroupBiasTables)

import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { Button } from 'UIkit/Button/Button'

export const ErrorPage: React.FC<{
  isLoading: boolean
  fetchTemplates: () => void
}> = ({ fetchTemplates, isLoading }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '200px',
      }}
    >
      <Alert severity="error">Templates are not available now</Alert>
      <div style={{ marginTop: '1rem' }}>
        <Button onClick={() => fetchTemplates()} loading={isLoading}>
          Retry
        </Button>
      </div>
    </div>
  )
}

import React from 'react'
import { DateRangePicker } from 'rsuite'
import { formatNumber } from '../../../../services/helper'

import DropCharts from './DropCharts'

import './GenerateData.scss'
import '../DragDropStyle.scss'

const chart_height = 130

const renderCurrentChart = (item) => {
  const { type_family } = item
  let content = ''
  const styleDiv = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }
  switch (true) {
    case type_family === 'id':
      content = <div style={styleDiv}>id</div>
      break
    case type_family === 'date':
      content = <div style={styleDiv}>date</div>
      break
    case type_family === 'sampling':
      content = <div style={styleDiv}>sampled</div>
      break
    case type_family === 'categorical':
    case type_family === 'continuous':
      content = <DropCharts {...item} height={150} _from="drop_component" />
      break
    default:
      content = <div style={styleDiv} />
      break
  }
  return content
}

class DropComponent extends React.PureComponent {
  getNeedStatistics = (d) => {
    const showNullsBody = (
      <div className="separate-row">
        <div className="_name"> Nulls: </div>
        <div className="value">{`${formatNumber(
          (d.nulls_ratio ? d.nulls_ratio : 0) * 100,
          '0.[00]'
        )}%`}</div>
      </div>
    )

    const showType = (
      <div className="separate-row" style={{ marginTop: '5px' }}>
        <div className="_name"> Type: </div>
        <div className="value">{d.type_family}</div>
      </div>
    )

    const underDensityChart = (
      <div className="statistics">
        {showType}
        <div className="separate-row">
          <div className="_name"> Mean: </div>
          <div className="value"> {formatNumber(d.mean)}</div>
        </div>
        <div className="separate-row">
          <div className="_name"> Median: </div>
          <div className="value"> {formatNumber(d.median)}</div>
        </div>
        <div className="separate-row">
          <div className="_name"> Range: </div>
          <div className="value">
            {formatNumber(d.min)} - {formatNumber(d.max)}
          </div>
        </div>
        <div className="separate-row">
          <div className="_name"> Std: </div>
          <div className="value"> {formatNumber(d.std)}</div>
        </div>
        {showNullsBody}
      </div>
    )

    const underHistogramChart = (
      <div className="statistics">
        {showType}
        <div className="separate-row">
          <div className="_name"> Unique Values: </div>
          <div className="value"> {formatNumber(d.n_unique)}</div>
        </div>
        <div className="separate-row">
          <div className="_name"> Most Frequent: </div>
          <div className="value">
            {d.name !== 'effort' && typeof d.most_frequent !== 'string'
              ? formatNumber(d.most_frequent)
              : d.most_frequent}
          </div>
        </div>
        <div className="separate-row">
          <div className="_name"> Most Occurrences: </div>
          <div className="value"> {formatNumber(d.most_occurrences)}</div>
        </div>
        {showNullsBody}
      </div>
    )

    return d.plot_type === 'density' ? underDensityChart : underHistogramChart
  }

  renderDatePicker = () => {
    return (
      <div className="separate-row">
        <div className="settings-label">
          <div> {'Date range: '} </div>
        </div>
        <div
          className="settings-value uk-margin-small-left date_range_picker_source_class"
          style={{ position: 'relative' }}
        >
          <DateRangePicker
            cleanable={false}
            value={[new Date('2017-02-01'), new Date('2017-05-20')]}
            onChange={(date, event) => {
              console.log('daTe', date)
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    const { item, index } = this.props
    return (
      <div
        className="generate-column source-drop-style"
        style={{ width: 330, margin: '5px', cursor: 'move' }}
      >
        <div className="rt-h-charts-item">
          <div className="rtable-header">
            <div className="header-container">
              <div className="_chart_header-text">
                {item.table}.{item.name}
              </div>
            </div>
            <div className="right-head-container">
              <span
                className="header-icon-style"
                onClick={() => this.props.deleteColumn(index)}
              >
                <i className="fas fa-window-close" />
              </span>
            </div>
          </div>
          <div
            className="charts-container"
            style={{
              width: 330,
              height: chart_height,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {item.type_family ? renderCurrentChart(item) : null}
          </div>
        </div>
        {this.getNeedStatistics(item)}
      </div>
    )
  }
}

export default DropComponent

import React from 'react'
import { connect } from 'react-redux'
import { IStore } from 'core/rootReducer'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'legacy/shared'

import { UploadButtonIcon, UploadButtonIconFail } from 'pages/Projects/icons'

import {
  getUploadLimitCounter,
  getIsUploadLimitExceeded,
  getIsLoadingState,
} from 'services/global/selectors'
import { uploadDatasetActions } from 'services/uploadDataset/reducer'

import User from 'legacy/components/Account/User'
import { Button } from 'UIkit/Button/Button'
import Drawer from 'UIkit/Drawer/Drawer'
import UploadDatasetForm from './UploadDatasetForm'
import { getUploadDatasetState } from '../../services/uploadDataset/selectors'

const UploadCounter = ({
  projectCounter,
  isLimitLoading,
}: {
  projectCounter: number
  isLimitLoading: boolean
}) => {
  const classes = useStyles()

  if (isLimitLoading) {
    return (
      <UploadCounterLayout>
        <Typography size="caption">Loading...</Typography>
      </UploadCounterLayout>
    )
  }
  if (projectCounter === 0) {
    return (
      <UploadZeroCounterLayout>
        <div style={{ marginRight: '14px' }}>
          <UploadButtonIconFail />
        </div>
        <Typography size="caption" className={classes.counterText}>
          You have no data products to upload. Please{' '}
          <Link
            to="/contactus"
            style={{
              color: '#992F38',
              textDecoration: 'underline',
            }}
          >
            contact us
          </Link>{' '}
          to upgrade{' '}
        </Typography>
      </UploadZeroCounterLayout>
    )
  }
  return (
    <UploadCounterLayout>
      <div style={{ marginRight: '14px' }}>
        <UploadButtonIcon />
      </div>
      <Typography size="caption" className={classes.counterText}>
        You have <b>{projectCounter} data products</b> to upload
      </Typography>
    </UploadCounterLayout>
  )
}

type ISelectedProps = ReturnType<typeof mapStateToProps>

type DispatchProps = typeof mapDispatchToProps

export const ActionPanel = ({
  projectCounter,
  isLimitReached,
  isLimitLoading,
  uploadState,
  openDrawer,
  closeDrawer,
}: ISelectedProps & DispatchProps) => {
  const { isDrawerOpened } = uploadState
  const classes = useStyles()

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {User.isCETier() && (
        <UploadCounter
          projectCounter={projectCounter}
          isLimitLoading={isLimitLoading}
        />
      )}
      <div>
        <Button
          onClick={openDrawer}
          variant="outlined"
          className={classes.uploadButton}
          disabled={User.isCETier() ? isLimitLoading || isLimitReached : false}
        >
          Upload data product
        </Button>
        <Button disabled={true}>Create data product</Button>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpened}
        onClose={closeDrawer}
        title="Upload data product"
        subtitle="Add datasource"
      >
        <UploadDatasetForm />
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state: IStore) => ({
  projectCounter: getUploadLimitCounter(state),
  isLimitReached: getIsUploadLimitExceeded(state),
  isLimitLoading: getIsLoadingState(state),
  uploadState: getUploadDatasetState(state),
})

const mapDispatchToProps = {
  uploadDataset: uploadDatasetActions.uploadDataset,
  openDrawer: uploadDatasetActions.openDrawer,
  closeDrawer: uploadDatasetActions.closeDrawer,
}

const UploadCounterLayout = ({ children }: any) => (
  <div
    style={{
      display: 'flex',
      marginRight: '40px',
      backgroundColor: '#C1C9FC',
      padding: '8px',
      borderRadius: '4px',
      color: '#4F5FC6',
    }}
  >
    {children}
  </div>
)

const UploadZeroCounterLayout = ({ children }: any) => (
  <div
    style={{
      display: 'flex',
      marginRight: '40px',
      backgroundColor: 'rgba(255, 184, 190, 0.8)',
      padding: '8px',
      borderRadius: '4px',
      color: '#992F38',
    }}
  >
    {children}
  </div>
)

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  uploadButton: {
    marginRight: '12px',
  },
  counterText: {
    lineHeight: '1.5rem',
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionPanel)

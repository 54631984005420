import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { fonts, Typography } from 'legacy/shared'
import cn from 'classnames'

interface IDataGroupProps {
  className?: string
  title?: string
}

export const DataGroup: FC<IDataGroupProps> = ({
  title,
  className,
  children,
}) => {
  const classes = useStyles()

  return (
    <Box className={cn(classes.dataGroup, className)}>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.body}>{children}</Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  dataGroup: {
    margin: `${theme.spacing(2.5)}px 0`,
    fontFamily: fonts.primary,
    padding: 0,
    borderRadius: 4,
    width: '100%',
  },
  body: {},
  title: {
    marginBottom: theme.spacing(1.2),
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
  },
}))

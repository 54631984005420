import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'UIkit/Button/Button'
import { TextField, Typography, Form, spacing } from '../../../shared'
import { ModalButtonContainer } from '../../../../components/Modals/ModalButtonContainer'
import { getProjectDataState } from '../projectSelectors'
import {
  getProjectColumnDataState,
  getProjectFormReducerState,
  getProjectFormErrorMessageState,
} from './modalSelectors'
import { updateDatasetColumnName } from '../actions'
import { createValidatorWithSchema } from '../../../utils/validate'
import { modalActions } from '../../../../services/modal/reducer'

const useStyles = makeStyles({
  errorMsg: {
    marginTop: spacing.gutter * 2,
  },
})

const getConstraints = (columns) => ({
  name: {
    presence: true,
    length: {
      minimum: 1,
      message: 'Empty column name',
    },
    exclusion: {
      within: columns,
      message: 'Column with name %{value} already exists',
    },
  },
})

export const EditColumnName = ({
  data,
  columnData,
  reducerState,
  errorMessage,
  hideModal,
  updateDatasetColumnName,
}) => {
  const classes = useStyles()
  const loading = reducerState === 'saving'

  const validate = createValidatorWithSchema(
    getConstraints(data.meta.columns.map((column) => column.name))
  )

  const handleSubmit = (name) => {
    updateDatasetColumnName({
      id: data.dataset_id,
      names: {
        old_name: columnData.name,
        new_name: name,
      },
    })
  }

  return (
    <>
      <Form
        initialValues={{
          name: columnData.name ? columnData.name : '',
        }}
        onSubmit={({ name }) => handleSubmit(name)}
        validate={validate}
      >
        {({ values, errors, handleChange, submitForm }) => (
          <>
            <TextField
              name="name"
              label="Name"
              fullWidth
              value={values.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
            />
            <ModalButtonContainer>
              <Button loading={loading} onClick={submitForm}>
                Save
              </Button>
              <Button variant="outlined" onClick={hideModal}>
                Cancel
              </Button>
            </ModalButtonContainer>
            {errorMessage || errors.name ? (
              <Typography color="red" className={classes.errorMsg}>
                {errorMessage || errors.name}
              </Typography>
            ) : null}
          </>
        )}
      </Form>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  data: getProjectDataState,
  columnData: getProjectColumnDataState,
  reducerState: getProjectFormReducerState,
  errorMessage: getProjectFormErrorMessageState,
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
  updateDatasetColumnName,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditColumnName)

import React, { FC } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import {
  selectModalConfirmationConfig,
  selectModalIsLoading,
} from 'services/modal/modalSelectors'
import { modalActions } from 'services/modal/reducer'
import { Button } from 'UIkit/Button/Button'
import { Typography } from 'legacy/shared'
import { ModalButtonContainer } from 'components/Modals/ModalButtonContainer'

const useStyles = makeStyles({
  descriptionContainer: {},
})

export const ConfirmationModal: FC<any> = ({
  hideModal,
  confirmationConfig,
  isLoading,
}) => {
  const classes = useStyles()

  return (
    <div>
      {confirmationConfig && confirmationConfig.actionDescription && (
        <div className={classes.descriptionContainer}>
          <Typography>{confirmationConfig.actionDescription}</Typography>
        </div>
      )}
      <div>
        <ModalButtonContainer>
          <Button
            variant="outlined"
            onClick={confirmationConfig?.confirmCancelAction || hideModal}
          >
            {confirmationConfig?.cancelButtonText || 'Cancel'}
          </Button>
          <Button
            color={confirmationConfig?.confirmButtonColor || 'secondary'}
            variant="contained"
            loading={isLoading}
            onClick={confirmationConfig?.confirmButtonAction}
          >
            {confirmationConfig?.confirmButtonText || 'Confirm'}
          </Button>
        </ModalButtonContainer>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  confirmationConfig: selectModalConfirmationConfig,
  isLoading: selectModalIsLoading,
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ShareModal from '../../ShareModal/ShareModal'
import { getProjectIdModal } from '../projectSelectors'

// TODO full refactor with sagas

const mapStateToProps = createStructuredSelector({
  projectId: getProjectIdModal,
})

export default connect(mapStateToProps, null)(ShareModal)

import validateModule from 'validate.js'

const noNumbersInStringRegex = /^([a-zA-Z _-]+)$/

export const validateFullName = (value: string = ''): null | string => {
  if (value.length === 0) {
    return 'Full name is required'
  }

  const hasNumbers = !noNumbersInStringRegex.test(value)

  if (hasNumbers) {
    return 'Full name should not contain numbers'
  }

  const fullNameArr = value.trim().split(' ')

  if (fullNameArr.length < 2) {
    return 'Full name must contain at least 2 words'
  }

  return null
}

validateModule.validators.fullname = validateFullName

const validate = (values: object, constraints: object) =>
  validateModule(values, constraints, { fullMessages: false })

export const createValidatorWithSchema =
  (constraints: object) => (values: object) =>
    validate(values, constraints)

const validIpRegex =
  /\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b/gi
const validHostRegex =
  /^(?=^.{1,253}$)(([a-z\d]([a-z\d-]{0,62}[a-z\d])*[\.]){1,3}[a-z]{1,61})$/gm
export const isHostValid = (host: string) =>
  host.match(validHostRegex) ||
  host.match(validIpRegex) ||
  host.toLowerCase() === 'localhost'

export const isPortValid = (port: string) =>
  Number(port) >= 1 && Number(port) <= 2 ** 16 - 1 // A port number is a 16-bit unsigned integer, thus ranging from 0 to 65535

export const SandboxesConfirmActions = {
  BUILD_SANDBOX: 'sandboxes/BUILD_SANDBOX',
  DID_BUILD_SANDBOX: 'sandboxes/DID_BUILD_SANDBOX',
  DID_BUILD_SANDBOX_FAIL: 'sandboxes/DID_BUILD_SANDBOX_FAIL',
}

export const buildSandbox = (sandboxName) => ({
  type: SandboxesConfirmActions.BUILD_SANDBOX,
  sandboxName,
})

export const didBuildSandbox = () => ({
  type: SandboxesConfirmActions.DID_BUILD_SANDBOX,
})

export const didBuildSandboxFail = (message = 'Server error') => ({
  type: SandboxesConfirmActions.DID_BUILD_SANDBOX_FAIL,
  message,
})

import { createSelector } from 'reselect'
import { getProjectState } from '../projectSelectors'

export const getProjectListState = createSelector(
  getProjectState,
  (state) => state.modal
)

export const getProjectColumnDataState = createSelector(
  getProjectListState,
  (state) => state.columnData
)

export const getProjectFormReducerState = createSelector(
  getProjectListState,
  (state) => state.reducerState
)

export const getProjectFormErrorMessageState = createSelector(
  getProjectListState,
  (state) => state.errorMessage
)

import React from 'react'

import HeaderComponent from 'layouts/Header'
import PageContainer from 'layouts/PageContainer'

import Templates from './Templates'

const TemplatesPage = () => {
  return (
    <>
      <HeaderComponent title="Product Catalogue" />
      <PageContainer>
        <Templates />
      </PageContainer>
    </>
  )
}

export default TemplatesPage

import { createSelector } from 'reselect'

const selectModalState = (state) => state.modal

export const selectIsModalOpen = createSelector(
  selectModalState,
  (modalState) => modalState.isOpened
)

export const selectModalType = createSelector(
  selectModalState,
  (modalState) => modalState.modalType
)

export const selectModalConfig = createSelector(
  selectModalState,
  (modalState) => modalState.config
)

export const selectModalConfirmationConfig = createSelector(
  selectModalConfig,
  (state) => state.confirmationConfig
)

export const selectModalContentConfig = createSelector(
  selectModalConfig,
  (state) => state.contentConfig
)

export const selectModalIsLoading = createSelector(
  selectModalState,
  (state) => state.isLoading
)

import React from 'react'
import ReactDOM from 'react-dom'
import * as d3 from 'd3'
import { event } from 'd3-selection'
import { connect } from 'react-redux'

import { dataSetsService } from 'legacy/utils/services'
import { formatNumber, transformCouplings } from '../services/helper'
import { actionUpdateDisabledColumns } from '../store/actions'

import './Chart.scss'

const origin_color = '#515fe7' // origin data color
const synth_color = '#23a956' // synth data color
const gr_origin_color = '#5f71f2' // origin gradient color
const gr_synth_color = '#39b969' // synth gradient color
const changed_color = '#ff15c2' // changed bar color
const gr_changed_color = '#ff15d2' // changed bar gradient color

// durations(axis, drag)
const axisDuration = 200
const dragDuration = 130

class Chart extends React.Component {
  constructor(props) {
    super(props)
    const _height = props.height ? props.height : 200

    this.margin = { top: 20, right: 25, bottom: 65, left: 40 }
    this.width = 300 - this.margin.left - this.margin.right
    this.height = _height - this.margin.top - this.margin.bottom
  }

  componentDidMount() {
    const {
      transform_data,
      xAxisData,
      density_support,
      plot_type,
      density_arr,
      name,
      settings,
      disableDragBars,
    } = this.props

    // build data
    const { transform_arr, keys } = this.buildData(
      transform_data,
      xAxisData,
      plot_type,
      name
    )

    this.initGraph(
      transform_arr,
      xAxisData,
      density_support,
      keys,
      plot_type,
      density_arr,
      name,
      settings,
      disableDragBars
    )
  }

  initGraph(
    transform_arr,
    xAxisData,
    density_support,
    keys,
    plot_type,
    density_arr,
    name,
    settings,
    disableDragBars
  ) {
    this.tooltip = d3.select('.tooltip')

    if (!this.tooltip.node()) {
      this.tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
    }

    this.line = d3
      .line()
      .x((d) => {
        return this.x(d.x)
      })
      .y((d) => {
        return this.y(d.y)
      })
      .curve(d3.curveMonotoneX)

    this.svg = d3
      .select(ReactDOM.findDOMNode(this))
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)

    // x axis
    this.svg.append('g').attr('class', 'axis axis-x')

    // // y axis
    this.svg.append('g').attr('class', 'axis axis-y')

    this.g = this.svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)

    // add bar chart
    this.g.append('g').attr('class', 'barChart')

    // add line chart
    this.g.append('g').attr('class', 'lines')

    // add bar chart move element
    this.g.append('g').attr('class', 'moveElement')

    this.prepareDomains(
      transform_arr,
      xAxisData,
      density_support,
      keys,
      plot_type,
      density_arr
    )
    this.renderBarChart(
      transform_arr,
      keys,
      plot_type,
      name,
      settings,
      disableDragBars
    )
    if (plot_type === 'density' && density_support) {
      this.renderLineChart(density_arr, density_support)
    }

    // update axes
    this.updateAxes(plot_type)
  }

  prepareDomains = (
    transform_arr,
    xAxisData,
    density_support,
    keys,
    plot_type,
    density_arr
  ) => {
    if (plot_type === 'density') {
      const allData = xAxisData.concat(density_support || [])
      const xDomain = d3.extent(allData, (d) => d)
      const xDelta = Math.abs(xDomain[1] - xDomain[0]) / 20
      const xDomainHist = d3.extent(xAxisData, (d) => d)
      const k = (xDomainHist[1] - xDomainHist[0]) / (xDomain[1] - xDomain[0])

      this.x = d3
        .scaleLinear()
        // .domain([xDomain[0] - xDelta, xDomain[1] + xDelta])
        .domain([xDomain[0], xDomain[1] + xDelta])
        .rangeRound([0, this.width])
      // this.x.bandwidth = () => (this.width * k) / xAxisData.length;
      this.bandwidth = (this.width * k) / xAxisData.length
    } else {
      const shallowXAxisData = this.props.truncated
        ? [...xAxisData, '...']
        : xAxisData
      this.x = d3
        .scaleBand()
        .domain(shallowXAxisData)
        .rangeRound([0, this.width])

      this.bandwidth = this.x.bandwidth()
    }

    this.x1 = d3
      .scaleBand()
      .rangeRound([0, this.bandwidth])
      .domain(keys.map((d) => d.name))
      .padding(0.2)

    this.y = d3.scaleLinear().rangeRound([this.height, 0])

    const max_bar_values = d3.max(transform_arr, (d) =>
      d3.max(keys, (key) => d[key.name])
    )
    const min_bar_values = d3.min(transform_arr, (d) =>
      d3.min(keys, (key) => d[key.name])
    )

    if (plot_type === 'density' && density_arr) {
      let max_values = ''
      let min_values = ''
      if (density_arr.length > 1) {
        max_values = Math.max(
          ...density_arr[0].values,
          ...density_arr[1].values,
          max_bar_values
        )
        min_values = Math.min(
          ...density_arr[0].values,
          ...density_arr[1].values,
          min_bar_values
        )
      } else {
        max_values = Math.max(...density_arr[0].values, max_bar_values)
        min_values = Math.min(...density_arr[0].values, min_bar_values)
      }

      const _min_value = min_values < 0 ? min_values : 0
      this.y.domain([_min_value, max_values]).nice()
    } else {
      const _min_value = min_bar_values < 0 ? min_bar_values : 0
      this.y.domain([_min_value, max_bar_values])
    }
  }

  updateYDomain = (transform_arr, keys) => {
    const max_bar_values = d3.max(transform_arr, (d) =>
      d3.max(keys, (key) => d[key.name])
    )
    const min_bar_values = d3.min(transform_arr, (d) =>
      d3.min(keys, (key) => d[key.name])
    )

    const _min_value = min_bar_values < 0 ? min_bar_values : 0
    this.y.domain([_min_value, max_bar_values])
  }

  updateAxes = (plot_type) => {
    const for_rotate = this.svg
      .select('.axis-x')
      .attr(
        'transform',
        `translate(${this.margin.left}, ${this.height + this.margin.top})`
      )
      .call(
        d3
          .axisBottom(this.x)
          .ticks(4)
          .tickValues(
            plot_type === 'density'
              ? null
              : this.x.domain().length > 10
              ? this.x.domain().filter((d, i) => i % 2 === 0)
              : this.x.domain().length > 20
              ? this.x.domain().filter((d, i) => i % 3 === 0)
              : this.x.domain()
          )
          .tickSize(2)
          .tickFormat(
            this.x.domain().filter((d) => typeof d === 'string').length === 0
              ? (d) => formatNumber(d)
              : (d) => d
          )
      )
      .selectAll('text')
      .style('font-size', (d) => (d === '...' ? '15px' : '11px'))
      .style('font-weight', 400)
      .style('fill', '777c8d')

    if (
      this.x.domain().filter((d) => typeof d === 'string').length !== 0 &&
      this.x.domain().filter((d) => d.length > 4).length !== 0
    ) {
      for_rotate
        .style('text-anchor', 'end')
        .attr('dx', '-.2em')
        .attr('dy', '.6em')
        .attr('transform', 'rotate(-35)')
    }

    // y axis
    this.svg
      .select('.axis-y')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(3)
          .tickSize(0)
          .tickFormat((d) => formatNumber(d))
      )
      .selectAll('text')
      .style('font-size', '11px')
      .style('font-weight', 400)
      .style('fill', '777c8d')
  }

  updateYAxis = () => {
    // y axis
    this.svg
      .select('.axis-y')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .transition()
      .duration(axisDuration)
      .ease(d3.easeLinear)
      .call(
        d3
          .axisLeft(this.y)
          .ticks(3)
          .tickSize(0)
          .tickFormat((d) => formatNumber(d))
      )
  }

  renderLineChart(density_arr, density_support) {
    this.g.select('.lines').selectAll('.line-group').remove()

    if (density_arr.length && density_support.length) {
      const combine_density = density_arr.map((d, i) => {
        const arr = density_support.map((dd, i) => {
          return { x: dd, y: d.values[i], color: d.color }
        })
        return arr
      })

      const line = this.g
        .select('.lines')
        .selectAll('.line-group')
        .data(combine_density)
        .enter()
        .append('g')
        .attr('class', 'line-group')

      line
        .append('path')
        .attr('class', 'line')
        .attr('transform', `translate(${this.bandwidth},0)`)
        .attr('d', (d, i) => {
          return this.line(d)
        })
        .style('fill', 'none')
        .style('stroke', 'white')
        .style('stroke-width', '3.5px')

      line
        .append('path')
        .attr('class', 'line')
        .attr('transform', `translate(${this.bandwidth},0)`)
        .attr('d', (d, i) => {
          return this.line(d)
        })
        .style('fill', 'none')
        .style('stroke', (d, i) =>
          d.length && d[0].color ? d[0].color : 'none'
        )
        .style('stroke-width', '1.5px')
    }
  }

  renderBarChart(data, keys, plot_type, col_name, settings, disableDragBars) {
    this.g.select('.barChart').selectAll('path').remove()

    this.g.select('.lines').selectAll('.line-group').remove()

    this.g.select('.moveElement').selectAll('rect').remove()

    this.g.selectAll('linearGradient').remove()

    const bgOrigin = this.g
      .append('linearGradient')
      .attr('id', `origin-gradient`)
      .attr('gradientTransform', 'rotate(90)')
    bgOrigin
      .append('stop')
      .attr('stop-color', `${gr_origin_color}`)
      .attr('offset', '0%')
    bgOrigin
      .append('stop')
      .attr('stop-color', origin_color)
      .attr('offset', '100%')

    const bgSynth = this.g
      .append('linearGradient')
      .attr('id', `synth-gradient`)
      .attr('gradientTransform', 'rotate(90)')
    bgSynth
      .append('stop')
      .attr('stop-color', `${gr_synth_color}`)
      .attr('offset', '0%')
    bgSynth
      .append('stop')
      .attr('stop-color', synth_color)
      .attr('offset', '100%')

    const bgChanged = this.g
      .append('linearGradient')
      .attr('id', `changed-gradient`)
      .attr('gradientTransform', 'rotate(90)')
    bgChanged
      .append('stop')
      .attr('stop-color', `${gr_changed_color}`)
      .attr('offset', '0%')
    bgChanged
      .append('stop')
      .attr('stop-color', changed_color)
      .attr('offset', '100%')

    const { tooltip } = this

    this.g
      .select('.barChart')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('transform', (d) => `translate(${this.x(d.x)},0)`)
      .on('mouseover', function (d, i) {
        d3.select(this).style('opacity', 0.75)
        const cur_values =
          keys.length > 1
            ? `
        <ul class='t-ul-style'>
          <li class='t-li-style' ><div class='t-li-legend' style='background-color: ${
            keys[0].color
          }'></div><div>Original: ${formatNumber(d.origin)}</div></li>
          <li class='t-li-style'><div class='t-li-legend' style='background-color: ${
            keys[1].color
          }'></div><div>Synthetic: ${formatNumber(d.synth)}</div></li>
          <li><div style='margin-left: 15px'>${
            plot_type === 'density' ? 'Edge' : 'Bin'
          }:
            ${
              plot_type === 'density'
                ? `[${formatNumber(data[i].x)}, ${formatNumber(data[i + 1].x)}]`
                : `${d.x}`
            }</div>
          </li>
        </ul>`
            : `
        <ul class="t-ul-style">
          <li class="t-li-style" ><div class="t-li-legend" style="background-color: ${
            keys[0].color
          }"></div><div>${keys[0].label}: ${formatNumber(
                d[keys[0].name]
              )}</div></li>
          <li><div style="margin-left: 15px">${
            plot_type === 'density' ? 'Edge' : 'Bin'
          }:
            ${
              plot_type === 'density'
                ? `[${formatNumber(data[i]?.x)}, ${formatNumber(data[i + 1]?.x)}]`
                : `${d?.x}`
            }</div>
          </li>
        </ul>`

        const tpl = cur_values

        tooltip.transition().duration(200).style('opacity', 0.9)
        tooltip
          .html(tpl)
          .style('left', `${event !== null ? event.pageX + 8 : 0}px`)
          .style('top', `${event !== null ? event.pageY - 48 : 0}px`)
      })
      .on('mouseout', function (d) {
        d3.select(this).style('opacity', 1)
        tooltip.transition().duration(500).style('opacity', 0)
      })
      .selectAll('path')
      .data((d) =>
        keys.map((key) => {
          return { key: key.name, color: key.color, value: d[key.name] }
        })
      )
      .join('path')
      .attr('fill', (d) => {
        let _urlKey = d.key

        if (
          d.key === 'synth' &&
          !disableDragBars &&
          Object.keys(settings).length &&
          settings.conditions &&
          Object.keys(settings.conditions).includes(col_name)
        ) {
          _urlKey = 'changed'
        }

        return `url(#${_urlKey}-gradient)`
      })
      .attr('d', (d) => {
        let _radius = 0

        // if bar narrow and low
        if (this.x1.bandwidth() <= 3 && this.y(0) - this.y(d.value) <= 3) {
          if (this.x1.bandwidth() > (this.y(0) - this.y(d.value)) * 2) {
            _radius = this.y(0) - this.y(d.value)
          } else {
            _radius = this.x1.bandwidth() / 2
          }
        } else if (this.x1.bandwidth() <= 3) {
          // if narrow
          _radius = this.x1.bandwidth() / 2
        } else if (this.y(0) - this.y(d.value) <= 3) {
          // if low
          _radius = this.y(0) - this.y(d.value)
        } else {
          _radius = 3
        }
        return `
          M${
            this.x1(d.key) + (plot_type === 'density' ? this.x1.bandwidth() : 0)
          },${this.y(d.value)}
          h${this.x1.bandwidth() - _radius}
          a${_radius},${_radius} 0 0 1 ${_radius},${_radius}
          v${this.y(0) - this.y(d.value) - _radius}
          h${-this.x1.bandwidth()}
          v${_radius - (this.y(0) - this.y(d.value))}
          a${_radius},${_radius} 0 0 1 ${_radius},${-_radius}
          Z
        `
      })

    if (
      plot_type === 'histogram' &&
      data.length &&
      Object.keys(data[0]).includes('synth')
    ) {
      const new_data = JSON.parse(JSON.stringify(data))

      // for intervals
      if (plot_type === 'density') {
        new_data.splice(new_data.length - 1, 1)
      }

      this.g
        .select('.moveElement')
        .selectAll('g')
        .data(new_data)
        .join('g')
        .attr('transform', (d) => `translate(${this.x(d.x)},0)`)
        .selectAll('rect')
        .data((d) => {
          return [
            {
              key: 'synth',
              x: d.x,
              plot_type,
              col_name,
              value: d.synth,
            },
          ]
        })
        .join('rect')
        .attr('x', (d, i) => {
          return (
            this.x1(d.key) + (plot_type === 'density' ? this.x1.bandwidth() : 0)
          )
        })
        .attr('y', (d) => this.y(d.value) - 5)
        .attr('height', 10)
        .attr('width', this.x1.bandwidth())
        .style('fill', '#fff')
        .style('fill-opacity', 0.0001)
        .style('cursor', 'ns-resize')
        .style('pointer-events', disableDragBars ? 'none' : '')
        .call(
          d3
            .drag()
            .on('start', this.dragstarted)
            .on('drag', this.dragged)
            .on('end', this.dragended)
        )
    }

    if (this.props.truncated) {
      this.g
        .append('text')
        .attr(
          'transform',
          `translate(${this.width - this.margin.right + 8},${this.height / 2})`
        )
        .attr('font-size', '15px')
        .text('...')
        .style('fill', '#999')
    }
  }

  redrawBarChart(data, keys, plot_type, col_name, settings, disableDragBars) {
    this.g
      .select('.barChart')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('transform', (d) => `translate(${this.x(d.x)},0)`)

      .selectAll('path')
      .data((d) =>
        keys.map((key) => {
          return { key: key.name, color: key.color, value: d[key.name] }
        })
      )
      .join('path')
      .transition()
      .duration(dragDuration)
      .ease(d3.easeLinear)
      .attr('fill', (d) => {
        let _urlKey = d.key

        if (
          d.key === 'synth' &&
          !disableDragBars &&
          Object.keys(settings).length &&
          settings.conditions &&
          Object.keys(settings.conditions).includes(col_name)
        ) {
          _urlKey = 'changed'
        }

        return `url(#${_urlKey}-gradient)`
      })
      .attr('d', (d) => {
        let _radius = 0

        // if bar narrow and low
        if (this.x1.bandwidth() <= 3 && this.y(0) - this.y(d.value) <= 3) {
          if (this.x1.bandwidth() > (this.y(0) - this.y(d.value)) * 2) {
            _radius = this.y(0) - this.y(d.value)
          } else {
            _radius = this.x1.bandwidth() / 2
          }
        } else if (this.x1.bandwidth() <= 3) {
          // if narrow
          _radius = this.x1.bandwidth() / 2
        } else if (this.y(0) - this.y(d.value) <= 3) {
          // if low
          _radius = this.y(0) - this.y(d.value)
        } else {
          _radius = 3
        }
        return `
          M${
            this.x1(d.key) + (plot_type === 'density' ? this.x1.bandwidth() : 0)
          },${this.y(d.value)}
          h${this.x1.bandwidth() - _radius}
          a${_radius},${_radius} 0 0 1 ${_radius},${_radius}
          v${this.y(0) - this.y(d.value) - _radius}
          h${-this.x1.bandwidth()}
          v${_radius - (this.y(0) - this.y(d.value))}
          a${_radius},${_radius} 0 0 1 ${_radius},${-_radius}
          Z
        `
      })

    if (
      plot_type === 'histogram' &&
      data.length &&
      Object.keys(data[0]).includes('synth')
    ) {
      const new_data = JSON.parse(JSON.stringify(data))

      // for intervals
      if (plot_type === 'density') {
        new_data.splice(new_data.length - 1, 1)
      }

      this.g
        .select('.moveElement')
        .selectAll('g')
        .data(new_data)
        .join('g')
        .attr('transform', (d) => `translate(${this.x(d.x)},0)`)
        .selectAll('rect')
        .data((d) => {
          return [
            {
              key: 'synth',
              x: d.x,
              plot_type,
              col_name,
              value: d.synth,
            },
          ]
        })
        .join('rect')
        .transition()
        .duration(dragDuration)
        .ease(d3.easeLinear)
        .attr('y', (d) => this.y(d.value) - 5)
    }
  }

  disabledDragMoveElement = () => {
    this.svg
      .select('.moveElement')
      .selectAll('rect')
      .style('pointer-events', 'none')
  }

  dragstarted = (d, i, nodes) => {
    if (event.defaultPrevented) return

    d3.select(nodes[i]).raise().classed('active', true)
  }

  dragged = (dt, i, nodes) => {
    const { settings } = this.props
    d3.select(nodes[i])
      .attr('y', (dt.y = event.y))
      .transition()
      .duration(dragDuration)
      .ease(d3.easeLinear)

    if (event.defaultPrevented) return

    const new_shallow_data = this.recalculateBarsRatio(dt)

    this.updateYDomain(new_shallow_data, this.temp_keys)

    this.updateYAxis()

    this.redrawBarChart(
      new_shallow_data,
      this.temp_keys,
      dt.plot_type,
      dt.col_name,
      settings
    )
  }

  dragended = (dt, i, nodes) => {
    d3.select(nodes[i]).classed('active', false)

    if (event.defaultPrevented) return

    if (dt.y) {
      const new_shallow_data = this.recalculateBarsRatio(dt)

      this.updateBars(new_shallow_data, dt.col_name, dt.x)
    }
  }

  recalculateBarsRatio = (dt) => {
    let shallowData = JSON.parse(JSON.stringify(this.temp_transform_arr))
    const idx_bar = shallowData.map((d) => d.x).indexOf(dt.x)

    const selected_bar_ratio =
      this.y.invert(dt.y) > 1
        ? 1
        : this.y.invert(dt.y) < 0
        ? 0
        : +this.y.invert(dt.y).toFixed(10)

    const new_classes = this.getNewClassesArrayChangeRatio(
      shallowData,
      idx_bar,
      selected_bar_ratio
    )

    shallowData = new_classes

    shallowData[idx_bar].synth = selected_bar_ratio
    const _sum = shallowData.reduce(
      (acc, cur) => +(acc + cur.synth).toFixed(10),
      0
    )
    if (_sum !== 0) {
      shallowData[idx_bar].synth =
        _sum > 0
          ? selected_bar_ratio - +(_sum - 1).toFixed(10)
          : selected_bar_ratio + +(1 - _sum).toFixed(10)
    }

    return shallowData
  }

  getNewClassesArrayChangeRatio = (cur_classes, idx, selected_ratio) => {
    const shallow_classes = [...cur_classes]

    const sum_without_selected_bar_ratio = 1 - shallow_classes[idx].synth
    const sum_after_change_selected_bar_ratio = 1 - selected_ratio

    const new_classes = shallow_classes.map((d) => {
      let new_ratio = 0
      if (shallow_classes[idx].synth === 1) {
        new_ratio =
          sum_after_change_selected_bar_ratio / (shallow_classes.length - 1)
      } else {
        const part_of_number = sum_without_selected_bar_ratio / d.synth
        new_ratio = sum_after_change_selected_bar_ratio / part_of_number
      }

      return { x: d.x, origin: d.origin, synth: +new_ratio.toFixed(10) }
    })

    return new_classes
  }

  updateBars = (dt, col_name, bar_name) => {
    const { dataset_id, settings } = this.props
    const _conditions = {}

    dt.forEach((d) => {
      _conditions[d.x] = d.synth
    })

    let setts = JSON.parse(JSON.stringify(settings))

    // check couplings and transform
    setts = transformCouplings(setts)

    if (
      !Object.keys(setts).length ||
      (Object.keys(setts).length && !Object.keys(setts).includes('conditions'))
    ) {
      setts.conditions = {}
    }
    setts.conditions[col_name] = _conditions

    dataSetsService
      .updateDatasetSettings({
        id: dataset_id,
        settings: setts,
      })
      .then((synth_bar) => {
        console.log('Success edit dataset synth bars value: ', synth_bar)
        this.props.actionUpdateDisabledColumns({
          name: 'conditions',
          checks: setts.conditions,
        })
      })
      .catch((e) => {
        console.log('ERROR edit dataset synth bars value...', e)
      })
  }

  buildData = (transform_data, xAxisData, plot_type, col_name) => {
    const transform_arr = []
    const keys = transform_data.map((d) => {
      return { name: d.name, label: d.label, color: d.color }
    })

    for (let i = 0; i < transform_data[0]?.values.length; i++) {
      const obj = {}

      obj.x = xAxisData[i]
      transform_data.forEach((d, ii) => {
        obj[keys[ii].name] = transform_data[ii]?.values[i]
      })

      transform_arr.push(obj)
    }

    // for intervals
    if (plot_type === 'density') {
      transform_arr.push({
        x: xAxisData[xAxisData.length - 1],
        origin: 0,
        synth: 0,
      })
    }

    this.temp_transform_arr = transform_arr
    this.temp_keys = keys

    return {
      transform_arr,
      keys,
    }
  }

  shouldComponentUpdate() {
    return false
  }

  componentWillReceiveProps(next) {
    if (JSON.stringify(next) !== JSON.stringify(this.props)) {
      const {
        transform_data,
        xAxisData,
        density_support,
        plot_type,
        density_arr,
        name,
        settings,
        disableDragBars,
      } = next

      // build data
      const { transform_arr, keys } = this.buildData(
        transform_data,
        xAxisData,
        plot_type,
        name
      )

      // update domains
      this.prepareDomains(
        transform_arr,
        xAxisData,
        density_support,
        keys,
        plot_type,
        density_arr
      )

      this.renderBarChart(
        transform_arr,
        keys,
        plot_type,
        name,
        settings,
        disableDragBars
      )

      if (plot_type === 'density' && density_support) {
        this.renderLineChart(density_arr, density_support)
      }

      // update axes
      this.updateAxes(plot_type)
    }

    // disable drag bar
    if (next.status === 'training' || next.disableDragBars) {
      this.disabledDragMoveElement()
    }
  }

  render() {
    const { index, height, _from } = this.props
    return (
      <div
        className="rtable-charts"
        style={{
          height: _from && _from === 'drop_component' ? 130 : height || 200,
        }}
        id={index || ''}
      >
        {this.props.children}
      </div>
    )
  }
}

// export default Chart;

const mapDispatchToProps = (dispatch, props) => {
  return {
    actionUpdateDisabledColumns: (data) =>
      dispatch(actionUpdateDisabledColumns(data)),
  }
}

export default connect(null, mapDispatchToProps)(Chart)

import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'legacy/shared'

export const useTableStyles = makeStyles({
  headerContent: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  table: {
    position: 'relative',
    // These styles are suggested for the table fill all available space in its containing element
    display: 'block',
    height: '100%',
    // These styles are required for a horizontally scrollable table overflow
    overflowY: 'hidden',
    overflowX: 'auto',
    paddingLeft: 0,
    marginBottom: 0,
    borderSpacing: 0,
    fontSize: '0.7rem',
    '& tr': {
      '&:last-child': {
        '&td': {
          borderBottom: 0,
        },
      },
    },
    '& th': {
      display: 'flex',
      position: 'relative',
      margin: 0,
      padding: '.5rem',
      borderRight: 'none',
      alignItems: 'center',
    },
  },

  thead: {
    // These styles are required for a scrollable body to align with the header properly
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  th: {
    display: 'flex',
    position: 'relative',
    margin: 0,
    padding: '.5rem',
    borderRight: 'none',
    alignItems: 'center',
  },
  tr_head: {
    borderBottom: 'none',
    margin: '0 1px',
    borderLeft: '2px solid transparent',
  },
  td: {
    margin: 0,
    padding: '0.5rem',
    borderRight: 'none',
    alignItems: 'center',
    position: 'relative',
  },
  tbody: {
    height: 'calc(100% - 37px)',
    border: `1px solid ${colors.platinum}`,
  },
  tr_body: {
    width: 'unset',
    minWidth: '100%',
    cursor: 'pointer',
    borderLeft: '1px solid transparent',
    '&:hover': {
      background: colors.hover,
      borderLeft: `2px solid ${colors.blueRoyal}`,
    },
  },
  tableSortIcon: {
    color: colors.blueRoyal,
  },
  resizer: {
    right: '-0.5rem',
    width: '1rem',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    touchAction: 'none',
    cursor: 'ew-resize',
  },
  isResizing: {
    width: '100vw',
    right: 0,
    left: 0,
    top: 0,
  },
  resizeDivider: {
    background: colors.platinum,
    width: '1px',
    height: '1.5rem',
    touchAction: 'none',
    position: 'absolute',
    right: 0,
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {},
  spinnerTableStyles: {
    marginTop: '2.25rem',
  },
})

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import classnames from 'classnames'
import { colors } from '../../theme'

const useStyles = makeStyles((theme) => ({
  image: {
    border: '1px solid',
    borderColor: 'grey',
    position: 'relative',
    width: 250,
    height: 150,
    [theme.breakpoints.down('xs')]: {
      height: 100,
      width: 200,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      borderColor: colors.buttonDefault,
    },
  },
  focusVisible: {},
  selected: {
    borderColor: colors.buttonDefault,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
    opacity: 1,
    transition: theme.transitions.create('opacity'),
  },
  disabledImage: {
    opacity: 0.5,
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: colors.disabledGrey,
  },
}))

const Component = (props) => {
  const { image, disabled, onClick, selected } = props
  const classes = useStyles()
  return (
    <ButtonBase
      onClick={onClick}
      className={classnames(classes.image, {
        [classes.selected]: selected,
      })}
      focusVisibleClassName={classes.focusVisible}
      disabled={disabled}
    >
      <span className={classes.imageBackdrop} />
      <span
        className={classnames(classes.imageSrc, {
          [classes.disabledImage]: disabled,
        })}
        style={{
          backgroundImage: `url(${image.url})`,
        }}
      />
    </ButtonBase>
  )
}

export const ImageButton = (props) => <Component {...props} />

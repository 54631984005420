import { makeRequest } from './apiClient'

// TODO move to test
function* mockRequest() {
  const progressRequest = { progress: 0 }
  const readyRequest = {
    meta: {
      quality_data: {
        quality: [
          {
            n_rows: 2000,
            quality_score: 9.769000000000002,
          },
          {
            n_rows: 4000,
            quality_score: 9.62,
          },
          {
            n_rows: 6000,
            quality_score: 9.665,
          },
          {
            n_rows: 8000,
            quality_score: 9.287,
          },
          {
            n_rows: 10000,
            quality_score: 8.937,
          },
        ],
      },
      fairness_data: { a: [1] },
    },
  }
  yield progressRequest
  yield progressRequest
  yield progressRequest
  yield progressRequest
  yield readyRequest
}

const g = mockRequest()
const f = mockRequest()
//

export const deleteDataset = ({ id, success, error }) => {
  makeRequest({
    path: `datasets/${id}`,
    method: 'DELETE',
    success,
    error,
  })
}

export const uploadNewDataset = ({ data, formdata, success, error }) => {
  makeRequest({
    path: `datasets`,
    method: 'POST',
    params: data,
    formData: formdata,
    success,
    error,
  })
}

export default {
  async uploadNewDataset({ data }) {
    return makeRequest({
      path: `datasets`,
      method: 'POST',
      params: data,
      formData: true,
    })
  },

  async getUserLimits() {
    const response = await makeRequest({
      path: `profile/limits`,
      method: 'GET',
    })
    return response
  },

  async getDatasets() {
    const response = await makeRequest({
      path: `datasets`,
      method: 'GET',
    })
    return response.datasets
  },

  async deleteDataset({ id }) {
    const response = await makeRequest({
      path: `datasets/${id}`,
      method: 'DELETE',
    })
    return response
  },

  async getSynthDatasets() {
    const response = await makeRequest({
      path: `synthesis`,
      method: 'GET',
    })
    const responseWithStatus = response.map((d) => ({
      ...d,
      type: 'SYNTHESIS',
    }))
    return responseWithStatus
  },

  async getDatasetById({ id, sample_size = 10 }) {
    const response = await makeRequest({
      path: `datasets/${id}?sample_size=${sample_size}`,
      method: 'GET',
    })
    return response
  },

  async getOriginalDatasetById({ id, sampleSize = 10 }) {
    const response = await makeRequest({
      path: `datasets/${id}?rev_offset=-1&sample_size=${sampleSize}`,
      method: 'GET',
    })
    return response
  },

  async getCurrentDatasetById({ id, sampleSize = 10 }) {
    const response = await makeRequest({
      path: `datasets/${id}?rev_offset=0&sample_size=${sampleSize}`,
      method: 'GET',
    })
    return response
  },

  // Generate synthetic data
  async generateSyntheticData({
    id,
    data = {
      num_rows: 1000,
      conditions: {},
      to_include_original_data: false,
      to_produce_nans: false,
      to_impute_nans: false,
      to_mitigate_bias: false,
      min_dist: 0.1,
    },
  }) {
    const response = await makeRequest({
      path: `datasets/${id}/transform`,
      method: 'POST',
      params: JSON.stringify(data),
    })
    return response
  },

  async getSyntheticData({ id, sample_size = 20 }) {
    const response = await makeRequest({
      path: `datasets/${id}/transform?sample_size=${sample_size}`,
      method: 'GET',
    })
    return response
  },

  async startModelTraining(id) {
    const response = await makeRequest({
      path: `datasets/${id}/learn`,
      method: 'POST',
    })
    return response
  },

  async stopModelTraining(id) {
    const response = await makeRequest({
      path: `datasets/${id}/learn`,
      method: 'DELETE',
    })
    return response
  },

  async getModelInfo(id) {
    try {
      const response = await makeRequest({
        path: `datasets/${id}/learn`,
        method: 'GET',
      })
      return response
    } catch (error) {
      // CHECK IF DATASET HAS A MODEL
      if (error.status === 404) {
        return null
      }
      throw error
    }
  },

  async getAnalyzePreview(id) {
    const response = makeRequest({
      path: `datasets/${id}/learn/preview`,
      method: 'GET',
    })
    return response
  },

  async updateDatasetInfo({ id, data }) {
    const response = makeRequest({
      path: `datasets/${id}`,
      method: 'PUT',
      params: JSON.stringify(data),
    })
    return response
  },

  // Update dataset settings
  async updateDatasetSettings({ id, settings }) {
    const response = makeRequest({
      path: `datasets/${id}/updatesettings`,
      method: 'POST',
      params: JSON.stringify({ settings }),
    })
    return response
  },

  // Update dataset column name
  async updateDatasetColumnName({ id, names }) {
    const response = makeRequest({
      path: `datasets/${id}/renamecolumn`,
      method: 'POST',
      params: JSON.stringify(names),
    })
    return response
  },

  // Export CSV
  async exportCSVDataset(id) {
    const response = makeRequest({
      path: `datasets/${id}/export`,
      method: 'GET',
      type: 'export',
    })
    return response
  },

  // Export PDF Fairness
  async exportPDFFairness(id) {
    const response = makeRequest({
      path: `datasets/${id}/fairness/pdf`,
      method: 'POST',
      params: JSON.stringify({}),
      type: 'pdf',
    })
    return response
  },

  async startBias({ id, targetColumn }) {
    const response = await makeRequest({
      path: `datasets/${id}/fairness`,
      method: 'POST',
      params: JSON.stringify({ target: targetColumn }),
    })
    return response
    // return { progress: 0 };
  },

  async getBiasProgress(id) {
    const response = await makeRequest({
      path: `datasets/${id}/fairness`,
      method: 'GET',
    })
    // const response = f.next().value;
    // console.log('bias', response);
    return response
  },

  async startQuality(id) {
    const response = await makeRequest({
      path: `datasets/${id}/quality`,
      method: 'POST',
    })
    return response
    // return { progress: 0 };
  },

  async getQualityProgress(id) {
    const response = await makeRequest({
      path: `datasets/${id}/quality`,
      method: 'GET',
    })
    // const response = g.next().value;
    // console.log(response);
    return response
  },

  async getRunningTaskList() {
    const response = await makeRequest({
      path: `command/running`,
      method: 'GET',
    })
    return response
  },
}

import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useFormik } from 'formik'

import { Box } from 'UIkit/Box/Box'
import { CheckBox, TextField, Typography } from 'legacy/shared'

import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'

export const BiasMitigation = ({
  group,
  isBiasMitigationDisabled,
  handleChangeRow,
}: any) => {
  const formik = useFormik({
    initialValues: { min_dist: group.min_dist },
    validate: createValidatorWithSchema({ min_dist: constraints.biasMinDist }),
    validateOnChange: true,
    onSubmit: (values) =>
      handleChangeRow(
        {
          value: values.min_dist,
          id: group.id,
          name: 'min_dist',
        },
        true
      ),
  })

  return (
    <Box pt={1} pb={4}>
      <Typography size="bodyBold">Bias Mitigation.</Typography>
      {isBiasMitigationDisabled.isDisabled ? (
        <Box pt={1}>
          <Typography>{isBiasMitigationDisabled.message}</Typography>{' '}
        </Box>
      ) : null}
      <Box pt={1}>
        <CheckBox
          disabled={isBiasMitigationDisabled.isDisabled}
          checked={group.to_mitigate_bias}
          onChange={(e: any, checked: boolean) =>
            handleChangeRow(
              {
                value: checked,
                id: group.id,
                name: 'to_mitigate_bias',
              },
              true
            )
          }
          label={
            <Typography size="captionBold" color="stone">
              Mitigate the biases detected in the dataset?
            </Typography>
          }
        />

        {group.to_mitigate_bias ? (
          <Box pt={1}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                value={formik.values.min_dist}
                type="number"
                name="min_dist"
                label="Bias mitigation"
                onChange={(e: any) => formik.handleChange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                inputProps={{ step: 0.1, min: 0 }}
                error={Boolean(formik.errors.min_dist)}
                helperText={formik.errors.min_dist}
                onBlur={formik.handleSubmit}
              />
            </form>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { spacing } from 'legacy/shared'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: spacing.gutter * 3,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginRight: 0,
      marginLeft: 0,
      marginBottom: spacing.gutter / 2,
    },
    '& > *:last-of-type': {
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      marginTop: spacing.gutter / 2,
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& > *': {
        marginRight: spacing.gutter / 2,
        marginBottom: 0,
      },
      '& > *:last-of-type': {
        marginRight: 0,
        marginLeft: spacing.gutter / 2,
        marginTop: 0,
      },
    },
  },
}))

export const ModalButtonContainer = (props: {
  children: JSX.Element | JSX.Element[]
  className?: string
}) => {
  const { children, className } = props
  const classes = useStyles()
  return <div className={classnames(classes.root, className)}>{children}</div>
}

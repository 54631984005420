import React from 'react'
import ReactTable from 'react-table-6'
import { formatNumber } from '../../services/helper'
import 'react-table-6/react-table.css'
import { Typography } from '../../shared'

import './Modelling.scss'

export default class Modelling extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(nextProps.data_item.results) !==
      JSON.stringify(this.props.blocks_data.results)
    )
      return true
    if (
      this.props.from &&
      this.props.from === 'monitor' &&
      JSON.stringify(nextProps.data_item) !==
        JSON.stringify(this.props.blocks_data)
    )
      return true
    return false
  }

  render() {
    const { data_item, from } = this.props
    const keys = Object.keys(data_item.results)
    const idx_quality = keys.findIndex((d) => d === 'quality') // find 'quality' index that sort keys
    const temp_key = keys.splice(idx_quality, 1)
    keys.push(temp_key[0])

    const columns = keys.map((d) => {
      return {
        Header: <Typography size="body">{d}</Typography>,
        accessor: d,
        minWidth: 160,
        Cell: (props) => {
          return Object.keys(props.original)[0] === 'error' ? (
            <Typography size="captionBold">{props.original.error}</Typography>
          ) : props.column.id === 'metric' && props.value === 'roc_auc' ? (
            <Typography size="captionBold">ROC AUC</Typography>
          ) : typeof props.value !== 'string' ? (
            <Typography size="captionBold">
              {formatNumber(
                from && from === 'monitor'
                  ? Math.random() * (100 + 0) - 0
                  : props.value,
                '0.[00]'
              ) + (props.column.id === 'quality' ? '%' : '')}
            </Typography>
          ) : (
            <Typography size="captionBold">{props.value}</Typography>
          )
        },
      }
    })

    const table_data = new Array(data_item.results)

    return (
      <div className="row m-0 mod-table">
        <div className="col col-12 mod-table-container">
          <div className="table">
            <ReactTable
              data={table_data}
              columns={columns}
              showPagination={false}
              resizable={false}
              sortable={false}
              defaultPageSize={table_data.length}
              className="-striped -highlight"
            />
          </div>
        </div>
      </div>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Routes } from 'legacy/utils'
import { Typography } from 'legacy/shared'

import HeaderComponent from 'layouts/Header'
import ProfileForm from './ProfileForm'
import ProfileImage from './ProfileImage'

const useStyles = makeStyles({
  linkSection: {
    marginTop: '1rem',
  },
})

const ProfilePage = () => {
  const classes = useStyles()

  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <HeaderComponent title="Profile" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '2.5rem',
        }}
      >
        <Grid container justify="center" spacing={3}>
          <Grid item md={3}>
            <ProfileImage />
          </Grid>
          <Grid item md={5}>
            <div>
              <div style={{ marginBottom: '16px' }}>
                <Typography size="subheaderBold">Profile Info</Typography>
              </div>
              <ProfileForm />
              <Typography size="captionBold" className={classes.linkSection}>
                <Link to={Routes.ProfileResetPassword()}>Reset Password</Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ProfilePage

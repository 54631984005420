import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  headerLayout: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '4.5rem',
  },
  contentLayout: {
    overflowX: 'auto',
    padding: '20px 40px',
  },
})

export const SandBoxHeaderLayout = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.headerLayout}>{children}</div>
}

export const SandBoxContentLayout = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.contentLayout}>{children}</div>
}

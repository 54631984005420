import update from 'immutability-helper'
import { initData } from '../../services/helper'

const initState = {
  avatar_path: '',
  start_loading: true,
  loading: 0,
  loadingReport: 0,
  loadingDatasource: 0,
  loadingDataset: 0,
  tabs_arr: [
    { activeTab: null },
    { activeTab: null },
    { activeTab: null },
    { activeTab: null },
    { activeTab: null },
    { activeTab: null },
    { activeTab: null },
  ],
  status: null,
  active_block: null,
  listDatasets: [],
  templates: [],
  synth_data: {},
  data: {},
  blocks_data: null,
  blocks_data_monitor: null,
  datasources: [],
  source_tables: [],
  connected_tables: {},
  query_data: {},
}

export default (state = initState, action) => {
  const { type, payload } = action
  switch (type) {
    case 'SET_AVATAR': {
      return {
        ...state,
        avatar_path: payload,
      }
    }

    case 'INIT_DATA': {
      return {
        ...state,
        data: Object.keys(payload).length ? initData(payload, 'origin') : {},
      }
    }

    case 'INIT_SYNTH_DATA': {
      return {
        ...state,
        synth_data: Object.keys(payload).length
          ? initData(payload, 'synth')
          : {},
        blocks_data: null,
      }
    }

    case 'INIT_BLOCKS_DATA': {
      return {
        ...state,
        [payload.from !== 'monitor' ? 'blocks_data' : 'blocks_data_monitor']:
          payload.data,
      }
    }

    case 'INIT_TEMPLATES': {
      return {
        ...state,
        templates: payload,
      }
    }

    case 'TABS_SIDEBAR': {
      const new_tabs_arr = update(state.tabs_arr, {
        $apply: (tabs) =>
          tabs.map((tab, i) => ({
            ...tab,
            activeTab: i === payload.big_tab ? payload.value : tab.activeTab,
            active: i === payload.big_tab,
          })),
      })
      return {
        ...state,
        tabs_arr: new_tabs_arr,
      }
    }

    case 'RESET_TABS': {
      const new_tabs_arr = update(state.tabs_arr, {
        $apply: (tabs) =>
          tabs.map((tab, i) => ({
            ...tab,
            activeTab: null,
            active: false,
          })),
      })

      return {
        ...state,
        tabs_arr: new_tabs_arr,
      }
    }

    case 'RESET_DATA': {
      return {
        ...state,
        data: {},
        synth_data: {},
        status: null,
        blocks_data: null,
      }
    }

    case 'RESET_STATE': {
      console.log('reset state')
      return {
        ...state,
        avatar_path: '',
        start_loading: true,
        loading: 0,
        loadingReport: 0,
        loadingDatasource: 0,
        loadingDataset: 0,
        tabs_arr: [
          { activeTab: null },
          { activeTab: null },
          { activeTab: null },
          { activeTab: null },
          { activeTab: null },
          { activeTab: null },
          { activeTab: null },
        ],
        status: null,
        active_block: null,
        listDatasets: [],
        templates: [],
        synth_data: {},
        data: {},
        blocks_data: null,
        datasources: [],
        source_tables: [],
        connected_tables: {},
        query_data: {},
      }
    }

    case 'GET_LIST_DATASETS': {
      return {
        ...state,
        listDatasets: payload,
      }
    }

    case 'DELETE_DATASET': {
      const idx_dataset = state.listDatasets.findIndex(
        (d) => d.dataset_id === payload
      )
      return {
        ...state,
        listDatasets: update(state.listDatasets, {
          $splice: [[idx_dataset, 1]],
        }),
      }
    }

    case 'CHANGE_PROJECT_TITLE': {
      return {
        ...state,
        data: update(state.data, {
          title: { $set: payload },
        }),
      }
    }

    case 'EDIT_DATASET_COLUMNS_NAME': {
      const new_data = updateDatasetColumnsName(state.data, payload)
      const new_synth_data = Object.keys(state.synth_data).length
        ? updateDatasetColumnsName(state.synth_data, payload)
        : state.synth_data
      return {
        ...state,
        data: new_data,
        synth_data: new_synth_data,
        blocks_data: null,
      }
    }

    case 'EDIT_DATASET_COLUMNS_TYPE_FAMILE': {
      const new_data = changeDatasetColumnTypeFamily(state.data, payload)
      const new_synth_data = Object.keys(state.synth_data).length
        ? changeDatasetColumnTypeFamily(state.synth_data, payload)
        : state.synth_data
      return {
        ...state,
        data: new_data,
        synth_data: new_synth_data !== null ? new_synth_data : state.synth_data,
        blocks_data: null,
      }
    }

    case 'START_LOADING': {
      return {
        ...state,
        loading: state.loading + 1,
      }
    }

    case 'START_LOADING_REPORT': {
      return {
        ...state,
        loadingReport: state.loadingReport + 1,
      }
    }

    case 'START_LOADING_DATASOURCE': {
      return {
        ...state,
        loadingDatasource: state.loadingDatasource + 1,
      }
    }

    case 'STOP_LOADING_DATASOURCE': {
      return {
        ...state,
        loadingDatasource:
          state.loadingDatasource - 1 < 0 ? 0 : state.loadingDatasource - 1,
      }
    }

    case 'START_LOADING_DATASET': {
      return {
        ...state,
        loadingDataset: state.loadingDataset + 1,
      }
    }

    case 'STOP_LOADING': {
      return {
        ...state,
        start_loading: false,
        loading: state.loading - 1 < 0 ? 0 : state.loading - 1,
      }
    }

    case 'STOP_LOADING_REPORT': {
      return {
        ...state,
        loadingReport:
          state.loadingReport - 1 < 0 ? 0 : state.loadingReport - 1,
        loadingDataset:
          state.loadingDataset - 1 < 0 ? 0 : state.loadingDataset - 1,
        loadingDatasource:
          state.loadingDatasource - 1 < 0 ? 0 : state.loadingDatasource - 1,
      }
    }

    case 'UPDATE_STATUS': {
      return {
        ...state,
        status: action.status,
      }
    }

    case 'UPDATE_DISABLED_COLUMNS': {
      return {
        ...state,
        data: update(state.data, {
          settings: {
            [payload.name]: { $set: payload.checks },
          },
        }),
        blocks_data: null,
      }
    }

    case 'UPDATE_COMPLEX_COLUMNS': {
      const arr = []
      payload.forEach((d) => {
        d.selects.forEach((dd) => {
          if (dd.selectedColumn.length) {
            arr.push({
              name: [...dd.selectedColumn],
              color: d.color,
              complexSelect: dd.label,
              complexType: d.complexType,
            })
          }
        })
      })
      return {
        ...state,
        data: update(state.data, {
          settings: {
            couplings: { $set: arr },
          },
        }),
      }
    }

    case 'DELETE_COMPLEX_COLUMNS': {
      const arr =
        payload !== null
          ? state.data.settings.couplings.filter(
              (d) => d.complexType !== payload
            )
          : []
      return {
        ...state,
        data: update(state.data, {
          settings: {
            couplings: { $set: arr },
          },
        }),
      }
    }

    case 'ADD_BLOCK': {
      return {
        ...state,
        blocks_data: update(state.blocks_data, {
          $push: [payload],
        }),
      }
    }

    case 'CHANGE_CHECKBOX': {
      const _data =
        payload.from === 'monitor'
          ? [...state.blocks_data_monitor]
          : [...state.blocks_data]
      return {
        ...state,
        [payload.from === 'monitor' ? 'blocks_data_monitor' : 'blocks_data']:
          update(_data, {
            [payload.data.index_blocks_data]: {
              cats: {
                [payload.data.index_cat]: {
                  values: {
                    [payload.data.index_subcat]: {
                      values: {
                        [payload.data.index_values]: {
                          check: {
                            $set: !_data[payload.data.index_blocks_data].cats[
                              payload.data.index_cat
                            ].values[payload.data.index_subcat].values[
                              payload.data.index_values
                            ].check,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          }),
      }
    }

    case 'CHANGE_DROPDOWN': {
      const _data =
        payload.from === 'monitor'
          ? [...state.blocks_data_monitor]
          : [...state.blocks_data]
      return {
        ...state,
        [payload.from === 'monitor' ? 'blocks_data_monitor' : 'blocks_data']:
          update(_data, {
            [payload.data.index_blocks_data]: {
              cats: {
                [payload.data.index_cat]: {
                  values: {
                    $apply: (drops) =>
                      drops.map((drop, i) => ({
                        ...drop,
                        active: drop.id === payload.data.id_dropdown,
                      })),
                  },
                },
              },
            },
          }),
      }
    }

    case 'REMOVE_BLOCK': {
      const _data =
        payload.from === 'monitor'
          ? [...state.blocks_data_monitor]
          : [...state.blocks_data]
      const idx = _data.findIndex((d) => d.id === payload.id)
      return {
        ...state,
        [payload.from === 'monitor' ? 'blocks_data_monitor' : 'blocks_data']:
          update(_data, {
            $splice: [[idx, 1]],
          }),
        active_block:
          state.active_block === payload ? null : state.active_block,
      }
    }

    case 'SORTED_BLOCKS_DATA': {
      return {
        ...state,
        blocks_data: payload,
      }
    }

    case 'SET_ACTIVE_BLOCK': {
      return {
        ...state,
        active_block: payload,
        blocks_data: payload === null ? null : state.blocks_data,
      }
    }

    case 'CHANGE_COLUMN_NAME': {
      return {
        ...state,
        data: update(state.data, {
          meta: {
            columns: {
              [payload.index]: {
                name: { $set: payload.title },
              },
            },
          },
        }),
        synth_data: state.synth_data
          ? update(state.synth_data, {
              meta: {
                columns: {
                  [payload.index]: {
                    name: { $set: payload.title },
                  },
                },
              },
            })
          : state.synth_data,
      }
    }

    // DATASOURCES

    case 'INIT_LIST_DATASOURCES': {
      const sort_datasources = payload.sort(function (a, b) {
        return a.datasource_id - b.datasource_id
      })
      return {
        ...state,
        datasources: sort_datasources,
      }
    }

    case 'UPDATE_DATASOURCES': {
      let new_datasources = []
      switch (payload.string) {
        case 'create':
          new_datasources = handlerCreateDatasource(
            state.datasources,
            payload.data
          )
          break
        case 'update':
          new_datasources = handlerUpdateDatasource(
            state.datasources,
            payload.data
          )
          break
        case 'delete':
          new_datasources = handlerDeleteDatasource(
            state.datasources,
            payload.data
          )
          break
        default:
          break
      }
      return {
        ...state,
        datasources: new_datasources,
      }
    }

    case 'INIT_SOURCE_TABLES': {
      const obj_connected_tables = {}
      payload.forEach((d) => {
        obj_connected_tables[d.name] = []
      })
      // find foreign tables for each _tables
      Object.keys(obj_connected_tables).forEach((d) => {
        // find connected tables
        const foreign_tables = []
        payload.forEach((f) => {
          f.foreign_keys.forEach((key) => {
            if (f.name === d) {
              foreign_tables.push(key.referred_table)
            } else if (key.referred_table === d) {
              foreign_tables.push(f.name)
            }
          })
        })
        obj_connected_tables[d] = foreign_tables
      })

      return {
        ...state,
        source_tables: payload,
        connected_tables: obj_connected_tables,
      }
    }

    case 'INIT_QUERY_DATA': {
      const new_payload = JSON.parse(JSON.stringify(payload))
      const new_columns = []
      if (
        Object.keys(new_payload).length &&
        new_payload.meta !== null &&
        Object.keys(new_payload.meta).length
      ) {
        new_payload.meta.columns.forEach((d, i) => {
          const col = d
          const split_prefix = col.name.split('.')
          col.name = split_prefix[split_prefix.length - 1]
          col.table = new_payload.settings.columns[i].table
          new_columns.push(col)
        })
        new_payload.meta.columns = new_columns
      }

      return {
        ...state,
        query_data: new_payload,
      }
    }

    case 'RESET_QUERY_DATA': {
      return {
        ...state,
        query_data: update(state.query_data, {
          meta: { $set: null },
          query: { $set: null },
          settings: {
            columns: { $set: [] },
          },
        }),
      }
    }
    // TODO move in separate reducer
    case 'dataSource/RESET_TABLE_QUERY_DATA': {
      return {
        ...state,
        source_tables: [],
        connected_tables: {},
        query_data: {},
      }
    }

    default:
      return state
  }
}

const updateDatasetColumnsName = (data, payload) => {
  const disables_obj =
    Object.keys(data).length && data.settings && data.settings !== null
      ? JSON.parse(JSON.stringify(data.settings))
      : {}

  Object.keys(disables_obj).length &&
    Object.keys(disables_obj).forEach((f) => {
      if (Array.isArray(disables_obj[f]) && disables_obj[f].length) {
        if (f === 'couplings') {
          const _idx = disables_obj[f].findIndex(
            (fi) => fi.name === payload.old_name
          )
          if (_idx !== -1) {
            disables_obj[f][_idx].name = payload.new_name
          }
        } else {
          const idx_dis = disables_obj[f]
            .map((m) => m)
            .indexOf(payload.old_name)
          if (idx_dis !== -1) {
            disables_obj[f].splice(idx_dis, 1, payload.new_name)
          }
        }
      } else if (typeof disables_obj[f] === 'object') {
        const keys = Object.keys(disables_obj[f]).length
          ? Object.keys(disables_obj[f])
          : []
        if (keys.length) {
          const idx_dis = keys.map((m) => m).indexOf(payload.old_name)
          if (idx_dis !== -1) {
            disables_obj[f][payload.new_name] =
              disables_obj[f][payload.old_name]
            delete disables_obj[f][payload.old_name]
          }
        }
      }
    })
  const new_data = update(data, {
    settings: { $set: disables_obj },
    meta: {
      columns: {
        $apply: (cols) =>
          cols.map((d, i) => {
            if (d.name === payload.old_name) {
              d.name = payload.new_name
            }

            return {
              ...d,
            }
          }),
      },
    },
    sample: {
      $apply: (objs) =>
        objs.map((d, i) => {
          d[payload.new_name] = d[payload.old_name]
          delete d[payload.old_name]

          return {
            ...d,
          }
        }),
    },
  })
  return new_data
}

const changeDatasetColumnTypeFamily = (data, payload) => {
  const idx_col = data.meta.columns.findIndex((d) => d.name === payload.name)

  if (idx_col !== -1) {
    const new_data = update(data, {
      meta: {
        columns: {
          [idx_col]: {
            type_family: { $set: payload.type },
          },
        },
      },
    })
    return new_data
  }
  return null
}

const handlerCreateDatasource = (data, payload) => {
  const new_data = update(data, { $push: [payload] })
  return new_data
}

const handlerUpdateDatasource = (data, payload) => {
  const idx_datasource = data.findIndex(
    (d) => d.datasource_id === payload.datasource_id
  )
  let new_data = []
  if (idx_datasource !== -1) {
    new_data = update(data, { $splice: [[idx_datasource, 1, payload]] })
  } else {
    new_data = data
  }
  return new_data
}

const handlerDeleteDatasource = (data, payload) => {
  const idx_datasource = data.findIndex(
    (d) => d.datasource_id === payload.datasource_id
  )
  let new_data = []
  if (idx_datasource !== -1) {
    new_data = update(data, { $splice: [[idx_datasource, 1]] })
  } else {
    new_data = data
  }
  return new_data
}

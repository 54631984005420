import { createSelector } from 'reselect'
import { getProjectState } from '../projectSelectors'

export const getSynthDatasetState = createSelector(
  getProjectState,
  (state) => state.synthDataset
)

export const getProjectSettingsLoadingState = createSelector(
  getSynthDatasetState,
  (state) => state.loading
)

export const getSynthesisProgressState = createSelector(
  getSynthDatasetState,
  (state) => state.synthProgress
)

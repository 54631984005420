import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import { InputBaseComponentProps, MenuItem, Theme } from '@material-ui/core'
import { Alert, Button, Box, TextField } from 'UIkit'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { IStore } from 'core/rootReducer'
import { SwitchCase } from 'legacy/shared'
import { isHostValid, isPortValid } from 'utils/validate'
import { DBTypes } from 'pages/DataSources/constants'
import { checkDBConnection } from 'services/createTestingSuite/sagas'
import {
  selectDBConnectFormValues,
  selectLoading,
} from 'services/createTestingSuite/selectors'
import {
  createTestingSuiteActions,
  initialDBConnectFormValues,
} from 'services/createTestingSuite/reducer'
import { IDBConfig } from 'services/createTestingSuite/types'

enum ConnectionStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}
interface IConnectDBProps {
  handleCreate?: (values: IDBConfig) => void
  handleCancel?: () => void
}

type SelectedProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

const defaultInputProps = {
  maxLength: 40,
}
const validate = (values: IDBConfig) => {
  const errors = {
    name: '',
    type: '',
    host: '',
    port: '',
    userName: '',
    password: '',
    database: '',
  }

  // @ts-ignore
  Object.keys(values).forEach((key: keyof IDBConfig) => {
    if (!values[key]) {
      errors[key] = 'Required'
    }
  })
  if (values.host && !isHostValid(values.host)) {
    errors.host = `Invalid host`
  }
  if (values.port !== '' && !isPortValid(values.port)) {
    errors.port = `Invalid port`
  }
  return errors
}

const ConnectDBForm: React.FunctionComponent<
  IConnectDBProps & SelectedProps & DispatchProps
> = ({ handleCreate, handleCancel, initialValues, setValues, loading }) => {
  const classes = useStyles()

  const [dBCheckStatus, setDBCheckStatus] = useState({
    message: '',
    status: ConnectionStatus.Idle,
  })

  const formik = useFormik({
    initialValues: { ...initialValues },
    validate,
    onSubmit(values: IDBConfig): void | Promise<any> {
      handleCreate(values)
    },
  })
  const { name, type, host, port, userName, password, database } = formik.values
  const inputsProps = [
    { name: 'name', value: name, label: 'Name*' },
    { name: 'type', value: type, label: 'Type*', select: 'true' },
    {
      name: 'host',
      value: host,
      label: 'Host*',
      style: { width: '48%', marginRight: '4%' },
    },
    {
      name: 'port',
      value: port,
      label: 'Port*',
      type: 'number',
      style: { width: '48%' },
    },
    {
      name: 'userName',
      value: userName,
      label: 'User Name*',
      style: { width: '48%', marginRight: '4%' },
    },
    {
      name: 'password',
      value: password,
      label: 'Password*',
      type: 'password',
      style: { width: '48%' },
    },
    { name: 'database', value: database, label: 'Database*' },
  ]
  const checkConnection = async () => {
    setDBCheckStatus({ message: '', status: ConnectionStatus.Loading })
    const res = await checkDBConnection({
      ...formik.values,
      port: String(formik.values.port),
    })

    if (res) {
      setDBCheckStatus({
        message: 'Success! Connection established.',
        status: ConnectionStatus.Succeeded,
      })
    } else {
      setDBCheckStatus({
        message: 'Error - Connection failed.',
        status: ConnectionStatus.Failed,
      })
    }
  }

  const isCheckConnectionDisabled =
    Object.values(formik.errors).some((el) => !!el) ||
    Object.values(formik.values).some((el) => !el)

  const isCreateDisabled =
    Object.values(formik.errors).some((el) => !!el) ||
    Object.values(formik.values).some((el) => !el) ||
    dBCheckStatus.status !== ConnectionStatus.Succeeded

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Box>
        {inputsProps.map((inputProp: InputBaseComponentProps) => (
          <TextField
            id={inputProp.label}
            key={inputProp.name}
            data-testid={inputProp.label}
            label={inputProp.label}
            variant="outlined"
            size="small"
            select={Boolean(inputProp.select)}
            inputProps={{ ...defaultInputProps, ...inputProp, style: null }}
            onChange={(e) => {
              formik.handleChange(e)
              setDBCheckStatus({ message: '', status: ConnectionStatus.Idle })
            }}
            onBlur={(e) => {
              formik.handleBlur(e)
              setValues(formik.values)
            }}
            className={classes.textField}
            error={
              formik.touched[inputProp.name as keyof IDBConfig] &&
              Boolean(formik.errors[inputProp.name as keyof IDBConfig])
            }
            helperText={
              formik.touched[inputProp.name as keyof IDBConfig] &&
              formik.errors[inputProp.name as keyof IDBConfig]
            }
            style={inputProp.style}
          >
            {inputProp.select &&
              inputProp.name === 'type' &&
              Object.keys(DBTypes)
                .filter((key) => !isNaN(Number(DBTypes[key as any])))
                .map((option) => (
                  <MenuItem key={option} data-testid={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
          </TextField>
        ))}
        <Box className={classes.check}>
          <Button
            onClick={checkConnection}
            disabled={isCheckConnectionDisabled}
            variant="outlined"
            size="large"
            data-testid="check_connection"
            loading={dBCheckStatus.status === ConnectionStatus.Loading}
          >
            Check connection
          </Button>
          <SwitchCase value={dBCheckStatus.status}>
            {{
              [ConnectionStatus.Failed]: (
                <Alert
                  data-testid="error-connection"
                  severity="error"
                  className={classes.alert}
                >
                  {dBCheckStatus.message}
                </Alert>
              ),
              [ConnectionStatus.Succeeded]: (
                <Alert
                  data-testid="db-connection-success"
                  severity="success"
                  className={classes.alert}
                >
                  {dBCheckStatus.message}
                </Alert>
              ),
            }}
          </SwitchCase>
        </Box>
      </Box>
      <Box mb={6} className={classes.buttonsContainer}>
        <Box mr={2}>
          <Button
            type="reset"
            onClick={() => {
              setValues(initialDBConnectFormValues)
              handleCancel()
            }}
            variant="outlined"
            size="large"
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
        <Button
          data-testid="create"
          type="submit"
          disabled={isCreateDisabled}
          size="large"
          loading={loading}
          onClick={() => handleCreate(formik.values)}
        >
          Create
        </Button>
      </Box>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    textField: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    check: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    alert: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: 0,
    },
  })
)

const mapStateToProps = (state: IStore) => ({
  initialValues: selectDBConnectFormValues(state),
  loading: selectLoading(state),
})
const mapDispatchToProps = {
  setValues: createTestingSuiteActions.setDBConnectFormValues,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectDBForm)

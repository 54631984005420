import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Route, Switch } from 'react-router-dom'
import { last } from 'lodash'
import { RouteWithRedirectSandboxBuild } from '../../App/RoutingPolicy'
import { Routes } from '../../../utils'
import { CleanRoomBuildingSteps, BuildingStepsOrder } from './constants'
import SandboxBuildHeader from './SandboxBuildHeader'
import { SandBoxContentLayout } from '../Layouts'
import SandboxBuildStepper from './SandboxBuildStepper'
import DatasetView from './dataset/DatasetView'
import OsView from './os/OsView'
import DbServerView from './dbServer/DbServerView'
import ConfirmSandboxView from './confirm/ConfirmView'
import { getEditableSteps } from './sandboxBuildSelectors'

const defineCurrentStepIndex = (order) => {
  const urlStep = last(window.location.pathname.split('/'))
  return order.indexOf(urlStep)
}

export const SandboxBuildView = (props) => {
  const { editableBuildSandboxSteps } = props

  return (
    <>
      <SandboxBuildHeader />
      <SandBoxContentLayout>
        <SandboxBuildStepper
          activeStep={defineCurrentStepIndex(BuildingStepsOrder)}
        />
        <Switch>
          <Route
            path={Routes.CleanRoomBuildStep(CleanRoomBuildingSteps.Dataset)}
            component={DatasetView}
          />
          <RouteWithRedirectSandboxBuild
            path={Routes.CleanRoomBuildStep(CleanRoomBuildingSteps.OS)}
            editableBuildSandboxSteps={editableBuildSandboxSteps}
          >
            <OsView />
          </RouteWithRedirectSandboxBuild>
          <RouteWithRedirectSandboxBuild
            path={Routes.CleanRoomBuildStep(CleanRoomBuildingSteps.DB)}
            editableBuildSandboxSteps={editableBuildSandboxSteps}
          >
            <DbServerView />
          </RouteWithRedirectSandboxBuild>
          <RouteWithRedirectSandboxBuild
            path={Routes.CleanRoomBuildStep(CleanRoomBuildingSteps.Confirm)}
            editableBuildSandboxSteps={editableBuildSandboxSteps}
          >
            <ConfirmSandboxView />
          </RouteWithRedirectSandboxBuild>
        </Switch>
      </SandBoxContentLayout>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  editableBuildSandboxSteps: getEditableSteps,
})

export default connect(mapStateToProps, null)(SandboxBuildView)

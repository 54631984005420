import { omit } from 'lodash'
import { SandboxesDatasetActions } from './actions'
import { SandboxesBuildActions } from '../actions'
import { SandboxesConfirmActions } from '../confirm/actions'
import { arrayToObjectById } from '../../../../utils'

const getSandboxDatasetDefaultState = () => ({
  byId: {},
  selectedDatasetsById: {},
  searchString: '',
  reducerState: 'regular',
})

export default (state = getSandboxDatasetDefaultState(), action) => {
  switch (action.type) {
    case SandboxesDatasetActions.FETCH_DATASETS_LIST: {
      return {
        ...state,
        reducerState: 'saving',
      }
    }

    case SandboxesDatasetActions.DID_FETCH_DATASETS_LIST:
      return {
        ...state,
        byId: arrayToObjectById(action.datasets),
        reducerState: 'regular',
      }

    case SandboxesDatasetActions.CHANGE_DATASET_SELECTION:
      if (state.selectedDatasetsById[action.dataset.id]) {
        return {
          ...state,
          selectedDatasetsById: omit(
            state.selectedDatasetsById,
            action.dataset.id
          ),
        }
      }
      return {
        ...state,
        selectedDatasetsById: {
          ...state.selectedDatasetsById,
          [action.dataset.id]: action.dataset,
        },
      }

    case SandboxesDatasetActions.SET_SEARCH_VALUE:
      return {
        ...state,
        searchString: action.searchString,
      }

    case SandboxesBuildActions.CANCEL_BUILD_SANDBOX:
    case SandboxesConfirmActions.DID_BUILD_SANDBOX: {
      return getSandboxDatasetDefaultState()
    }

    default:
      return state
  }
}

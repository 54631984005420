import React from 'react'
import { Container, Draggable } from 'react-smooth-dnd'

import DropComponent from './drop_components/DropComponent'

import './DragDropStyle.scss'

import _v from '../../../styles/_variables.scss'

const style = {
  width: '100%',
  overflowX: 'auto',
  height: '100%',
  borderStyle: _v.border_style,
  borderWidth: _v.border_width,
  borderColor: _v.border_color,
  borderRadius: _v.border_radius,
  outline: 'none',
  position: 'relative',
}

class DropContainer extends React.Component {
  state = {
    isDrag: false,
  }

  getPayload = (index) => {
    return this.props.items[index]
  }

  deleteColumn = (index) => {
    this.props.onDrop({ removedIndex: index, addedIndex: null, payload: {} })
  }

  render() {
    const { isDrag } = this.state
    return (
      <div style={{ ...style }}>
        <Container
          orientation="horizontal"
          groupName="source_drag_and_drop"
          getChildPayload={this.getPayload}
          onDrop={this.props.onDrop}
          onDragStart={() => this.setState({ isDrag: true })}
          onDragEnd={() => this.setState({ isDrag: false })}
          lockAxis="x"
          dropPlaceholder={{
            animationDuration: 150,
            showOnTop: true,
            className: 'cards-drop-preview',
          }}
        >
          {this.props.items.length ? (
            this.props.items.map((item, index) => (
              <Draggable key={index}>
                <DropComponent
                  item={item}
                  index={index}
                  deleteColumn={this.deleteColumn}
                />
              </Draggable>
            ))
          ) : !isDrag ? (
            <div className="to_start"> To start (zero text)...</div>
          ) : null}
        </Container>
      </div>
    )
  }
}

export default DropContainer

import { PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'
import {
  takeLatest,
  put,
  call,
  all,
  delay,
  race,
  take,
  select,
} from 'redux-saga/effects'

import { Routes, pushRoute, trackEvent } from 'legacy/utils'
import { notificationActions } from 'services/notification/reducer'
import { selectCurrentSuite } from 'services/createTestingSuite/selectors'

import { makeRequest } from 'legacy/utils/services/apiClient'
import { SuiteResponse } from 'api/generated'
import { createTestingSuiteActions } from './reducer'
import { ICreateTestingSuitePayload, IDBConfig } from './types'
import { modalActions } from '../modal/reducer'

const uploadSuite = async (payload: any) => {
  const { formData } = payload
  const suite: any = makeRequest({
    path: `suites/create`,
    method: 'POST',
    params: formData,
    formData: true,
  })

  return suite
}

export const checkDBConnection = async (payload: any) => {
  try {
    return await makeRequest({
      path: `suites/datasource/check`,
      method: 'POST',
      params: JSON.stringify(getMappedPayload(payload)),
    })
  } catch (err) {
    showError(err)
  }
}

const getSuite = async (payload: any) => {
  return makeRequest({
    path: `suites/${payload}`,
  })
}
const getSuiteDataSources = async (payload: any) => {
  return makeRequest({
    path: `suites/${payload}/datasources`,
  })
}

const updateSuite = async (payload: any) => {
  const { id, formData, updateType } = payload

  return makeRequest({
    path: updateType
      ? `suites/${id}/update/${updateType}`
      : `suites/${id}/update`,
    method: 'PUT',
    formData: true,
    params: formData,
  })
}

const updateDataSources = async (payload: any) => {
  const { id, formData } = payload

  return makeRequest({
    path: `suites/${id}/update/csv`,
    method: 'PUT',
    formData: true,
    params: formData,
  })
}

const deleteSuiteConfigFile = async (payload: any) => {
  const { id, updateType, suiteDataSourceID } = payload

  return makeRequest({
    path: suiteDataSourceID
      ? `suites/${id}/datasources/${suiteDataSourceID}`
      : `suites/${id}/update/${updateType}`,
    method: 'DELETE',
  })
}

const createSuiteFromDB = async (payload: IDBConfig) => {
  return makeRequest({
    path: `suites/create`,
    method: 'POST',
    params: JSON.stringify(getMappedPayload(payload)),
  })
}

const downloadData = async (id: any) => {
  const response = makeRequest({
    path: `suites/${id}/export/data`,
    type: 'pdf',
  })
  return response
}

const downloadReport = async (id: any) => {
  const response = makeRequest({
    path: `suites/${id}/export/report`,
    type: 'pdf',
  })
  return response
}

const startSynthesizeReport = async (payload: any) => {
  const { id } = payload
  return makeRequest({
    path: `suites/${id}/transform`,
    method: 'POST',
  })
}

export const getSynthesizeReport = async (payload: any) => {
  const { id } = payload
  return makeRequest({
    path: `suites/${id}/transform`,
    method: 'GET',
  })
}

const stopSynthesizeReport = async (payload: any) => {
  const { id } = payload
  return makeRequest({
    path: `suites/${id}/transform`,
    method: 'DELETE',
  })
}

function* handleUploadSuite({
  payload,
}: PayloadAction<ICreateTestingSuitePayload>): Generator<any> {
  try {
    const suite: SuiteResponse = yield call(uploadSuite, payload)

    // turn on Segment analytics
    trackEvent('Testing Suite Uploaded')
    //
    yield put(createTestingSuiteActions.didUploadSuite(suite))
    pushRoute(Routes.CreateTestingSuite(suite.id))
  } catch (error) {
    yield put(createTestingSuiteActions.didUploadSuiteFail())
    yield put(showError(error))
  }
}

function* handleGetSuite({
  payload,
}: PayloadAction<SuiteResponse>): Generator<any> {
  try {
    const suite = yield call(getSuite, payload)
    const dataSources = yield call(getSuiteDataSources, payload)

    yield put(createTestingSuiteActions.didGetSuite({ suite, dataSources }))
  } catch (error) {
    yield put(createTestingSuiteActions.didGetSuiteFail())
    yield put(showError(error))
  }
}

function* handleGetSuiteDataSources({
  payload,
}: PayloadAction<SuiteResponse>): Generator<any> {
  try {
    const dataSources = yield call(getSuiteDataSources, payload)

    yield put(createTestingSuiteActions.didGetSuiteDataSources(dataSources))
  } catch (error) {
    yield put(createTestingSuiteActions.didGetSuiteDataSourcesFail())
    yield put(showError(error))
  }
}

function* handleUpdateSuit({
  payload,
}: PayloadAction<{ id: number; formData: FormData; updateType: string }>) {
  try {
    const suite = yield call(updateSuite, payload)
    const dataSources = yield call(getSuiteDataSources, payload.id)

    yield put(createTestingSuiteActions.getReport(payload))
    yield put(createTestingSuiteActions.didUpdateSuite(suite as SuiteResponse))
    yield put(createTestingSuiteActions.didGetSuiteDataSources(dataSources))
  } catch (error) {
    yield put(createTestingSuiteActions.didUpdateSuiteFail())
    yield put(showError(error))
  }
}

function* handleUpdateSuiteDataSources({
  payload,
}: PayloadAction<{ id: number; formData: FormData }>) {
  try {
    const dataSources = yield call(updateDataSources, payload)

    yield put(createTestingSuiteActions.getReport(payload))
    yield put(createTestingSuiteActions.didGetSuiteDataSources(dataSources))
  } catch (error) {
    yield put(createTestingSuiteActions.didGetSuiteDataSourcesFail())
    yield put(showError(error))
  }
}

function* handleCreateSuiteFromDB({ payload }: PayloadAction<IDBConfig>) {
  try {
    const suite: SuiteResponse = yield call(createSuiteFromDB, payload)

    yield put(createTestingSuiteActions.didCreateFromDB(suite))
    pushRoute(Routes.CreateTestingSuite(suite.id))
  } catch (error) {
    yield put(createTestingSuiteActions.didCreateFromDBFailed())
    yield put(showError(error))
  }
}

function* handleSynthesizeReport({ payload }: any) {
  const suite: SuiteResponse = yield select(selectCurrentSuite)
  const errors = []

  if (!suite.pks_filename) {
    errors.push('Primary Keys')
  }

  if (!suite.fks_filename) {
    errors.push('Foreign keys')
  }

  if (!suite.join_statements_filename) {
    errors.push('Join Statements')
  }

  if (errors.length) {
    const errorMessage = errors.join(', ')

    yield put(
      notificationActions.showNotification({
        message: `${errorMessage} should be added`,
        severity: 'warning',
      })
    )

    return
  }
  yield put(createTestingSuiteActions.startSynthesizeSuite())
  yield call(startSynthesizeReport, payload)
  yield put(createTestingSuiteActions.fetchSynthProgress(payload))
}

function* handleGetReport({ payload }: any) {
  yield put(createTestingSuiteActions.fetchSynthProgress(payload))
}

export function* handleSynthProgress({ payload }: any) {
  try {
    while (true) {
      const data = yield call(getSynthesizeReport, payload)
      const { status } = data
      if (status === 'STATUS_FAILED') {
        yield put(createTestingSuiteActions.didGetReportFailed())
        yield put(showError(new Error('Receiving report failed')))
        return
      }
      if (status === 'STATUS_FINISHED') {
        yield put(createTestingSuiteActions.didSynthesizeSuite(data))
        return
      }
      yield put(createTestingSuiteActions.didGetReport(data))
      yield delay(5000)
    }
  } catch (error) {
    yield put(createTestingSuiteActions.didGetReportFailed())
    if (error.status !== 404) {
      yield put(showError(error))
    }
  }
}

function* handleDeleteConfigFile({ payload }: PayloadAction<any>) {
  try {
    yield call(deleteSuiteConfigFile, payload)
    yield all([
      put(createTestingSuiteActions.getReport(payload)),
      put(createTestingSuiteActions.getSuite(payload.id)),
    ])
  } catch (error) {
    yield put(showError(error))
  } finally {
    yield put(modalActions.hide())
  }
}

function* handleDownloadData({ payload }: PayloadAction<any>) {
  try {
    const result = yield call(downloadData, payload.id)
    if (!result.message) {
      yield put(
        notificationActions.showNotification({
          message: 'File downloaded successfully!',
        })
      )

      const url = window.URL.createObjectURL(new Blob([result.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = result.filename
      a.click()
      return
    }
    throw new Error("Data couldn't be downloaded")
  } catch (error) {
    yield put(showError(error))
  }
}

function* handleDownloadReport({ payload }: PayloadAction<any>) {
  try {
    const result = yield call(downloadReport, payload.id)
    if (!result.message) {
      yield put(
        notificationActions.showNotification({
          message: 'Report downloaded successfully!',
        })
      )

      const url = window.URL.createObjectURL(new Blob([result.data]))
      const a = document.createElement('a')
      a.href = url
      a.download = result.filename
      a.click()
      return
    }
    throw new Error("Report couldn't be downloaded")
  } catch (error) {
    yield put(showError(error))
  }
}

const showError = (err: Error) =>
  notificationActions.showNotification({
    message: err.message,
    severity: 'error',
  })

const getMappedPayload = (payload: IDBConfig) => {
  return omit({ ...payload, username: payload.userName }, ['userName'])
}

function* handleStopSynthesize({ payload }: PayloadAction<any>) {
  try {
    yield call(stopSynthesizeReport, payload)
  } catch (error) {
    yield put(showError(error))
  }
}

export default function* watchUploadTestingSuiteSaga() {
  yield takeLatest(
    createTestingSuiteActions.uploadSuite.type,
    handleUploadSuite
  )
  yield takeLatest(createTestingSuiteActions.getSuite.type, handleGetSuite)
  yield takeLatest(
    createTestingSuiteActions.getSuiteDataSources.type,
    handleGetSuiteDataSources
  )
  yield takeLatest(createTestingSuiteActions.updateSuite.type, handleUpdateSuit)
  yield takeLatest(
    createTestingSuiteActions.updateSuiteDataSources.type,
    handleUpdateSuiteDataSources
  )
  yield takeLatest(
    createTestingSuiteActions.createFromDB.type,
    handleCreateSuiteFromDB
  )
  yield takeLatest(
    createTestingSuiteActions.deleteSuiteConfigFile.type,
    handleDeleteConfigFile
  )
  yield takeLatest(createTestingSuiteActions.downloadData, handleDownloadData)
  yield takeLatest(
    createTestingSuiteActions.downloadReport,
    handleDownloadReport
  )

  yield takeLatest(
    createTestingSuiteActions.synthesizeSuite.type,
    handleSynthesizeReport
  )

  yield takeLatest(createTestingSuiteActions.getReport.type, handleGetReport)

  yield takeLatest(
    createTestingSuiteActions.stopSynthesizeSuite.type,
    handleStopSynthesize
  )

  yield takeLatest(
    createTestingSuiteActions.fetchSynthProgress,
    function* (action) {
      yield race([
        call(handleSynthProgress, action),
        take(createTestingSuiteActions.stopSynthesizeSuite),
      ])
    }
  )
}

import React, { FC, useState, useEffect } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  Tooltip,
  Button,
  Badge,
  Typography,
  Toolbar,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { colors } from 'legacy/shared'
import classnames from 'classnames'
// import { Autocomplete } from '@material-ui/lab'
import { UsersIcon } from './Icons/Users'
import { IPermission } from './typings'
import { ActionMenu } from './ActionMenu'
import { PERMISSIONS } from './contsants'
// import { UserPermissionCell } from './UserPermissionCell'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '17px 0px',
  },
  option: {},
  toolbarGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchInput: {
    height: '14px',
    padding: '11px',
    width: '160px',
  },
  space: {
    marginRight: '14px',
  },
  buttonFilter: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    color: '#9F9CB2',
    padding: 0,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
  },
  badgeRoot: {
    width: '32px',
    height: '32px',
  },
  badge: {
    backgroundColor: colors.darkPurple,
    color: colors.white,
    borderRadius: '2px',
    width: '14px',
    minWidth: '14px',
    fontSize: '10px',
    height: '14px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
  },
  smallItems: {
    padding: 0,
  },
}))

interface IEnhancedTableToolbarProps {
  data: IPermission[]
  sort: any
  search: string
  handleSearch: any
  handleFilter: any
}

export const EnhancedTableToolbar: FC<IEnhancedTableToolbarProps> = ({
  data = [],
  sort,
  search,
  handleSearch,
  handleFilter,
}) => {
  const classes = useToolbarStyles()

  const [activeFilters, setActiveFilters] = useState([])

  const handleActiveFilter = (field: string) => (_: any) => {
    setActiveFilters((filters) => {
      if (filters.includes(field)) {
        return filters.filter((elem) => elem !== field)
      }
      return [...filters, field]
    })
  }

  useEffect(() => {
    handleFilter('permission', activeFilters)
  }, [activeFilters])

  const filterItemsActions = PERMISSIONS.map(({ label }) => ({
    label,
    value: activeFilters.includes(label),
    action: handleActiveFilter(label),
    hasCheckbox: true,
  }))

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarGroup}>
        <Badge
          classes={{ badge: classes.badge, root: classes.badgeRoot }}
          color="primary"
          className={classes.space}
          badgeContent={data.length}
        >
          <UsersIcon />
        </Badge>

        <Typography
          className={classes.title}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Collaborators
        </Typography>
      </div>
      <div className={classes.toolbarGroup}>
        <ActionMenu
          actions={filterItemsActions}
          MenuItemProps={{ classes: { root: classes.smallItems } }}
        >
          <Tooltip title="Filter list">
            <Button
              onClick={sort}
              variant="outlined"
              className={classnames(classes.buttonFilter, classes.space)}
            >
              <FilterListIcon />
            </Button>
          </Tooltip>
        </ActionMenu>
        <TextField
          value={search}
          onChange={handleSearch}
          inputProps={{ className: classes.searchInput }}
          variant="outlined"
          placeholder="Search..."
        />
        {/* <Menu open={Boolean(search.length)}>
          {data.map(({ id, status, user_email: userMail }: any) => (
            <MenuItem>
              <UserPermissionCell id={id} status={status} userMail={userMail} />
            </MenuItem>
          ))}
        </Menu> */}
        {/* <Autocomplete
          id="select-permission-object"
          options={data}
          classes={{
            option: classes.option,
          }}
          getOptionLabel={({ user_email: userEmail }: any) => userEmail}
          renderOption={({ id, status, user_email: userMail }: any) => (
            <UserPermissionCell id={id} status={status} userMail={userMail} />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ className: classes.searchInput }}
              variant="outlined"
              placeholder="Search..."
            />
          )}
        /> */}
      </div>
    </Toolbar>
  )
}

import React from 'react'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

import ScatterPlot from './ScatterPlot'
import Axes from './Axes'
import { formatNumber } from '../../services/helper'
import { colors, correlation_colors } from '../Colors'
import { Typography } from '../../shared'

import DigitalBox from './DigitalBox'
import { LabelComponent } from '../Projects/report/Report'

import './Correlation.scss'

const ellipsesStyle = {
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  overflowWrap: 'break-word',
}
const TableTextComponent = (props) => {
  const { label, style } = props
  return (
    <LabelComponent
      {...props}
      label={label}
      size="body"
      style={{
        ...ellipsesStyle,
        ...style,
        padding: '.5rem',
      }}
    />
  )
}

export default class Correlation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
    }

    this.refTD = React.createRef()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentDidMount() {
    if (this.refTD.current !== null) {
      this.updateWidth()
    }
    window.addEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate() {
    if (this.refTD.current !== null) {
      this.updateWidth()
    }
  }

  updateDimensions = () => {
    if (this.refTD.current !== null) {
      const byX =
        this.state.width !== this.refTD.current.getBoundingClientRect().width
      if (byX) {
        this.updateWidth()
      }
    }
  }

  updateWidth = () => {
    this.setState({
      width: this.refTD.current.getBoundingClientRect().width,
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.width !== this.state.width) return true
    if (
      JSON.stringify(nextProps.data_item.results) !==
      JSON.stringify(this.props.blocks_data.results)
    )
      return true
    if (
      this.props.from &&
      this.props.from === 'monitor' &&
      JSON.stringify(nextProps.data_item) !==
        JSON.stringify(this.props.blocks_data)
    )
      return true
    return false
  }

  render() {
    const { data_item } = this.props
    const { width } = this.state

    // current table data
    const cur_tdata = []
    const valid_settings = []
    Object.entries(data_item.settings).length &&
      data_item.settings.columns.forEach((d) => {
        Object.keys(data_item.results.correlation_similarity).forEach((f) => {
          if (f === d) {
            valid_settings.push(d)
          }
        })
      })

    // BUILD TABLE DATA
    Object.entries(data_item.settings).length &&
      valid_settings.forEach((row_name, i) => {
        const obj = {
          first: row_name,
        }

        valid_settings.forEach((column_name, ii) => {
          switch (true) {
            case ii < i:
              obj[column_name] = {
                original_sample: data_item.results.original_sample
                  ? {
                      x: data_item.results.original_sample[column_name],
                      y: data_item.results.original_sample[row_name],
                      x_label: column_name,
                      y_label: row_name,
                      color: colors[0],
                    }
                  : null,
                synthetic_sample: {
                  x: data_item.results.synthetic_sample[column_name],
                  y: data_item.results.synthetic_sample[row_name],
                  x_label: column_name,
                  y_label: row_name,
                  color: colors[1],
                },
              }
              break
            case ii === i:
              obj[column_name] =
                +data_item.results.correlation_similarity[column_name][
                  column_name
                ]
              break
            case ii > i:
              obj[column_name] =
                data_item.results.correlation_similarity[row_name][
                  column_name
                ] !== null
                  ? +data_item.results.correlation_similarity[row_name][
                      column_name
                    ]
                  : 0
              break
            default:
              break
          }
        })

        cur_tdata.push(obj)
      })

    // if exist charts then add extra row
    if (cur_tdata.length && cur_tdata.length !== 1) {
      const extra_row = { ...cur_tdata[cur_tdata.length - 1] }
      extra_row.first = ''
      cur_tdata.push(extra_row)
    }

    const columns =
      Object.entries(data_item.settings).length !== 0
        ? ['first', ...valid_settings].map((d, i) => {
            return {
              Header: (row) => {
                const ref_opt = {}
                if (i === 1) {
                  ref_opt.ref = this.refTD
                }
                return i !== 0 ? (
                  <div
                    {...ref_opt}
                    className="cor_hdr_style"
                    style={ellipsesStyle}
                  >
                    <Typography size="body">{row.column.id}</Typography>
                  </div>
                ) : (
                  ''
                )
              },
              accessor: d,
              style: { padding: 0 },
              className: 'td-style',
              Cell: (row) => {
                if (i !== 0) {
                  // Extra row, x axis
                  if (
                    row.index === cur_tdata.length - 1 &&
                    cur_tdata.length !== 1
                  ) {
                    const { original_sample, synthetic_sample } =
                      row.original[d]
                    return typeof row.value === 'object' &&
                      (original_sample === null ||
                        (original_sample.x !== null &&
                          original_sample.y !== null &&
                          original_sample.x.length &&
                          original_sample.y.length)) &&
                      synthetic_sample.x !== null &&
                      synthetic_sample.y !== null &&
                      synthetic_sample.x.length &&
                      synthetic_sample.y.length ? (
                      <Axes
                        data_item={row.original[row.column.id]}
                        show_axis={{
                          x: true,
                          y: false,
                          width,
                          height: 15,
                        }}
                        width={width}
                      />
                    ) : null
                    // } else if (+row.original[d] === 1 || row.column.id === row.original.first) {
                  }
                  if (row.column.id === row.original.first) {
                    return (
                      <div className="diagonal-line">
                        <svg
                          width={width}
                          height={120}
                          style={{ height: '120px' }}
                        >
                          <g>
                            <line
                              x1="0"
                              y1="0"
                              x2={width}
                              y2="119"
                              stroke="#e0e4ed"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      </div>
                    )
                  }
                  if (typeof row.original[d] === 'object') {
                    const { original_sample, synthetic_sample } =
                      row.original[d]
                    return (
                      <div style={{ width: '100%', height: '100%' }}>
                        {width !== 0 &&
                        (original_sample === null ||
                          (original_sample.x !== null &&
                            original_sample.y !== null &&
                            original_sample.x.length &&
                            original_sample.y.length)) &&
                        synthetic_sample.x !== null &&
                        synthetic_sample.y !== null &&
                        synthetic_sample.x.length &&
                        synthetic_sample.y.length ? (
                          <ScatterPlot
                            data_item={row.original[d]}
                            width={width}
                          />
                        ) : null}
                      </div>
                    )
                  }
                  return (
                    <DigitalBox
                      data={formatNumber(row.original[d], '0.[00]') * 100}
                      color={correlation_colors(row.original[d])}
                    />
                  )
                }
                const d_to_data_item = valid_settings[0]
                const { original_sample, synthetic_sample } =
                  row.original[d_to_data_item]
                // ref is needed to track screen changes. track by second column first row   (first square with \ )
                return (
                  <div className="cor-first-column">
                    <TableTextComponent
                      label={row.original[d]}
                      style={{
                        paddingRight: row.index === 0 ? '45px' : '',
                      }}
                    />

                    {/* Y axis */}
                    {row.index !== cur_tdata.length - 1 &&
                    row.index !== 0 &&
                    cur_tdata.length !== 1 &&
                    (original_sample === null ||
                      (original_sample.x !== null &&
                        original_sample.y !== null &&
                        original_sample.x.length &&
                        original_sample.y.length)) &&
                    synthetic_sample.x !== null &&
                    synthetic_sample.y !== null &&
                    synthetic_sample.x.length &&
                    synthetic_sample.y.length ? (
                      <Axes
                        data_item={row.original[d_to_data_item]}
                        show_axis={{
                          x: false,
                          y: true,
                          width: 40,
                          height: 120,
                        }}
                        width={width}
                      />
                    ) : null}
                  </div>
                )
              },
              minWidth: i === 0 && cur_tdata.length !== 1 ? 200 : 160,
            }
          })
        : []

    return (
      <div className="row m-0 cor-table">
        <div className="col col-12 cor-table-container">
          {data_item.results ? (
            <ReactTable
              data={cur_tdata}
              columns={columns}
              showPagination={false}
              resizable={false}
              sortable={false}
              pageSize={
                Object.entries(data_item.settings).length &&
                valid_settings.length &&
                valid_settings.length === 1
                  ? valid_settings.length
                  : Object.entries(data_item.settings).length &&
                    valid_settings.length
                  ? valid_settings.length + 1
                  : 3
              }
            />
          ) : null}
        </div>
      </div>
    )
  }
}

import React, { FC } from 'react'
import { Paper as MUIPaper, PaperProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

interface MUIPaperProps extends PaperProps {
  /**
   * The variant to use.
   */
  variant?: 'elevation' | 'outlined'
  /**
   * If true, rounded corners are disabled.
   */
  square?: boolean
  /**
   * Shadow depth, corresponds to dp in the spec. It accepts values between 0 and 24 inclusive.
   */
  elevation?: number
}

const usePaperStyles = makeStyles({
  root: {
    padding: '1.5rem',
  },
})

export const Paper: FC<MUIPaperProps> = ({
  children,
  className = {},
  variant = 'elevation',
  square = false,
  elevation = 2,
}) => {
  const classes = usePaperStyles()
  const paperClassName = classnames(classes.root, className)
  return (
    <MUIPaper
      variant={variant}
      square={square}
      elevation={elevation}
      className={paperClassName}
    >
      {children}
    </MUIPaper>
  )
}

import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { fonts, colors } from 'legacy/shared'

interface IDataViewGroupCounterProps {
  value: number
  color: string
  label: string
  formatValue?: (value: any) => string | number
}

export const DataViewGroupCounter: FC<IDataViewGroupCounterProps> = ({
  label,
  value,
  color,
  formatValue,
}) => {
  const styles = useStyles()
  return (
    <div className={styles.counter}>
      <div className={styles.label}>{label}</div>
      <div className={styles.box}>
        <div className={styles.value} style={{ backgroundColor: color }}>
          {formatValue ? formatValue(value) : value}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  counter: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: fonts.primary,
    fontSize: '14px',
    lineHeight: '20px',
    marginRight: '24px',
  },
  label: {
    marginRight: '10px',
  },
  box: {
    width: '25px',
    height: '25px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    background: colors.counterBackground,
    border: `1px solid ${colors.counterBorder}`,
    borderRadius: '4px',
  },
  value: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

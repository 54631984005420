const replaceIfNull = (field: any) => {
  return field !== null ? field : ''
}
export const getInitialValues = (User: any) => {
  return {
    email: User.email,
    fullname: `${replaceIfNull(User.first_name)} ${replaceIfNull(
      User.last_name
    )}`,
    phoneNumber: replaceIfNull(User.phone_number),
    title: replaceIfNull(User.job_title),
    company: replaceIfNull(User.company),
    showProductTour: User.show_onboarding,
  }
}

export const ModalTypes = {
  ShareProject: 'ShareProject',
  ShareSandbox: 'ShareSandbox',
  Confirmation: 'Confirmation',
  ProjectEditColumnName: 'ProjectEditColumnName',
  ChangeProjectName: 'ChangeProjectName',
  ProjectAddGroup: 'ProjectAddGroup',
  ProjectEdit: 'ProjectEdit',
  DataSourceEdit: 'DataSourceEdit',
  DataSourceCreate: 'DataSourceCreate',
}

export const ModalOptions = {
  [ModalTypes.ShareProject]: {
    headerText: 'Share data product',
  },
  [ModalTypes.ShareSandbox]: {
    headerText: 'Share credentials via email',
  },
  [ModalTypes.ProjectEditColumnName]: {
    headerText: 'Edit column name',
  },
  [ModalTypes.ProjectEdit]: {
    headerText: 'Edit project description',
  },
  [ModalTypes.DataSourceEdit]: {
    headerText: 'Edit datasource',
  },
  [ModalTypes.DataSourceCreate]: {
    headerText: 'Create datasource',
  },
}

import * as React from 'react'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { customTheme } from '../theme'

export const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

import { takeLatest, put, call, select } from 'redux-saga/effects'
import { notificationActions } from 'services/notification/reducer'

import { groupsService } from 'legacy/utils/services'
import { projectPermissionsActions as actions } from '../reducers/projectPermissionsReducer'
import * as api from '../../../utils/services/permissions'

import { updateTab } from '../actions'
import { getProjectPermissionsForUser } from '../projectSelectors'
import { modalActions } from '../../../../services/modal/reducer'

export function* handleGetProjectPermission({ payload }: any): Generator<any> {
  try {
    const permissionData: any = yield call(api.getProjectUsersWithPermissions, {
      datasetId: payload.projectId,
    })
    yield put(actions.didGetProjectPermissions(permissionData))
    const canEdit = yield select(getProjectPermissionsForUser)
    if (canEdit) {
      return yield put(updateTab('original'))
    }

    //
    const synthesizeData: any = yield call(groupsService.fetchSynthesizeData, {
      datasetId: payload.projectId,
    })

    if (synthesizeData.length) {
      return yield put(updateTab('sds'))
    }

    yield put(updateTab('error'))
    throw Error('Data is not synthesized yet')
    //
  } catch (err) {
    yield put(
      notificationActions.showNotification({
        message: err.message || 'Failed receive permissions',
        severity: 'error',
      })
    )
    yield put(actions.didGetProjectPermissionsFail(err))
  }
}

export function* handleRequestProjectPermission({
  payload,
}: any): Generator<any> {
  try {
    const data = yield call(api.createProjectPermissionsRequest, {
      datasetId: payload.datasetId,
      request: { permission: payload.permissionId },
    })
    yield put(actions.didRequestProjectPermissions(data))
    yield put(
      notificationActions.showNotification({
        message: 'Owner request was successfully sent.',
        severity: 'success',
      })
    )
    yield put(modalActions.hide())
  } catch (err) {
    yield put(modalActions.hide())
    yield put(
      notificationActions.showNotification({
        message: 'Owner request wasn`t sent.',
        severity: 'error',
      })
    )
    yield put(actions.didGetProjectPermissionsFail(err))
  }
}

export default function* watchProjectPermissionsSaga() {
  yield takeLatest(
    actions.getProjectPermissions.type,
    handleGetProjectPermission
  )
  yield takeLatest(
    actions.requestProjectPermissions.type,
    handleRequestProjectPermission
  )
}

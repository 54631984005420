import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button as MUIButton } from '@material-ui/core'
import { colors, Spinner } from 'legacy/shared'

import { safeTestId } from 'utils/safeTestId'

const useStyles = makeStyles({
  label: {
    position: 'relative',
  },
})

const getButtonComponent = () => {
  return MUIButton
}

const getSpinnerColor = (color, variant) => {
  if (variant === 'contained') {
    return colors.white
  }

  switch (color) {
    case 'default':
      return colors.buttonDefault

    case 'primary':
      return colors.buttonPrimary

    case 'secondary':
      return colors.red

    case 'tertiary':
      return colors.buttonGray

    case 'transparent':
      return null

    default:
      return colors.buttonDefault
  }
}

const Component = ({
  children,
  variant = 'contained',
  color = 'default',
  disabled = false,
  loading = false,
  onClick,
  type = 'button',
  className,
  size = 'medium',
  ...rest
}) => {
  const ButtonComponent = getButtonComponent()
  const classes = useStyles()

  return (
    <ButtonComponent
      size={size}
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      classes={{ root: className, label: classes.label }}
      {...rest}
    >
      {children}
      {loading ? (
        <Spinner
          data-testid={safeTestId(rest['data-testid'], 'spinner')}
          color={getSpinnerColor(color, variant)}
          style={{
            position: 'absolute',
            margin: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        />
      ) : null}
    </ButtonComponent>
  )
}

export const Button = (props) => <Component {...props} />

Button.displayName = 'Button'

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { NotificationContainer } from 'layouts/Notification'
import Main from 'layouts/Main'

import { Sidebar } from '../SideBar/SidebarCloud'
import { getSideBarTabs } from '../SideBar/sideBarConfig'
import { Routes } from '../../utils'
import DatasetsPreloadPage from '../Datasets/DatasetsPreloadPage'
import SandboxesFreeTier from '../Sandboxes/SandboxesFreeTier'
import SandboxBuildView from '../Sandboxes/build/SandboxBuildView'
import ModalContainer from '../../../components/Modals/ModalContainer'
import ProjectView from '../Projects/ProjectView'
import Datasources from '../Datasources/Datasources'
import DatasourcesFreeTier from '../Datasources/DatasourcesFreeTier'
import Source from '../Datasources/Source'
import { TierRoute } from './RoutingPolicy'
import ProductTourInit from '../ProductTour/ProductTourInit'

const App = () => {
  return (
    <>
      <div style={{ height: '100%', display: 'flex' }}>
        <Sidebar tabs={getSideBarTabs('CLOUD')} />
        <Main>
          <Switch>
            <Route path="/projects" component={DatasetsPreloadPage} exact />
            <Route path="/projects/:id/synthesis" component={ProjectView} />
            <TierRoute
              path="/datasources/:id"
              component={Source}
              componentFreeTier={DatasourcesFreeTier}
            />
            <TierRoute
              path="/datasources"
              component={Datasources}
              componentFreeTier={DatasourcesFreeTier}
            />
            <TierRoute
              path={Routes.CleanRoomBuild()}
              component={SandboxBuildView}
              componentFreeTier={SandboxesFreeTier}
            />
          </Switch>
        </Main>
      </div>
      <div style={{ position: 'absolute', bottom: 20, right: 120 }}>
        <ProductTourInit />
      </div>
      <ModalContainer />
      <NotificationContainer />
    </>
  )
}

export default App

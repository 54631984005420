import { combineReducers } from 'redux'
import undoable from 'redux-undo'

import dataSources from './datasources/reducer'
import newProject from './project/reducer'

export default combineReducers({
  dataSources: undoable(dataSources),
  newProject,
})

import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { constraints } from 'utils/constraints'
import { createValidatorWithSchema } from 'utils/validate'

import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import { Form, TextField, spacing } from 'legacy/shared'

import { dataSourcesActions } from 'services/datasources/datasources/reducer'

const defaultValues = {
  name: '',
  type: '',
  host: '',
  port: '',
  username: '',
  password: '',
  database: '',
}

const selectData = [
  { label: 'MYSQL', value: 'MYSQL' },
  { label: 'POSTGRESQL', value: 'POSTGRESQL' },
  { label: 'ORACLE', value: 'ORACLE' },
  { label: 'MSSQL', value: 'MSSQL' },
  { label: 'MONGODB', value: 'MONGODB' },
  { label: 'SYSTEM', value: 'SYSTEM' },
]

const DataSourceValidationSchema = {
  name: constraints.requiredString,
  host: constraints.requiredString,
  port: constraints.requiredString,
  username: constraints.requiredString,
  password: constraints.requiredString,
}

const fieldMargin = {
  marginBottom: spacing.gutterLarge,
}

export const DataSourceForm: React.FC<any> = ({
  initialValues = defaultValues,
  initCheckStatus,
  checkDataSource,
  ButtonComponent,
}) => {
  useEffect(() => {
    return () => initCheckStatus()
  }, [initCheckStatus])

  return (
    <Form
      onSubmit={checkDataSource}
      initialValues={initialValues}
      validate={createValidatorWithSchema(DataSourceValidationSchema)}
    >
      {({ values, errors, handleSubmit, handleChange, setFieldValue }: any) => {
        const handleInputChange = (event: any) => {
          initCheckStatus()
          handleChange(event)
        }
        const handleTypeChange = (value: any) => {
          initCheckStatus()
          setFieldValue('type', value)
        }
        return (
          <>
            <TextField
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <div style={fieldMargin}>
              <FormControl variant="outlined">
                <InputLabel id="datasource-type-label">Type</InputLabel>
                <Select
                  labelId="datasource-type-label"
                  name="type"
                  label="Type"
                  value={values.type}
                  onChange={(event: any) => {
                    handleTypeChange(event.target.value)
                  }}
                >
                  {selectData.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <TextField
              name="host"
              label="Host"
              value={values.host}
              onChange={handleInputChange}
              error={Boolean(errors.host)}
              helperText={errors.host && errors.host[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              name="port"
              label="Port"
              value={values.port}
              onChange={handleInputChange}
              error={Boolean(errors.port)}
              helperText={errors.port && errors.port[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              name="username"
              label="Username"
              value={values.username}
              onChange={handleInputChange}
              error={Boolean(errors.username)}
              helperText={errors.username && errors.username[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              name="password"
              label="Password"
              value={values.password}
              onChange={handleInputChange}
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password[0]}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <TextField
              name="database"
              label="Database"
              value={values.database}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              style={fieldMargin}
            />
            <ButtonComponent values={values} handleSubmit={handleSubmit} />
          </>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = {
  checkDataSource: dataSourcesActions.checkDataSource,
  initCheckStatus: dataSourcesActions.initCheckStatus,
}

export default connect(null, mapDispatchToProps)(DataSourceForm)

import User from '../../Account/User'

export const validateRowNumber = (
  rowNumber: number | string
): {
  invalid: boolean
  message: string
} => {
  const maxNumberCE = 20000

  if (Number(rowNumber) < 0) {
    return { invalid: true, message: 'Negative value' }
  }

  if (User.isCETier()) {
    if (Number(rowNumber) > maxNumberCE) {
      return {
        invalid: true,
        message: `CommunityEdition limit for this dataset is ${maxNumberCE}`,
      }
    }
  }

  return { invalid: false, message: '' }
}

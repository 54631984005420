export const ProjectModalActions = {
  SELECT_COLUMN: 'projectModal/SELECT_COLUMN',
  SUBMIT_ACTION: 'projectModal/SUBMIT_ACTION',
  DID_SUBMIT_ACTION_FAIL: 'projectModal/DID_SUBMIT_ACTION_FAIL',
}

// Select column name edit
export const selectColumn = (data) => ({
  type: ProjectModalActions.SELECT_COLUMN,
  data,
})

export const submitAction = () => ({
  type: ProjectModalActions.SUBMIT_ACTION,
})

export const didSubmitActionFail = (message) => ({
  type: ProjectModalActions.DID_SUBMIT_ACTION_FAIL,
  message,
})

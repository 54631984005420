import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Box from '@material-ui/core/Box'
import {
  changeBlockCheckbox,
  changeBlockDropdown,
  updateBlockSettings,
} from './actions'
import { CheckBox, SelectSimple, Typography } from '../../../shared'

import '../../Report/ReportSelects.scss'

const useStyles = makeStyles(() => ({
  labelStyles: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  labelStylesLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  checkboxStyles: {
    padding: '5px',
  },
  formGroup: {
    overflowY: 'auto',
    maxHeight: '10.6rem',
    marginTop: '.3rem',
  },
  upperText: {
    textTransform: 'uppercase',
  },
  cat: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '.6rem',
  },
}))

const Selects = ({
  dataset_id,
  disabled,
  blocks_data,
  block_id,
  index_block,
  updateBlockSettings,
}) => {
  const classes = useStyles()

  const handlerChangeCheck = (
    label,
    idx_blocks_data,
    idx_cat,
    idx_subcat,
    idx_values
  ) => {
    // this.props.changeBlockCheckbox({
    //   idx_blocks: idx_blocks_data,
    //   idx_cat: idx_cat,
    //   idx_subcat: idx_subcat,
    //   idx_values: idx_values
    // });
    updateAnalyzeSettings(label, idx_blocks_data)
  }

  const handleDropdown = ({
    type,
    name_set,
    label,
    id_drop,
    idx_blocks_data,
    idx_cat,
  }) => {
    const newSettings = JSON.parse(
      JSON.stringify(blocks_data[idx_blocks_data].settings)
    )

    // if label in dropdown match with label in explanatory_variables
    if (
      newSettings &&
      newSettings.explanatory_variables.some((d) => d === label)
    ) {
      newSettings.explanatory_variables.splice(
        newSettings.explanatory_variables.findIndex((d) => d === label),
        1
      )
    }

    // change model if categorical -> continuous or continuous -> categorical
    if (name_set === 'response_variable') {
      // current active dropdown value response_variable
      const current_column =
        blocks_data[idx_blocks_data].options.categorical_columns.findIndex(
          (d) => d === newSettings.response_variable
        ) !== -1
          ? { 0: 'categorical_columns', 1: 'continuous_models' }
          : { 0: 'continuous_columns', 1: 'categorical_models' }

      newSettings.model =
        blocks_data[idx_blocks_data].options[current_column[0]].findIndex(
          (d) => d === label
        ) === -1
          ? blocks_data[idx_blocks_data].options[current_column[1]][0]
          : newSettings.model
    }

    newSettings[name_set] = label

    makeUpdateSettingsRequest(newSettings)
  }

  const handleChangeCheckboxes = ({ name, label, newSettings }) => {
    // if label contained in current settings do splice label from settings  ELSE add label to settings
    if (newSettings && newSettings[name]?.some((d) => d === label)) {
      newSettings[name].splice(
        newSettings[name].findIndex((d) => d === label),
        1
      )
    } else {
      newSettings[name].push(label)
    }
  }

  const updateAnalyzeSettings = (label, idx_blocks_data, type, name_set) => {
    const newSettings = JSON.parse(
      JSON.stringify(blocks_data[idx_blocks_data].settings)
    )
    if (blocks_data[idx_blocks_data].name === 'correlation') {
      // if settings === {}
      if (Object.entries(newSettings).length) {
        handleChangeCheckboxes({ name: 'columns', label, newSettings })
      } else {
        // add new array columns to settings
        newSettings.columns = new Array(label)
      }

      makeUpdateSettingsRequest(newSettings)
    } else if (blocks_data[idx_blocks_data].name === 'modelling') {
      handleChangeCheckboxes({
        name: 'explanatory_variables',
        label,
        newSettings,
      })
      makeUpdateSettingsRequest(newSettings)
    }
  }

  const makeUpdateSettingsRequest = (obj_to_settings) => {
    updateBlockSettings({
      id: dataset_id,
      item_id: block_id,
      settings: obj_to_settings,
    })
  }

  return blocks_data !== null && blocks_data.length ? (
    <Box color="black">
      <Box mt={0.25} mb={0.25}>
        <Typography size="bodyBold">
          {blocks_data[index_block].name === 'correlation'
            ? 'Variable Selections'
            : 'Modelling Options'}
        </Typography>
      </Box>
      {blocks_data[index_block].cats.map((d, idx_cat) => (
        <Box key={d.title + idx_cat} className={classes.cat}>
          <Typography size="captionBold" className={classes.upperText}>
            {d.title}
          </Typography>
          {d.type === 'input' ? (
            d.values.map((m, idx_subcat) => (
              <Box key={`rs_scat_${idx_subcat}`} mt={m.title !== null ? 1 : 0}>
                {m.title !== null ? (
                  <Typography size="smallBold" className={classes.upperText}>
                    {m.title}
                  </Typography>
                ) : null}
                <FormGroup row className={classes.formGroup}>
                  {m.values.map((dd, idx_values) => (
                    <CheckBox
                      key={dd.label + idx_values}
                      disabled={dd.disabled || disabled}
                      label={dd.label}
                      checked={dd.check}
                      labelClassName={classes.labelStyles}
                      className={classes.checkboxStyles}
                      labelClassNameLabel={classes.labelStylesLabel}
                      onChange={() =>
                        handlerChangeCheck(
                          dd.label,
                          index_block,
                          idx_cat,
                          idx_subcat,
                          idx_values
                        )
                      }
                    />
                  ))}
                </FormGroup>
              </Box>
            ))
          ) : d.type === 'dropdown' ? (
            <SelectSimple
              label=""
              style={{ marginTop: '8px' }}
              disabled={disabled}
              value={
                d.values.filter((d) => d.active).length
                  ? d.values.filter((d) => d.active)[0].label
                  : ''
              }
              values={d.values}
              handleChange={(label) =>
                handleDropdown({
                  label,
                  name_set: d.name,
                  idx_blocks_data: index_block,
                })
              }
            />
          ) : null}
        </Box>
      ))}
    </Box>
  ) : null
}

const mapDispatchToProps = {
  changeBlockCheckbox,
  changeBlockDropdown,
  updateBlockSettings,
}

export default connect(null, mapDispatchToProps)(Selects)

import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'UIkit/Button/Button'
import { pushRoute, Routes } from '../../../utils'
import { unLockNextStep } from './actions'
import { getPrevStep } from './sandboxBuildSelectors'

const useStyles = makeStyles({
  navButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backButton: {
    marginRight: '36px',
  },
})

export const NavButtons = (props) => {
  const {
    isNextDisable = true,
    isBackHidden = false,
    buttonNames = { next: 'Next step', back: 'Back' },
    confirmAction = null,
    backAction = null,
    loading = false,
    unLockNextStep,
    prevStep,
  } = props
  const classes = useStyles()

  const onBack = () => {
    pushRoute(Routes.CleanRoomBuildStep(prevStep))
  }

  const onNext = () => {
    unLockNextStep()
  }

  return (
    <div className={classes.navButtons}>
      {!isBackHidden ? (
        <Button
          variant="outlined"
          onClick={backAction || onBack}
          className={classes.backButton}
          disabled={loading}
        >
          {buttonNames.back}
        </Button>
      ) : null}
      <Button
        disabled={isNextDisable}
        onClick={confirmAction || onNext}
        loading={loading}
      >
        {buttonNames.next}
      </Button>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({ prevStep: getPrevStep })

const mapDispatchToProps = {
  unLockNextStep,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavButtons)

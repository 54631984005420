import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import classnames from 'classnames'

import HeaderComponent from 'layouts/Header'
import { Typography, colors } from 'legacy/shared'

import { CETierActions } from 'legacy/components/FreeTier/reducer'
import { getIsLoading } from 'legacy/components/FreeTier/selectors'
import datasetUpgradeImage from 'legacy/images/icons_svg/datasources_free_tier.svg'
import { Button } from 'UIkit/Button/Button'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      [theme.breakpoints.down(1115)]: {
        justifyContent: 'center',
      },
    },
    image: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      height: '27rem',
      width: '27rem',
    },
    paper: {
      maxWidth: '21rem',
      margin: theme.spacing(0, 7),
      marginTop: theme.spacing(1),
    },
    rightMarginTop: {
      marginTop: theme.spacing(2),
    },
    rightButtonsMarginTop: {
      marginTop: theme.spacing(4),
    },
    checkContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    button: {
      width: '140px',
    },
    or: {
      margin: theme.spacing(0, 1),
    },
    learnmore: {
      width: '100%',
      marginBottom: '20px',
    },
    lineHeight: {
      lineHeight: '1.3rem',
    },
  }
})

const data = {
  url: datasetUpgradeImage,
  headerTitle: '',
  title: 'Upgrade your account to access all of your enterprise data',
  subtitles: [
    {
      title:
        'Synthesized has started the data collaboration revolution, unleashing the value of data trapped in any enterprise database, data warehouse or data lake.',
    },
    {
      title:
        'The Synthesized data platform streamlines the process of data extraction and data provisioning with out-of-the-box connectivity to virtually any data source on any cloud, private or public, on the planet.',
    },
    {
      title:
        'The platform supercharges any relational datasource and enables collaboration within the business by',
    },
  ],
  checks: [
    {
      title:
        'Consolidating data and test projects on one platform synchronised with an arbitrary number of datasources',
    },
    {
      title: 'Streamlining collaboration internally with ML-powered access',
    },
  ],
}

export const FreeTierPage = ({ sendContactUs, isLoading }: any) => {
  const classes = useStyles()

  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <HeaderComponent title="Contact Us" />
      <div className={classes.root}>
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${data.url})` }}
        />
        <div>
          <div className={classes.paper}>
            <Typography size="title" color="freeTierPage">
              {data.title}
            </Typography>
            {data.subtitles.map((d) => (
              <Typography
                key={d.title}
                color="freeTierPage"
                className={classnames(
                  classes.rightMarginTop,
                  classes.lineHeight
                )}
              >
                {d.title}
              </Typography>
            ))}
            {data.checks.map((d) => (
              <div
                key={d.title}
                className={classnames(
                  classes.checkContainer,
                  classes.rightMarginTop
                )}
              >
                <CheckCircleOutlineOutlinedIcon
                  style={{
                    marginRight: '.5rem',
                    fontSize: '2rem',
                    fill: colors.freeTierPage,
                  }}
                />
                <Typography color="freeTierPage" className={classes.lineHeight}>
                  {d.title}
                </Typography>
              </div>
            ))}
            <div
              className={classnames(
                classes.buttons,
                classes.rightButtonsMarginTop
              )}
            >
              <Button
                className={classes.button}
                onClick={() => sendContactUs()}
                loading={isLoading}
              >
                Contact Us
              </Button>
            </div>
            <Box
              className={classnames(
                classes.learnmore,
                classes.rightButtonsMarginTop
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  isLoading: getIsLoading,
})

const mapDispatchToProps = { sendContactUs: CETierActions.sendContactUs }

export default connect(mapStateToProps, mapDispatchToProps)(FreeTierPage)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UploadDatasetPayload } from './types'

const prefix = 'uploadDataset'

interface IUploadDatasetState {
  isFileUploading: boolean
  isDrawerOpened: boolean
  file: null | File
}

const initialState: IUploadDatasetState = {
  isFileUploading: false,
  isDrawerOpened: false,
  file: null,
}

const uploadDatasetReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.isDrawerOpened = true
    },
    closeDrawer: (state) => {
      state.isDrawerOpened = false
    },
    uploadDataset: (
      state,
      { payload }: PayloadAction<UploadDatasetPayload>
    ) => {
      state.isFileUploading = true
      state.file = { ...state.file, name: payload.filename }
    },
    didUploadDataset: (state, { payload }) => {
      state.isFileUploading = false
      state.isDrawerOpened = false
    },
    didUploadDatasetFail: (state) => {
      state.isFileUploading = false
    },
    reset: (state) => {
      state.isFileUploading = false
      state.file = null
    },
  },
})

export const uploadDatasetActions = {
  ...uploadDatasetReducer.actions,
}

export default uploadDatasetReducer.reducer

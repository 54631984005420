import React from 'react'
import { Popover, Whisper } from 'rsuite'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'legacy/shared'

export const DescriptionCell: React.FunctionComponent<{
  value: any
}> = React.memo(
  ({ value }) => {
    const classes = useStyles()
    const speaker = (
      <Popover style={{ marginTop: '11px', maxWidth: '40rem' }}>
        <div className={classes.popoverBody}>{value}</div>
      </Popover>
    )
    return (
      <Whisper placement="bottom" speaker={speaker} delay={150}>
        <div className={classes.descriptionColumn}>{value}</div>
      </Whisper>
    )
  },
  (prev, next) => prev.value === next.value
)

const useStyles = makeStyles({
  descriptionColumn: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.lightGrey,
  },
  popoverBody: {
    textTransform: 'none',
    color: 'black',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
})

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { Spinner } from 'legacy/shared'

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: '40px',
  },
})

export const SpinnerSection = ({ className = '' }) => {
  const classes = useStyles()
  return (
    <div className={classnames(classes.root, className)}>
      <Spinner />
    </div>
  )
}

import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Popper as MUIPopper } from '@material-ui/core'
import { Paper } from '../../../../UIkit/Paper'
import { colors } from '../../theme'

const useStyles = makeStyles(() => ({
  root: {
    width: 500,
  },
  paper: {
    border: `1px solid ${colors.disabledGrey}`,
  },
  arrow: {
    position: 'absolute',
    fontSize: '7px',
    width: 0,
    height: 0,
    left: '8px',
    borderLeft: `1em solid transparent`,
    borderRight: `1em solid transparent`,
    borderBottom: `1em solid ${colors.disabledGrey}`,
    marginTop: '-0.9em',

    '&:before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 1em 1em 1em',
      borderColor: `white transparent transparent transparent`,
    },
  },
}))

export const Popper = ({ open, anchorEl, children, classNames = {} }) => {
  const classes = useStyles()
  const arrowRef = useRef(null)

  return (
    <div className={classes.root}>
      <MUIPopper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ left: '-10px' }}
        transition
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef.current,
          },
        }}
      >
        {/* {({ TransitionProps }) => (
            <Fade { ...TransitionProps } timeout={ 150 }> */}
        {/* <span className={classes.arrow} ref={arrowRef.current}></span>  */}
        <Paper className={classNames.paper || ''}>{children}</Paper>
        {/* </Fade>
          )} */}
      </MUIPopper>
    </div>
  )
}

import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import { omit } from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

import { Grid } from '@material-ui/core'
import { Box } from 'UIkit'
import { Button } from 'UIkit/Button/Button'
import { DNDTable, CheckBox, TextField, Typography } from 'legacy/shared'

import { modalActions } from 'services/modal/reducer'
import { ModalTypes } from 'services/modal/constants'

import { scenarioActions } from 'services/project/scenarios/reducer'
import {
  selectDataGroups,
  selectIsGroupCreating,
  selectIsSynthesizing,
} from 'services/project/scenarios/selectors'

import { filterGroups } from './utils'
import { BiasMitigation } from './BiasMitigation'
import ActionMenu from './ActionMenu'
import { Tags } from '../../Datasets/Tags'

import { getBiasMitigationDisabled } from '../projectSettings/selectors'

import { GroupStatus } from './GroupStatus'
import GroupsCharts from './GroupsCharts'

const useStyles = makeStyles({
  marginRight: {
    marginRight: '1rem',
  },
  dataGroupsTable: {
    marginTop: '1rem',
  },
  checkBoxLabel: {
    margin: 'auto',
  },
  checkBox: { padding: 0 },
  checkBoxWithLabel: { padding: 0, paddingRight: '0.5rem' },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellStyles: {
    padding: '4px 16px',
  },
  tags: {
    margin: '.125rem 0',
  },
  inputBackground: {
    backgroundColor: '#fff',
  },
})

const eventPrevent = (e) => {
  e.stopPropagation()
  e.preventDefault()
  e.target.blur()
}

export const TableTextField = ({
  name,
  type = 'text',
  row,
  onBlur,
  InputProps = {},
  ...otherProps
}) => {
  const [value, setValue] = useState(row[name])
  const classes = useStyles()
  return (
    <TextField
      autoFocus={name === 'title'}
      type={type}
      value={value}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) =>
        setValue(type === 'number' ? +e.target.value : e.target.value)
      }
      onBlur={() => {
        const isTouched = row[name] !== value
        onBlur({ id: row.id, value, name }, isTouched)
      }}
      label=""
      placeholder=""
      autoComplete="off"
      variant="outlined"
      style={{ width: 'auto', backgroundColor: '#e4e7f7' }}
      InputProps={{ ...InputProps, classes: { root: classes.inputBackground } }}
      {...otherProps}
    />
  )
}

const GroupsTable = ({
  groups,
  isGroupCreatingState,
  isSynthesizing,
  isBiasMitigationDisabled,
  canEdit,
  updateGroup,
  updateGroupPosition,
  showModal,
}) => {
  const classes = useStyles()
  const [isShowNameTextField, setIsShowNameTextField] = useState({})
  const [searchValue, setSearchValue] = useState('')

  const { callback } = useDebouncedCallback(
    (value) => setSearchValue(value),
    300
  )

  const tableData = filterGroups(groups, searchValue)

  const handleChangeRow = useCallback(({ value, name, id }, isTouched) => {
    if (isTouched) {
      updateGroup({ value, key: name, id })
    }
  }, [])

  const handleChangeGroupName = useCallback(
    ({ value, name, id }, isTouched) => {
      setIsShowNameTextField({ id, is: false })
      if (isTouched && value.trim()) {
        updateGroup({ value, id, key: name })
      }
    },
    []
  )

  const handleDeleteDetail = useCallback(({ group, nameDetail }) => {
    const updatedConditions = omit(group.conditions, nameDetail)

    updateGroup({
      id: group.id,
      value: updatedConditions,
      key: 'conditions',
    })
  }, [])

  const handleCreateGroup = () => {
    showModal({
      modalType: ModalTypes.ProjectAddGroup,
      config: {
        headerText: 'Creating New Data Group',
      },
    })
  }
  const CollapseComponent = React.useCallback(
    ({ row }) => (
      <>
        <GroupsCharts group={row} canEdit={canEdit} />
        <BiasMitigation
          group={row}
          isBiasMitigationDisabled={isBiasMitigationDisabled}
          handleChangeRow={handleChangeRow}
        />
      </>
    ),
    []
  )

  return (
    <Box pt={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        data-testid="group-table"
      >
        <Grid>
          <TextField
            onChange={(e) => callback(e.target.value)}
            label="Search"
            name="search"
            placeholder="Search..."
            autoComplete="off"
          />
        </Grid>
        <Grid>
          <Button
            onClick={handleCreateGroup}
            loading={isGroupCreatingState}
            disabled={!canEdit || isSynthesizing}
          >
            Create new group
          </Button>
        </Grid>
      </Grid>
      <div className={classes.dataGroupsTable}>
        <DNDTable
          rows={tableData}
          headerRow={[
            {
              id: 'title',
              label: 'Group name',
              sortable: true,
              width: '15rem',
            },
            { id: 'details', label: 'Group details' },
            {
              id: 'samples',
              label: 'Number of samples',
              sortable: true,
              width: '11rem',
            },
            {
              id: 'to_impute_nans',
              label: 'Impute missing values',
              align: 'center',
              width: '10rem',
            },
            {
              id: 'to_include_original_data',
              label: 'Include original data',
              align: 'center',
              width: '9rem',
            },
            { label: '', class: classes.actionWidth, width: '56px' },
          ]}
          bodyRow={[
            ({ row }) =>
              canEdit &&
              isShowNameTextField.is &&
              row.id === isShowNameTextField.id ? (
                <TableTextField
                  data-testid="group-title-input"
                  row={row}
                  name="title"
                  onBlur={handleChangeGroupName}
                />
              ) : (
                <div
                  data-testid="group-title"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setIsShowNameTextField({ id: row.id, is: true })
                  }
                >
                  <GroupStatus synthStatus={row.status} />
                  <Typography>{row.title}</Typography>
                </div>
              ),
            ({ row }) => (
              <Tags
                disabled={!canEdit}
                tags={Object.keys(row.conditions)}
                color="primary"
                className={classes.tags}
                clickable
                onDelete={(nameDetail) =>
                  handleDeleteDetail({ group: row, nameDetail })
                }
              />
            ),
            ({ row }) => (
              <TableTextField
                disabled={!canEdit}
                row={row}
                name="samples"
                type="number"
                onBlur={handleChangeRow}
              />
            ),
            ({ row }) => (
              <div className={classes.center}>
                <div onClick={(e) => eventPrevent(e)}>
                  <CheckBox
                    disabled={!canEdit}
                    checked={row.to_impute_nans}
                    onChange={(e, checked) =>
                      handleChangeRow(
                        {
                          value: checked,
                          id: row.id,
                          name: 'to_impute_nans',
                        },
                        true
                      )
                    }
                    labelClassName={classes.checkBoxLabel}
                    className={classes.checkBox}
                  />
                </div>
              </div>
            ),
            ({ row }) => (
              <div className={classes.center}>
                <div onClick={(e) => eventPrevent(e)}>
                  <CheckBox
                    disabled={!canEdit}
                    checked={row.to_include_original_data}
                    onChange={(e, checked) =>
                      handleChangeRow(
                        {
                          value: checked,
                          id: row.id,
                          name: 'to_include_original_data',
                        },
                        true
                      )
                    }
                    labelClassName={classes.checkBoxLabel}
                    className={classes.checkBox}
                  />
                </div>
              </div>
            ),

            ({ row }) => <ActionMenu group={row} disabled={!canEdit} />,
          ]}
          cellClasses={{ root: classes.cellStyles }}
          defaultSort={{ name: 'created_at', order: 'desc' }}
          hover={true}
          customProps={{
            id: isShowNameTextField.id,
            isShow: isShowNameTextField.is,
          }}
          updateGroupPosition={updateGroupPosition}
          isDragDisabled={isSynthesizing || !canEdit}
          CollapseComponent={CollapseComponent}
          loading={isSynthesizing}
        />
      </div>
    </Box>
  )
}

const mapStateToProps = createStructuredSelector({
  groups: selectDataGroups,
  isGroupCreatingState: selectIsGroupCreating,
  isBiasMitigationDisabled: getBiasMitigationDisabled,
  isSynthesizing: selectIsSynthesizing,
})

const mapDispatchToProps = {
  updateGroup: scenarioActions.updateGroup,
  updateGroupPosition: scenarioActions.updateGroupPosition,
  showModal: modalActions.show,
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable)

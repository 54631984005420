export const trainingStatus = {
  Init: 'init',
  Training: 'training',
  Ready: 'ready',
  Failed: 'failed',
}

export const statuses = [
  {
    status: trainingStatus.Init,
    title: 'Model is not ready',
  },
  {
    status: trainingStatus.Training,
    title: 'Please wait...',
  },
  {
    status: trainingStatus.Ready,
    title: 'Complete',
  },
  {
    status: 'failed',
    title: 'Try later',
  },
]

import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import { Typography, spacing } from 'legacy/shared'

import { Header } from 'layouts/Auth/Header'
import { Layout } from 'layouts/Auth/Layout'

import { Routes } from 'legacy/utils'

import SignInForm from './SignInForm'

export const SignInPage: React.FC = () => {
  return (
    <Layout>
      <Header heading="Welcome back!" subTitle="Sign in to Synthesized" />
      <SignInForm />
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        style={{ marginTop: `${spacing.gutterLarge}px` }}
      >
        <Typography size="captionBold">
          Don&rsquo;t have an account?&nbsp;
          <Link to={Routes.SignUp()}>Sign Up Here</Link>
        </Typography>
      </Grid>
    </Layout>
  )
}

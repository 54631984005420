import { createTheme } from '@material-ui/core/styles'

const defaultTheme = createTheme()

export const colors = {
  black: 'black',
  white: '#FFFFFF',
  red: '#FE4E5C',
  green: '#00BB00',
  darkPurple: '#3B2F6C',
  purple: '#d23cc2',
  purpleHover: '#de3ecd',
  hoverRed: '#FF6671',
  blueRoyal: '#515fe7',
  blueRoyalHover: '#5767fa',
  blueYankees: '#1d2642',
  buttonDefault: '#0D0840',
  buttonDefaultHover: '#252053',
  buttonPrimary: '#5065FB',
  buttonPrimaryHover: '#6073FB',
  buttonGray: '#E7E6EC',
  disabledGrey: '#DCE0E6',
  lightGrey: '#777C8D',
  platinum: '#E0E4ED',
  honeydew: '#e3f7ed',
  posBias: '#F26F63',
  negBias: '#F2B84B',
  freeTierPage: '#3B2F6C',
  cornsilk: '#fff5da',
  mikado: '#ffbe0b',
  hover: '#f9f8f8',
  creame: '#cfced926',
  lightPurple: '#6E6B8C',
  progressbarDefault: '#69589B',
  // Project
  projectStatistics: '#8d93a4',
  // Table
  lightPink: '#FFCACE',
  lightYellow: '#FFF1D1',
  lightGreen: '#CCF7E2',
  textRed: '#B33741',
  textGreen: '#34885F',
  textYellow: '#B39247',
  lightBlue: '#e7e6ec40',
  expander: '#3E3965',
  expanderBackground: '#cfced9b3',
  tableTextColor: '#00001C',
  tableBackground: '#ECEBF0',
  tableBorder: '#DCE0E6',
  tableSelectedRow: '#f1f0f4',
  tableHeaderBackground: '#cfced91a',
  // Counters
  counterBorder: '#DCE0E6',
  counterBackground: '#FAFAFB',
}

export const fonts = {
  primary: 'Lato',
  secondary: 'Open Sans',
}

export const spacing = {
  gutter: defaultTheme.spacing(1),
  gutterLarge: defaultTheme.spacing(2),
}

export const customTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 12,
        fontFamily: fonts.primary,
        fontWeight: 700,
        letterSpacing: 0,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        '&$disabled': {
          backgroundColor: colors.disabledGrey,
          color: '#0000008A',
        },
      },
      contained: {
        boxShadow:
          '0 2px 6px 0 rgba(117,99,99,0.11), 0 1px 3px 0 rgba(0,0,0,0.07)',
        backgroundColor: colors.buttonDefault,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.buttonDefaultHover,
        },
      },
      containedPrimary: {
        backgroundColor: colors.buttonPrimary,
        '&:hover': {
          backgroundColor: colors.buttonPrimaryHover,
        },
      },
      containedSecondary: {
        backgroundColor: colors.red,
        '&:hover': {
          backgroundColor: colors.hoverRed,
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: colors.buttonDefault,
        borderColor: colors.buttonDefault,
        '&:hover': {
          backgroundColor: '#E7DCEC',
        },
        '&$disabled': {
          backgroundColor: colors.white,
          borderColor: '#DCE0E6',
          color: '#0000008A',
        },
      },
    },
    MuiInput: {
      root: {
        fontFamily: fonts.primary,
      },
    },
    MuiOutlinedInput: {
      multiline: {
        height: 'auto',
      },
      inputMultiline: {
        lineHeight: '20px',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: fonts.primary,
      },
      outlined: {
        transform: 'translate(14px, 13px) scale(1)',
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
    MuiStepper: {
      root: {
        fontFamily: fonts.primary,
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: fonts.primary,
      },
      head: {
        fontSize: '12px',
        fontWeight: '600',
      },
      body: {
        color: colors.darkPurple,
        borderColor: '#DCE0E6',
        '&:first-child': {
          borderLeft: '1px solid #DCE0E6',
        },
        '&:last-child': {
          borderRight: '1px solid #DCE0E6',
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: fonts.primary,
    },
    body1: {
      fontFamily: fonts.primary,
    },
  },
  palette: { background: { default: '#fff' } },
})

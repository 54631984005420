import React, { RefObject, useMemo, useRef, ReactNode } from 'react'
import { FixedSizeList, VariableSizeList } from 'react-window'
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from 'react-table'
import SpinnerSample from 'legacy/components/Projects/SpinnerSample'
import { useClientRect } from 'hooks/useClientRect'
import { isEqual } from 'lodash'
import { Box } from '@material-ui/core'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { useTableStyles } from './styles'
import { colors } from '../../legacy/shared'

export type RowProps = {
  row: any
  index?: number
  style?: any
  state?: any
  listInstance?: RefObject<typeof FixedSizeList>
  tableIntstance?: RefObject<any>
  onRowClick: (arg1: MouseEvent, arg2: any) => void
}

interface ITableProps {
  loading: boolean
  data: any
  columns: any
  itemSize?: number
  itemSizeCallback?: (index: number) => number
  variableSize?: boolean
  onRowClick: (arg1: MouseEvent, arg2: any) => void
  rowRender?: (rowProps: RowProps) => any
  footer?: ReactNode
}

export const Table = React.memo(
  ({
    loading,
    data,
    columns,
    onRowClick,
    rowRender,
    variableSize = false,
    itemSize = 54,
    itemSizeCallback,
    footer,
  }: ITableProps) => {
    const classes = useTableStyles()
    const tableBodyRef = useRef(null)
    const listInstance = useRef(null)
    const [rect] = useClientRect(tableBodyRef)

    const dataToTable = useMemo(() => {
      return data.length ? data : [{ noData: true }]
    }, [data])

    const defaultColumn = React.useMemo(
      () => ({
        // When using the useFlexLayout:
        minWidth: 30, // minWidth is only used as a limit for resizing
        width: 150, // width is used for both the flex-basis and flex-grow
        maxWidth: 500, // maxWidth is only used as a limit for resizing
      }),
      []
    )

    const sortBy = useMemo(
      () => [
        {
          id: 'created_at',
          desc: true,
        },
      ],
      []
    )

    // TODO: add doc
    const sortTypes = React.useMemo(
      () => ({
        customDate: (a: any, b: any, id: string) => {
          const _a = a.values[id]
          const _b = b.values[id]
          let x = null
          let y = null

          x = _a === '' || _a === null ? new Date(null) : new Date(_a)
          y = _b === '' || _b === null ? new Date(null) : new Date(_b)

          return x === y ? 0 : x > y ? 1 : -1
        },
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      gotoPage,
      setPageSize,
      state: { pageIndex = 0, pageSize = 10 },
    } = useTable(
      {
        columns,
        data: dataToTable,
        defaultColumn,
        initialState: { sortBy },
        sortTypes,
      },
      useSortBy,
      useResizeColumns,
      useFlexLayout
    )

    const handleChangePage = (event: React.SyntheticEvent, newPage: number) => {
      gotoPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
      setPageSize(Number(event.target.value))
    }

    const headerProps = (props: any, { column }: any) =>
      getStyles(props, column.align)

    const getRowProps = (row: any, style: any) => ({
      style,
      onClick: (event: MouseEvent) => onRowClick(event, row),
    })

    const outerRowRender = React.useCallback(
      ({ index, style }) => {
        const row = rows[index]
        prepareRow(row)
        return rowRender({
          tableIntstance: tableBodyRef.current,
          listInstance: listInstance.current,
          row,
          state: {},
          index,
          style,
          onRowClick,
        })
      },
      [prepareRow, rows]
    )

    const defaultRenderer = React.useCallback(
      ({ index, style }) => {
        const row = rows[index]
        prepareRow(row)
        return (
          <div
            {...row.getRowProps(getRowProps(row, { ...style }))}
            className={classes.tr_body}
          >
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps(cellProps)} className={classes.td}>
                  {cell.render('Cell')}
                </div>
              )
            })}
          </div>
        )
      },
      [prepareRow, rows]
    )

    const renderRow = rowRender ? outerRowRender : defaultRenderer

    return (
      <>
        <div
          {...getTableProps()}
          className={classes.table}
          style={{ minWidth: 'max-content' }}
        >
          <div className={classes.thead}>
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={classes.tr_head}
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps(
                      column.getSortByToggleProps(headerProps)
                    )}
                    onClick={() => {
                      if (!column.disableSortBy) {
                        return column.isSortedDesc
                          ? column.toggleSortBy(false, false)
                          : column.toggleSortBy(true, false)
                      }
                    }}
                    title={column.id}
                    className={classes.th}
                  >
                    {column.render('Header')}
                    {!column.disableSortBy && (
                      <Box ml={0.5}>
                        <i className={classes.tableSortIcon}>
                          {!column.isSorted && (
                            <UnfoldMoreIcon
                              fontSize="small"
                              htmlColor={colors.platinum}
                            />
                          )}
                          {column.isSorted && column.isSortedDesc === true && (
                            <ArrowDownward fontSize="small" />
                          )}
                          {column.isSorted && column.isSortedDesc === false && (
                            <ArrowUpward fontSize="small" />
                          )}
                        </i>
                      </Box>
                    )}
                    {column.canResize && (
                      <div
                        {...column.getResizerProps()}
                        className={`${classes.resizer} ${
                          column.isResizing && classes.isResizing
                        }`}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                      />
                    )}
                    {column.canResize && (
                      <div className={classes.resizeDivider} />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            {...getTableBodyProps()}
            ref={tableBodyRef}
            className={`${classes.tbody} ${
              isNoData(dataToTable) && classes.noDataContainer
            }`}
          >
            {loading && (
              <SpinnerSample classNameSpinner={classes.spinnerTableStyles} />
            )}
            {!loading && isNoData(dataToTable) && (
              <div className={classes.noData}>No Data...</div>
            )}
            {!loading &&
              !isNoData(dataToTable) &&
              (variableSize ? (
                <VariableSizeList
                  height={rect !== null ? rect.height - 1 : 400}
                  width="100%"
                  itemSize={itemSizeCallback}
                  ref={listInstance}
                  itemCount={rows.length}
                  overscanCount={10}
                  style={{ overflow: 'hidden auto' }}
                >
                  {renderRow}
                </VariableSizeList>
              ) : (
                <FixedSizeList
                  height={rect !== null ? rect.height - 1 : 400}
                  width="100%"
                  ref={listInstance}
                  itemCount={rows.length}
                  itemSize={itemSize}
                  overscanCount={10}
                  style={{ overflow: 'hidden auto' }}
                >
                  {renderRow}
                </FixedSizeList>
              ))}
          </div>
          {footer}
        </div>
      </>
    )
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.data, nextProps.data) &&
    prevProps.loading === nextProps.loading
)

const cellProps = (props: any, { cell }: any) =>
  getStyles(props, cell.column.align)

const getStyles = (props: any, align = 'left') => [
  props,
  {
    style: {
      justifyContent:
        align === 'center'
          ? 'center'
          : align === 'right'
          ? 'flex-end'
          : 'flex-start',
      display: 'flex',
    },
  },
]

const isNoData = (data: any) =>
  data.length && data.length === 1 && data[0].noData

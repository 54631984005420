import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import { Button } from 'UIkit/Button/Button'
import { Box } from 'UIkit'

import { scenarioActions } from 'services/project/scenarios/reducer'

import { ColumnTitleLayout } from 'legacy/components/Projects/ColumnData/ColumnTitle'
import { HeaderChart } from '../ProjectDistPlot'

const useStyles = makeStyles({
  borderLayout: {
    width: '300px',
  },
  borderRight: {
    borderRight: '1px solid rgba(224, 224, 224, 1)', // TODO delete and use constant variable from layouts.jsx
  },
  undoButton: {
    marginLeft: 'auto',
  },
  selectionPadding: {
    padding: '.5rem',
    paddingTop: 0,
  },
})

const ScenarioChartContainer = ({
  last,
  d,
  group,
  data,
  synth_data,
  index,
  updateGroup,
  canEdit,
  ...otherProps
}) => {
  const classes = useStyles()

  const conditions = useMemo(
    () => Object.keys(group.conditions),
    [group?.conditions]
  )

  const handleUndoButtonClick = (name) => {
    const updatedConditions = omit(group.conditions, name)

    updateGroup({
      id: group.id,
      value: updatedConditions,
      key: 'conditions',
    })
  }

  return (
    <div>
      <Box
        p={0.5}
        border={1}
        borderRight={last ? 1 : 0}
        className={classes.borderLayout}
        style={{
          backgroundColor:
            conditions.length && conditions.includes(d.name) ? '#f9faff' : '',
        }}
      >
        <ColumnTitleLayout title={d.name}>
          {conditions.length && conditions.includes(d.name) ? (
            <Button
              className={classes.undoButton}
              variant="outlined"
              onClick={() => handleUndoButtonClick(d.name)}
              disabled={!canEdit}
              size="small"
            >
              Undo
            </Button>
          ) : null}
        </ColumnTitleLayout>
        <HeaderChart
          {...otherProps}
          from="scenario"
          group={{ ...group }}
          settings={{ conditions: { ...group.conditions } }}
          d={d}
          cur_data={data}
          data={data}
          synth_data={synth_data}
          i={index}
        />
      </Box>
    </div>
  )
}

const mapDispatchToProps = {
  updateGroup: scenarioActions.updateGroup,
}

export default connect(null, mapDispatchToProps)(ScenarioChartContainer)

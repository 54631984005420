import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatus } from 'utils/constants'

const prefix = 'newProject'

const initialState: { createStatus: RequestStatus } = {
  createStatus: RequestStatus.Idle,
}

const newProjectReducer = createSlice({
  name: prefix,
  initialState,
  reducers: {
    createProject: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.createStatus = RequestStatus.Loading
    },
    didCreateProject: (state) => {
      state.createStatus = RequestStatus.Idle
    },
    didCreateProjectFail: (state) => {
      state.createStatus = RequestStatus.Idle
    },
  },
})

export const newProjectActions = newProjectReducer.actions

export default newProjectReducer.reducer

import * as React from 'react'
import classnames from 'classnames'
import { withStyles, Menu as MUIMenu, MenuItem, Fade } from '@material-ui/core'
import { Typography } from '../typography'

const menuStyles = () => ({
  paper: {
    minWidth: 160,
    boxShadow:
      '0 10px 20px 0 rgba(117,99,99,0.11), 0 3px 6px 0 rgba(0,0,0,0.07)',
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

class Component extends React.Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleItemClicked = (e, item, value) => {
    e.stopPropagation()

    if (item.onClick) {
      item.onClick(value)
    }

    this.handleClose()
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  renderTriggerComponent = () => {
    const { renderTriggerComponent } = this.props

    if (renderTriggerComponent) {
      return renderTriggerComponent({ showMenu: this.handleClick })
    }

    return null
  }

  renderMenuItems = () => {
    const { items, value, menuItemClassName } = this.props
    const commonProps = {
      classes: {
        root: menuItemClassName,
      },
    }
    return items.map((item) => {
      return (
        <MenuItem
          {...commonProps}
          onClick={(e) => this.handleItemClicked(e, item, value)}
          key={item.text}
          disabled={item.disabled}
        >
          <Typography color="grey" size="caption">
            {item.text}
          </Typography>
        </MenuItem>
      )
    })
  }

  render() {
    const { anchorEl } = this.state
    const {
      className,
      menuListClassName,
      classes,
      'data-testid': testId,
      anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
      },
    } = this.props

    return (
      <>
        {this.renderTriggerComponent()}
        <MUIMenu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={anchorOrigin}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionComponent={Fade}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          MenuListProps={{
            classes: {
              root: classnames(classes.menuList, menuListClassName),
            },
          }}
          classes={{
            paper: classnames(classes.paper, className),
          }}
          disableAutoFocusItem
          data-testid={testId}
        >
          {this.renderMenuItems()}
        </MUIMenu>
      </>
    )
  }
}

const StyledComponent = withStyles(menuStyles)(Component)

export const Menu = (props) => <StyledComponent {...props} />

Menu.displayName = 'Menu'

import { makeRequest } from './apiClient'

// get users with permissions for dataset
export const getProjectUsersWithPermissions = ({ datasetId }) =>
  makeRequest({
    path: `permissions/dataset/${datasetId}`,
    method: 'GET',
  })

// delete project permission for user
export const deleteProjectUserPermission = async ({ datasetId, id }) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/${id}`,
    method: 'DELETE',
  })

  return response
}

// get permissions for project
export const getProjectPermissions = async ({ datasetId }) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}`,
    method: 'GET',
  })

  return response
}

// get permission for datasets
export const getProjectPermissionForUser = async ({
  datasetId,
  permissionId,
}) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/${permissionId}`,
    method: 'GET',
  })

  return response
}

// share permissions for project with list of users
export const shareDatasetWithEmail = async ({ datasetId, invites }) => {
  const response = await makeRequest({
    contentType: 'application/json',
    path: `permissions/dataset/${datasetId}/share`,
    method: 'POST',
    params: JSON.stringify(invites),
  })

  return response
}

// update project permissions for particular user
export const updateProjectPermissionsForUser = async ({
  datasetId,
  request,
}) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/${request.permission}`,
    method: 'PUT',
    params: JSON.stringify(request),
  })

  return response
}

export const getProjectPermissionsRequests = async ({ datasetId }) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/requests`,
    method: 'GET',
  })

  return response
}

export const createProjectPermissionsRequest = async ({
  datasetId,
  request,
}) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/requests`,
    method: 'POST',
    params: JSON.stringify(request),
  })

  return response
}

export const acceptProjectPermissionsRequest = async ({
  datasetId,
  requestId,
}) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/${requestId}`,
    method: 'GET',
  })

  return response
}

export const deleteProjectPermissionsRequest = async ({ datasetId, id }) => {
  const response = await makeRequest({
    path: `permissions/dataset/${datasetId}/requests/${id}`,
    method: 'DELETE',
  })

  return response
}

import { storage } from '../../utils/storage'
import _ from '../App/Constants'

class User {
  isAuth() {
    return storage.get(_.SESSION_TOKEN)
  }

  login(data, formData) {
    storage.set(_.SESSION_TOKEN, data.access_token)
    storage.set(_.SESSION_REFRESH_TOKEN, data.refresh_token)
    if (formData) {
      storage.set(_.USER_EMAIL, formData.email)
    }
  }

  update_access_token(data) {
    storage.set(_.SESSION_TOKEN, data.access_token)
  }

  create_user_info(data) {
    storage.set(_.USER_ID, data.user_id)
    storage.set(_.USER_AVATAR_PATH, data.avatar_path)
    storage.set(_.USER_EMAIL, data.email)
    storage.set(_.USER_FIRST_NAME, data.first_name)
    storage.set(_.USER_LAST_NAME, data.last_name)
    storage.set(_.USER_PHONE_NUMBER, data.phone_number)
    storage.set(_.USER_JOB_TITLE, data.job_title)
    storage.set(_.USER_COMPANY, data.company)
    storage.set(_.USER_ROLES, data.role)
    storage.set(_.USER_TIER, data.tier)
    storage.set(_.USER_SHOW_ONBOARDING, data.settings.show_onboarding)
  }

  update_user_info(data) {
    storage.set(_.USER_FIRST_NAME, data.first_name)
    storage.set(_.USER_LAST_NAME, data.last_name)
    storage.set(_.USER_PHONE_NUMBER, data.phone_number)
    storage.set(_.USER_JOB_TITLE, data.job_title)
    storage.set(_.USER_COMPANY, data.company)
    storage.set(_.USER_SHOW_ONBOARDING, data.settings.show_onboarding)
  }

  initQueries(data) {
    storage.set(_.QUERIES, JSON.stringify(data))
  }

  setDisplayColumns(id, columns) {
    const value = JSON.parse(storage.get('displayColumns'))
    const updated = { ...value, [id]: columns }
    storage.set('displayColumns', JSON.stringify(updated))
  }

  getDisplayColumns(id) {
    const displayColumns = JSON.parse(storage.get('displayColumns'))
    return displayColumns ? displayColumns[id] : null
  }

  isAdmin() {
    return (
      storage.get(_.USER_ROLES) && storage.get(_.USER_ROLES).includes('ADMIN')
    )
  }

  isFreeTier() {
    return false
  }

  isCETier() {
    return storage.get(_.USER_TIER) === 'FREE'
  }

  logout() {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom('shutdown')
    }
    storage.clean()
  }

  setSideBarOpen(value) {
    storage.set('isSideBarOpen', value)
  }

  get id() {
    return storage.get(_.USER_ID)
  }

  get sessionToken() {
    return storage.get(_.SESSION_TOKEN)
  }

  get refreshToken() {
    return storage.get(_.SESSION_REFRESH_TOKEN)
  }

  get avatar_path() {
    return storage.get(_.USER_AVATAR_PATH)
  }

  get email() {
    return storage.get(_.USER_EMAIL)
  }

  get first_name() {
    return storage.get(_.USER_FIRST_NAME)
  }

  get last_name() {
    return storage.get(_.USER_LAST_NAME)
  }

  get phone_number() {
    return storage.get(_.USER_PHONE_NUMBER)
  }

  get job_title() {
    return storage.get(_.USER_JOB_TITLE)
  }

  get company() {
    return storage.get(_.USER_COMPANY)
  }

  get roles() {
    return storage.get(_.USER_ROLES)
  }

  get show_onboarding() {
    return storage.get(_.USER_SHOW_ONBOARDING)
  }

  get queries() {
    return storage.get(_.QUERIES)
  }

  get isSideBarOpen() {
    if (storage.get('isSideBarOpen') === null) {
      return true
    }
    return storage.get('isSideBarOpen')
  }
}

export default new User()

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { makeStyles } from '@material-ui/core/styles'
import copy from 'copy-to-clipboard'
import HeaderComponent from 'layouts/Header'
import PageContainer from 'layouts/PageContainer'

import { TextField, Typography } from 'legacy/shared'
import { getInviteCode } from 'services/inviteCode/selectors'
import { inviteCodeActions } from 'services/inviteCode/reducer'
import { Button } from 'UIkit/Button/Button'

const useStyles = makeStyles({
  textField: {
    width: '10rem',
  },
  marginTypography: {
    marginLeft: '.25rem',
  },
})

interface ISelectedProps {
  inviteCode: string
}

type DispatchProps = typeof mapDispatchToProps

const InviteCode: React.FunctionComponent<ISelectedProps & DispatchProps> = ({
  inviteCode,
  generateInviteCode,
}) => {
  const classes = useStyles()
  const [duration, setDuration] = useState('')

  const handleGenerateInviteCode = () => {
    if (duration) {
      generateInviteCode({ hours: duration })
    }
  }

  return (
    <>
      <HeaderComponent title="Generate invite code" />
      <PageContainer>
        <div>
          <TextField
            label="Duration in hours"
            value={duration}
            type="number"
            placeholder="48"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            onChange={(e: any) => setDuration(e.target.value)}
          />
        </div>
        <Button
          onClick={handleGenerateInviteCode}
          style={{ marginTop: '1rem' }}
        >
          Generate Code
        </Button>

        {inviteCode ? (
          <>
            <div
              style={{
                marginTop: '1.5rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography>{'Invite code: '}</Typography>
              <Typography size="bodyBold" className={classes.marginTypography}>
                {inviteCode.length > 100
                  ? inviteCode.slice(0, 100)
                  : inviteCode}
              </Typography>
            </div>
            <Button
              color="primary"
              onClick={() => copy(inviteCode)}
              style={{ marginTop: '1rem' }}
            >
              Copy to clipboard
            </Button>
          </>
        ) : null}
      </PageContainer>
    </>
  )
}

const mapStateToProps = createStructuredSelector<any, ISelectedProps>({
  inviteCode: getInviteCode,
})

const mapDispatchToProps = {
  generateInviteCode: inviteCodeActions.generateInviteCode,
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteCode)

import React, { useState } from 'react'
import ActionPanel from 'pages/Projects/ActionPanel'
import ProjectsTable from 'pages/Projects/ProjectsTable'

import HeaderComponent, { SearchComponent } from 'layouts/Header'
import PageContainer from 'layouts/PageContainer'

import ProductTour from '../ProductTour/ProductTour'

function DatasetsPreloadPage() {
  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      <ProductTour />
      <HeaderComponent title="Data Products" />
      <PageContainer>
        {/* TODO add Grid layout */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: '1.5rem',
          }}
        >
          <SearchComponent
            searchValue={searchValue}
            handleSetSearchValue={(value) => setSearchValue(value)}
          />
          <div
            style={{
              display: 'flex',
              alignSelf: 'center',
            }}
          >
            <ActionPanel />
          </div>
        </div>
        <ProjectsTable searchValue={searchValue} />
      </PageContainer>
    </>
  )
}

export default DatasetsPreloadPage

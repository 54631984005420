const storageName = `${window.location.host}_synthesis`

export const storage = {
  storage: localStorage,

  setStorage(storage) {
    this.storage = storage
  },

  set(key, value = '') {
    const dataString = this.storage.getItem(storageName)
    if (!dataString) {
      const data = {}
      data[key] = value
      return this.storage.setItem(storageName, JSON.stringify(data))
    }
    const data = JSON.parse(dataString)
    data[key] = value
    this.storage.setItem(storageName, JSON.stringify(data))
  },

  get(key) {
    const dataString = this.storage.getItem(storageName)
    if (!dataString) {
      return null
    }
    const data = JSON.parse(dataString)
    if (data[key] === undefined) {
      return null
    }
    return data[key]
  },

  getAll() {
    const dataString = this.storage.getItem(storageName)
    if (!dataString) {
      return null
    }
    return JSON.parse(dataString)
  },

  clean() {
    this.storage.removeItem(storageName)
  },
}

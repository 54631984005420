import {
  takeLatest,
  put,
  call,
  delay,
  select,
  race,
  take,
} from 'redux-saga/effects'

import _ from 'legacy/components/App/Constants'
import { notificationActions } from 'services/notification/reducer'

import { handleUpdateBiasSettings } from '../projectSettings/sagas'
import { ProjectActions } from '../actions'
import { ProjectSettingsActions } from '../projectSettings/actions'
import {
  QualityAndBiasActions,
  updateBiasStatus,
  updateQualityStatus,
  updateBiasStatusFail,
  didFetchQualityData,
  didFetchBiasData,
} from './actions'
import { processStatus } from './constants'
import { getProjectStatusState } from '../projectSelectors'
import { getTargetColumnState } from '../projectSettings/selectors'
import { dataSetsService } from '../../../utils/services'
import User from '../../Account/User'

export function* handleStartQuality({ id }) {
  if (!User.isFreeTier() && !User.isCETier()) {
    try {
      yield put(updateQualityStatus(processStatus.Progress, 0))
      yield call(dataSetsService.startQuality, id)
      const { task, cancel } = yield race({
        task: call(handlePoolQualityStatus, { id }),
        cancel: take(ProjectActions.RESET_STATE),
      })
    } catch (error) {
      yield put(updateQualityStatus(processStatus.Fail))
    }
  }
}

export function* handlePoolQualityStatus({ id }) {
  while (true) {
    yield delay(5000)
    try {
      const result = yield call(dataSetsService.getQualityProgress, id)
      if (result.status === 'STATUS_FINISHED') {
        const dataset = yield call(dataSetsService.getCurrentDatasetById, {
          id,
        })
        if (dataset.meta.quality_data) {
          return yield put(didFetchQualityData(dataset.meta.quality_data))
        }
        throw Error('meta.quality_data is empty')
      }
      yield put(updateQualityStatus(processStatus.Progress, result.progress))
    } catch (e) {
      return yield put(updateQualityStatus(processStatus.Fail))
    }
  }
}

function* handleStartBias({ id, targetColumn }) {
  try {
    yield put(updateBiasStatus(processStatus.Progress, 0))
    yield call(dataSetsService.startBias, { id, targetColumn })
    yield call(handlePoolBiasStatus, { id })
  } catch (error) {
    yield put(updateBiasStatusFail())
  }
}

function* handlePoolBiasStatus({ id }) {
  while (true) {
    yield delay(5000)
    try {
      const result = yield call(dataSetsService.getBiasProgress, id)
      if (result.status === 'STATUS_FINISHED') {
        if (result?.preview?.meta?.fairness_data) {
          return yield put(didFetchBiasData(result.preview.meta.fairness_data))
        }
        const dataset = yield call(dataSetsService.getCurrentDatasetById, {
          id,
        })
        if (dataset.meta.fairness_data) {
          return yield put(didFetchBiasData(dataset.meta.fairness_data))
        }
        throw Error('meta.fairness_data is empty')
      }
      yield put(updateBiasStatus(processStatus.Progress, result.progress))
    } catch (e) {
      return yield put(updateBiasStatusFail())
    }
  }
}

function* handleStartBiasAndQuality({ id }) {
  const targetColumn = yield select(getTargetColumnState)
  if (targetColumn !== 'none') {
    yield call(handleStartBias, { id, targetColumn })
    yield call(handleStartQuality, { id })
  } else {
    yield call(handleStartQuality, { id })
  }
}

function* handleUpdateBiasColumn({ id, settings }) {
  const trainingStatus = yield select(getProjectStatusState)
  const { targetColumn } = settings
  if (trainingStatus === 'ready') {
    yield put(updateBiasStatus(processStatus.Progress, 0))
    yield call(handleUpdateBiasSettings, { id, settings })
    yield call(handleStartBias, { id, targetColumn })
  } else {
    yield call(handleUpdateBiasSettings, { id, settings })
  }
}

export function* handleResumeTasks({ id }) {
  const targetColumn = yield select(getTargetColumnState)

  if (targetColumn !== 'none' && _.IS_FAIRNESS_ENABLED) {
    const biasStatus = yield call(
      (id) =>
        dataSetsService
          .getBiasProgress(id)
          .then((result) => result.status)
          .catch(() => null),
      id
    )
    if (biasStatus === null) {
      return yield call(handleStartBiasAndQuality, { id })
    }
    if (biasStatus === 'STATUS_FINISHED') {
      yield put(updateBiasStatus(processStatus.Ready))
    }
    if (biasStatus === 'STATUS_QUEUED' || biasStatus === 'STATUS_STARTED') {
      yield put(updateBiasStatus(processStatus.Progress, 0))
      yield call(handlePoolBiasStatus, { id })
    }
  }

  const qualityStatus = yield call(
    (id) =>
      dataSetsService
        .getQualityProgress(id)
        .then((result) => result.status)
        .catch(() => null),
    id
  )

  if (qualityStatus === null) {
    return yield call(handleStartQuality, { id })
  }

  if (qualityStatus === 'STATUS_FINISHED') {
    yield put(updateQualityStatus(processStatus.Ready))
  }

  if (qualityStatus === 'STATUS_QUEUED' || qualityStatus === 'STATUS_STARTED') {
    yield put(updateQualityStatus(processStatus.Progress, 0))
    yield call(handlePoolQualityStatus, { id })
  }
}

function* handleExportPDFFairness({ id, callback }) {
  try {
    const result = yield call(dataSetsService.exportPDFFairness, id)
    if (!result.message) {
      yield put(
        notificationActions.showNotification({
          message: 'File downloaded successfully!',
        })
      )
      callback(result)
    } else {
      yield put(
        notificationActions.showNotification({
          message: result.message,
          severity: 'error',
        })
      )
    }
  } catch (error) {}
}

export default function* watchBiasAndQualitySaga() {
  yield takeLatest(
    QualityAndBiasActions.START_BIAS_AND_QUALITY,
    handleStartBiasAndQuality
  )
  yield takeLatest(QualityAndBiasActions.START_QUALITY, handleStartQuality)
  yield takeLatest(
    QualityAndBiasActions.EXPORT_PDF_FAIRNESS,
    handleExportPDFFairness
  )
  yield takeLatest(
    ProjectSettingsActions.UPDATE_BIAS_SETTINGS,
    handleUpdateBiasColumn
  )
}

import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'
import { authService } from 'legacy/utils/services'
import { notificationActions } from 'services/notification/reducer'

import { inviteCodeActions } from './reducer'

function* handleGenerateInviteCode({ payload }: PayloadAction<any>) {
  try {
    const response = yield call(authService.getInviteCode, payload.hours)
    yield put(inviteCodeActions.didGenerateInviteCode(response))
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: `Code couldn't be generate`,
      })
    )
  }
}

export default function* watchTemplateSaga() {
  yield takeLatest(
    inviteCodeActions.generateInviteCode.type,
    handleGenerateInviteCode
  )
}

import { makeRequest } from './apiClient'

export default {
  // Get report
  async getReport({ id }) {
    const response = await makeRequest({
      path: `datasets/${id}/report`,
      method: 'GET',
    })
    return response
  },

  // Add block
  async addBlock({ id, type }) {
    const response = await makeRequest({
      path: `datasets/${id}/report-items`,
      method: 'POST',
      params: JSON.stringify({ type }),
    })
    return response
  },

  // Update settings
  async updateBlockSettings({ id, item_id, settings }) {
    const response = await makeRequest({
      path: `datasets/${id}/report-items/${item_id}/updatesettings`,
      method: 'POST',
      params: JSON.stringify({ settings }),
    })
    return response
  },

  // Delete block
  async deleteBlock({ id, item_id }) {
    const response = await makeRequest({
      path: `datasets/${id}/report-items/${item_id}`,
      method: 'DELETE',
    })
    return response
  },

  // Move block
  async moveBlock({ id, item_id, new_order }) {
    const response = await makeRequest({
      path: `datasets/${id}/report-items/${item_id}/move`,
      method: 'POST',
      params: JSON.stringify({ new_order }),
    })
    return response
  },
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Typography } from 'legacy/shared'
import { Box } from 'UIkit'

import { formatNumber } from 'legacy/services/helper'

const useStyles = makeStyles({
  root: {
    height: '91px',
    padding: '0.6rem',
    backgroundColor: '#f9faff',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '5px',
  },
})

const StatisticRow = ({ title, value }: any) => {
  const classes = useStyles()

  return (
    <Box className={classes.row}>
      <Typography size="smallBold">{title}: </Typography>
      <Typography size="small" color="projectStatistics">
        {value}
      </Typography>
    </Box>
  )
}

const HistogramColumnStatistics = ({ data }: any) => {
  return (
    <>
      <StatisticRow title="Unique Values" value={formatNumber(data.n_unique)} />
      <StatisticRow
        title="Most Frequent"
        value={
          data.name !== 'effort' && typeof data.most_frequent !== 'string'
            ? formatNumber(data.most_frequent)
            : data.most_frequent
        }
      />
      <StatisticRow
        title="Most Occurrences"
        value={formatNumber(data.most_occurrences)}
      />
    </>
  )
}

const DensityColumnStatistics = ({ data }: any) => {
  return (
    <>
      <StatisticRow title="Mean" value={formatNumber(data.mean)} />
      <StatisticRow title="Median" value={formatNumber(data.median)} />
      <StatisticRow
        title="Range"
        value={`${formatNumber(data.min)} - ${formatNumber(data.max)}`}
      />
      <StatisticRow title="Std" value={formatNumber(data.std)} />
    </>
  )
}

export const ColumnsStatistics = ({ columnData }: any) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} borderTop={1}>
      {columnData.plot_type === 'density' ? (
        <DensityColumnStatistics data={columnData} />
      ) : (
        <HistogramColumnStatistics data={columnData} />
      )}
    </Box>
  )
}

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { rootReducer } from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const createStore = (preloadedState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
    preloadedState,
    devTools: true,
  })
  sagaMiddleware.run(rootSaga)
  return store
}

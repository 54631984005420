import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'
import { colors } from '../Colors'
import { getProjectSynthDataState } from './projectSelectors'

import Chart from '../Chart'

import '../Datasets/DatasetsTablePage.scss'
import HorizontalBarChart from '../../shared/components/horizontalBarChart/HorizontalBarChart'

const findSumValues = (arr) => {
  return arr !== null && arr.length
    ? arr.reduce((a, b) => {
        let temp = 0
        if (b !== null && !isNaN(b)) {
          temp = b
        }
        return a + temp
      }, 0)
    : 0
}

const ProjectChart = React.memo(
  ({
    activeTab,
    origin_data,
    curr_synth_data,
    plot_data,
    plot_type,
    type,
    mean,
    std,
    median,
    max,
    min,
    index,
    name,
    dataset_id,
    status,
    disableDragBars,
    settings,
    isBrush,
    brushValue,
    handleChangeBrush,
    from = '',
    ...otherProps
  }) => {
    const transform_data = []
    const density_arr = []
    if (origin_data.meta && origin_data.meta !== null) {
      const origin_dt = origin_data.meta.columns[index].plot_data
      const sum_origin_hist_values =
        origin_dt && origin_dt.hist !== null
          ? findSumValues(origin_dt.hist)
          : null

      if (origin_dt && origin_dt.density && origin_dt.density !== null) {
        density_arr.push({
          name: 'origin',
          label: 'Original',
          color: colors[0],
          values:
            sum_origin_hist_values === null
              ? origin_dt.density
              : sum_origin_hist_values > 1
              ? origin_dt.density.map((d) => d / sum_origin_hist_values)
              : origin_dt.density,
        })
      }

      transform_data.push({
        name: 'origin',
        label: 'Original',
        color: colors[0],
        values:
          sum_origin_hist_values === null
            ? []
            : sum_origin_hist_values > 1
            ? origin_dt.hist.map((d) => d / sum_origin_hist_values)
            : origin_dt.hist,
      })
    }

    if (
      curr_synth_data.meta &&
      curr_synth_data.meta !== null &&
      curr_synth_data.meta.columns
    ) {
      const idx_synth_col = curr_synth_data.meta.columns.findIndex(
        (d) => d.name === name
      )

      if (idx_synth_col !== -1) {
        const synth_dt = curr_synth_data.meta.columns[idx_synth_col].plot_data

        // sum all hist values
        const sum_synth_hist_values =
          synth_dt && synth_dt.hist !== null
            ? findSumValues(synth_dt.hist)
            : null

        if (synth_dt && synth_dt.density && synth_dt.density !== null) {
          density_arr.push({
            name: 'synth',
            label: 'Synthetic',
            color: colors[1],
            values:
              sum_synth_hist_values === null
                ? synth_dt.density
                : sum_synth_hist_values > 1
                ? synth_dt.density.map((d) => d / sum_synth_hist_values)
                : synth_dt.density,
          })
        }

        transform_data.push({
          name: 'synth',
          label: 'Synthetic',
          color: colors[1],
          values:
            sum_synth_hist_values === null
              ? []
              : sum_synth_hist_values > 1
              ? synth_dt.hist.map((d) => d / sum_synth_hist_values)
              : synth_dt.hist,
        })
      }
    }

    let options = {}

    if (plot_type === 'histogram') {
      options = {
        config: {
          transform_data,
          settings: Object.keys(settings).length
            ? settings
            : settings && settings === null
            ? {}
            : origin_data.settings && origin_data.settings !== null
            ? origin_data.settings
            : {}, // for get settings. check by settings param for datasetCharts.js in history view(monitoring)
          xAxisData: plot_data?.bins,
          truncated: plot_data?.truncated,
          plot_type,
          index,
          name,
          dataset_id,
        },
        status,
        disableDragBars,
        group: otherProps.group,
        from,
      }
    } else {
      options = {
        activeTab,
        disableDragBars,
        isBrush,
        brushValue,
        handleChangeBrush,
        from,
        config: {
          transform_data,
          density_arr,
          settings: Object.keys(settings).length
            ? settings
            : settings && settings === null
            ? {}
            : origin_data.settings && origin_data.settings !== null
            ? origin_data.settings
            : {}, // for get settings. check by settings param for datasetCharts.js in history view(monitoring)
          xAxisData: plot_type === 'density' ? plot_data?.edges : plot_data?.bins,
          density_support: plot_data?.density_support,
          truncated: plot_data?.truncated,
          plot_type,
          index,
          name,
          dataset_id,
        },
      }
    }
    return plot_type === 'histogram' ? (
      <HorizontalBarChart {...options} />
    ) : (
      <Chart {...options} />
    )
  },
  (prev, next) =>
    _.isEqual(
      prev.origin_data?.meta?.columns[prev.index],
      next.origin_data?.meta?.columns[next.index]
    ) &&
    _.isEqual(
      prev.curr_synth_data?.meta?.columns[prev.index],
      next.curr_synth_data?.meta?.columns[next.index]
    ) &&
    _.isEqual(prev.settings, next.settings) &&
    _.isEqual(prev.brushValue, next.brushValue) &&
    prev.disableDragBars === next.disableDragBars &&
    prev.status === next.status &&
    prev.activeTab === next.activeTab &&
    _.isEqual(prev.group, next.group)
)

const mapStateToProps = createStructuredSelector({
  synthData: getProjectSynthDataState,
})

export default connect(mapStateToProps, null)(ProjectChart)

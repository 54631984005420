import { createSelector } from 'reselect'
import { getSandboxBuildState } from '../sandboxBuildSelectors'

export const getSandboxDataset = createSelector(
  getSandboxBuildState,
  (state) => state.dataset
)

export const getSandboxDatasetReducerState = createSelector(
  getSandboxDataset,
  (state) => state.reducerState
)

export const getDatasetsById = createSelector(
  getSandboxDataset,
  (state) => state.byId
)

export const getDatasets = createSelector(getDatasetsById, (byId) =>
  Object.values(byId)
)

export const getTransformedDatasets = createSelector(
  getDatasets,
  (datasets) => {
    return datasets.map((dataset) => ({
      ...dataset,
      // created_at: dataset.created_at
      //   ? new Intl.DateTimeFormat('en-GB', {
      //       year: 'numeric',
      //       month: 'numeric',
      //       day: 'numeric',
      //       hour: 'numeric',
      //       minute: 'numeric'
      //     }).format(new Date(dataset.created_at))
      //   : ''
    }))
  }
)

export const getSelectedDatasetsById = createSelector(
  getSandboxDataset,
  (state) => state.selectedDatasetsById
)

export const getSelectedDatasetsIds = createSelector(
  getSelectedDatasetsById,
  (state) => Object.keys(state)
)

export const getSelectedDatasetsNames = createSelector(
  getSelectedDatasetsById,
  (state) =>
    Object.values(state)
      .map((dataset) => dataset.title)
      .join(', ')
      .trim()
)

export const getSearchStringValue = createSelector(
  getSandboxDataset,
  (state) => state.searchString
)

export const getIsStepCompleted = createSelector(
  getSelectedDatasetsById,
  (state) => Object.keys(state).length > 0
)

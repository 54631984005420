import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Typography } from 'legacy/shared'

const useStyles = makeStyles({
  text: {
    textAlign: 'center',
    width: '310px',
    margin: '0 auto',
  },
})

export const Confirmation: React.FC = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="body" color="grey" className={classes.text}>
        Check your email for a link to reset your password
      </Typography>
    </>
  )
}

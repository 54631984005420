import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, put, call } from 'redux-saga/effects'

import { pushRoute, Routes } from 'legacy/utils'

import { notificationActions } from 'services/notification/reducer'
import { templateService } from 'legacy/utils/services'

import { templateActions } from './reducer'

function* handleFetchTemplates() {
  try {
    const templates = yield call(templateService.getTemplateList)
    yield put(templateActions.didFetchTemplates({ templates }))
  } catch (error) {
    yield put(
      templateActions.didFetchTemplatesFail({ message: 'Server error' })
    )
  }
}

function* handleCreateDataset({ payload }: PayloadAction<any>) {
  try {
    const response = yield call(
      templateService.createDatasetFromTemplate,
      payload.id
    )

    pushRoute(Routes.Project(response.dataset_id))
  } catch (error) {
    yield put(
      notificationActions.showNotification({
        message: 'Dataset could not be create from selected template',
        severity: 'error',
      })
    )
  }
  yield put(templateActions.didCreateDataset())
}

export default function* watchTemplateSaga() {
  yield takeLatest(templateActions.fetchTemplates.type, handleFetchTemplates)
  yield takeLatest(templateActions.createDataset.type, handleCreateDataset)
}

import React, { useState } from 'react'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'

import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { IconButton } from '../icon-button'

import { useTableStyles, MemoTableCell } from './Table'

const getItemStyle = (isDragging: any, draggableStyle: any) => {
  const styles: any = {}
  if (isDragging) {
    styles.borderTop = '1px solid #DCE0E6'
  }
  return {
    ...draggableStyle,
    ...styles,
  }
}

const getStyleForDetails = (header: any, detailsRef: any) => {
  if (header.width) {
    return { width: header.width }
  }
  if (header.id === 'details') {
    return {
      width:
        detailsRef.current !== null ? detailsRef.current.clientWidth : 'auto',
    }
  }
  return {}
}

export const CollapseRow = ({
  index,
  headerRow,
  bodyRows,
  row,
  tableClasses,
  customProps,
  hover,
  provided,
  snapshot,
  detailsRef,
  CollapseComponent,
}: any) => {
  const [open, setOpen] = useState(index === 0)
  const classes = useTableStyles()

  return (
    <>
      <TableRow
        key={row.id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        hover={hover}
        classes={{ selected: classes.selected }}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <TableCell
          {...provided.dragHandleProps}
          classes={{ root: tableClasses.cell.root }}
          style={{ width: '58px' }}
        >
          <DragIndicatorIcon style={{ color: '' }} />
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)} style={{ padding: 0 }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {bodyRows.map((key: any, i: number) => {
          const header = headerRow[i]
          if (typeof key === 'string') {
            return (
              // @ts-ignore
              <MemoTableCell
                key={`cell-${row.id}-${i}`}
                row={{ ...row }}
                accessor={key}
                tableClasses={tableClasses}
                style={header.width ? { width: header.width } : {}}
              >
                {() => row[key]}
              </MemoTableCell>
            )
          }
          const CustomElem = key
          return (
            // @ts-ignore
            <MemoTableCell
              key={`cell-${row.id}-${i}`}
              row={{ ...row }}
              tableClasses={tableClasses}
              customProps={{ ...customProps }}
              style={getStyleForDetails(header, detailsRef)}
            >
              {({ row }: any) => <CustomElem row={row} />}
            </MemoTableCell>
          )
        })}
      </TableRow>
      <TableRow key={`${row.id}-s`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CollapseComponent row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

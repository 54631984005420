import React from 'react'

export const DataSourceIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M37 14H11C10.4696 14 9.96086 14.2107 9.58579 14.5858C9.21071 14.9609 9 15.4696 9 16V20C9 20.5304 9.21071 21.0391 9.58579 21.4142C9.96086 21.7893 10.4696 22 11 22H37C37.5304 22 38.0391 21.7893 38.4142 21.4142C38.7893 21.0391 39 20.5304 39 20V16C39 15.4696 38.7893 14.9609 38.4142 14.5858C38.0391 14.2107 37.5304 14 37 14ZM15 20H11V16H15V20Z" />
    <path d="M37 26H11C10.4696 26 9.96086 26.2107 9.58579 26.5858C9.21071 26.9609 9 27.4696 9 28V32C9 32.5304 9.21071 33.0391 9.58579 33.4142C9.96086 33.7893 10.4696 34 11 34H37C37.5304 34 38.0391 33.7893 38.4142 33.4142C38.7893 33.0391 39 32.5304 39 32V28C39 27.4696 38.7893 26.9609 38.4142 26.5858C38.0391 26.2107 37.5304 26 37 26ZM15 32H11V28H15V32Z" />
  </svg>
)

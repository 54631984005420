import { ProjectActions } from '../actions'
import { trainingStatus } from '../constants'
import { QualityAndBiasActions } from '../biasAndQuality/actions'
import { ShareProjectActions } from '../shareProject/actions'
import { processStatus } from '../biasAndQuality/constants'
import { transformToUICouplings } from '../helper'

const defaultProjectState = {
  data: {},
  synthData: {},
  previewData: {},
  estimatedTime: null,
  status: trainingStatus.Init,
  preprocessingStatus: 'Please wait...',
  selectedTab: '',
  loadingDataset: 'regular',
  qualityStatus: processStatus.Init,
  qualityProgress: null,
  biasStatus: processStatus.Init,
  biasProgress: null,
  activeRow: null, // TODO temporary. delete when upgrade share modal
  notFoundPage: false,
}

export default (state = defaultProjectState, action) => {
  switch (action.type) {
    case ProjectActions.FETCH_DATASET:
      return {
        ...state,
        loadingDataset: 'saving',
      }

    case ProjectActions.DID_FETCH_DATASET:
      return {
        ...state,
        data: action.data,
        status: action.status || state.status,
        previewData: action.previewData || state.previewData,
        loadingDataset: 'regular',
      }

    case ProjectActions.DID_UPDATE_TITLE:
      return { ...state, data: { ...state.data, title: action.title } }

    case ProjectActions.DID_UPDATE_DATASET: {
      const newSettings = { ...action.settings }
      newSettings.couplings = transformToUICouplings(newSettings.couplings)
      return {
        ...state,
        data: {
          ...state.data,
          settings: { ...newSettings },
        },
      }
    }

    case ProjectActions.DID_UPDATE_DATASET_FAIL: {
      return { ...state }
    }

    case ProjectActions.DID_UPDATE_DATASET_COLUMN_NAME: {
      const newData = updateDatasetColumnName(state.data, action.names)
      const newPreviewData = updateDatasetColumnName(
        state.previewData,
        action.names
      )
      const newSynthData = updateDatasetColumnName(
        state.synthData,
        action.names
      )
      return {
        ...state,
        data: {
          ...state.data,
          settings: { ...state.data.settings, ...newData.settings },
          meta: {
            ...state.data.meta,
            columns: [...newData.meta.columns],
          },
          sample: [...newData.sample],
        },
        synthData: state.synthData.meta
          ? {
              ...state.synthData,
              meta: {
                ...state.synthData.meta,
                columns: [...newSynthData.meta.columns],
              },
              sample: [...newSynthData.sample],
            }
          : { ...state.synthData },
        previewData: state.previewData.meta
          ? {
              ...state.previewData,
              meta: {
                ...state.previewData.meta,
                columns: [...newPreviewData.meta.columns],
              },
            }
          : { ...state.synthData },
      }
    }

    case ProjectActions.DID_FETCH_SYNTH:
      return {
        ...state,
        synthData: action.data,
      }

    case ProjectActions.DID_FETCH_PREVIEW_DATA:
      return {
        ...state,
        previewData: action.data,
        estimatedTime: action.estimatedTime || state.estimatedTime,
        loadingDataset: action.loadingDataset || state.loadingDataset,
      }

    case ProjectActions.UPDATE_STATUS:
      return {
        ...state,
        status: action.status,
      }

    case ProjectActions.SET_PREPROCESSING_STATUS:
      return {
        ...state,
        preprocessingStatus: action.status,
      }

    case ProjectActions.UPDATE_TAB:
      return {
        ...state,
        selectedTab: action.id,
      }

    case ProjectActions.DID_STOP_TRAINING:
    case ProjectActions.DID_STOP_TRAINING_FAIL:
      return {
        ...state,
        status: trainingStatus.Init,
        synthData: {},
        estimatedTime: null,
      }

    case ProjectActions.START_TRAINING:
      return {
        ...state,
        status: trainingStatus.Training,
        previewData: {},
        estimatedTime: null,
      }

    case ProjectActions.DID_TRAINING:
      return { ...state, status: trainingStatus.Ready, estimatedTime: null }

    case ProjectActions.DID_TRAINING_FAIL:
      return {
        ...state,
        status: trainingStatus.Init,
        synthData: {},
        estimatedTime: null,
      }

    case ProjectActions.DID_LOADING_DATASET:
    case ProjectActions.DID_LOADING_DATASET_FAIL:
      return { ...state, loadingDataset: 'regular' }

    case ProjectActions.SET_NOT_FOUND_PAGE:
      return { ...state, notFoundPage: true }

    case ProjectActions.STOP_TRAINING:
      return { ...state }

    case QualityAndBiasActions.UPDATE_QUALITY_STATUS:
      return {
        ...state,
        qualityStatus: action.status,
        qualityProgress: action.progress,
      }

    case QualityAndBiasActions.DID_FETCH_QUALITY_DATA:
      return {
        ...state,
        qualityStatus: processStatus.Ready,
        qualityProgress: null,
        data: {
          ...state.data,
          meta: { ...state.data.meta, quality_data: action.qualityData },
        },
      }

    case QualityAndBiasActions.UPDATE_BIAS_STATUS:
      return {
        ...state,
        biasStatus: action.status,
        biasProgress: action.progress,
      }

    case QualityAndBiasActions.UPDATE_BIAS_STATUS_FAIL:
      return {
        ...state,
        biasStatus: processStatus.Fail,
        biasProgress: null,
      }

    case QualityAndBiasActions.DID_FETCH_BIAS_DATA:
      return {
        ...state,
        biasStatus: processStatus.Ready,
        biasProgress: null,
        data: {
          ...state.data,
          meta: { ...state.data.meta, fairness_data: action.biasData },
        },
      }

    // TODO temporary. delete when upgrade share modal
    case ShareProjectActions.DID_ACTIVE_ROW:
      return { ...state, activeRow: action.id }

    case ProjectActions.RESET_STATE:
      return defaultProjectState

    default:
      return state
  }
}

export const updateDatasetColumnName = (data, action) => {
  const dataCopy = JSON.parse(JSON.stringify(data))
  const disables_obj =
    Object.keys(dataCopy).length &&
    dataCopy.settings &&
    dataCopy.settings !== null
      ? dataCopy.settings
      : {}

  Object.keys(disables_obj).length &&
    Object.keys(disables_obj).forEach((f) => {
      if (Array.isArray(disables_obj[f]) && disables_obj[f].length) {
        const idx_dis = disables_obj[f].map((m) => m).indexOf(action.old_name)
        if (idx_dis !== -1) {
          disables_obj[f].splice(idx_dis, 1, action.new_name)
        }
      } else if (typeof disables_obj[f] === 'object') {
        const keys = Object.keys(disables_obj[f]).length
          ? Object.keys(disables_obj[f])
          : []
        if (keys.length) {
          const idx_dis = keys.map((m) => m).indexOf(action.old_name)
          if (idx_dis !== -1) {
            disables_obj[f][action.new_name] = disables_obj[f][action.old_name]
            delete disables_obj[f][action.old_name]
          }
        }
      }
    })

  if (dataCopy) {
    const idxColumn = dataCopy.meta
      ? dataCopy.meta.columns.findIndex((d) => d.name === action.old_name)
      : -1
    if (idxColumn !== -1) {
      dataCopy.meta.columns[idxColumn].name = action.new_name
    }
    if (dataCopy.sample) {
      dataCopy.sample.map((d) => {
        d[action.new_name] = d[action.old_name]
        delete d[action.old_name]
      })
    }
  }

  return dataCopy
}

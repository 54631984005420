import _ from 'lodash'
import * as d3 from 'd3-legacy'

export const line = (x, y) =>
  d3
    .line()
    .x((d) => {
      return x.current(d.x)
    })
    .y((d) => {
      return y.current(d.y)
    })
    .curve(d3.curveMonotoneX)

export const renderLineChart = ({
  g,
  x,
  y,
  density_arr,
  density_support,
  bandwidth,
}) => {
  g.current.select('.lines').selectAll('.line-group').remove()

  if (density_arr.length && density_support.length) {
    const combine_density = density_arr.map((d, i) => {
      const arr = density_support.map((dd, i) => {
        return { x: dd, y: d.values[i], color: d.color }
      })
      return arr
    })

    const _line = g.current
      .select('.lines')
      .selectAll('.line-group')
      .data(combine_density)
      .enter()
      .append('g')
      .attr('class', 'line-group')

    _line
      .append('path')
      .attr('class', 'line')
      .attr('transform', `translate(${bandwidth.current},0)`)
      .attr('d', (d) => line(x, y)(d))
      .style('fill', 'none')
      .style('stroke', 'white')
      .style('stroke-width', '3.5px')

    _line
      .append('path')
      .attr('class', 'line')
      .attr('transform', `translate(${bandwidth.current},0)`)
      .attr('d', (d) => line(x, y)(d))
      .style('fill', 'none')
      .style('stroke', (d, i) => (d.length && d[0].color ? d[0].color : 'none'))
      .style('stroke-width', '1.5px')
  }
}

import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { SandboxesBuildActions, didUnlockNextStep } from './actions'
import { getNextStep } from './sandboxBuildSelectors'
import watchSandboxesDatasetSaga from './dataset/sandboxDatasetSagas'
import watchSandboxConfirmSaga from './confirm/sandboxConfirmSagas'
import { Routes, pushRoute } from '../../../utils'

function* handleUnlockNextStep() {
  const nextStep = yield select(getNextStep)
  yield put(didUnlockNextStep(nextStep))
  yield call(pushRoute, Routes.CleanRoomBuildStep(nextStep))
}

export default function* watchSandboxBuildSaga() {
  yield takeLatest(SandboxesBuildActions.UNLOCK_NEXT_STEP, handleUnlockNextStep)
  yield all([watchSandboxesDatasetSaga(), watchSandboxConfirmSaga()])
}

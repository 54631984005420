import * as React from 'react'
import { Tabs as MUITabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Typography } from '../typography'

const useStyles = makeStyles({
  typography: {
    textTransform: 'uppercase',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rootTabs: {
    padding: '0 1.5rem',
    backgroundColor: '#eff0f6',
    minHeight: '44px',
    paddingTop: '.5rem',
  },
  rootTab: {
    marginRight: '.5rem',
    backgroundColor: '#fcfcff',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    minHeight: '36px',
    '& > span': {
      zIndex: 2,
    },
  },
  selected: {
    backgroundColor: '#fff',
  },
})

function Component({
  tabs,
  tabClasses = { root: {}, selected: {}, wrapper: {} },
  tabsClasses = { root: {}, indicator: {} },
  value = false,
  variant = 'standard',
  size = 'smallBold',
  children,
  ...restProps
}) {
  const classes = useStyles()
  return (
    <MUITabs
      indicatorColor="primary"
      variant={variant}
      classes={{
        root: classNames(classes.rootTabs, tabsClasses.root),
        indicator: tabsClasses.indicator,
      }}
      value={value}
      scrollButtons="off"
      {...restProps}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          value={tab.id}
          label={
            <Typography size={size} className={classes.typography}>
              {tab.label}
            </Typography>
          }
          classes={{
            root: classNames(tabClasses.root, tab.className),
            selected: tabClasses.selected,
            wrapper: tabClasses.wrapper,
          }}
          disabled={tab.disabled}
          style={{ pointerEvents: 'auto' }}
          icon={tab.icon ? tab.icon : null}
        />
      ))}
      {children}
    </MUITabs>
  )
}

export const Tabs = (props) => <Component {...props} />

import React, { FC, ReactNode, useState } from 'react'
import { Tabs, Spinner } from 'legacy/shared'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { notificationActions } from 'services/notification/reducer'
import classnames from 'classnames'

import { Button } from 'UIkit/Button/Button'
import { IStore } from 'core/rootReducer'
import { colors } from '../../shared/theme'
import { Invites } from './Invites'
import { Permissions } from './Permissions'
import { getDataset } from '../Projects/projectSelectors'
import { modalActions } from '../../../services/modal/reducer'

const pseudoStyles = {
  content: '""',
  position: 'absolute',
  width: '50%',
  height: '100%',
  zIndex: 1,
  background: colors.white,
  transformOrigin: '100% 0',
}

const button = {
  width: '76px',
  height: '36px',
}

const useStyles = makeStyles({
  modal: {},
  modalHeader: {
    padding: '20px',
  },
  modalBody: {
    minHeight: '250px',
    width: '560px',
  },
  rootTabs: {
    backgroundColor: '#eff0f6',
    minHeight: '40px',
    paddingTop: '.5rem',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '40px 20px',
  },
  actionsItem: {
    '&:first-child': {
      marginRight: '6px',
    },
  },
  rootTab: {
    minHeight: '36px',
    '& > span': {
      zIndex: 2,
    },
  },
  selected: {
    position: 'relative',
    '&::after': {
      ...pseudoStyles,
      left: 0,
      borderTopLeftRadius: '4px',
    },
    '&::before': {
      ...pseudoStyles,
      right: 0, // 13px
      borderTopRightRadius: '4px',
    },
  },
  indicator: {
    display: 'none',
  },
  buttonCancel: {
    ...button,
    color: '#69589B',
    background: '#FFFFFF',
    border: '1px solid #69589B',
    '&:hover': {
      color: '#69589B',
      background: '#FFFFFF',
      border: '1px solid #69589B',
    },
  },
  buttonAction: {
    ...button,
  },
  content: {},
  hideContent: {
    display: 'none',
  },
  tabContent: {
    padding: '20px',
  },
  spinnerContent: {
    width: '100%',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {},
})

interface ISharedModalProps {
  isLoading?: boolean
  error?: boolean
  project: any
  projectId: number
  hideModal: any
  showNotification: any
}

interface ITabContent {
  [key: string]: ReactNode
}

interface ITabActions {
  cancel: {
    disabled: boolean
    action: Function
    label: string
  }
  sumbit: {
    disabled: boolean
    action: Function
    label: string
  }
}

export const ShareModal: FC<ISharedModalProps> = ({
  project,
  hideModal,
  showNotification,
  projectId,
}) => {
  const classes = useStyles()
  const TABS = [
    { id: 'invites', label: 'Invites' },
    { id: 'permissions', label: 'Permissions' },
  ]

  const [startTab] = TABS

  const [activeTab, setValue] = useState(startTab.id)
  const [isLoading, setLoading] = useState(false)
  const [buttonActions, setButtonActions] = useState(null)

  const handleChangeTab = (_: any, newValue: string) => {
    setValue(newValue)
  }

  const handleLoading = (value: boolean) => {
    setLoading(value)
  }

  const handleSetActions = (actions: ITabActions) => {
    setButtonActions(actions)
  }

  const commonProps = {
    handleLoading,
    handleSetActions,
    projectId,
    project,
    hideModal,
    showNotification,
  }

  const tabContent: ITabContent = {
    invites: <Invites {...commonProps} />,
    permissions: <Permissions {...commonProps} />,
  }

  return (
    <div className={classes.modal}>
      <h5 className={classes.modalHeader}>
        {isLoading ? 'Loading...' : 'Share data product'}
      </h5>
      <div className={classes.modalBody}>
        {isLoading && (
          <div className={classes.spinnerContent}>
            <Spinner size={44} className={classes.spinner} />
          </div>
        )}
        <div
          className={classnames(classes.content, {
            [classes.hideContent]: isLoading,
          })}
        >
          <Tabs
            tabs={TABS}
            onChange={handleChangeTab}
            value={activeTab as any}
            tabsClasses={{
              root: classes.rootTabs,
              indicator: classes.indicator,
            }}
            tabClasses={{
              wrapper: {},
              root: classes.rootTab,
              selected: classes.selected,
            }}
          />
          <div className={classes.tabContent}>{tabContent[activeTab]}</div>
        </div>
      </div>
      {buttonActions && (
        <div className={classes.actionsContainer}>
          <div className={classes.actionsItem}>
            <Button onClick={hideModal} className={classes.buttonCancel}>
              Cancel
            </Button>
          </div>
          <div className={classes.actionsItem}>
            <Button
              loading={buttonActions.submit.loading}
              disabled={buttonActions.submit.disabled}
              onClick={buttonActions.submit.action}
              className={classes.buttonAction}
            >
              {buttonActions.submit.label}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: IStore) => ({
  project: getDataset(state),
})

const mapDispatchToProps = {
  hideModal: modalActions.hide,
  showNotification: notificationActions.showNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal)

import { FairnessDataMetafield } from '../../../../api/generated/modelPkg'

export const getTotalScore = (
  biasData: FairnessDataMetafield
): number | null => {
  return biasData && typeof biasData.dist_score === 'number'
    ? Number(biasData.dist_score.toFixed(2)) * 100
    : null
}

export const getBiasTarget = (biasData: FairnessDataMetafield) =>
  // @ts-ignore
  // update FairnessDataMetafield interface
  biasData?.target || '-'

export const getIsSignificantBias = (biasData: FairnessDataMetafield) => {
  // @ts-ignore
  // update FairnessDataMetafield interface
  return biasData?.dist_biases?.count?.length || false
}

export const getDistBiases = (biasData: FairnessDataMetafield) => {
  return biasData &&
    biasData.dist_biases &&
    Object.keys(biasData.dist_biases).length
    ? biasData.dist_biases
    : {}
}

export const getDistBiasesToDrop = (biasData: FairnessDataMetafield) => {
  // @ts-ignore
  // TODO update FairnessDataMetafield interface
  return biasData?.dist_biases_to_drop || {}
}

export const getBiasMitigationFailed = (biasData: FairnessDataMetafield) => {
  // @ts-ignore
  // TODO update FairnessDataMetafield interface
  return biasData?.bias_mitigation_failed || false
}

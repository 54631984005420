import * as React from 'react'
import { Typography as MUITypography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { colors } from '../../theme'

export const typographyColorStyles = {
  black: {
    color: colors.black,
  },
  white: {
    color: colors.white,
  },
  red: {
    color: colors.red,
  },
  blueRoyal: {
    color: colors.blueRoyal,
  },
  lightGrey: {
    color: colors.lightGrey,
  },
  projectStatistics: {
    color: colors.projectStatistics,
  },
  freeTierPage: {
    color: colors.freeTierPage,
  },
  tourLightGrey: {
    color: '#9499B3',
  },
  tourGrey: {
    color: '#53556D',
  },
}

export const typographySizesStyles = {
  extraSmall: {
    fontSize: 10,
    fontWeight: 400,
  },
  extraSmallBold: {
    fontSize: 10,
    fontWeight: 600,
  },
  small: {
    fontSize: 12,
    fontWeight: 400,
  },
  smallBold: {
    fontSize: 12,
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
    fontWeight: 400,
  },
  captionBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  body: {
    fontSize: 16,
    fontWeight: 400,
  },
  bodyBold: {
    fontSize: 16,
    fontWeight: 600,
  },
  body2: {
    fontSize: 18,
    fontWeight: 400,
  },
  subheader: {
    fontSize: 18,
    fontWeight: 400,
  },
  subheaderBold: {
    fontSize: 18,
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 22,
    fontWeight: 400,
  },
}

const useTypographyStyles = makeStyles({
  root: {
    letterSpacing: 0,
    lineHeight: 'normal',
    textTransform: 'none',
    textDecoration: 'none',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  ...typographySizesStyles,
  ...typographyColorStyles,
})

const Component = ({
  children,
  size = 'body',
  color,
  variant,
  className,
  noWrap = false,
}) => {
  const classes = useTypographyStyles()
  const typographyClassName = classnames(
    classes.root,
    classes[size],
    classes[color],
    className
  )
  return (
    <MUITypography
      className={typographyClassName}
      variant={variant}
      noWrap={noWrap}
    >
      {children}
    </MUITypography>
  )
}

export const Typography = (props) => <Component {...props} />

import React from 'react'
import { Box } from '@material-ui/core'

export default ({ children }: any) => {
  return (
    <Box
      component="main"
      style={{ overflow: 'auto', width: '100%', position: 'relative' }}
    >
      {children}
    </Box>
  )
}

import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStylesCell = makeStyles(() => ({
  cell: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cellBefore: {
    marginRight: '8px',
  },
  cellAfter: {
    marginLeft: '8px',
  },
  cellContent: {},
  cellContentWrapper: {
    textAlign: 'left' as const,
  },
  cellDescription: {
    fontSize: '12px',
    lineHeight: '15px',
    color: '#9F9CB2',
  },
}))

interface ISimpleCellProps {
  className?: string
  before?: React.ReactNode
  after?: React.ReactNode
  description?: React.ReactNode
  classes?: any
}

export const SimpleCell: FC<ISimpleCellProps> = ({
  className,
  before,
  after,
  description,
  children,
}) => {
  const styles = useStylesCell()
  return (
    <div className={cn(styles.cell, className)}>
      <div className={styles.cellBefore}>{before}</div>
      <div className={styles.cellContentWrapper}>
        <div className={styles.cellContent}>{children}</div>
        <div className={styles.cellDescription}>{description}</div>
      </div>
      <div className={styles.cellAfter}>{after}</div>
    </div>
  )
}

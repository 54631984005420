import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'UIkit/Button/Button'

import HeaderComponent from 'layouts/Header'
import { notificationActions } from 'services/notification/reducer'
import { dataSourceService } from 'legacy/utils/services'
import User from '../Account/User'
import {
  actionInitQueryData,
  actionInitSourceTables,
  actionResetQueryData,
  actionSetActiveBlock,
  actionStartLoadingDatasource,
  actionStopLoadingDatasource,
  actionTabsSidebar,
} from '../../store/actions'
import { fetchDataSource } from './actions'
import SourceDragDrop from './SourceDragDrop'
import QueryBuilder from '../../../pages/DataSources/QueryBuilder'
import LoaderComponent from '../Projects/report/LoaderComponent'

import '../Datasets/DatasetsTablePage.scss'
import './Source.scss'

const useStyles = makeStyles({
  headerRoot: {
    position: 'relative',
  },
})

const Source = (props) => {
  const { query_data, loadingDatasource, match } = props
  const source_param_id = +match.params.id
  const classes = useStyles()

  useEffect(() => {
    props.actionTabsSidebar({ big_tab: 4, value: null })
    props.actionSetActiveBlock(null)
    props.fetchDataSource(source_param_id)
  }, [])

  const handleResetQueryData = () => {
    const idx_curr_query = JSON.parse(User.queries)
      .map((d) => d.datasource_id)
      .indexOf(source_param_id)

    props.actionStartLoadingDatasource()
    dataSourceService
      .updateQuerySettings({
        id: JSON.parse(User.queries)[idx_curr_query].query_id,
        data: { settings: { columns: [] } },
      })
      .then(() => {
        props.actionResetQueryData()
        props.actionStopLoadingDatasource()
      })
      .catch((error) => {
        props.showNotification({
          message: error.message,
          severity: 'error',
        })
        props.actionStopLoadingDatasource()
      })
  }

  return (
    <div className="row m-0 source">
      <div
        className="col col-12 p-0 d-flex"
        style={{ flexDirection: 'column', height: '100%' }}
      >
        <HeaderComponent
          title="Datasources"
          subTitle={`Datasource_${source_param_id}`}
          backPath="/datasources"
          classNames={{ root: classes.headerRoot }}
        >
          {loadingDatasource !== 0 ? <LoaderComponent /> : null}
          {Object.keys(query_data).length && query_data.meta !== null ? (
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
            >
              <Button onClick={() => handleResetQueryData()}>Reset</Button>
            </div>
          ) : null}
        </HeaderComponent>
        <div
          className="uk-text-center uk-flex uk-flex-row uk-flex-nowrap custom_height_style source_content"
          style={loadingDatasource !== 0 ? { pointerEvents: 'none' } : {}}
          data-uk-grid
        >
          <SourceDragDrop />
          <QueryBuilder />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { query_data, loadingDatasource } = state.data
  return {
    ...state,
    query_data: query_data || {},
    loadingDatasource,
  }
}

const mapDispatchToProps = {
  actionTabsSidebar,
  actionSetActiveBlock,
  actionInitSourceTables,
  actionInitQueryData,
  actionResetQueryData,
  actionStartLoadingDatasource,
  actionStopLoadingDatasource,
  fetchDataSource,
  showNotification: notificationActions.showNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Source))

import React from 'react'

import { IStore } from 'core/rootReducer'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import User from 'legacy/components/Account/User'
import _ from 'legacy/components/App/Constants'
import { Menu, Typography } from 'legacy/shared'
import { Routes, pushRoute } from 'legacy/utils'

import { getIsTourEnabled } from 'legacy/components/ProductTour/selectors'
import { productTourActions } from 'legacy/components/ProductTour/reducer'
import {
  selectProfileImageUrl,
  selectProfileLoadingState,
} from 'services/profile/selectors'

import { signInActions } from 'services/auth/signIn/reducer'

interface IMenuOption {
  text: string
  onClick: () => void
}

interface ISelectedProps {
  imageUrl: ReturnType<typeof selectProfileImageUrl>
  isTourEnabled: ReturnType<typeof getIsTourEnabled>
  isProfileFormLoading: boolean
}

type IHeaderMenuProps = ISelectedProps & typeof mapDispatchToProps

const useStyles = makeStyles({
  menu: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
    marginLeft: '1rem',
    borderLeft: '1px solid #e0e4ed',
    cursor: 'pointer',
  },
  communityEdition: {
    border: '1px solid #fb0;',
    borderRadius: '4px',
    background: '#fffcef',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
})

const generalOptions = (signOut: () => void): IMenuOption[] => [
  {
    text: 'Profile',
    onClick: () => pushRoute(Routes.Profile()),
  },
  {
    text: 'Logout',
    onClick: () => signOut(),
  },
]

const inviteGenerationOption = (): IMenuOption | null => {
  if (User.isAdmin() && _.IS_INVITES_ENABLED) {
    return {
      text: 'Get Invite Code',
      onClick: () => {
        pushRoute(Routes.InviteCode())
      },
    }
  }
  return null
}

const restartProductTout = (
  isTourEnabled: boolean,
  restart: () => void
): IMenuOption | null => {
  if (!isTourEnabled) {
    return {
      text: 'Start Product Tour',
      onClick: () => restart(),
    }
  }
  return null
}

const ProfileInfo: React.FC<{ imageUrl?: string }> = ({ imageUrl }) => {
  const classes = useStyles()

  const firstName = User.first_name ? User.first_name : ''
  const lastName = User.last_name ? User.last_name : ''
  const profileNameOrEmail =
    firstName || lastName ? `${firstName} ${lastName}` : User.email
  return (
    <>
      {imageUrl && (
        <img
          src={imageUrl}
          alt=""
          width="38"
          height="38"
          style={{ width: '38px', height: '38px', borderRadius: '50%' }}
        />
      )}
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
        >
          <Typography noWrap>{profileNameOrEmail}</Typography>
          <ExpandMoreIcon fontSize="small" />
        </div>
        <div
          style={{
            marginLeft: '1rem',
            marginRight: '1rem',
            marginTop: '4px',
            marginBottom: '4px',
          }}
        >
          {User.isCETier() && (
            <Typography
              className={classes.communityEdition}
              size="small"
              noWrap
            >
              Community Edition
            </Typography>
          )}
        </div>
      </div>
    </>
  )
}

export const HeaderMenu: React.FC<IHeaderMenuProps> = ({
  imageUrl,
  isTourEnabled,
  // TODO when user info will be moved in redux store delete isProfileFormLoading
  // eslint-disable-next-line
  isProfileFormLoading,
  //
  signOut,
  restart,
}) => {
  const classes = useStyles()
  return (
    <div>
      <Menu
        items={[
          ...generalOptions(signOut),
          restartProductTout(isTourEnabled, restart),
          inviteGenerationOption(),
        ].filter(Boolean)}
        renderTriggerComponent={({ showMenu }: any) => (
          <div onClick={showMenu} className={classes.menu}>
            <ProfileInfo imageUrl={imageUrl} />
          </div>
        )}
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector<IStore, ISelectedProps>({
  imageUrl: selectProfileImageUrl,
  isTourEnabled: getIsTourEnabled,
  isProfileFormLoading: selectProfileLoadingState,
})

const mapDispatchToProps = {
  signOut: signInActions.signOut,
  restart: productTourActions.restart,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu)
